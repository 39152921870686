import { UploadFileRounded } from '@mui/icons-material';
import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import { useIndexedDB } from 'react-indexed-db';
import { useDispatch } from 'react-redux';
import { updateBackgroundIndexDBArrayAction } from '../../../store/reducers/backgroundReducer/action';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import './UploadBackgroundDropZone.css';

export default function UploadBackgroundDropZone(props) {
  const { backgroundItem, index } = props;
  const { add, getByID, getByIndex, deleteRecord } = useIndexedDB('background');
  const dispatch = useDispatch();

  const getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    meaVanaConsole('BackgroundDropZoneUploader handleChangeStatus');
    meaVanaConsole(status, meta, file);
    if (status === 'headers_received') {
      meaVanaConsole('stat adding');
      addToIndexDB(file);
    } else if (status === 'aborted') {
    }
  };

  const addToIndexDB = (file) => {
    var reader = new FileReader();

    //reader.readAsBinaryString(file);
    reader.readAsDataURL(file);

    reader.onload = function (e) {
      //alert(e.target.result);
      let bits = e.target.result;
      let ob = {
        name: backgroundItem.name,
        created: new Date(),
        updated: new Date(),
        type: file.type,
        data: bits
      };
      add(ob).then(
        (event) => {
          meaVanaConsole('addToIndexDB success ID Generated: ', event);
          dispatch(
            updateBackgroundIndexDBArrayAction(index, {
              ...backgroundItem,
              uploaded: false,
              created: new Date(),
              updated: new Date()
            })
          );
          props.getBackgroundFromIndexDB();
        },
        (error) => {
          meaVanaConsole('addToIndexDB error :', error);
        }
      );
    };
  };

  const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length > 0 ? 'Add more files' : 'Choose files';

    return (
      <div className="wallpaper-upload-background-drop-file-input">
        <div className="wallpaper-upload-background-drop-file-input__label">
          <UploadFileRounded fontSize="large" />
          <p>Click to import file or Drag & Drop your file here</p>
        </div>
        <input
          type="file"
          accept={accept}
          onChange={(e) => {
            meaVanaConsole('inside input onchange : ', getFilesFromEvent(e));
            onFiles(getFilesFromEvent(e));
          }}
        />
      </div>
    );
    /*
      <label
        style={{
          backgroundColor: '#007bff',
          color: '#fff',
          cursor: 'pointer',
          padding: 15,
          borderRadius: 3
        }}>
        {text}
        <input
          style={{ display: 'none' }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
  */
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      inputContent={(files, extra) =>
        extra.reject ? 'Image, audio and video files only' : 'Drag Files'
      }
      accept="image/*"
      InputComponent={Input}
      styles={{
        dropzone: {
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '0.5em'
        },
        dropzoneActive: {
          borderColor: 'green'
        },
        dropzoneReject: { borderColor: 'red' },
        inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {})
      }}
    />
  );
}
