import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";
import { updateLoggedInSateAction } from "../appReducer/action";
import { store } from "../../store";
import { authStateAction } from "./constants";

// action login request
export const loginRequestAction = (user) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_REQUEST, user: user });
  };
};

// action login success
export const loginSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_SUCCESS, payload: data });
  };
};

// action login failure
export const loginFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_FAILURE, error: error });
  };
};

// action updateUserInfo request
export const updateUserInfoRequestAction = (user) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.UPDATE_USER_INFO_REQUEST, user: user });
  };
};

// action updateUserInfo success
export const updateUserInfoSuccessAction = (data, newUser) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.UPDATE_USER_INFO_SUCCESS,
      payload: { data: data, newUser: newUser }
    });
  };
};

// action updateUserInfo failure
export const updateUserInfoFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.UPDATE_USER_INFO_FAILURE, error: error });
  };
};

// action deleteAccount request
export const deleteAccountRequestAction = (password) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.DELETE_ACCOUNT_REQUEST,
      payload: password
    });
  };
};

// action deleteAccount success
export const deleteAccountSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.DELETE_ACCOUNT_SUCCESS, payload: data });
  };
};

// action deleteAccount failure
export const deleteAccountFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.DELETE_ACCOUNT_FAILURE, error: error });
  };
};
// action disableAccount request
export const disableAccountRequestAction = (password) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.DISABLE_ACCOUNT_REQUEST,
      payload: password
    });
  };
};

// action disableAccount success
export const disableAccountSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.DISABLE_ACCOUNT_SUCCESS, payload: data });
  };
};

// action disableAccount failure
export const disableAccountFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.DISABLE_ACCOUNT_FAILURE, error: error });
  };
};

// action updateUserPassword request
export const updateUserPasswordRequestAction = (newUserPassword) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.UPDATE_USER_PASSWORD_REQUEST,
      payload: newUserPassword
    });
  };
};

// action updateUserPassword success
export const updateUserPasswordSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.UPDATE_USER_PASSWORD_SUCCESS,
      payload: data
    });
  };
};

// action updateUserPassword failure
export const updateUserPasswordFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.UPDATE_USER_PASSWORD_FAILURE,
      error: error
    });
  };
};

// action loginSSOGoogle request
export const loginSSOGoogleRequestAction = (user = {}) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_SSO_GOOGLE_REQUEST, user: user });
  };
};

// action loginSSOGoogle success
export const loginSSOGoogleSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_SSO_GOOGLE_SUCCESS, payload: data });
  };
};

// action loginSSOGoogle failure
export const loginSSOGoogleFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_SSO_GOOGLE_ERROR, error: error });
  };
};

// action loginSSOFacebook request
export const loginSSOFacebookRequestAction = (user = {}) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_SSO_FACEBOOK_REQUEST, user: user });
  };
};

// action loginSSOFacebook success
export const loginSSOFacebookSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.LOGIN_SSO_FACEBOOK_SUCCESS,
      payload: data
    });
  };
};

// action loginSSOFacebook failure
export const loginSSOFacebookFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.LOGIN_SSO_FACEBOOK_ERROR, error: error });
  };
};

// action reset password request
export const resetPasswordRequestAction = (user) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.RESET_PASSWORD_REQUEST, user: user });
  };
};

// action reset password success
export const resetPasswordSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.RESET_PASSWORD_SUCCESS, payload: data });
  };
};

// action reset password failure
export const resetPasswordFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.RESET_PASSWORD_FAILURE, error: error });
  };
};

// action forgot password request
export const forgotPasswordRequestAction = (email) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.FORGOT_PASSWORD_REQUEST, email: email });
  };
};

// action forgot password success
export const forgotPasswordSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.FORGOT_PASSWORD_SUCCESS, payload: data });
  };
};

// action forgot password failure
export const forgotPasswordFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.FORGOT_PASSWORD_FAILURE, error: error });
  };
};

// action verifay email forgot password request
export const VerifyEmailforgotPasswordRequestAction = (code) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.FORGOT_PASSWORD_VERIFY_EMAIL_REQUEST,
      code: code
    });
  };
};

// action verifay email forgot password success
export const VerifyEmailforgotPasswordSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.FORGOT_PASSWORD_VERIFY_EMAIL_SUCCESS,
      payload: data
    });
  };
};

// action verifay email forgot password failure
export const VerifyEmailforgotPasswordFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.FORGOT_PASSWORD_VERIFY_EMAIL_FAILURE,
      error: error
    });
  };
};

export const LogoutAction = () => {
  return (dispatch) => {
    dispatch(updateLoggedInSateAction(false));
    dispatch({ type: authStateAction.LOGOUT });
  };
};

export const updateUserTokensAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.UPDATE_USER_TOKEN, payload: data });
  };
};

//reset forget password state
export const resetForgotPasswordStateAction = () => {
  return (dispatch) => {
    dispatch({ type: authStateAction.RESET_FORGOT_PASSWORD_STATE });
  };
};

// reset auth state params
export const resetAuthAction = () => {
  return (dispatch) => {
    dispatch({ type: authStateAction.RESET_AUTH_STATE });
  };
};
// reset auth state params
export const resetProfileStateAction = () => {
  return (dispatch) => {
    dispatch({ type: authStateAction.RESET_PROFILE_ACTIONS });
  };
};

export const activeAuthenticationLoading = () => {
  return (dispatch) => {
    dispatch({ type: authStateAction.ACTIVE_AUTHENTICATION_LOADING });
  };
};
export const desactiveAuthenticationLoading = () => {
  return (dispatch) => {
    dispatch({ type: authStateAction.DESACTIVE_AUTHENTICATION_LOADING });
  };
};

export const checkOldUser = (email) => {
  const stateAuth = store.getState().auth;
  meaVanaConsole("old user :", stateAuth.oldUser);
  if (stateAuth.oldUser && stateAuth.oldUser.email !== email) {
    meaVanaConsole("RESET_SETTINGS_DEFAULT");
    // store.dispatch({ type: RESET_SETTINGS_DEFAULT });
  }
};

// action register request
export const registerRequestAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: authStateAction.REGISTER_REQUEST,
      user: user
    });
  };
};

// action register success
export const registerSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.REGISTER_SUCCESS, payload: data });
  };
};

// action register failure
export const registerFailureAction = (error) => {
  return (dispatch) => {
    dispatch({ type: authStateAction.REGISTER_FAILURE, error: error });
  };
};

export const addUserSessionToLogRocket = (user) => {
  /*
import LogRocket from 'logrocket';
LogRocket.init('meavana/meavana-extension-log');

*/
  /*  LogRocket.identify(user.dashboard._id, {
      name: user.username,
      email: user.email,
      signupDate: user.signupDate,
    });*/
};
