import { OPENWEATHERMAP_API_KEY } from "../../config/constants";
import {
  updateCoordsSearchCity,
  updateEditingCity,
  updateHourForcastWeatherFailureAction,
  updateHourForcastWeatherRequestAction,
  updateHourForcastWeatherSuccessAction,
  updateSearchCity,
  updateTodayWeatherAction,
  updateTodayWeatherFailureAction,
  updateTodayWeatherInfoFailureAction,
  updateTodayWeatherInfoRequestAction,
  updateTodayWeatherInfoSuccessAction,
  updateTodayWeatherRequestAction,
  updateTodayWeatherSuccessAction,
  updateWeatherUSTempUnit,
  updateWeekForcastWeatherFailureAction,
  updateWeekForcastWeatherSuccessAction,
  updateWeekWeatherAction
} from "../../store/reducers/weatherReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import axios from "axios";
import { checkErrorResponseFormat } from "../Middleware/utils.server";

export const getByCityTodayWeatherApiOpenWeatherMap = (
  city,
  firstTimeUsingWeather
) => {
  return async (dispatch) => {
    try {
      dispatch(updateTodayWeatherRequestAction());
      axios
        .get(
          `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&APPID=${OPENWEATHERMAP_API_KEY}`
        )
        .then((response) => {
          meaVanaConsole(
            "response getByCityTodayWeatherApiOpenWeatherMap :",
            response.data
          );

          if (firstTimeUsingWeather && response.data.sys.country === "US") {
            dispatch(updateWeatherUSTempUnit());
          }

          dispatch(
            updateTodayWeatherSuccessAction(
              todayWeatherDataFormating(response.data)
            )
          );
          dispatch(updateEditingCity(false));
          //  dispatch(updateSearchCity(""));

          const coords = {
            latitude: response.data.coord.lat,
            longitude: response.data.coord.lon
          };
          dispatch(updateCoordsSearchCity(coords));
          dispatch(getWeekWeatherApiOpenWeatherMap(coords));
        })
        .catch((error1) => {
          meaVanaConsole(
            "getByCityTodayWeatherApiOpenWeatherMap err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(
            updateTodayWeatherFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "getByCityTodayWeatherApiOpenWeatherMap err",
        checkErrorResponseFormat(error)
      );
      dispatch(
        updateTodayWeatherFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

export const getTodayWeatherApiOpenWeatherMap = (
  coords,
  firstTimeUsingWeather
) => {
  return async (dispatch) => {
    try {
      dispatch(updateTodayWeatherRequestAction());
      axios
        .get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&APPID=${OPENWEATHERMAP_API_KEY}`
        )
        .then((response) => {
          meaVanaConsole(
            "response getTodayWeatherApiOpenWeatherMap :",
            response.data
          );

          if (firstTimeUsingWeather && response.data.sys.country === "US") {
            dispatch(updateWeatherUSTempUnit());
          }

          dispatch(
            updateTodayWeatherSuccessAction(
              todayWeatherDataFormating(response.data)
            )
          );
        })
        .catch((error1) => {
          meaVanaConsole(
            "getTodayWeatherApiOpenWeatherMap err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(
            updateTodayWeatherFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "getTodayWeatherApiOpenWeatherMap err",
        checkErrorResponseFormat(error)
      );
      dispatch(
        updateTodayWeatherFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

export const getWeekWeatherApiOpenWeatherMap = (coords) => {
  return async (dispatch) => {
    try {
      dispatch(updateTodayWeatherInfoRequestAction());
      dispatch(updateHourForcastWeatherRequestAction());
      dispatch(updateWeekForcastWeatherFailureAction());

      axios
        .get(
          `https://api.openweathermap.org/data/2.5/onecall?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&APPID=${OPENWEATHERMAP_API_KEY}`
        )
        .then((response) => {
          meaVanaConsole(
            "response getWeekWeatherApiOpenWeatherMap :",
            response.data
          );

          let hourlyWeather = response.data.hourly;
          hourlyWeather.splice(24, 24);

          let weekWeather = response.data.daily;
          let todayWeatherInfo = response.data.current;
          // weekWeather.splice(7, 1);
          dispatch(updateTodayWeatherInfoSuccessAction(todayWeatherInfo));
          dispatch(updateHourForcastWeatherSuccessAction(hourlyWeather));
          dispatch(updateWeekForcastWeatherSuccessAction(weekWeather));
        })
        .catch((error1) => {
          meaVanaConsole(
            "getWeekWeatherApiOpenWeatherMap err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(
            updateTodayWeatherInfoFailureAction(
              checkErrorResponseFormat(error1)
            )
          );
          dispatch(
            updateHourForcastWeatherFailureAction(
              checkErrorResponseFormat(error1)
            )
          );
          dispatch(
            updateWeekForcastWeatherFailureAction(
              checkErrorResponseFormat(error1)
            )
          );
        });
    } catch (error) {
      meaVanaConsole(
        "getWeekWeatherApiOpenWeatherMap err",
        checkErrorResponseFormat(error)
      );
      dispatch(
        updateTodayWeatherInfoFailureAction(checkErrorResponseFormat(error))
      );
      dispatch(
        updateHourForcastWeatherFailureAction(checkErrorResponseFormat(error))
      );
      dispatch(
        updateWeekForcastWeatherFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

const todayWeatherDataFormating = (data) => {
  return {
    id: data.id,
    cod: data.cod,
    base: data.base,
    timezone: data.timezone,
    date: data.date,
    location: {
      name: data.name,
      country: data.sys.country,
      coord: data.coord,
      sunrise: data.sys.sunrise,
      sunset: data.sys.sunset,
      type: data.sys.type,
      id: data.sys.id
    },
    weather: {
      id: data.weather[0].id,
      description: data.weather[0].description,
      name: data.weather[0].main,
      icon: data.weather[0].icon,
      temp: data.main.temp,
      tempMax: data.main.temp_max,
      tempMin: data.main.temp_min,
      feelsLike: data.main.feels_like
    }
  };
};
