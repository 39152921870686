import { combineReducers } from 'redux';
import { weatherPersisted } from './weatherReducer/reducer';
import { searchEnginePersisted } from './searchEngineReducer/reducer';
import { bookmarkPersisted } from './bookmarksReducer/reducer';
import { authPersisted } from './authReducer/reducer';
import { backgroundPersisted } from './backgroundReducer/reducer';
import { logsPersisted } from './logsReducer/reducer';
import { notePersisted } from './notesReducer/reducer';
import { dashboardPersisted } from './dashboardReducer/reducer';
import { todoPersisted } from './todoReducer/reducer';
import { clockPersisted } from './clockReducer/reducer';
import { appPersisted } from './appReducer/reducer';
import { homePersisted } from './homeReducer/reducer';
import { integrationPersisted } from './integrationsReducer/reducer';
import { newsPersisted } from './newsReducer/reducer';
import { animationPersisted } from './animReducer/reducer';
import { youtubePersisted } from './youtubeReducer/reducer';
import { unsplashPersisted } from './unsplashReducer/reducer';
import { musicPersisted } from './musicReducer/reducer';
import { cryptoPersisted } from './cryptoReducer/reducer';
import { picturesPersisted } from './picturesReducer/reducer';
import { videoPersisted } from './VideoReducer/reducer';
import { coincapPersisted } from './coincapReducer/reducer';
import { analyticsPersisted } from './analyticsReducer/reducer';
import { wallpaperPersisted } from './wallpaperReducer/reducer';
import { timerPersisted } from './timerReducer/reducer';
import { alarmPersisted } from './alarmReducer/reducer';
import { googlePersisted } from './googleReducer/reducer';

export default combineReducers({
  alarms: alarmPersisted /**blacklist from global store perist */,
  //analytics: analyticsPersisted /**blacklist from global store perist */,
  animation: animationPersisted /**blacklist from global store perist */,
  app: appPersisted,
  auth: authPersisted,
  background: backgroundPersisted,
  bookmarks: bookmarkPersisted,
  clock: clockPersisted,
  coincap: coincapPersisted /**blacklist from global store perist */,
  crypto: cryptoPersisted /**blacklist from global store perist */,
  dashboard: dashboardPersisted,
  home: homePersisted,
  integration: integrationPersisted /**blacklist from global store perist */,
  // logs: logsPersisted /**blacklist from global store perist */,
  music: musicPersisted /**blacklist from global store perist */,
  news: newsPersisted /**blacklist from global store perist */,
  notes: notePersisted,
  pictures: picturesPersisted /**blacklist from global store perist */,
  searchEngine: searchEnginePersisted /**blacklist from global store perist */,
  timer: timerPersisted /**blacklist from global store perist */,
  todos: todoPersisted,
  unsplash: unsplashPersisted /**blacklist from global store perist */,
  video: videoPersisted /**blacklist from global store perist */,
  wallpaper: wallpaperPersisted /**blacklist from global store perist */,
  weather: weatherPersisted /**blacklist from global store perist */,
  youtube: youtubePersisted /**blacklist from global store perist */,
  google: googlePersisted /**blacklist from global store perist */
});
