import axios from "axios";
import { APP_ENV, SERVER_URL } from "../../config/constants";
import {
  getAllTopicFailure,
  getAllTopicRequest,
  grtAllTopicSuccess,
  updateSelectedTopicAction,
  updateSelectedTopicFailureAction,
  updateSelectedTopicRequestAction,
  updateSelectedTopicSuccessAction
} from "../../store/reducers/backgroundReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import axiosInstance from "../Middleware/axiosInstance";
import { checkErrorResponseFormat } from "../Middleware/utils.server";
import { dashbordDataSetUpMultipleDashbords } from "./dashboard.server";

export const getAllTopic = (page = 1) => {
  return async (dispatch) => {
    try {
      let prams = "";
      if (APP_ENV === "development") {
        prams = "?page=" + page + "&sortby=usersCount&order=descending";
      } else {
        prams = "?page=" + page + "&sortby=name&order=ascending";
      }
      dispatch(getAllTopicRequest());
      axios
        .get(SERVER_URL + "topic/get-all" + prams)
        .then((response) => {
          meaVanaConsole("getAllTopic response: ", response.data);

          dispatch(grtAllTopicSuccess(response.data.topics));

          if (APP_ENV === "production" && response.data.meta.pages > page) {
            dispatch(getAllTopic(page + 1));
          }
        })
        .catch((error1) => {
          meaVanaConsole("getAllTopic err1 ", checkErrorResponseFormat(error1));

          dispatch(getAllTopicFailure(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("getAllTopic err2", checkErrorResponseFormat(error));

      dispatch(getAllTopicFailure(checkErrorResponseFormat(error)));
    }
  };
};

export const selectTopicService = (
  topicId,
  dashboardId,
  topicStyling = false
) => {
  return async (dispatch) => {
    try {
      dispatch(updateSelectedTopicRequestAction(topicId));

      axiosInstance()
        .put(SERVER_URL + "user/add-topic-to-user-dashboard/", {
          dashboardId: dashboardId,
          topics: [topicId]
        })
        .then((response) => {
          meaVanaConsole("selectTopicService response: ", response.data);

          dispatch(
            dashbordDataSetUpMultipleDashbords(
              response.data,
              topicId,
              topicStyling
            )
          );
          dispatch(updateSelectedTopicSuccessAction());
        })
        .catch((error1) => {
          meaVanaConsole(
            "selectTopicService err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            updateSelectedTopicFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "selectTopicService err2",
        checkErrorResponseFormat(error)
      );

      dispatch(
        updateSelectedTopicFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

/*
export const updateBackgroundAuthAction = (dashbord) => {
  return async (dispatch) => {
    try {
      dispatch(updateBackgroundRequest());
      axiosInstance()
        .put(SERVER_URL + "dashboard/" + dashbord._id + "/update-background")
        .then((response) => {
          meaVanaConsole(
            "updateBackgroundAuthAction response: ",
            response.data
          );

          dispatch(
            updateBackgroundSuccess(
              response.data.backgroundImage,
              response.data.backgroundImage.quotes[0]
            )
          );
        })
        .catch((error1) => {
          meaVanaConsole(
            "updateBackgroundAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(updateBackgroundFailure(checkErrorResponseFormat(error1)));

          dispatch(updateAppStateLoadingAction(false));
        });
    } catch (error) {
      meaVanaConsole(
        "updateBackgroundAuthAction err2",
        checkErrorResponseFormat(error)
      );
      dispatch(updateBackgroundFailure(checkErrorResponseFormat(error)));
      dispatch(updateAppStateLoadingAction(false));
    }
  };
};

export const getDailyBackgound = (
  functionCalledFromUpdatingSelectedTopic = false
) => {
  return async (dispatch) => {
    try {
      dispatch(updateBackgroundRequest());
      axiosInstance()
        .get(SERVER_URL + "dashboard/daily-images")
        .then((response) => {
          meaVanaConsole("getDailyBackgound response: ", response.data);

          if (
            response.data[0].imagesBatch &&
            response.data[0].imagesBatch.length > 2
          ) {
            meaVanaConsole(
              "add images background to cahce :",
              response.data[0].imagesBatch
            );
          }
          dispatch(
            updateBackgroundSuccess(
              response.data[0].dailyImage,
              response.data[0].dailyImage.quotes[0],
              response.data[0].imagesBatch
            )
          );
          dispatch(
            updateSelectedTopicAction({
              id: response.data[0]._id,
              name: response.data[0].name,
            })
          );

          if (functionCalledFromUpdatingSelectedTopic) {
            dispatch(updateSelectedTopicSuccessAction());
          }
          dispatch(updateAppStateLoadingAction(false));
        })
        .catch((error1) => {
          meaVanaConsole(
            "getDailyBackgound err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(updateBackgroundFailure(checkErrorResponseFormat(error1)));
          if (functionCalledFromUpdatingSelectedTopic) {
            dispatch(
              updateSelectedTopicFailureAction(checkErrorResponseFormat(error1))
            );
          }
          dispatch(updateAppStateLoadingAction(false));
        });
    } catch (error) {
      meaVanaConsole("getDailyBackgound err2", checkErrorResponseFormat(error));

      dispatch(updateBackgroundFailure(checkErrorResponseFormat(error)));
      if (functionCalledFromUpdatingSelectedTopic) {
        dispatch(
          updateSelectedTopicFailureAction(checkErrorResponseFormat(error))
        );
      }
      dispatch(updateAppStateLoadingAction(false));
    }
  };
};
*/
