import React from "react";

import "./Settings.css";
import { useDispatch, useSelector } from "react-redux";
import { GetImageUrl } from "../../Utils/GetImageUrl";
import { PROJECT_VERSION } from "../../config/constants";
import { assetsIcons } from "../../config/images";

export default function AboutSettings(props) {
  return (
    <div className="about-settings-view">
      <div className="about-header-settings">
        <span className="about-header-settings-title">About</span>
        <span className="header-settings-subtitle">More About Us</span>
      </div>

      <div className="about-container-bottom">
        <div className="about-container-button-external">
          {/* 
         <div className="about-button-external-url"  onClick={()=> window.open("someLink", "_blank")}>Website</div>
          <div className="about-button-external-url"  onClick={()=> window.open("someLink", "_blank")}>Blog</div>
          <div className="about-button-external-url"  onClick={()=> window.open("someLink", "_blank")}>Reviews</div>
          <div className="about-button-external-url"  onClick={()=> window.open("someLink", "_blank")}>Help</div>
          <div className="about-button-external-url"  onClick={()=> window.open("someLink", "_blank")}>FAQ</div>
  */}
          <span className="about-link-chrome-store">
            Find us on the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/meavana/kgbcoelgfffkmkpfnldemdinmcbpjlaa?hl=en&authuser=1"
            >
              Chrome Store
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://meavana.com/"
            >
              WebSite
            </a>
          </span>
        </div>
        <div className="about-container-main-info">
          <img
            className="about-societe-logo"
            src={GetImageUrl(
              "/assets/logo/meavana-main-white-logo-purple-bg-rectangle-white-stroked.svg"
            )}
          />
          <span className="about-societe-title">MeaVana</span>
          <span className="about-societe-txt-version">{PROJECT_VERSION}</span>
          <span className="about-societe-subtitle">
            Thank you for being a part of MeaVana
          </span>
        </div>
        <div className="about-container-social-media">
          <img
            className="about-image-social-media"
            alt="facebook icon"
            src={GetImageUrl(assetsIcons.facebook.color)}
            onClick={() =>
              window.open("https://www.facebook.com/meavanadash/", "_blank")
            }
          />
          <img
            className="about-image-social-media"
            alt="Instagram icon"
            src={GetImageUrl(assetsIcons.instagram.color)}
            onClick={() =>
              window.open("https://www.instagram.com/meavanadash/", "_blank")
            }
          />
          <img
            className="about-image-social-media"
            alt="Twitter icon"
            src={GetImageUrl(assetsIcons.twitter.color)}
            onClick={() =>
              window.open("https://twitter.com/meavanadash", "_blank")
            }
          />
          <img
            className="about-image-social-media"
            alt="LinkedIN icon"
            src={GetImageUrl(assetsIcons.linkedin.color)}
            onClick={() =>
              window.open(
                //"https://www.linkedin.com/company/meavana/about/?viewAsMember=true",
                "https://www.linkedin.com/company/meavana/",
                "_blank"
              )
            }
          />
          <img
            className="about-image-social-media"
            alt="Discord icon"
            src={GetImageUrl(assetsIcons.discord.color)}
            onClick={() =>
              window.open("https://discord.com/invite/YdBrmFAwv2", "_blank")
            }
          />
          <img
            className="about-image-social-media"
            alt="Pinterest Icon"
            src={GetImageUrl(assetsIcons.pinterest.color)}
            onClick={() =>
              window.open("https://www.pinterest.com/meavanadash/", "_blank")
            }
          />
          <img
            className="about-image-social-media"
            alt="YouTube icon"
            src={GetImageUrl(assetsIcons.youtube.color)}
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UC9X4mdWkuXAVIjeR4ieJyPA",
                "_blank"
              )
            }
          />
        </div>
      </div>
      <div className="about-container-bottom">
        <div className="about-container-terms">
          <span
            className="about-txt-terms"
            onClick={() => props.setactiveSettingItem(4)}
          >
            Contact
          </span>
          <span
            onClick={() =>
              window.open(
                "https://meavana-policies.s3.us-east-2.amazonaws.com/MeaVana+User+Agreement_04Nov21.htm",
                "_blank"
              )
            }
            className="about-txt-terms"
          >
            Terms of Use
          </span>
        </div>
      </div>
    </div>
  );
}
