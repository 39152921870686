import axios from "axios";
import {
  UNSPLASH_CLIENT_ID,
  UNSPLASH_API_BASE_URL
} from "../../../config/constants";
import {
  getUnsplashRandomPictureRequestAction,
  getUnsplashRandomPictureSuccessAction,
  getUnsplashRandomPictureFailureAction,
  getSearchedPictureRequestAction,
  getSearchedPictureSuccessAction,
  getSearchedPictureFailureAction
} from "../../../store/reducers/unsplashReducer/action";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export const getUnsplashRandomPicture = () => {
  return async (dispatch) => {
    let params = {
      client_id: UNSPLASH_CLIENT_ID
    };
    try {
      dispatch(getUnsplashRandomPictureRequestAction());
      axios
        .get(UNSPLASH_API_BASE_URL + "/photos/random", {
          params
        })
        .then((response) => {
          meaVanaConsole(
            "getUnsplashAssetsSuccessAction response: ",
            response.data
          );
          dispatch(getUnsplashRandomPictureSuccessAction(response.data));
        })
        .catch((error1) => {
          meaVanaConsole("getUnsplashAssetsFailureAction error1: ", error1);
          dispatch(getUnsplashRandomPictureFailureAction(error1));
        });
    } catch (error) {
      meaVanaConsole("getUnsplashAssetsFailureAction error: ", error);
      //
      dispatch(getSearchedPictureFailureAction(error));
    }
  };
};

export const getUnsplashSearchedResults = (
  searchTerm,
  page,
  resultsPerPage
) => {
  return async (dispatch) => {
    let params = {
      client_id: UNSPLASH_CLIENT_ID,
      query: searchTerm,
      page: page,
      per_page: resultsPerPage,
      orientation: "landscape"
    };
    try {
      dispatch(getSearchedPictureRequestAction(searchTerm));
      axios
        .get(UNSPLASH_API_BASE_URL + "/search/photos", {
          params
        })
        .then((response) => {
          meaVanaConsole(
            "getUnsplashSearchedResults response: ",
            response.data.results
          );
          dispatch(
            getSearchedPictureSuccessAction(searchTerm, response.data.results)
          );
        })
        .catch((error1) => {
          meaVanaConsole("getUnsplashSearchedResults error1: ", error1);
          dispatch(getSearchedPictureFailureAction(searchTerm, error1));
        });
    } catch (error) {
      meaVanaConsole("getUnsplashSearchedResults error: ", error);
      dispatch(getSearchedPictureFailureAction(searchTerm, error));
    }
  };
};
