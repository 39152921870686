/* global chrome */
import React from "react";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import {
  loginSSOGoogleFailureAction,
  loginSSOGoogleRequestAction
} from "../../../store/reducers/authReducer/action";
import { GoogleLoginAuthServer } from "../../../server/Auth/auth.server";
//import { signInWithGoogle } from "../../../config/firebase";
import { APP_ENV } from "../../../config/constants";
import Button from "@mui/material/Button";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { GoogleChromeAuth } from "../../../components/AuthFunctions/GoogleChromeAuth";
import { assetsIcons } from "../../../config/images";

export default function Google() {
  const stateAuth = useSelector((state) => state.auth);
  const appState = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const chromeGoogleLogin = () => {
    dispatch(loginSSOGoogleRequestAction());
    if (typeof chrome != "undefined" && chrome.runtime) {
      meaVanaConsole("send message to login function in background");
      chrome.runtime.sendMessage(
        { message: "loginGoogleSSO" },
        function (response) {
          meaVanaConsole("response chrome google login", response);
          if (response.result === "success") {
            let user = {
              tokenId: response.tokenId,
              deviceId: appState.deviceId
            };
            dispatch(GoogleLoginAuthServer(user));
          } else {
            dispatch(loginSSOGoogleFailureAction(response));
          }
        }
      );
       // dispatch(GoogleChromeAuth(appState.deviceId))
    } else {
      meaVanaConsole("chrome undefined");
    }
  };
    /*
  const firebaseGoogleLogin = () => {
    dispatch(loginSSOGoogleRequestAction());
    

    dispatch(signInWithGoogle(appState.deviceId));
  };

  if (APP_ENV === "development") {
    return (
      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-facebook-login"
        placement="top"
        rootClose={true}
        overlay={
          <Popover
            id={`popover-facebook-login`}
            className="popover-share-meavana"
          >
            <Popover.Body className="popover-share-meavana-body">
              <div className="share-meavana-grid">
                <Button
                  className="contact-form-btn-send button-setting-popup"
                  variant="contained"
                  onClick={chromeGoogleLogin}
                  color="info"
                >
                  Chrome
                </Button>
                <Button
                  className="contact-form-btn-send button-setting-popup"
                  variant="contained"
                  onClick={firebaseGoogleLogin}
                  color="warning"
                >
                  Firebase
                </Button>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <IconButton
          className="google-sso-button"
          disabled={stateAuth.loadingGoogleSSO}
        >
          <img
            alt="google-sso"
            src={GetImageUrl(assetsIcons.google.color)}
            style={stateAuth.loadingGoogleSSO ? { opacity: 0.6 } : {}}
          />
          {stateAuth.loadingGoogleSSO && (
            <CircularProgress
              style={{ position: "absolute" }}
              size={35}
              sx={{
                color: "#ffffff",
                alignSelf: "center",
              }}
            />
          )}
        </IconButton>
      </OverlayTrigger>
    );
  } else {
    */
  return (
    <IconButton
      className="google-sso-button"
      onClick={chromeGoogleLogin}
      disabled={stateAuth.loadingGoogleSSO}
    >
      <img
        alt="google-sso"
        src={GetImageUrl(assetsIcons.google.color)}
        style={stateAuth.loadingGoogleSSO ? { opacity: 0.6 } : {}}
      />
      {stateAuth.loadingGoogleSSO && (
        <CircularProgress
          style={{ position: "absolute" }}
          size={35}
          sx={{
            color: "#ffffff",
            alignSelf: "center"
          }}
        />
      )}
    </IconButton>
  );
}
