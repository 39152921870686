import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateGuestAction,
  updateAppStateLoadingAction
} from '../../../store/reducers/appReducer/action';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import LoginSSO from '../LoginSSO/LoginSSO';
import './AuthBottomBar.css';

export default function AuthBottomBar(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      //to load the Guest mode by Default
      dispatch(updateAppStateLoadingAction(true));
      dispatch(updateGuestAction(true));
    } catch (e) {
      meaVanaConsole('error >>> ', e);
    }
  }, []);

  return (
    <div className="container-no-account">
      <label
        className="txt-redirect-action-auth-bottom-bar"
        style={{ textAlign: 'left' }}
        onClick={() => {
          dispatch(updateAppStateLoadingAction(true));
          dispatch(updateGuestAction(true));
        }}
      >
        Guest
      </label>
      <LoginSSO />
      <label
        className="txt-redirect-action-auth-bottom-bar"
        onClick={() => {
          meaVanaConsole('show Contact form ');
          props.setshowContactForm(true);
        }}
        style={{ textAlign: 'right' }}
      >
        Contact
      </label>
    </div>
  );
}
