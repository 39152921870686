export const chromeBookmarks = {
  children: [
    {
      children: [
        {
          dateAdded: 1603184925458,
          id: '739',
          index: 0,
          parentId: '738',
          title: 'Messenger',
          url: 'https://www.facebook.com/messages'
        },
        {
          dateAdded: 1603184925458,
          id: '740',
          index: 1,
          parentId: '738',
          title: 'Facebook',
          url: 'https://www.facebook.com/'
        }
      ],
      dateAdded: 1604837043342,
      dateGroupModified: 1604837043342,
      id: '738',
      index: 0,
      parentId: '1',
      title: 'Social'
    },
    {
      dateAdded: 1604836568820,
      id: '735',
      index: 1,
      parentId: '1',
      title: 'Gmail',
      url: 'https://accounts.google.com/b/0/AddMailService'
    },
    {
      dateAdded: 1604836568849,
      id: '736',
      index: 2,
      parentId: '1',
      title: 'Maps',
      url: 'https://maps.google.com/'
    },
    {
      dateAdded: 1604836568848,
      id: '737',
      index: 3,
      parentId: '1',
      title: 'YouTube',
      url: 'https://youtube.com/'
    }
  ],

  topvisited: [
    {
      title: 'localhost:3000',
      url: 'http://localhost:3000/'
    },
    {
      title: '(60) YouTube',
      url: 'https://youtube.com/'
    },
    {
      title: 'Projects · Dashboard · GitLab',
      url: 'https://gitlab.com/'
    },
    {
      title:
        'Online Shopping site in India: Shop Online for Mobiles, Books, Watches, Shoes and More - Amazon.in',
      url: 'https://amazon.in/'
    },
    {
      title: 'CodingWithRaj | Portfolio',
      url: 'https://coding-with-raj.vercel.app/'
    },
    {
      title: '(3) LinkedIn',
      url: 'https://www.linkedin.com/'
    },
    {
      title: 'Google Meet',
      url: 'https://meet.google.com/'
    },
    {
      title: 'Striver’s SDE Sheet – Top Coding Interview Problems',
      url: 'https://takeuforward.org/interviews/strivers-sde-sheet-top-coding-interview-problems/'
    },
    {
      title: 'Dashboard – Vercel',
      url: 'https://vercel.com/dashboard'
    }
  ],
  dateAdded: 1636680890772,
  dateGroupModified: 1641322870238,
  id: '1',
  index: 0,
  parentId: '0',
  title: 'Bookmarks bar'
};
