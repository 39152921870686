import { appStateAction } from '../appReducer/constants';
import { initialState, videostateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { integrationStateAction } from '../integrationsReducer/constants';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const videoPersistConfig = {
  key: 'video',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING video :', error)
};
export const videoPersisted = persistReducer(videoPersistConfig, videoReducer);

export default function videoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case integrationStateAction.RESET_INTEGRATION_PIN_STATE:
      return {
        ...state,
        videosApiSource: state.videosApiSource.map((video) => {
          return { ...video, pined: false };
        })
      };

    case videostateAction.UPDATE_VIDEO_WINDOW_STATE:
      if (action.payload == null) {
        return {
          ...state,
          activeVideoId: null,
          videosApiSource: state.videosApiSource.map((video) => {
            return { ...video, active: false };
          })
        };
      } else {
        return {
          ...state,
          activeVideoId: action.payload,
          videosApiSource: state.videosApiSource.map((video) => {
            if (action.payload === video.id) {
              return { ...video, active: true };
            }
            return { ...video, active: false };
          })
        };
      }
    case videostateAction.UPDATE_VIDEO_PIN:
      if (action.payload.videoId == null) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          videosApiSource: state.videosApiSource.map((video) => {
            if (action.payload.videoId === video.id) {
              return { ...video, pined: action.payload.pin };
            }
            return { ...video };
          })
        };
      }

    default:
      return state;
  }
}
