import { assetsIcons } from "../../../config/images";

export const stateMigrations = {
  1: (state) => {
    console.log("update youtube store version 1 :");
    return {
      ...state,
      icons: assetsIcons.youtube
    };
  }
};
