export const initialState = {
  hour24: false,
  showAmPm: false,
  showSeconds: false,

  analogClock: "normal",
  showAnalog: false,
  themeMode: "light",

  showStopWatch: false,
  showAlarm: false,
  showTimer: false,
  showTimeFeature: false,

  analogClockSize: 150,
  timeDateFontSize: 100,
  timeDateFontWeight: 400
};

export const clockStateAction = {
  UPDATE_AM_PM: "clock/UPDATE_AM_PM",
  UPDATE_ANALOG_CLOCK: "clock/UPDATE_ANALOG_CLOCK",
  UPDATE_TIME_FORMAT: "clock/UPDATE_TIME_FORMAT",
  UPDATE_TIME_SECONDS: "clock/UPDATE_TIME_SECONDS",
  SHOW_HIDE_TIMEFEATURE: "clock/SHOW_HIDE_TIMEFEATURE",
  SET_ANALOG_CLOCK_SIZE: "clock/SET_ANALOG_CLOCK_SIZE",
  SET_TIME_DATE_FONT_SIZE: "clock/SET_TIME_DATE_FONT_SIZE",
  SET_TIME_DATE_FONT_WEIGHT: "clock/SET_TIME_DATE_FONT_WEIGHT",
  SHOW_HIDE_STOPWATCH: "clock/SHOW_HIDE_STOPWATCH",
  SHOW_HIDE_ALARM: "clock/SHOW_HIDE_ALARM",
  SHOW_HIDE_TIMER: "clock/SHOW_HIDE_TIMER",
  SHOW_ANALOG: "clock/SHOW_ANALOG",
  UPDATE_SHOW_ANALOG: "clock/UPDATE_SHOW_ANALOG",
  UPDATE_THEME_MODE: "clock/UPDATE_THEME_MODE"
};
