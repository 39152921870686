/* global chrome */
import { PushPin, PushPinOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpotifyChromeAuth } from "../../../components/AuthFunctions/SpotifyChromeAuth";
import {
  getSpotifyAccessToken,
  getSpotifyRefreshToken
} from "../../../server/Integration/spotify.server";
import {
  getMusicDataFailureAction,
  setMusicAccessTokenAction,
  setMusiccodeAction,
  setMusicExpiresInAction,
  setMusicRefreshTokenAction,
  updateMusicPinAction,
  updateMusicWindowStateAction
} from "../../../store/reducers/musicReducer/action";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function MusicPopup({ dark }) {
  const musicState = useSelector((state) => state.music);
  const dispatch = useDispatch();
  const spotifyData = musicState.musicapiSource.filter(
    (music) => music.id === "spotify"
  )[0];
/*
  const loginWithSpotify = async (id, code) => {
    dispatch(updateMusicWindowStateAction(id));
    if (!code) {
      if (typeof chrome != "undefined" && chrome.runtime) {
        meaVanaConsole("send message to login function in background");
        chrome.runtime.sendMessage(
          { message: "loginSpotifySSO" },
          function (response) {
            meaVanaConsole("response from spotify sso client >>>> ", response);
            if (response.result === "success") {
              dispatch(setMusiccodeAction(id, response.code));
              dispatch(getMusicDataFailureAction(id, null));
            } else {
              dispatch(getMusicDataFailureAction(id, response.status));
            }
          }
        );
       
      } else {
        meaVanaConsole("chrome undefined");
        dispatch(updateMusicWindowStateAction(spotifyData.id));
      }
    }
    meaVanaConsole("spotifyData >>> ", spotifyData);
  };
*/
  /***** Active sportify */
  /*
  return (
    <div className="integration-grid">
      {musicState.musicapiSource.map((music, index) => (
        <div
          key={"music-grid-item-container" + music.id}
          className={
            music.active
              ? "integration-grid-item-active integration-grid-item"
              : "integration-grid-item"
          }
        >
          {music.pined ? (
            <PushPin
              className="popover-integration-button-pin"
              onClick={() => {
                dispatch(updateMusicPinAction(music.id, false));
              }}
            />
          ) : (
            <PushPinOutlined
              className="popover-integration-button-pin"
              onClick={() => {
                dispatch(updateMusicPinAction(music.id, true));
              }}
            />
          )}

          <div
            className="integration-grid-item-container"
            onClick={() => {
              if (music.active) {
                dispatch(updateMusicWindowStateAction());
              } else {
                music.id === "spotify"
                  ? loginWithSpotify(music.id, music.code)
                  : dispatch(updateMusicWindowStateAction(music.id));
              }
            }}
          >
            <img
              src={
                dark
                  ? GetImageUrl(music.icons.white)
                  : GetImageUrl(music.icons.black)
              }
              alt={music.id + " icon"}
            />
            <span>{music.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
 
  */
  /*****Inactive sportify */

    return (
     <div className="integration-grid">
       {musicState.musicapiSource.map((music, index) => (
         <div
         key={"music-grid-item-container" + music.id}
           className={
             music.active
               ? "integration-grid-item-active integration-grid-item integration-grid-item-inactive"
               : "integration-grid-item integration-grid-item-inactive"
           }
         >

             <div className="integration-grid-item-inactive-msg-container">
             <span style={{ fontSize: "0.8em" }}>Coming soon</span>
           </div>
         <div
           className="integration-grid-item-container"

         >
           <img
             src={
               dark
                 ? GetImageUrl(music.icons.white)
                 : GetImageUrl(music.icons.black)
             }
             alt={music.id + " icon"}
           />
           <span>{music.name}</span>
         </div>
       </div>
     ))}
   </div>
   );
}
