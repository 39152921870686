import { CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import "./ForgotPassword.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";
import { resetForgotPasswordStateAction } from "../../../store/reducers/authReducer/action";
import {
  forgotPasswordAuthServer,
  ResetPasswordAuthServer,
  VerifyEmailforgotPasswordAuthServer
} from "../../../server/Auth/auth.server";

export default function ForgotPassword(props) {
  const stateAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetForgotPasswordStateAction());
  }, []);

  const [step, setstep] = useState(1);
  const [inputEmail, setinputEmail] = useState("");
  const [inputEmailTouched, setinputEmailTouched] = useState(false);

  const [emailErrorMsg, setemailErrorMsg] = useState({
    message: "",
    error: false
  });
  const [inputCode, setinputCode] = useState("");
  const [inputCodeTouched, setinputCodeTouched] = useState(false);

  const [CodeErrorMsg, setCodeErrorMsg] = useState({
    message: "",
    error: false
  });
  const [inputPassword, setinputPassword] = useState("");
  const [inputPasswordTouched, setinputPasswordTouched] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const [PasswordErrorMsg, setPasswordErrorMsg] = useState({
    message: "",
    error: false
  });
  const [inputConfirmPassword, setinputConfirmPassword] = useState("");
  const [inputConfirmPasswordTouched, setinputConfirmPasswordTouched] =
    useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const [ConfirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState({
    message: "",
    error: false
  });

  const verifEmailFormat = (email = "") => {
    if (validator.isEmpty(email, { ignore_whitespace: true })) {
      setemailErrorMsg({
        error: true,
        message: "Please enter your email address"
      });
    } else {
      if (!validator.isEmail(email)) {
        setemailErrorMsg({
          error: true,
          message: "Email must be a valid format"
        });
      } else if (email.indexOf(" ") >= 0) {
        setemailErrorMsg({
          error: true,
          message: "Email must not contain white spaces"
        });
      } else {
        setemailErrorMsg({ error: false, message: "" });
      }
    }
  };

  const forgotPassword = (event) => {
    event.preventDefault();
    if (stateAuth.successForgotPassword) {
      step1Submit(event);
    } else {
      if (inputEmailTouched && inputEmail != "" && !emailErrorMsg.error) {
        dispatch(forgotPasswordAuthServer(inputEmail));
      }
    }
  };

  const step1Submit = (event) => {
    event.preventDefault();
    if (stateAuth.successForgotPassword) {
      setstep(2);
    }
  };

  const step2Submit = (event) => {
    event.preventDefault();
    if (stateAuth.successForgotPasswordVerifyEmail) {
      setstep(3);
    }
  };
  const forgotPasswordCodeVerify = (event) => {
    event.preventDefault();
    if (stateAuth.successForgotPasswordVerifyEmail) {
      step2Submit(event);
    } else {
      if (inputCodeTouched && inputCode != "" && !CodeErrorMsg.error) {
        dispatch(VerifyEmailforgotPasswordAuthServer(inputCode));
      } else {
      }
    }
  };

  const step3Submit = (event) => {
    event.preventDefault();
    if (stateAuth.successResetPassword) {
      setstep(4);
    }
  };

  const resetPassword = (event) => {
    event.preventDefault();
    verifConfirmPasswordFormat(inputConfirmPassword);
    verifPasswordFormat(inputPassword);
    if (inputPassword != inputConfirmPassword) {
      setConfirmPasswordErrorMsg({
        error: true,
        message: "Confirm password doesn't match New password"
      });
    } else {
      if (
        inputPasswordTouched &&
        inputConfirmPasswordTouched &&
        inputPassword != "" &&
        !PasswordErrorMsg.error &&
        !ConfirmPasswordErrorMsg.error
      ) {
        let userResetPassword = {
          email: inputEmail,
          password: inputPassword,
          passwordConfirm: inputConfirmPassword
        };
        dispatch(ResetPasswordAuthServer(userResetPassword));
      }
    }
  };

  const verifPasswordFormat = (password = "") => {
    var passwordCorrectFormatNumber = /\d/;
    var passwordCorrectFormatLowercase = /[a-z]/;
    var passwordCorrectFormatUppercase = /[A-Z]/;
    var passwordCorrectFormat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setPasswordErrorMsg({ error: true, message: "Please enter a password" });
    } else {
      if (password.length < 5) {
        setPasswordErrorMsg({
          error: true,
          message: "Please enter a password longer than 5 characters"
        });
      } else if (password.length > 32) {
        setPasswordErrorMsg({
          error: true,
          message: "Please enter a password shorter than 32 characters"
        });
      } /*else if (!passwordCorrectFormatNumber.test(password)) {
        setPasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one numeric digit",
        });
      } else if (!passwordCorrectFormatLowercase.test(password)) {
        setPasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one lowercase letter",
        });
      } else if (!passwordCorrectFormatUppercase.test(password)) {
        setPasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one uppercase letter",
        });
      } else if (password.indexOf(" ") >= 0) {
        setPasswordErrorMsg({
          error: true,
          message: "Your password must not contain white spaces",
        });
      } else if (!passwordCorrectFormat.test(password)) {
        setPasswordErrorMsg({
          error: true,
          message: "Please enter a valid password",
        });
      } */ else {
        setPasswordErrorMsg({ error: false, message: "" });
      }
    }
  };
  const verifConfirmPasswordFormat = (password = "") => {
    var passwordCorrectFormatNumber = /\d/;
    var passwordCorrectFormatLowercase = /[a-z]/;
    var passwordCorrectFormatUppercase = /[A-Z]/;
    var passwordCorrectFormat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setConfirmPasswordErrorMsg({
        error: true,
        message: "Please enter a password"
      });
    } else {
      if (password.length < 5) {
        setConfirmPasswordErrorMsg({
          error: true,
          message: "Please enter a password longer than 5 characters"
        });
      } else if (password.length > 32) {
        setConfirmPasswordErrorMsg({
          error: true,
          message: "Please enter a password shorter than 32 characters"
        });
      } /* else if (!passwordCorrectFormatNumber.test(password)) {
        setConfirmPasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one numeric digit",
        });
      } else if (!passwordCorrectFormatLowercase.test(password)) {
        setConfirmPasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one lowercase letter",
        });
      } else if (!passwordCorrectFormatUppercase.test(password)) {
        setConfirmPasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one uppercase letter",
        });
      } else if (password.indexOf(" ") >= 0) {
        setConfirmPasswordErrorMsg({
          error: true,
          message: "Your password must not contain white spaces",
        });
      } else if (!passwordCorrectFormat.test(password)) {
        setConfirmPasswordErrorMsg({
          error: true,
          message: "Please enter a valid password",
        });
      }*/ else {
        setConfirmPasswordErrorMsg({ error: false, message: "" });
      }
    }
  };

  switch (step) {
    case 1:
      return (
        <div className="inner inner-subcontainer">
          <form onSubmit={forgotPassword} style={{ width: "100" }}>
            <div className="form-group">
              <label>Please enter your email address</label>
              <input
                type="email"
                id="email"
                className={
                  emailErrorMsg.error
                    ? "form-control input-error"
                    : "form-control"
                }
                placeholder="Enter email"
                value={inputEmail}
                disabled={
                  stateAuth.successForgotPassword
                    ? stateAuth.successForgotPassword
                    : false
                }
                onFocus={(e) => {
                  setemailErrorMsg({ error: false, message: "" });
                }}
                onBlur={(event) => {
                  verifEmailFormat(inputEmail);
                }}
                onChange={(event) => {
                  setinputEmail(event.target.value);
                  setinputEmailTouched(true);
                  //  verifEmailFormat(event.target.value);
                }}
              />
              <p className="error-msg-txt">{emailErrorMsg.message}</p>
            </div>
            {!stateAuth.successForgotPassword ? (
              <p className="error-msg-txt">
                {stateAuth.errorForgotPassword
                  ? stateAuth.errorForgotPassword.message
                  : ""}
              </p>
            ) : (
              <p className="succes-msg-txt">
                {stateAuth.responseForgotPassword
                  ? stateAuth.responseForgotPassword.message
                  : ""}
              </p>
            )}
            <div className="container-btn-forgot-password">
              <button
                className="btn btn-cancel-forgot-password btn-lg btn-block"
                type="button"
                onClick={(event) => {
                  meaVanaConsole("hide Forgot Password ");

                  event.preventDefault();
                  props.showForgotPassword();
                }}
              >
                Cancel
              </button>
              {/*stateAuth.successForgotPassword ? (
                <button
                  className="btn btn-submit-forgot-password btn-lg btn-block"
                  onClick={step1Submit}
                >
                  Next
                </button>
            ) : (*/}
              <button
                className="btn btn-submit-forgot-password btn-lg btn-block"
                type="submit"
                onClick={forgotPassword}
                disabled={
                  !stateAuth.successForgotPassword &&
                  (stateAuth.loadingForgotPassword ||
                    emailErrorMsg.error ||
                    !inputEmailTouched)
                }
              >
                {stateAuth.loadingForgotPassword ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#fff",
                      alignSelf: "center"
                    }}
                  />
                ) : stateAuth.successForgotPassword ? (
                  "Next"
                ) : (
                  "Send Code"
                )}
              </button>
            </div>
          </form>
        </div>
      );
    case 2:
      return (
        <div className="inner inner-subcontainer">
          <form onSubmit={forgotPasswordCodeVerify} style={{ width: "100" }}>
            <div className="form-group">
              <label>Please enter the code sent to your email</label>
              <input
                type="text"
                id="code"
                className={
                  CodeErrorMsg.error
                    ? "form-control input-error"
                    : "form-control"
                }
                placeholder="Enter code"
                disabled={
                  stateAuth.successForgotPasswordVerifyEmail
                    ? stateAuth.successForgotPasswordVerifyEmail
                    : false
                }
                value={inputCode}
                onFocus={(e) => {
                  // setemailErrorMsg({ error: false, message: "" });
                }}
                onBlur={(event) => {}}
                onChange={(event) => {
                  setinputCode(event.target.value);
                  setinputCodeTouched(true);
                }}
              />
              <p className="error-msg-txt">{CodeErrorMsg.message}</p>
            </div>
            {!stateAuth.successForgotPasswordVerifyEmail ? (
              <p className="error-msg-txt">
                {stateAuth.errorForgotPasswordVerifyEmail
                  ? stateAuth.errorForgotPasswordVerifyEmail.message
                  : ""}
              </p>
            ) : (
              <p className="succes-msg-txt">
                {stateAuth.responseForgotPasswordVerifyEmail
                  ? stateAuth.responseForgotPasswordVerifyEmail.message
                  : ""}
              </p>
            )}
            <div className="container-btn-forgot-password">
              <button
                className="btn btn-cancel-forgot-password btn-lg btn-block"
                type="button"
                onClick={() => {
                  meaVanaConsole("hide Forgot Password ");

                  props.showForgotPassword();
                }}
              >
                Cancel
              </button>
              {/*stateAuth.successForgotPasswordVerifyEmail ? (
                <button
                 
                  className="btn btn-submit-forgot-password btn-lg btn-block"
                  onClick={step2Submit}
                >
                  Next
                </button>
              ) : (*/}
              <button
                type="submit"
                className="btn btn-submit-forgot-password btn-lg btn-block"
                onClick={forgotPasswordCodeVerify}
                disabled={
                  !stateAuth.successForgotPasswordVerifyEmail &&
                  (stateAuth.loadingForgotPasswordVerifyEmail ||
                    CodeErrorMsg.error ||
                    !inputCodeTouched)
                }
              >
                {stateAuth.loadingForgotPasswordVerifyEmail ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#fff",
                      alignSelf: "center"
                    }}
                  />
                ) : stateAuth.successForgotPasswordVerifyEmail ? (
                  "Next"
                ) : (
                  "Verify Code"
                )}
              </button>
            </div>
          </form>
        </div>
      );
    case 3:
      return (
        <div className="inner inner-subcontainer">
          {!stateAuth.successResetPassword ? (
            <form onSubmit={resetPassword} style={{ width: "100" }}>
              <div
                className="form-group"
                // style={{ marginBottom: "18px" }}
              >
                <label>Enter New Password</label>
                <div className="container-input-icon-auth">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className={
                      PasswordErrorMsg.error
                        ? "form-control input-error"
                        : "form-control"
                    }
                    placeholder="Enter password"
                    onFocus={(e) => {
                      setPasswordErrorMsg({
                        error: false,
                        message: ""
                      });
                    }}
                    value={inputPassword}
                    onBlur={(e) => {
                      verifPasswordFormat(inputPassword);
                    }}
                    onChange={(event) => {
                      setinputPassword(event.target.value);
                      setinputPasswordTouched(true);
                      // verifPasswordFormat(event.target.value);
                    }}
                  />
                  {!showPassword ? (
                    <IconButton
                      className="button-icon-auth"
                      onClick={() => setshowPassword(true)}
                    >
                      <VisibilityOff fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="button-icon-auth"
                      onClick={() => setshowPassword(false)}
                    >
                      <Visibility fontSize="small" />
                    </IconButton>
                  )}
                </div>
                <p className="error-msg-txt">{PasswordErrorMsg.message}</p>
              </div>
              <div
                className="form-group"
                // style={{ marginBottom: "18px" }}
              >
                <label>Confirm New Password</label>
                <div className="container-input-icon-auth">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="password"
                    className={
                      ConfirmPasswordErrorMsg.error
                        ? "form-control input-error"
                        : "form-control"
                    }
                    placeholder="Enter password"
                    onFocus={(e) => {
                      setConfirmPasswordErrorMsg({
                        error: false,
                        message: ""
                      });
                    }}
                    value={inputConfirmPassword}
                    onBlur={(e) => {
                      verifConfirmPasswordFormat(inputPassword);
                    }}
                    onChange={(event) => {
                      setinputConfirmPassword(event.target.value);
                      setinputConfirmPasswordTouched(true);
                      //  verifConfirmPasswordFormat(event.target.value);
                    }}
                  />
                  {!showConfirmPassword ? (
                    <IconButton
                      className="button-icon-auth"
                      onClick={() => setshowConfirmPassword(true)}
                    >
                      <VisibilityOff fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="button-icon-auth"
                      onClick={() => setshowConfirmPassword(false)}
                    >
                      <Visibility fontSize="small" />
                    </IconButton>
                  )}
                </div>
                <p className="error-msg-txt">
                  {ConfirmPasswordErrorMsg.message}
                </p>
              </div>
              {!stateAuth.successResetPassword ? (
                <p className="error-msg-txt">
                  {stateAuth.errorResetPassword
                    ? stateAuth.errorResetPassword.message
                    : ""}
                </p>
              ) : (
                <p className="succes-msg-txt">
                  {stateAuth.responseResetPassword
                    ? stateAuth.responseResetPassword.message
                    : ""}
                </p>
              )}
              <div className="container-btn-forgot-password">
                <button
                  className="btn btn-cancel-forgot-password btn-lg btn-block"
                  type="button"
                  onClick={() => {
                    meaVanaConsole("hide Forgot Password ");

                    props.showForgotPassword();
                  }}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-submit-forgot-password btn-lg btn-block"
                  onClick={resetPassword}
                  disabled={
                    stateAuth.loadingResetPassword ||
                    ConfirmPasswordErrorMsg.error ||
                    PasswordErrorMsg.error ||
                    !inputPasswordTouched ||
                    !inputConfirmPasswordTouched
                  }
                >
                  {stateAuth.loadingResetPassword ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#fff",
                        alignSelf: "center"
                      }}
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          ) : (
            <>
              <h5 style={{ width: "100%", textAlign: "center" }}>
                Your password is updated{" "}
              </h5>

              <div
                className="container-btn-forgot-password"
                style={{ justifyContent: "center" }}
              >
                <button
                  className="btn btn-submit-forgot-password btn-lg btn-block"
                  onClick={() => {
                    meaVanaConsole("hide Forgot Password ");

                    props.showForgotPassword();
                  }}
                >
                  Go to login
                </button>
              </div>
            </>
          )}
        </div>
      );

    default:
      return (
        <div className="inner inner-subcontainer">
          <h5 style={{ width: "100%", textAlign: "center" }}>
            Some error happened please reload your page or contact us
          </h5>
          <div
            className="container-btn-forgot-password"
            style={{ justifyContent: "center" }}
          >
            <button
              className="btn btn-submit-forgot-password btn-lg btn-block"
              onClick={() => {
                meaVanaConsole("hide Forgot Password ");

                props.showForgotPassword();
              }}
            >
              Go to login
            </button>
          </div>
        </div>
      );
  }
}
