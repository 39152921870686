import { arrayMove } from 'react-sortable-hoc';
import { appStateAction } from '../appReducer/constants';

import { initialState, todoStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const todoPersistConfig = {
  key: 'todo',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING todo :', error)
};
export const todoPersisted = persistReducer(todoPersistConfig, todoReducer);

export default function todoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case todoStateAction.GET_DASHBOARD_TODOS:
      return {
        ...state,
        todos: action.payload,
        updatedList: false
      };

    case todoStateAction.ADD_EMPTY_TODO:
      return {
        ...state,
        selectedToDo: action.payload[0],
        todos: action.payload,
        updatedList: true
      };
    case todoStateAction.ADD_TODO_SUCCESS:
      return {
        ...state,
        updatedList: true
      };
    case todoStateAction.ADD_TODO_REQUEST:
      let updatedNewTodos = state.todos.map((todo) => {
        if (todo._id.includes('empty') || !todo._id) {
          todo = action.payload.todo;
        }
        return todo;
      });
      return {
        ...state,
        selectedToDo: state.selectedToDo._id.includes('empty')
          ? action.payload.todo
          : state.selectedToDo,
        todos: updatedNewTodos,
        updatedList: false
      };
    case todoStateAction.ADD_TODO_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case todoStateAction.UPDATE_TODO_REQUEST:
      return {
        ...state
      };
    case todoStateAction.UPDATE_TODO_SUCCESS:
      let updatedBulkToDos = state.todos.map((todo) => {
        return { ...todo, saved: true };
      });
      return {
        ...state,
        todos: updatedBulkToDos,
        updatedList: false
      };
    case todoStateAction.UPDATE_TODO_IN_STATE:
      let updatedToDos = state.todos.map((todo) => {
        let updatedToDo = action.payload.todo;
        if (todo._id === updatedToDo._id) {
          todo = updatedToDo;
          todo.saved = false;
        }
        return todo;
      });
      return {
        ...state,
        todos: updatedToDos,
        updatedList: true
      };
    case todoStateAction.UPDATE_TODO_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case todoStateAction.DELETE_TODO_SUCCESS:
      return {
        ...state,
        updatedList: true
      };
    case todoStateAction.DELETE_TODO_REQUEST:
      let reorderedToDosAfterDelete = state.todos;
      let index = state.todos.findIndex((todo) => todo._id === action.payload.todoId);
      if (index >= 0) {
        reorderedToDosAfterDelete = arrayMove(state.todos, index, state.todos.length - 1);
        reorderedToDosAfterDelete.pop();
      } else {
        reorderedToDosAfterDelete = reorderedToDosAfterDelete.filter(
          (todo) => todo._id !== action.payload.todoId
        );
      }
      return {
        ...state,
        updatedList: false,
        todos: reorderedToDosAfterDelete
      };
    case todoStateAction.DELETE_ALL_TODOS_REQUEST:
      return {
        ...state,
        updatedList: false,
        selectedToDo: {},
        todos: []
      };
    case todoStateAction.DELETE_ALL_TODOS_SUCCESS:
      return {
        ...state,
        updatedList: true,
        selectedToDo: {},
        todos: []
      };
    case todoStateAction.DELETE_ALL_TODOS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case todoStateAction.DELETE_TODO_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case todoStateAction.SET_SELECTED_TODO:
      let updatedSelectedTodos = state.todos.map((todo) => {
        if (todo._id === action.payload._id) {
          todo = action.payload;
        }
        return todo;
      });
      return {
        ...state,
        selectedToDo: action.payload,
        todos: updatedSelectedTodos,
        updatedList: true
      };

    case todoStateAction.UPDATE_TODO_LIST:
      return {
        ...state,
        todos: action.payload.todos,
        updatedList: true
      };

    case todoStateAction.UPDATE_TODO_BULK_LIST_REQUEST:
      return {
        ...state,
        todos: action.payload.todos,
        updatedList: false
      };
    case todoStateAction.UPDATE_TODO_BULK_LIST_SUCCESS:
      return {
        ...state,

        updatedList: true
      };
    case todoStateAction.UPDATE_TODO_BULK_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
