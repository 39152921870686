export const initialState = {
  hours: 0,
  minutes: 0,
  seconds: 0
};

export const timerStateAction = {
  SET_HOURS: "timer/SET_HOURS",
  SET_MINUTES: "timer/SET_MINUTES",
  SET_SECONDS: "timer/SET_SECONDS"
};
