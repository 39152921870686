import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetImageUrl } from '../../Utils/GetImageUrl';
import './IntegrationsFeature.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import { KeyboardBackspace, WidgetsRounded } from '@mui/icons-material';
import useWindowDimensions from '../../Utils/useWindowDimensions';
import { meaVanaConsole } from '../../Utils/MeaVanaConsole';

import MusicPopup from './Music/MusicPopup';
import NewsPopup from './News/NewsPopup';
import VideosPopup from './Videos/VideosPopup';
import CryptoPopup from './Crypto/CryptoPopup';
import PicturesPopup from './Pictures/PicturesPopup';
import { assetsIcons } from '../../config/images';
import GooglePopup from './Google/GooglePopup';

export default function IntegrationsFeature(props) {
  const appState = useSelector((state) => state.app);
  const integrationState = useSelector((state) => state.integration);
  const dispatch = useDispatch();

  const [integrationAppLevel, setIntegrationAppLevel] = useState(0);
  const [integrationAppIndex, setIntegrationAppIndex] = useState(0);

  const [iconSrc, setIconSrc] = useState(assetsIcons.integration.white);
  const [iconOpacity, setIconOpacity] = useState(1);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    switch (appState.backgroundMode) {
      case 'background-home-picture':
        setIconSrc(assetsIcons.integration.white);

        setIconOpacity(1);
        break;
      case 'background-home-dark':
        setIconSrc(assetsIcons.integration.white);
        setIconOpacity(1);
        break;
      case 'background-home-light':
        setIconSrc(assetsIcons.integration.black);
        setIconOpacity(0.6);
        break;
      default:
        setIconSrc(assetsIcons.integration.white);
        setIconOpacity(1);
        break;
    }
  }, [appState.backgroundMode]);
  if (props.display || props.opacity === 0) {
    return (
      <div className="container-btn-integration" style={{ opacity: 0, cursor: 'inherit' }}>
        <img
          className="home-icon-font-size icon-integration"
          style={{ opacity: iconOpacity }}
          src={iconSrc}
          alt="integration icon"
        />
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-integration"
        placement="bottom"
        rootClose={true}
        overlay={
          <Popover id={`popover-integration`} className="popover-integration">
            <Popover.Header className="popover-integration-header">
              {integrationAppLevel !== 0 ? (
                <IconButton
                  className="popover-integration-button-back"
                  onClick={() => {
                    setIntegrationAppLevel(0);
                    setIntegrationAppIndex(0);
                  }}
                >
                  <KeyboardBackspace fontSize="medium" />
                </IconButton>
              ) : null}
              {integrationAppLevel === 0
                ? integrationState.integrationApp.name
                : integrationState.integrationApp.pages[integrationAppIndex].name}
            </Popover.Header>
            <Popover.Body className="popover-integration-body">
              {integrationAppLevel === 0 ? (
                <div className="integration-grid">
                  {integrationState.integrationApp.pages.map((integration, index) => {
                    return (
                      <div
                        className="integration-grid-item"
                        key={'integration-grid-item-' + integration.id}
                        onClick={() => {
                          meaVanaConsole('click item :', index);
                          setIntegrationAppLevel(integration.level);
                          setIntegrationAppIndex(index);
                        }}
                      >
                        <img
                          src={
                            appState.themeMode.includes('light')
                              ? GetImageUrl(integration.icons.black)
                              : GetImageUrl(integration.icons.white)
                          }
                          alt={integration.id + ' icon'}
                        />
                        <span>{integration.name}</span>
                      </div>
                    );
                  })}
                </div>
              ) : integrationState.integrationApp.pages[integrationAppIndex].id === 'news' ? (
                <NewsPopup dark={appState.themeMode.includes('dark')} />
              ) : integrationState.integrationApp.pages[integrationAppIndex].id === 'video' ? (
                <VideosPopup dark={appState.themeMode.includes('dark')} />
              ) : integrationState.integrationApp.pages[integrationAppIndex].id === 'crypto' ? (
                <CryptoPopup dark={appState.themeMode.includes('dark')} />
              ) : integrationState.integrationApp.pages[integrationAppIndex].id === 'pictures' ? (
                <PicturesPopup dark={appState.themeMode.includes('dark')} />
              ) : integrationState.integrationApp.pages[integrationAppIndex].id === 'music' ? (
                <MusicPopup dark={appState.themeMode.includes('dark')} />
              ) : integrationState.integrationApp.pages[integrationAppIndex].id === 'google' ? (
                <GooglePopup dark={appState.themeMode.includes('dark')} />
              ) : null}
            </Popover.Body>
          </Popover>
        }
      >
        <div
          className="container-btn-integration"
          style={props.display ? { display: 'none' } : { opacity: props.opacity }}
        >
          <img
            className="home-icon-font-size icon-integration"
            style={{ opacity: iconOpacity }}
            src={iconSrc}
            alt="integration icon"
          />
        </div>
      </OverlayTrigger>
    );
  }
}
