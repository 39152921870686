import { CHROME_EXTENTION_URL } from "../config/constants";

export const facebookShare = (url = CHROME_EXTENTION_URL) => {
  window.open("http://www.facebook.com/sharer.php?u=" + url, "_blank");
};

export const linkedinShare = (url = CHROME_EXTENTION_URL) => {
  window.open(
    "https://www.linkedin.com/sharing/share-offsite/?url=" + url,
    "_blank"
  );
};

export const pinterestShare = (url = CHROME_EXTENTION_URL) => {
  window.open("http://pinterest.com/pin/create/button/?url=" + url, "_blank");
};

export const redditShare = (url = CHROME_EXTENTION_URL, title = "") => {
  window.open(
    "https://reddit.com/submit?url=" + url + "&title=" + title,
    "_blank"
  );
};

export const whatsappShare = (
  url = CHROME_EXTENTION_URL,
  title = "",
  phone_number = ""
) => {
  window.open(
    "https://api.whatsapp.com/send?phone=" +
      phone_number +
      "&text=" +
      title +
      "%20" +
      url,
    "_blank"
  );
};
export const twitterShare = (
  url = CHROME_EXTENTION_URL,
  text = "",
  via = "",
  hash_tags = ""
) => {
  window.open(
    "https://twitter.com/intent/tweet?url=" +
      url +
      "&text=" +
      text +
      "&via=" +
      via +
      "&hashtags=" +
      hash_tags,
    "_blank"
  );
};

export const gmailShare = (title = "", body = "") => {
  window.open(
    "https://mail.google.com/mail/?view=cm&su=" + title + "&body=" + body,
    "_blank"
  );
};
