import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateActiveAnimationBackgroundAction } from "../../../store/reducers/animReducer/action";
import {
  updateBackgroundModeAction,
  updateThemeColorAction,
  updateThemeFontFamilyAction,
  updateThemeStyleAction,
  updateThemeTextSizeAction
} from "../../../store/reducers/appReducer/action";
import { updateTimeFontSizeAction } from "../../../store/reducers/clockReducer/action";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function UpdateAppearances(style) {
  return async (dispatch) => {
    try {
      const message = {
        font: "",
        animatedBackground: "",
        background: "",
        theme: "",
        size: "",
        opacity: "",
        mode: ""
      };

      const updateThemeFontFamily = (font) => {
        document.body.classList.remove(['Classic-Font','Old-Fashioned-Font','Artistic-Font']);
       
          document.body.classList.add(font);
      

        dispatch(updateThemeFontFamilyAction(font));
      };

      const updateBackgroundMode = (backgroundMode) => {
        dispatch(updateActiveAnimationBackgroundAction(false));
        document.body.classList.remove(['background-home-picture','background-home-dark','background-home-light']);

          document.body.classList.add(backgroundMode);
       

        dispatch(updateBackgroundModeAction(backgroundMode));
      };

      const updateThemeTextSize = (size) => {
        document.body.classList.remove(['Large-Size','Medium-Size','Small-Size']);
  
          document.body.classList.add(size);
        

        dispatch(updateThemeTextSizeAction(size));
        dispatch(updateTimeFontSizeAction(100));
      };

      const updateThemeColor = (mode, opacity) => {
        document.body.classList.remove(['light-transparent-theme','light-translucent-theme','light-opaque-theme','dark-transparent-theme','dark-translucent-theme','dark-opaque-theme','light-opaque-theme','meavana-transparent-theme','meavana-translucent-theme','meavana-opaque-theme']);

        document.body.classList.add(mode + "-" + opacity + "-theme");

        dispatch(updateThemeColorAction(mode));
        dispatch(updateThemeStyleAction(opacity));
      };
      if (style.font) {
        switch (style.font) {
          case "standard":
            updateThemeFontFamily("Classic-Font");
            message.font = "updated ,value=standard";
            break;
          case "classic":
            updateThemeFontFamily("Old-Fashioned-Font");
            message.font = "updated ,value=classic";
            break;
          case "artistic":
            updateThemeFontFamily("Artistic-Font");
            message.font = "updated ,value=artistic";
            break;

          default:
            updateThemeFontFamily("Classic-Font");
            message.font = "updated ,value=standard";
            break;
        }
      } else {
        updateThemeFontFamily("Classic-Font");
        message.font = "updated ,value=standard";
      }
      if (style.animatedBackground) {
      }
      if (style.background) {
        switch (style.background) {
          case "picture":
            updateBackgroundMode("background-home-picture");
            message.background = "updated ,value=picture";
            break;
          case "dark":
            updateBackgroundMode("background-home-dark");
            message.background = "updated ,value=dark";
            break;
          case "light":
            updateBackgroundMode("background-home-light");
            message.background = "updated ,value=light";
            break;

          default:
            updateBackgroundMode("background-home-picture");
            message.background = "updated ,value=picture";
            break;
        }
      } else {
        updateBackgroundMode("background-home-picture");
        message.background = "updated ,value=picture";
      }
      if (style.theme) {
        switch (style.theme) {
          case "standard":
            break;
          case "minimalist":
            break;
          case "vintage":
            break;

          default:
            break;
        }
      }
      if (style.size) {
        switch (style.size) {
          case "large":
            updateThemeTextSize("Large-Size");
            message.size = "updated ,value=large";
            break;
          case "medium":
            updateThemeTextSize("Medium-Size");
            message.size = "updated ,value=medium";
            break;
          case "small":
            updateThemeTextSize("Small-Size");
            message.size = "updated ,value=small";
            break;

          default:
            updateThemeTextSize("Medium-Size");
            message.size = "updated ,value=medium";
            break;
        }
      } else {
        updateThemeTextSize("Medium-Size");
        message.size = "updated ,value=medium";
      }
      if (style.opacity && style.mode) {
        updateThemeColor(style.mode, style.opacity);
        message.opacity = "updated ,value=" + style.opacity;
        message.mode = "updated ,value=" + style.mode;
      } else {
        updateThemeColor("dark", "translucent");
      }

      meaVanaConsole("UpdateAppearances :", message);
    } catch (error) {
      meaVanaConsole("UpdateAppearances error:", error);
    }
  };
}

