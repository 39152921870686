import { searchEngineStateAction } from "./constants";

export const resetSearchEngineStateAction = () => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.RESET_SEARCH_ENGINE_STATE
    });
  };
};

export const deleteSearchEngineAction = (index, engine) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.DELETE_SEARCH_ENGINE,
      payload: { index: index, engine: engine }
    });
  };
};

export const updateActiveSearchEngineAction = (searchEngine) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_ENGINE,
      payload: searchEngine
    });
  };
};
export const updateActiveSecondarySearchEngineAction = (
  searchEngine,
  index
) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_ACTIVE_SECONDARY_SEARCH_ENGINE,
      payload: searchEngine,
      index: index
    });
  };
};
export const addSecondarySearchEngineAction = () => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.ADD_SECONDARY_SEARCH_ENGINE
    });
  };
};
export const deleteSecondarySearchEngineAction = (index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.DELETE_SECONDARY_SEARCH_ENGINE,
      index: index
    });
  };
};
export const updateSearchEngineOrderAction = (items) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_ENGINE_ORDER,
      payload: items
    });
  };
};
export const updateSearchEngineRectangleAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_ENGINE_REACTANGLE,
      payload: state
    });
  };
};
export const updateSecondarySearchEngineRectangleAction = (state, index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SECONDARY_SEARCH_ENGINE_REACTANGLE,
      payload: state,
      index: index
    });
  };
};
export const updateSearchEngineBorderAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_ENGINE_BORDER,
      payload: state
    });
  };
};
export const updateSearchEngineLineAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_ENGINE_LINE,
      payload: state
    });
  };
};
export const updateSearchEngineColoredAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_COLORED,
      payload: state
    });
  };
};
export const updateSecondarySearchEngineBorderAction = (state, index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SECONDARY_SEARCH_ENGINE_BORDER,
      payload: state,
      index: index
    });
  };
};

export const updateSecondarySearchEngineLineAction = (state, index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SECONDARY_SEARCH_LINE,
      payload: state,
      index: index
    });
  };
};
export const updateSecondarySearchEngineCloredAction = (state, index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SECONDARY_SEARCH_COLORED,
      payload: state,
      index: index
    });
  };
};
export const updateSearchEngineOpenNewTabAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_ENGINE_OPEN_NEW_TAB,
      payload: state
    });
  };
};
export const updateSecondarySearchEngineOpenNewTabAction = (state, index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SECONDARY_SEARCH_ENGINE_OPEN_NEW_TAB,
      payload: state,
      index: index
    });
  };
};
export const updateShowMultipleSearchEngineAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SHOW_MULTIPLE_SEARCH,
      payload: state
    });
  };
};
export const updateSearchEngineSizeAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SEARCH_SIZE,
      payload: value
    });
  };
};

export const updateSecondarySearchEngineSizeAction = (value, index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_SECONDARY_SEARCH_SIZE,
      payload: value,
      index: index
    });
  };
};
export const updateAllSecondarySearchEngineAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.UPDATE_ALL_SECONDARY_SEARCH_ENGINE,
      payload: value
    });
  };
};
export const deleteAllSecondarySearchEngineAction = () => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.DELETE_ALL_SECONDARY_SEARCH_ENGINE
    });
  };
};

export const addSearchEngineAction = (index) => {
  return (dispatch) => {
    dispatch({
      type: searchEngineStateAction.ADD_SEARCH_ENGINE,
      payload: index
    });
  };
};
