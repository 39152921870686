export const defaultTopicStyling = {
  clock: {
    showAmPm: false,
    showTwentyFour: false,
    size: 100,
    showSeconds: false,
    kind: 'DigitalClockStyle'
  },
  bookmarks: {
    large: false,
    useChromeBookmarks: false,
    blackAndWhite: false,
    square: false,
    moveToCenter: false,
    openNewTab: true,
    defaultBookmarkList: [
      {
        bookmarks: [],
        isFolder: false,
        index: 1,
        url: 'www.google.com',
        name: 'Google',
        _id: '62fcddf9829721b8be87db70',
        createdAt: '2022-08-17T12:24:25.131Z',
        updatedAt: '2022-08-17T12:24:25.131Z'
      },
      {
        bookmarks: [],
        isFolder: false,
        index: 2,
        url: 'mail.google.com',
        name: 'Gmail',
        _id: '62fcddf9829721b8be87db71',
        createdAt: '2022-08-17T12:24:25.131Z',
        updatedAt: '2022-08-17T12:24:25.131Z'
      },
      {
        bookmarks: [],
        isFolder: false,
        index: 3,
        url: 'www.youtube.com',
        name: 'Youtube',
        _id: '62fcddf9829721b8be87db72',
        createdAt: '2022-08-17T12:24:25.131Z',
        updatedAt: '2022-08-17T12:24:25.131Z'
      },
      {
        bookmarks: [],
        isFolder: false,
        index: 4,
        url: 'www.facebook.com',
        name: 'Facebook',
        _id: '62fcddf9829721b8be87db73',
        createdAt: '2022-08-17T12:24:25.137Z',
        updatedAt: '2022-08-17T12:24:25.137Z'
      },
      {
        bookmarks: [],
        isFolder: false,
        index: 5,
        url: 'www.instagram.com',
        name: 'Instagram',
        _id: '62fcddf9829721b8be87db74',
        createdAt: '2022-08-17T12:24:25.137Z',
        updatedAt: '2022-08-17T12:24:25.137Z'
      },
      {
        bookmarks: [],
        isFolder: false,
        index: 6,
        url: 'www.netflix.com',
        name: 'Netflix',
        _id: '62fcddf9829721b8be87db75',
        createdAt: '2022-08-17T12:24:25.137Z',
        updatedAt: '2022-08-17T12:24:25.137Z'
      },
      {
        bookmarks: [],
        isFolder: false,
        index: 7,
        url: 'www.Amazon.com',
        name: 'Amazon',
        _id: '62fcddf9829721b8be87db76',
        createdAt: '2022-08-17T12:24:25.137Z',
        updatedAt: '2022-08-17T12:24:25.137Z'
      }
    ]
  },
  features: {
    integrations: 'show',
    bookmarks: 'show',
    weather: 'show',
    notes: 'show',
    quote: 'show',
    searchBar: 'dim',
    date: 'show',
    time: 'show'
  },
  integrations: [],
  searchEngines: [
    {
      size: 35,
      openNewTab: true,
      color: false,
      line: false,
      border: false,
      rectangle: false,
      searchEngine: 'google'
    }
  ],
  font: 'standard',
  animatedBackground: 'null',
  background: 'picture',
  theme: 'null',
  size: 'medium',
  opacity: 'translucent',
  mode: 'dark',
  defaultBookmarkList: [
    {
      bookmarks: [],
      isFolder: false,
      index: 1,
      url: 'www.google.com',
      name: 'Google',
      _id: '62fcddf9829721b8be87db70',
      createdAt: '2022-08-17T12:24:25.131Z',
      updatedAt: '2022-08-17T12:24:25.131Z'
    },
    {
      bookmarks: [],
      isFolder: false,
      index: 2,
      url: 'mail.google.com',
      name: 'Gmail',
      _id: '62fcddf9829721b8be87db71',
      createdAt: '2022-08-17T12:24:25.131Z',
      updatedAt: '2022-08-17T12:24:25.131Z'
    },
    {
      bookmarks: [],
      isFolder: false,
      index: 3,
      url: 'www.youtube.com',
      name: 'Youtube',
      _id: '62fcddf9829721b8be87db72',
      createdAt: '2022-08-17T12:24:25.131Z',
      updatedAt: '2022-08-17T12:24:25.131Z'
    },
    {
      bookmarks: [],
      isFolder: false,
      index: 4,
      url: 'www.facebook.com',
      name: 'Facebook',
      _id: '62fcddf9829721b8be87db73',
      createdAt: '2022-08-17T12:24:25.137Z',
      updatedAt: '2022-08-17T12:24:25.137Z'
    },
    {
      bookmarks: [],
      isFolder: false,
      index: 5,
      url: 'www.instagram.com',
      name: 'Instagram',
      _id: '62fcddf9829721b8be87db74',
      createdAt: '2022-08-17T12:24:25.137Z',
      updatedAt: '2022-08-17T12:24:25.137Z'
    },
    {
      bookmarks: [],
      isFolder: false,
      index: 6,
      url: 'www.netflix.com',
      name: 'Netflix',
      _id: '62fcddf9829721b8be87db75',
      createdAt: '2022-08-17T12:24:25.137Z',
      updatedAt: '2022-08-17T12:24:25.137Z'
    },
    {
      bookmarks: [],
      isFolder: false,
      index: 7,
      url: 'www.Amazon.com',
      name: 'Amazon',
      _id: '62fcddf9829721b8be87db76',
      createdAt: '2022-08-17T12:24:25.137Z',
      updatedAt: '2022-08-17T12:24:25.137Z'
    }
  ]
};
