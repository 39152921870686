import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './UploadBackground.css';
import UploadBackgroundItem from './UploadBackgroundItem';

export default function UploadBackground({ windowSize }) {
  const background = useSelector((state) => state.background);

  const [gridTemplateCulumnsWidth, setGridTemplateCulumnsWidth] = useState(
    'wallpaper-upload-background-grid-template-colums-400'
  );

  const getGridTemplateCulumnsWidth = (width) => {
    if (width >= 795) {
      setGridTemplateCulumnsWidth('wallpaper-upload-background-grid-template-colums-800');
    }
    if (width < 795 && width >= 595) {
      setGridTemplateCulumnsWidth('wallpaper-upload-background-grid-template-colums-600');
    }
    if (width < 595) {
      setGridTemplateCulumnsWidth('wallpaper-upload-background-grid-template-colums-400');
    }
  };

  useEffect(() => {
    getGridTemplateCulumnsWidth(windowSize.width);
  }, [windowSize]);

  return (
    <div className="wallpaper-upload-background-container">
      <div className="header-settings">
        <span className="header-settings-title">Upload your Background:</span>
        <span className="header-settings-subtitle">Choose and upload pictures to use them</span>
      </div>

      <div className={'wallpaper-upload-background-container-grid ' + gridTemplateCulumnsWidth}>
        {background.backgroundIndexFromDBArray.map((item, index) => (
          <UploadBackgroundItem
            backgroundItem={item}
            key={'UploadBackgroundItem-' + index}
            index={index}
            selectedIndex={background.backgroundIndexFromDBIndex}
            useBAckgroundIndexDB={background.backgroundIndexFromDB}
          />
        ))}
      </div>
    </div>
  );
}
