import React from "react";
import "./Style.css";

import Clock from "react-live-clock";
import { useDispatch, useSelector } from "react-redux";

export default function TimeContainer(props) {
  //const [date, setdate] = useState(new Date());
  const clockState = useSelector((state) => state.clock);

  return (
    <div
      className="time"
      style={
        props.display
          ? { display: "none" }
          : props.opacity === 0
          ? { display: "none" }
          : { opacity: props.opacity, fontWeight: props.fontWeight }
      }
    >
      {clockState.hour24 && !clockState.showSeconds && (
        <Clock format="H:mm" interval={1000} ticking={true} />
      )}
      {!clockState.hour24 && !clockState.showSeconds && (
        <Clock format="h:mm" interval={1000} ticking={true} />
      )}

      {clockState.hour24 && clockState.showSeconds && (
        <Clock
          format="H:mm:ss"
          className="time-container-with-second"
          interval={1000}
          ticking={true}
        />
      )}
      {!clockState.hour24 && clockState.showSeconds && (
        <Clock
          format="h:mm:ss"
          className="time-container-with-second"
          interval={1000}
          ticking={true}
        />
      )}
    </div>
  );
}
