import {
  CircularProgress,
  ImageList,
  ImageListItem,
  Switch
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetWindow from "../../../../components/WidgetWindow/WidgetWindow";
import {
  getUnsplashRandomPicture,
  getUnsplashSearchedResults
} from "../../../../server/Integration/pictures/unsplash.server";
import {
  updateBackgroundFromUnsplash,
  updateBackgroundSuccess
} from "../../../../store/reducers/backgroundReducer/action";
import {
  setShowSearchedPictures,
  setShowUnsplashWindow,
  setUnsplashSelectedPinctureAction,
  setUnsplashUseBackgroundAction
} from "../../../../store/reducers/unsplashReducer/action";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { meaVanaConsole } from "../../../../Utils/MeaVanaConsole";
import "./UnsplashWindow.css";
import { updatePictureWindowStateAction } from "../../../../store/reducers/picturesReducer/action";

export default function UnsplashWindow() {
  const picturesState = useSelector((state) => state.pictures);
  const unsplashPictureState = picturesState.picturesApiSource.find(
    (item) => item.id == "unsplash"
  );

  const [windowSize, setwindowSize] = useState(500);
  const [gridTemplateCulumnsWidth, setGridTemplateCulumnsWidth] = useState(
    "window-integration-unsplash-grid-template-colums-400"
  );

  const unsplashState = useSelector((state) => state.unsplash);
  const dispatch = useDispatch();

  const onClickWindowCloseButton = () => {
    dispatch(updatePictureWindowStateAction());
  };

  let [searchTerm, setSearchTerm] = useState("");

  const getGridTemplateCulumnsWidth = (width) => {
    if (width >= 795) {
      setGridTemplateCulumnsWidth(
        "window-integration-unsplash-grid-template-colums-800"
      );
    }
    if (width < 795 && width >= 595) {
      setGridTemplateCulumnsWidth(
        "window-integration-unsplash-grid-template-colums-600"
      );
    }
    if (width < 595) {
      setGridTemplateCulumnsWidth(
        "window-integration-unsplash-grid-template-colums-400"
      );
    }
  };

  useEffect(() => {
    getGridTemplateCulumnsWidth(windowSize.width);
  }, [windowSize]);

  //handler for when input search term changes(not usable now)
  const handleOnChangeSearchTerm = (e) => {
    let _searchTerm = e.target.value;
    meaVanaConsole("Unsplash handleOnChangeSearchTerm:" + _searchTerm);
    dispatch(getUnsplashSearchedResults(_searchTerm));
    //show the searched picture list
    dispatch(setShowSearchedPictures(true));
  };

  //handler for when a picture is clicked from list(will have other operations download etc)
  const handleOnClickSearchedPicture = (e, picture) => {
    meaVanaConsole("update bakground picture :", picture);

    dispatch(setUnsplashSelectedPinctureAction(picture));
  };

  //get any random picture
  const getRandomImage = () => {
    dispatch(getUnsplashRandomPicture());
  };

  //handler for search button
  const handleOnClickSearchBtn = (e) => {
    e.preventDefault();
    if (searchTerm !== "") {
      dispatch(getUnsplashSearchedResults(searchTerm, 1, 30));
      //show the searched picture list
      dispatch(setShowSearchedPictures(true));
    }
  };
  const srcset = (image, size, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`
    };
  };

  return (
    <WidgetWindow
      title={unsplashPictureState.name}
      isActive={unsplashPictureState.active}
      onClickWindowCloseButton={onClickWindowCloseButton}
      setwindowSize={setwindowSize}
    >
      <div className="unsplash-search-container md-5 py-2">
        <form onSubmit={handleOnClickSearchBtn}>
          <FormControl
            placeholder="Enter keyword"
            aria-label="image"
            aria-describedby="basic-addon1"
            id="searchbox"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <Button
            variant="outline-secondary"
            type="submit"
            id="button-addon2"
            onClick={handleOnClickSearchBtn}
          >
            Search
          </Button>
        </form>

        <div className="unsplash-switch-container">
          Use Unsplash for background
          <Switch
            checked={unsplashState.useUnspashForBackground}
            onChange={(event) => {
              dispatch(setUnsplashUseBackgroundAction(event.target.checked));
            }}
            inputProps={{ "aria-label": "controlled" }}
            color="success"
          />
        </div>
      </div>
      <div
        className={
          "window-integration-unsplash-image-grid " + gridTemplateCulumnsWidth
        }
      >
        {unsplashState.loadingPicture && (
          <div className="unsplash-loading-container">
            <CircularProgress
              size={50}
              sx={{
                color: "#fff",
                alignSelf: "center"
              }}
            />
          </div>
        )}

        {unsplashState.pictures.map((picture, index) => (
          <div
            className="window-integration-unsplash-image-grid-item"
            key={"card-unsplash-" + index}
            onClick={(event) => {
              if (!unsplashState.loadingUnspashBackground) {
                dispatch(setUnsplashUseBackgroundAction(true));
                handleOnClickSearchedPicture(event, picture);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              className="window-integration-unsplash-image-grid-item-img"
              src={picture.urls.regular}
              alt={picture.alt_description}
              loading="lazy"
            />
            <div className="window-integration-unsplash-action-container"></div>
            {unsplashState.loadingUnspashBackground &&
              unsplashState.selectedPicture.id === picture.id && (
                <div className="active-picture-unsplash-action-container">
                  <CircularProgress
                    size={30}
                    sx={{
                      color: "#fff",
                      alignSelf: "center"
                    }}
                  />
                </div>
              )}
          </div>
        ))}
      </div>
    </WidgetWindow>
  );
}
