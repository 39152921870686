import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Close, Info } from "@mui/icons-material";
import {
  BsLayoutSidebarInset,
  BsLayoutSidebarInsetReverse,
  BsArrowsFullscreen,
  BsFullscreenExit,
  BsBoxArrowUpLeft,
  BsBoxArrowUp,
  BsBoxArrowUpRight,
  BsBoxArrowLeft,
  BsBoxArrowRight,
  BsBoxArrowDownLeft,
  BsBoxArrowDown,
  BsBoxArrowDownRight,
  BsArrowsMove
} from "react-icons/bs";
import { RiLayoutGridFill } from "react-icons/ri";

import { OverlayTrigger, Popover } from "react-bootstrap";

import { Rnd } from "react-rnd";
import "./WidgetWindow.css";
import { IconButton } from "@mui/material";
import useWindowDimensions from "../../Utils/useWindowDimensions";

export default function WidgetWindow(props) {
  const { key, title, isActive, titleImg } = props;
  let rnd;
  const dispatch = useDispatch();
  const [posistionWindow, setposistionWindow] = useState({ x: 10, y: 10 });
  const [sizeWindow, setsizeWindow] = useState({ height: 500, width: 610 });
  const [fullScreen, setfullScreen] = useState(false);
  const [activeWindow, setactiveWindow] = useState(false);
  const [oldWindowStyle, setoldWindowStyle] = useState({
    position: { x: 10, y: 10 },
    size: { height: 500, width: 500 }
  });

  const { height, width } = useWindowDimensions();

  let onDragStop = (d) => {
    let pos = { x: d.x, y: d.y };

    setposistionWindow(pos);
  };

  let onResizeStop = (style) => {
    setsizeWindow({
      height: style.height.replace("px", ""),
      width: style.width.replace("px", "")
    });

    if (props.setwindowSize) {
      props.setwindowSize({
        height: style.height.replace("px", ""),
        width: style.width.replace("px", "")
      });
    }
  };
  const updatePositionWinsow = (position) => {
    let pos = { x: 0, y: 0 };
    let size = { width: width / 3, height: height / 3 };
    if (width < 930) {
      size.width = 310;
    }
    if (height < 900) {
      size.height = 300;
    }

    switch (position) {
      case "top-right":
        pos = { x: 0, y: 0 };
        break;
      case "top-center":
        pos = { x: size.width, y: 0 };
        break;
      case "top-left":
        pos = { x: width - size.width, y: 0 };
        break;
      case "center-right":
        pos = { x: 0, y: size.height };
        break;
      case "center-center":
        pos = { x: size.width, y: size.height };
        break;
      case "center-left":
        pos = { x: width - size.width, y: size.height };
        break;
      case "bottom-right":
        pos = { x: 0, y: height - size.height };
        break;
      case "bottom-center":
        pos = { x: size.width, y: height - size.height };
        break;
      case "bottom-left":
        pos = { x: width - size.width, y: height - size.height };
        break;
      default:
        pos = { x: 0, y: 0 };
        break;
    }

    rnd.updatePosition(pos);
    rnd.updateSize(size);
    if (props.setwindowSize) {
      props.setwindowSize({
        height: size.height,
        width: size.width
      });
    }

    document.body.click();
  };

  return (
    <Rnd
      style={{
        zIndex: activeWindow ? 150 : isActive ? 100 : -100,
        visibility: isActive ? "visible" : "hidden",
        display: isActive ? "block" : "none"
      }}
      // bounds="window"
      className="rnd-window-container"
      bounds="parent"
      ref={(c) => {
        rnd = c;
      }}
      default={{
        x: 10,
        y: 10,
        width: "610px",
        height: "500px"
      }}
      disableDragging={false}
      dragHandleClassName={"integration-window-header"}
      minWidth="310px"
      minHeight="300px"
      maxHeight="100vh"
      maxWidth="100vw"
      onDragStop={(e, d) => onDragStop(d)}
      onResizeStop={(e, direction, ref) => onResizeStop(ref.style)}
      onMouseOver={() => setactiveWindow(true)}
      onMouseLeave={() => setactiveWindow(false)}
    >
      <div className="integration-window" key={"window-integration-news-1"}>
        <div className="integration-window-header">
          <div className="integration-window-header-left"></div>

          <div className="integration-window-header-center">
            {titleImg ? (
              <img
                src={titleImg.src}
                width={titleImg.width}
                alt={titleImg.alt}
              />
            ) : (
              ""
            )}

            {title}
          </div>

          <div className="integration-window-header-right">
            <OverlayTrigger
              trigger="click"
              key="OverlayTrigger-calendar"
              placement="bottom"
              rootClose={true}
              overlay={
                <Popover className="calendar-popover">
                  <Popover.Body className="calendar-popover-body">
                    <div className="share-meavana-grid">
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("top-right");
                        }}
                      >
                        <BsBoxArrowUpLeft size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("top-center");
                        }}
                      >
                        <BsBoxArrowUp size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("top-left");
                        }}
                      >
                        <BsBoxArrowUpRight size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("center-right");
                        }}
                      >
                        <BsBoxArrowLeft size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("center-center");
                        }}
                      >
                        <BsArrowsMove size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("center-left");
                        }}
                      >
                        <BsBoxArrowRight size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("bottom-right");
                        }}
                      >
                        <BsBoxArrowDownLeft size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("bottom-center");
                        }}
                      >
                        <BsBoxArrowDown size="1.5rem" />
                      </IconButton>
                      <IconButton
                        className="integration-window-header-btn"
                        onClick={() => {
                          updatePositionWinsow("bottom-left");
                        }}
                      >
                        <BsBoxArrowDownRight size="1.5rem" />
                      </IconButton>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <IconButton className="integration-window-header-btn">
                <RiLayoutGridFill size="1.5rem" />
              </IconButton>
            </OverlayTrigger>

            <IconButton
              className="integration-window-header-btn"
              onClick={() => {
                if (!fullScreen) {
                  setoldWindowStyle({
                    position: posistionWindow,
                    size: sizeWindow
                  });
                  rnd.updatePosition({ x: 0, y: 0 });
                  rnd.updateSize({ width: width, height: height });
                  if (props.setwindowSize) {
                    props.setwindowSize({
                      height: height,
                      width: width
                    });
                  }
                } else {
                  rnd.updatePosition(oldWindowStyle.position);
                  rnd.updateSize(oldWindowStyle.size);
                  if (props.setwindowSize) {
                    props.setwindowSize({
                      height: oldWindowStyle.size.height,
                      width: oldWindowStyle.size.width
                    });
                  }
                }
                setfullScreen(!fullScreen);
              }}
            >
              {fullScreen ? (
                <BsFullscreenExit size="1.5rem" />
              ) : (
                <BsArrowsFullscreen size="1.5rem" />
              )}
            </IconButton>

            <IconButton
              className="integration-window-header-btn"
              onClick={() => {
                props.onClickWindowCloseButton();
              }}
            >
              <Close fontSize="medium" />
            </IconButton>
          </div>
        </div>

        <div className="integration-window-body">{props.children}</div>
      </div>
    </Rnd>
  );
}
