import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetWindow from "../../../../components/WidgetWindow/WidgetWindow";
import { updateNewsWindowStateAction } from "../../../../store/reducers/newsReducer/action";
import "./NewsWindow.css";

export default function NewsWindow() {
  const newsState = useSelector((state) => state.news);
  const dispatch = useDispatch();

  const activeSource = newsState.newsSources[newsState.activeNewsIndex];
  const [windowSize, setWindowSize] = useState(500);
  const [gridTemplateColumnsWidth, setGridTemplateColumnsWidth] = useState(
    "window-integration-unsplash-grid-template-colums-400"
  );

  const getGridTemplateColumnsWidth = (width) => {
    if (width >= 795) {
      setGridTemplateColumnsWidth(
        "window-integration-news-grid-template-colums-800"
      );
    }

    if (width < 795 && width >= 595) {
      setGridTemplateColumnsWidth(
        "window-integration-news-grid-template-colums-600"
      );
    }

    if (width < 595) {
      setGridTemplateColumnsWidth(
        "window-integration-news-grid-template-colums-400"
      );
    }
  };

  useEffect(() => {
    getGridTemplateColumnsWidth(windowSize.width);
  }, [windowSize]);

  return (
    <WidgetWindow
      title={activeSource.name}
      isActive={true}
      onClickWindowCloseButton={() => {
        dispatch(updateNewsWindowStateAction());
      }}
      setWindowSize={setWindowSize}
    >
      {activeSource.status === "success" && activeSource.data.length > 0 ? (
        <div className={`window-integration-grid ${gridTemplateColumnsWidth}`}>
          {activeSource.data.map((item, index) => (
            <div
              className="window-integration-grid-item"
              key={`card-news-${index}`}
              onClick={() => {
                window.open(item.link, "_blank");
              }}
            >
              <div className="window-integration-news-hover-container"></div>
              <img
                className="window-integration-grid-item-img"
                src={item.image}
                alt={item.image}
                loading="lazy"
              />

              <div className="window-integration-grid-item-info-box">
                <div className="window-integration-grid-item-title-box">
                  <span className="window-integration-grid-item-title">
                    {item.title}
                  </span>
                  <span className="window-integration-grid-item-subtitle">
                    {item.description}
                  </span>
                </div>
                <div className="window-integration-grid-item-action-box">
                  {/**
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${item.title}`}
                        onClick={() => {
                          window.open(item.url, "_blank");
                        }}
                      >
                        <Info />
                      </IconButton>
                     */}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>loading news ...</div>
      )}
    </WidgetWindow>
  );
}
