import { assetsIcons } from '../../../config/images';

export const initialState = {
  googleApps: [
    {
      id: 'gcalendar',
      name: 'Calendar',
      accessToken: '',
      refreshToken: '',
      icons: {
        black: '/assets/icons/Integration/Music/Spotify/black.svg',
        color: '/assets/icons/Integration/Music/Spotify/color.svg',
        white: '/assets/icons/Integration/Music/Spotify/white.svg'
      },
      active: false,
      loaded: false,
      status: 'empty',
      error: null,
      errorMessage: '',
      pined: false
    }
  ],
  activeNewsIndex: null,
  lastUpdatedAt: null
};

export const googleStateAction = {
  UPDATE_GOOGLE_WINDOW_STATE: 'google/UPDATE_GOOGLE_WINDOW_STATE',
  UPDATE_GOOGLE_PIN: 'google/UPDATE_GOOGLE_PIN',
  GET_GOOGLE_API_REQUEST: 'google/GET_GOOGLE_API_REQUEST',
  GET_GOOGLE_API_SUCCESS: 'google/GET_GOOGLE_API_SUCCESS',
  GET_GOOGLE_API_FAILURE: 'google/GET_GOOGLE_API_FAILURE'
};
