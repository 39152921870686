import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Add, Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import validator from 'validator';

import { CircularProgress } from '@mui/material';
import {
  addBookmarkServer,
  bulkUpdateBookmarksServer,
  deleteBookmarkServer,
  updateBookmarkServer
} from '../../../server/Dashbord/bookmark.server';
import { setSelectedBookmark } from '../../../store/reducers/bookmarksReducer/action';
import { BookmarkGetImageSrcUrl } from '../../../Utils/BookmarkGetImageSrcUrl';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import { assetsIcons } from '../../../config/images';

export default function UserBookmarks(props) {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const bookmarksState = useSelector((state) => state.bookmarks);

  let dashboardId = dashboard && dashboard._id ? dashboard._id : null;
  const bookmarkList = bookmarksState && bookmarksState.bookmarks ? bookmarksState.bookmarks : [];

  const [openDialogUpdateBookmark, setopenDialogUpdateBookmark] = useState(false);
  const [openDialogAddBookmark, setopenDialogAddBookmark] = useState(false);

  const redirectToUrl = (e, url) => {
    //redirect only if bookmark is clicked not the remove/delete icon
    if (bookmarksState.openBookmarkInNewWindow) {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_blank');
    } else {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_self');
    }
  };
  const onBookmarkDragEnd = (result) => {
    //return without processing further if no result
    //or return if result exists but no drag happened i.e bookmark is at same place where it was()
    if (!result || (result && result.oldIndex === result.newIndex)) return;

    //update the index of bookmark dragged/dropped within Array
    let reorderedBookmarks = arrayMove(bookmarkList, result.oldIndex, result.newIndex);
    //update the index property of Bookmark objects as well to be saved in Backend/DB
    reorderedBookmarks = reorderedBookmarks.map((_bookmark, index) => {
      return {
        ..._bookmark,
        prevIndex: _bookmark.index,
        index: index + 1
      };
    });

    dispatch(bulkUpdateBookmarksServer(dashboardId, reorderedBookmarks));
  };

  const handleClickOpen = (bookmark) => {
    //set the current selected bookmark to be shown in dialog
    dispatch(setSelectedBookmark(bookmark));
    //show the dialog
    setopenDialogUpdateBookmark(true);
    // dispatch(toggleBookmarkUpdateDialog(true));
  };

  /**
   * A Bookmark Component for showing a single Bookmark
   * @param {object} an object containing index value and a bookmark object(props:name and value)
   * @returns a single Bookmark Div
   */
  const Bookmark = SortableElement(({ index, bookmark }) => {
    if (bookmark === null) return null;
    //image source url for favicons to be shown
    const bookmarkImgSrc = bookmark.url
      ? BookmarkGetImageSrcUrl(bookmark.url)
      : GetImageUrl(assetsIcons.folder);

    return (
      <Tooltip
        arrow
        //  followCursor
        key={'bookmark-tooltip-item-' + index}
        title={bookmark.name}
        placement="top"
      >
        <div
          className={
            bookmarksState.bookmarkShapeRectangle
              ? 'rectangular-shape bookmarks-item'
              : 'bookmarks-item'
          }
          key={'bookmark-item-container' + index}
        >
          <IconButton
            className="delete-bookmark-btn"
            id="moreOptionBtn"
            aria-label="Delete"
            onClick={(event) => {
              event.preventDefault();
              handleClickOpen(bookmark);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>

          <div
            className="bookmarks-item-container"
            onClick={(event) => {
              event.preventDefault();
              redirectToUrl(event, bookmark.url);
            }}
          >
            <img
              src={bookmarkImgSrc}
              alt={bookmark.name}
              className={`bookmark-img ${!bookmarksState.bookmarkColored && 'grayscale-filter'}`}
            ></img>
            {/* <span className="bookmark-txt"> {bookmark.name}</span> */}
          </div>
        </div>
      </Tooltip>
    );
  });

  const [inputBookmarkName, setinputBookmarkName] = useState('');
  const [inputBookmarkUrl, setinputBookmarkUrl] = useState('');
  const [errorAddBookmark, seterrorAddBookmark] = useState({
    error: false,
    message: ''
  });
  const [inputUrlTouched, setinputUrlTouched] = useState(false);

  const verifBookmarkUrlFormat = (url = '') => {
    if (validator.isEmpty(url, { ignore_whitespace: true })) {
      seterrorAddBookmark({
        error: true,
        message: 'Please enter an URL address'
      });
    } else {
      if (!validator.isURL(url)) {
        seterrorAddBookmark({
          error: true,
          message: 'URL must be a valid format'
        });
      } else {
        seterrorAddBookmark({ error: false, message: '' });
      }
    }
  };
  const addBookmark = async () => {
    await verifBookmarkUrlFormat(inputBookmarkUrl);
    if (!errorAddBookmark.error && inputBookmarkUrl) {
      seterrorAddBookmark({ error: false, message: '' });
      let newbookmark = {
        name: inputBookmarkName === '' ? inputBookmarkUrl : inputBookmarkName,
        url: inputBookmarkUrl
        // index:bookmarkList.length +1
      };
      dispatch(addBookmarkServer(newbookmark, dashboardId));

      handleCloseDialogueAddBookmark();
    }
  };

  const handleClickOpenDialogueAddBookmark = () => {
    setinputBookmarkName('');
    setinputBookmarkUrl('');
    setinputUrlTouched(false);
    seterrorAddBookmark({ error: false, message: '' });
    setopenDialogAddBookmark(true);
  };

  const handleCloseDialogueAddBookmark = () => {
    setopenDialogAddBookmark(false);
    setinputBookmarkName('');
    setinputBookmarkUrl('');
    setinputUrlTouched(false);
    seterrorAddBookmark({ error: false, message: '' });
  };

  /**
   *
   * @returns a popup dialog to update an existing Bookmark
   * @param {object} bookmark a bookmark object to be updated
   */
  const UpdateBookmarkItem = ({}) => {
    //get the state for Bookmarks
    const bookmarksState = useSelector((state) => state.bookmarks);
    //use the state for the values required
    // let showUpdateDialog = bookmarksState && bookmarksState.showUpdateDialog ? bookmarksState.showUpdateDialog :false;
    let bookmark = bookmarksState && bookmarksState.selectedBookmark;

    //use the selected bookmark for initial values
    let [inputBookmarkName, setinputBookmarkName] = useState(bookmark.name);
    let [inputBookmarkUrl, setinputBookmarkUrl] = useState(bookmark.url);

    //function to handle click of updating a bookmark
    const handleUpdateBookmark = () => {
      //construct a bookmark witn new values
      let updatedBookmark = {
        _id: bookmark._id,
        name: inputBookmarkName,
        url: inputBookmarkUrl
      };
      //dispatch the action to updpate the bookmark
      dispatch(updateBookmarkServer(updatedBookmark, dashboardId));
      handleCloseDialoguedeleteBookmark();
    };

    const handleDeleteBookmark = () => {
      dispatch(deleteBookmarkServer(bookmark, dashboardId));

      handleCloseDialoguedeleteBookmark();
    };

    //close the dialog and clear the inputs
    const handleCloseDialoguedeleteBookmark = () => {
      setopenDialogUpdateBookmark(false);
      setinputBookmarkName('');
      setinputBookmarkUrl('');
    };

    //render the upload Dialog
    return (
      <div className="bookmarks-update-item">
        <Dialog open={openDialogUpdateBookmark} onClose={handleCloseDialoguedeleteBookmark}>
          <DialogContent>
            <div className="form-group form-group-bookmark">
              <label>Are you sure you want to delete this bookmark ?</label>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="info" onClick={handleDeleteBookmark}>
              {bookmarksState.deleteBoomarkLOading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#fff',
                    alignSelf: 'center'
                  }}
                />
              ) : (
                'Delete'
              )}
            </Button>
          </DialogActions>
        </Dialog>
        {/*   <Dialog open={openDialogUpdateBookmark} onClose={handleClose}>
          <DialogTitle>Update Bookmark</DialogTitle>
          <DialogContent>
            <div className="form-group form-group-bookmark">
              <label>Bookmark name</label>
              <input
                type="text"
                className="form-control form-control-bookmark"
                placeholder="bookmark name"
                value={inputBookmarkName}
                onChange={(event) => setinputBookmarkName(event.target.value)}
              />
            </div>
            <div className="form-group form-group-bookmark">
              <label>Bookmark url</label>
              <input
                type="text"
                className="form-control form-control-bookmark"
                placeholder="bookmark url"
                value={inputBookmarkUrl}
                onChange={(event) => setinputBookmarkUrl(event.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="info"
              onClick={handleDeleteBookmark}
            >
              Remove
            </Button>
            <Button variant="contained" color="info" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={handleUpdateBookmark}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>*/}
      </div>
    );
  };

  const SortableBookmarkList = SortableContainer(({ bookmarks }) => {
    return (
      <div
        className={`bookmarks-container-sortable-list ${
          bookmarksState.bookmarkSizeBig ? 'bookmark-lg' : 'bookmark-sm'
        } ${'show-scroll-bar' /*props.showIcons ? "show-scroll-bar" : "hide-scroll-bar"*/}
        `}
      >
        {bookmarks.map((bookmark, index) => {
          return bookmark === null ? null : (
            <Bookmark key={`bookmark-item-${index}`} index={index} bookmark={bookmark} />
          );
        })}

        {bookmarksState.addBoomarkLoading ? (
          <div
            className={
              bookmarksState.bookmarkShapeRectangle
                ? 'rectangular-shape bookmarks-item'
                : 'bookmarks-item'
            }
          >
            <div className="bookmarks-item-container">
              <CircularProgress
                size={25}
                sx={{
                  color: '#fff',
                  alignSelf: 'center'
                }}
              />
            </div>
          </div>
        ) : null}
        <div
          className={
            bookmarksState.bookmarkShapeRectangle
              ? 'rectangular-shape bookmarks-item bookmarks-add-item'
              : 'bookmarks-item bookmarks-add-item'
          }
          key="add-new-bookmark-item"
        >
          <IconButton
            className="bookmark-add-btn "
            aria-label="Add"
            onClick={handleClickOpenDialogueAddBookmark}
          >
            <Add className="home-icon-setting-font-size" />
          </IconButton>
        </div>
      </div>
    );
  });

  return (
    <>
      <SortableBookmarkList
        bookmarks={bookmarkList}
        axis="xy"
        distance={1}
        onSortEnd={onBookmarkDragEnd}
      />
      <UpdateBookmarkItem />
      <Dialog open={openDialogAddBookmark} onClose={handleCloseDialogueAddBookmark}>
        <form onSubmit={addBookmark}>
          <DialogTitle>Add Bookmark</DialogTitle>
          <DialogContent>
            <div className="form-group form-group-bookmark">
              <label>Bookmark name (Optional)</label>
              <input
                type="text"
                className="form-control form-control-bookmark"
                placeholder=""
                value={inputBookmarkName}
                onChange={(event) => setinputBookmarkName(event.target.value)}
              />
            </div>
            <div className="form-group form-group-bookmark">
              <label>Bookmark url</label>
              <input
                type="text"
                className="form-control form-control-bookmark"
                placeholder=""
                value={inputBookmarkUrl}
                onChange={(event) => {
                  event.preventDefault();
                  setinputBookmarkUrl(event.target.value);
                  setinputUrlTouched(true);
                }}
                onBlur={(event) => {
                  event.preventDefault();
                  verifBookmarkUrlFormat(inputBookmarkUrl);
                }}
                onFocus={(event) => {
                  event.preventDefault();
                  seterrorAddBookmark({ error: false, message: '' });
                }}
              />
            </div>
            {errorAddBookmark.error ? (
              <p className="error-msg-txt">{errorAddBookmark.message}</p>
            ) : (
              <p className="error-msg-txt"></p>
            )}
          </DialogContent>
          <DialogActions>
            {inputBookmarkUrl != '' ? (
              <div className="container-preview-add-bookmark">
                <label>Preview :</label>
                <Tooltip
                  arrow
                  //  followCursor
                  key="bookmark-tooltip-item-preview-add"
                  title={inputBookmarkName !== '' ? inputBookmarkName : inputBookmarkUrl}
                  placement="top"
                >
                  <div
                    className={
                      bookmarksState.bookmarkShapeRectangle
                        ? 'rectangular-shape bookmarks-item'
                        : 'bookmarks-item'
                    }
                    key="bookmark-item-container-preview-add"
                  >
                    <div className="bookmarks-item-container">
                      <img
                        src={BookmarkGetImageSrcUrl(inputBookmarkUrl)}
                        alt={inputBookmarkName !== '' ? inputBookmarkName : inputBookmarkUrl}
                        className={`bookmark-img ${
                          !bookmarksState.bookmarkColored && 'grayscale-filter'
                        }`}
                      ></img>
                      {/* <span className="bookmark-txt"> {bookmark.name}</span> */}
                    </div>
                  </div>
                </Tooltip>
              </div>
            ) : (
              <div className="container-preview-add-bookmark"></div>
            )}

            <Button color="info" variant="contained" onClick={handleCloseDialogueAddBookmark}>
              CANCEL
            </Button>
            <Button
              color="info"
              variant="contained"
              type="submit"
              disabled={bookmarksState.addBoomarkLoading || errorAddBookmark.error}
              onClick={addBookmark}
            >
              {bookmarksState.addBoomarkLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#fff',
                    alignSelf: 'center'
                  }}
                />
              ) : (
                'Add'
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
