import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WidgetWindow from '../../components/WidgetWindow/WidgetWindow';

import { updateWallpaperWindowStateAction } from '../../store/reducers/wallpaperReducer/action';
import { meaVanaConsole } from '../../Utils/MeaVanaConsole';
import { Tabs, Tab } from 'react-bootstrap';

import './WallpaperWindow.css';
import UnsplashSearchContainer from './UnsplashSearchContainer/UnsplashSearchContainer';
import MeavanaTopics from './MeavanaTopics/MeavanaTopics';
import AnimationsBackground from './AnimationsBackground/AnimationsBackground';
import { setLoadingUnsplashUseBackgroundAction } from '../../store/reducers/unsplashReducer/action';
import { updateSelectedTopicSuccessAction } from '../../store/reducers/backgroundReducer/action';
import UploadBackground from './UploadBackground/UploadBackground';

export default function WallpaperWindow() {
  const [windowSize, setwindowSize] = useState(500);

  const wallpaperState = useSelector((state) => state.wallpaper);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoadingUnsplashUseBackgroundAction(false));
    dispatch(updateSelectedTopicSuccessAction());
  }, []);

  const onClickWindowCloseButton = () => {
    dispatch(updateWallpaperWindowStateAction());
  };
  if (wallpaperState.active) {
    return (
      <WidgetWindow
        title={wallpaperState.name}
        isActive={wallpaperState.active}
        onClickWindowCloseButton={onClickWindowCloseButton}
        setwindowSize={setwindowSize}
        typeOfWindow="wallpaper"
      >
        <Tabs
          defaultActiveKey="unsplash_picture"
          id="fill-tab-example"
          className="wallpaper-tabs"
          fill={true}
          justify={true}
        >
          <Tab eventKey="unsplash_picture" className="wallpaper-tab" title="Search Pictures">
            <UnsplashSearchContainer windowSize={windowSize} />
          </Tab>
          <Tab eventKey="meavana_topics" className="headings" title="Meavana Topics">
            <MeavanaTopics windowSize={windowSize} />
          </Tab>
          <Tab eventKey="animations" className="headings" title="Animations">
            <AnimationsBackground windowSize={windowSize} />
          </Tab>
          <Tab eventKey="upload" className="headings" title="Upload">
            <UploadBackground windowSize={windowSize} />
          </Tab>
        </Tabs>
      </WidgetWindow>
    );
  } else {
    return null;
  }
}
