import axios from "axios";
import { Authorization, SERVER_URL } from "../../config/constants";
import {
  LogoutAction,
  updateUserTokensAction
} from "../../store/reducers/authReducer/action";
import { store } from "../../store/store";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import { sendEmailLogout } from "../../Utils/SendEmailJs";

export default function axiosInstance() {
  const authState = store.getState().auth;
  const AUTHORIZATION = Authorization;

  let headers = {};

  if (authState.ACCESS_TOKEN != null) {
    headers.Authorization = AUTHORIZATION + authState.ACCESS_TOKEN;
  }

  const axiosInstance = axios.create({
    baseURL: SERVER_URL,
    headers
  });

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = AUTHORIZATION + authState.ACCESS_TOKEN;

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      meaVanaConsole("this is in response", response.data);
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      meaVanaConsole("error in interceptor", error);
      meaVanaConsole("error.response in interceptor", error.response.data);

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = authState.REFRESH_TOKEN.refreshToken;
        meaVanaConsole("axios refresh-access-token start");
        const params = new URLSearchParams();
        params.append("refreshToken", refreshToken);

        return axios
          .post(SERVER_URL + "user/refresh-access-token/", params)
          .then((res) => {
            if (res.status === 201) {
              //  store the access and the refresh

              store.dispatch(updateUserTokensAction(res.data));
              meaVanaConsole("refresh-access-token response", res.data);

              originalRequest.headers["Authorization"] =
                AUTHORIZATION + res.data.accessToken;
              return axios(originalRequest);
            } else {
              meaVanaConsole("refresh-access-token  ", res.data);
              console.log("user logout refrech token expired");
              meaVanaConsole(
                "dispatch LogoutAction refresh-access-token response != 201"
              );
              sendEmailLogout(
                "User Logout",
                "LogoutAction refresh-access-token response != 201 **axiosInstance.js line 73 ** USER :" +
                  JSON.stringify(authState.user) +
                  " ***response :" +
                  JSON.stringify(res),
                authState.user.username,
                authState.user.email
              );
              store.dispatch(LogoutAction());
              return;
            }
          })
          .catch((err) => {
            meaVanaConsole("error in refresh ", err);
            console.log("user logout refrech token expired");
            meaVanaConsole("dispatch LogoutAction refresh-access-token error");
            sendEmailLogout(
              "User Logout",
              "LogoutAction refresh-access-token error **axiosInstance.js line 82 ** USER :" +
                JSON.stringify(authState.user) +
                " ***Error :" +
                JSON.stringify(err),
              authState.user.username,
              authState.user.email
            );
            store.dispatch(LogoutAction());
            return;
          });
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}
