import React, { useEffect, useState } from "react";
import "./TimeFeatures.css";
import {
  Close,
  PauseCircleOutlined,
  PlayCircleOutline,
  StopCircleOutlined
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
export default function StopwatchComponent(props) {
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn]);

  /*
  useEffect(() => {
    setTime(0);
    setTimerOn(false)
  }, [props.showStopWatch,props.showTimeFeature])
  */

  return (
    <div
      className="Stopwatch-container"
      style={!props.showStopWatch ? { display: "none" } : {}}
    >
      <div
        className="btn-close-time-feature"
        onClick={() => {
          props.setshowStopWatch(!props.showStopWatch);
          props.setshowTimeFeature(!props.showTimeFeature);
        }}
      >
        <Close fontSize="medium" />
      </div>
      <h3>Stopwatch</h3>
      <CountdownCircleTimer
        isPlaying={false}
        duration={0}
        size={150}
        // trailColor="#fff"
        onComplete={() => {
          return [true, 100];
        }}
        colors={[
          ["#00bb1f", 0.4],
          ["#e7af04", 0.4],
          ["#d40202", 0.2]
        ]}
      >
        {({ remainingTime }) => {
          return (
            <div className="container-input-group-timer">
              <div className="input-timer-container">
                <div className="input-timer">
                  {("0" + Math.floor((time / 60000) % 60)).slice(-2)}
                </div>
              </div>
              :
              <div className="input-timer-container">
                <div className="input-timer">
                  {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
                </div>
              </div>
              :
              <div className="input-timer-container">
                <div className="input-timer">
                  {("0" + ((time / 10) % 100)).slice(-2)}
                </div>
              </div>
            </div>
          );
        }}
      </CountdownCircleTimer>

      <div className="Stopwatch-buttons">
        {!timerOn && time === 0 && (
          //play
          <div className="btn-icons-timer" onClick={() => setTimerOn(true)}>
            <PlayCircleOutline fontSize="large" />
          </div>
        )}
        {timerOn && (
          // Stop

          <div className="btn-icons-timer" onClick={() => setTimerOn(false)}>
            <PauseCircleOutlined fontSize="large" />
          </div>
        )}
        {!timerOn && time > 0 && (
          //reset
          <div className="btn-icons-timer" onClick={() => setTime(0)}>
            <StopCircleOutlined fontSize="large" />
          </div>
        )}
        {!timerOn && time > 0 && (
          //Resume
          <div className="btn-icons-timer" onClick={() => setTimerOn(true)}>
            <PlayCircleOutline fontSize="large" />
          </div>
        )}
      </div>
    </div>
  );
}
