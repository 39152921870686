/* global chrome */

import React, { useEffect } from 'react';
import BackgroundMeaVana from './Background/BackgroundMeaVana';
import Home from './Home/Home';
import Guest from './Guest/Guest';
import Loading from './Loading/Loading';
import Auth from './Auth/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { getCanvasID } from '../Utils/Fingerprint';
import {
  updateAppStateLoadingAction,
  updateDeviceIDAction
} from '../store/reducers/appReducer/action';
import { meaVanaConsole } from '../Utils/MeaVanaConsole';
import {
  getChromeBookmarksAction,
  getTopVisitedSitesAction
} from '../store/reducers/bookmarksReducer/action';
import { APP_ENV } from '../config/constants';
import { chromeBookmarks } from '../Utils/Bookmaks';
import {
  checkBackgroundLastUpdate,
  checkUpdateTopicFromBackend
} from '../Utils/checkBackgroundLastUpdate';
import { getAllTopic } from '../server/Dashbord/backgound.server';
import { getDifferenceInHours } from '../Utils/timeDifferenceFunctions';
import { getNewsItems } from '../server/Integration/news.server';
import {
  getTodayWeatherApiOpenWeatherMap,
  getWeekWeatherApiOpenWeatherMap
} from '../server/Weather/weather.server';
import { getCurrentDashboardAction } from '../server/Dashbord/dashboard.server';

export default function AppContainer() {
  const appState = useSelector((state) => state.app);
  const backgroundState = useSelector((state) => state.background);
  const newsState = useSelector((state) => state.news);
  const bookmarks = useSelector((state) => state.bookmarks);
  const weatherState = useSelector((state) => state.weather);
  const analyticsState = useSelector((state) => state.analytics);

  const dispatch = useDispatch();

  useEffect(() => {
    //  meaVanaConsole("analyticsState :",  analyticsState);
    meaVanaConsole('update theme styling');
    let themeClassList = [
      appState.themeMode,
      appState.themeFontFamily,
      appState.themeTextSize,
      appState.backgroundMode
    ];
    try {
      if (document.body.classList.length > 4) {
        document.body.className = '';
        document.body.classList.add(...themeClassList);
      }

      if (!document.body.classList.contains(appState.themeMode)) {
        document.body.classList.add(appState.themeMode);
      }
      if (!document.body.classList.contains(appState.backgroundMode)) {
        document.body.classList.add(appState.backgroundMode);
      }
      if (!document.body.classList.contains(appState.themeFontFamily)) {
        document.body.classList.add(appState.themeFontFamily);
      }
      if (!document.body.classList.contains(appState.themeTextSize)) {
        document.body.classList.add(appState.themeTextSize);
      }
    } catch (e) {
      meaVanaConsole('error >>> ', e);
    }
  }, [
    appState.themeMode,
    appState.themeFontFamily,
    appState.themeTextSize,
    appState.backgroundMode
  ]);

  useEffect(() => {
    checkBAckgroundImageForUpdate();
    getBookmarksFromChrome();
    getTopVisitedSites();
    updateDeviceID();
    updateWeatherInfo();
    updateNewsApiData();
  }, []);

  /* useEffect(() => {
    if (typeof chrome != "undefined" && chrome.tabs) {
      chrome.tabs.onActivated.addListener((activeInfo) =>
        onActivatedTab(activeInfo)
      );
    } else {
      meaVanaConsole(" chrome is undefined");
    }
  }, []);*/

  const updateDeviceID = () => {
    if (!appState.deviceId) {
      const fingerprint = getCanvasID({ debug: false });
      dispatch(updateDeviceIDAction(fingerprint));
    }
  };

  const getBookmarksFromChrome = () => {
    if (typeof chrome != 'undefined' && chrome.bookmarks) {
      chrome.bookmarks.getTree((bookmarkTree) => {
        meaVanaConsole('old chrome bookmark from meavana', bookmarks.chromeBoomkmarks);
        meaVanaConsole('bookmarkTree', bookmarkTree);
        let arrayCHromeBM = [];
        if (bookmarkTree[0].children) {
          if (bookmarkTree[0].children[0] && bookmarkTree[0].children[0].children) {
            arrayCHromeBM = bookmarkTree[0].children[0].children;
          }
        }
        meaVanaConsole('chrome bookmark saved', arrayCHromeBM);

        meaVanaConsole('dispatch get chrome bookmarks');

        dispatch(getChromeBookmarksAction(arrayCHromeBM));
      });
    } else {
      if (APP_ENV === 'development') {
        meaVanaConsole('in development chrome bookmark tree', chromeBookmarks);

        let arrayCHromeBM = chromeBookmarks.children;

        meaVanaConsole('chrome bookmark saved', arrayCHromeBM);

        meaVanaConsole('dispatch get chrome bookmarks');

        dispatch(getChromeBookmarksAction(arrayCHromeBM));
      } else {
        dispatch(getChromeBookmarksAction([]));
      }
    }
  };

  const getTopVisitedSites = () => {
    if (typeof chrome != 'undefined' && chrome.topSites) {
      chrome.topSites.get((data) => {
        meaVanaConsole('top visited data', data);
        dispatch(getTopVisitedSitesAction(data));
      });
    } else if (APP_ENV === 'development') {
      meaVanaConsole('in development top visited sites tree', chromeBookmarks);

      let topVisited = chromeBookmarks.topvisited;

      meaVanaConsole('Top VIsited', topVisited);

      meaVanaConsole('dispatch to get top visited');

      dispatch(getTopVisitedSitesAction(topVisited));
    } else {
      dispatch(getTopVisitedSitesAction([]));
    }
  };

  const checkBAckgroundImageForUpdate = () => {
    /*if (
      checkUpdateTopicFromBackend(backgroundState.topicLastUpdate) ||
      backgroundState.topics.length === 0
    ) {
      dispatch(getAllTopic());
    }*/
    if (
      checkBackgroundLastUpdate(backgroundState.backgroundLastUpdate) &&
      !appState.showLoading &&
      appState.isLoggedIn
    ) {
      meaVanaConsole('update background start app');

      dispatch(getCurrentDashboardAction());
    }
  };

  const updateNewsApiData = () => {
    meaVanaConsole('start updateNewsApiData ');
    if (APP_ENV === 'production') {
      meaVanaConsole(' updateNewsApiData APP_ENV production');
      for (let i = 0; i < newsState.newsSources.length; i++) {
        const newsSource = newsState.newsSources[i];

        if (
          newsSource.data.length === 0 ||
          newsSource.lastUpdatedAt == null ||
          getDifferenceInHours(newsSource.lastUpdatedAt, new Date()) >= 6
        ) {
          dispatch(getNewsItems(newsSource.rssUrl, i));
        }
      }
    } else {
      meaVanaConsole(' updateNewsApiData APP_ENV  development');
    }
  };

  const updateWeatherInfo = () => {
    meaVanaConsole(
      'updateWeatherInfo  getDifferenceInHours lastUpdate',
      getDifferenceInHours(new Date(weatherState.lastUpdate), new Date())
    );
    if (
      (!!navigator.geolocation &&
        getDifferenceInHours(new Date(weatherState.lastUpdate), new Date()) >= 1) ||
      weatherState.firstTimeUsingWeather
    ) {
      meaVanaConsole('start updateWeatherInfo');
      if (
        weatherState.coordsSearchCity &&
        weatherState.coordsSearchCity.latitude &&
        weatherState.coordsSearchCity.longitude
      ) {
        dispatch(
          getTodayWeatherApiOpenWeatherMap(
            weatherState.coordsSearchCity,
            weatherState.firstTimeUsingWeather
          )
        );
        dispatch(getWeekWeatherApiOpenWeatherMap(weatherState.coordsSearchCity));
      } else {
        navigator.geolocation.getCurrentPosition(
          (c) => {
            dispatch(
              getTodayWeatherApiOpenWeatherMap(c.coords, weatherState.firstTimeUsingWeather)
            );
            dispatch(getWeekWeatherApiOpenWeatherMap(c.coords));
          },
          (error) => {
            meaVanaConsole('error getCurrentPosition', error);
            meaVanaConsole("Permission denied. Can't show weather information.");
          }
        );
      }
    }
  };

  return (
    <>
      <BackgroundMeaVana isLoggedIn={appState.isLoggedIn} guestMode={appState.guestMode} />
      <Loading />
      {appState.isLoggedIn ? <Home /> : appState.guestMode ? <Guest /> : <Auth />}
    </>
  );
}
