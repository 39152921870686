import { initialState, params, weatherStateAction } from './constants';
import { appStateAction } from '../appReducer/constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const weatherPersistConfig = {
  key: 'weather',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING weather :', error)
};
export const weatherPersisted = persistReducer(weatherPersistConfig, weatherReducer);
export default function weatherReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case weatherStateAction.UPDATE_TODAY_WEATHER_REQUEST:
      return {
        ...state,
        errorTodayWeather: null,
        loadingTodayWeather: true
      };
    case weatherStateAction.UPDATE_SEARCH_CITY:
      return {
        ...state,
        searchCity: action.payload
      };
    case weatherStateAction.UPDATE_COORDS_SEARCH_CITY:
      return {
        ...state,
        coordsSearchCity: action.payload
      };
    case weatherStateAction.UPDATE_EDITING_CITY:
      return {
        ...state,
        editingCity: action.payload
      };
    case weatherStateAction.CLEAR_TODAY_WEATHER_ERROR:
      return {
        ...state,
        errorTodayWeather: null,
        loadingTodayWeather: false
      };
    case weatherStateAction.UPDATE_TODAY_WEATHER_SUCCESS:
      return {
        ...state,
        todayWeather: action.payload,
        geoAccess: true,
        errorTodayWeather: null,
        loadingTodayWeather: false
      };
    case weatherStateAction.UPDATE_TODAY_WEATHER_FAILURE:
      return {
        ...state,

        errorTodayWeather: action.error,
        loadingTodayWeather: false
      };

    case weatherStateAction.UPDATE_TODAY_WEATHER_INFO_REQUEST:
      return {
        ...state,
        errorTodayWeatherInfo: null,
        loadingTodayWeatherInfo: true
      };
    case weatherStateAction.UPDATE_TODAY_WEATHER_INFO_SUCCESS:
      return {
        ...state,
        todayWeatherInfo: action.payload,
        errorTodayWeatherInfo: null,
        loadingTodayWeatherInfo: false
      };
    case weatherStateAction.UPDATE_TODAY_WEATHER_INFO_FAILURE:
      return {
        ...state,
        errorTodayWeatherInfo: action.error,
        loadingTodayWeatherInfo: false
      };

    case weatherStateAction.UPDATE_HOUR_FORCAST_WEATHER_REQUEST:
      return {
        ...state,
        errorhourForcastWeather: null,
        loadinghourForcastWeather: true
      };
    case weatherStateAction.UPDATE_HOUR_FORCAST_WEATHER_SUCCESS:
      return {
        ...state,
        hourForcastWeather: action.payload,
        errorhourForcastWeather: null,
        loadinghourForcastWeather: false
      };
    case weatherStateAction.UPDATE_HOUR_FORCAST_WEATHER_FAILURE:
      return {
        ...state,
        errorhourForcastWeather: action.error,
        loadinghourForcastWeather: false
      };

    case weatherStateAction.UPDATE_WEEK_FORCAST_WEATHER_REQUEST:
      return {
        ...state,
        errorweekForcastWeather: null,
        loadingweekForcastWeather: true
      };
    case weatherStateAction.UPDATE_WEEK_FORCAST_WEATHER_SUCCESS:
      return {
        ...state,
        weekForcastWeather: action.payload,
        errorweekForcastWeather: null,
        loadingweekForcastWeather: false
      };
    case weatherStateAction.UPDATE_WEEK_FORCAST_WEATHER_FAILURE:
      return {
        ...state,
        errorweekForcastWeather: action.error,
        loadingweekForcastWeather: false
      };

    case weatherStateAction.UPDATE_TEMP_UNIT_F:
      return {
        ...state,
        tempUnitCel: false
      };
    case weatherStateAction.UPDATE_TEMP_UNIT_C:
      return {
        ...state,
        tempUnitCel: true
      };
    case weatherStateAction.UPDATE_US_TEMP_UNIT:
      return {
        ...state,
        tempUnitCel: false,
        firstTimeUsingWeather: false
      };

    case weatherStateAction.UPDATE_PIN_WEATHER_HOME:
      return {
        ...state,
        pinWeatherToHomeState: action.payload
      };

    default:
      return state;
  }
}
