import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setBookmarkNewWindow,
  setBookmarkPositionTop,
  setBookmarkShapeRectangle,
  setBookmarkSizeBig,
  setBookmarkToBlackWhite,
  setShowChromeBookmark,
  updateBookmarkTopicStylingAction,
  updateShowBookmarkTopicStylingAction
} from '../../../store/reducers/bookmarksReducer/action';
import { defaultTopicStyling } from '../../../Utils/DefaultTopicStyling';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';

export default function UpdateBookmarksSettigs(bookmarkSettigs, defaultBookmarkList) {
  return async (dispatch) => {
    try {
      const message = {
        blackAndWhite: '',
        large: '',
        moveToCenter: '',
        openNewTab: '',
        square: '',
        useChromeBookmarks: '',
        defaultBookmarkList: ''
      };
      if (bookmarkSettigs) {
        if ('blackAndWhite' in bookmarkSettigs) {
          dispatch(setBookmarkToBlackWhite(!bookmarkSettigs.blackAndWhite));
          message.blackAndWhite = 'updated ,value=' + bookmarkSettigs.blackAndWhite;
        }
        if ('large' in bookmarkSettigs) {
          dispatch(setBookmarkSizeBig(bookmarkSettigs.large));
          message.large = 'updated ,value=' + bookmarkSettigs.large;
        }
        if ('moveToCenter' in bookmarkSettigs) {
          dispatch(setBookmarkPositionTop(bookmarkSettigs.moveToCenter));
          message.moveToCenter = 'updated ,value=' + bookmarkSettigs.moveToCenter;
        }
        if ('openNewTab' in bookmarkSettigs) {
          dispatch(setBookmarkNewWindow(bookmarkSettigs.openNewTab));
          message.openNewTab = 'updated ,value=' + bookmarkSettigs.openNewTab;
        }
        if ('square' in bookmarkSettigs) {
          dispatch(setBookmarkShapeRectangle(bookmarkSettigs.square));
          message.square = 'updated ,value=' + bookmarkSettigs.square;
        }
        if ('useChromeBookmarks' in bookmarkSettigs) {
          dispatch(setShowChromeBookmark(bookmarkSettigs.useChromeBookmarks));
          message.useChromeBookmarks = 'updated ,value=' + bookmarkSettigs.useChromeBookmarks;
        }
      }

      if (defaultBookmarkList) {
        dispatch(updateBookmarkTopicStylingAction(defaultBookmarkList));
        message.defaultBookmarkList = 'updated ,value from topic style';
      } else {
        dispatch(updateBookmarkTopicStylingAction(defaultTopicStyling.defaultBookmarkList));
        message.defaultBookmarkList = 'updated ,value from default topic style';
      }
      dispatch(updateShowBookmarkTopicStylingAction(true));

      meaVanaConsole('UpdateBookmarksSettigs :', message);
    } catch (error) {
      meaVanaConsole('UpdateBookmarksSettigs error:', error);
    }
  };
}
