import { arrayMove } from "react-sortable-hoc";
import { addNoteServer } from "../../../server/Dashbord/note.server";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";
import { noteStateAction } from "./constants";

export const setSelectedNote = (note) => {
  return {
    type: noteStateAction.SET_SELECTED_NOTE,
    payload: note
  };
};

export const updateNoteModeView = (value = false) => {
  return async (dispatch) => {
    dispatch({
      type: noteStateAction.SET_ACTIVE_MODE,
      payload: value
    });
  };
};
export const updateNotesListAction = (notes = []) => {
  return async (dispatch) => {
    dispatch({
      type: noteStateAction.UPDATE_NOTE_LIST,
      payload: { notes: notes }
    });
  };
};

export const createEmptyNote = (notesList, dashboardId) => {
  return async (dispatch) => {
    try {
      let notes = notesList.filter((note) => note._id != "empty");

      let newNote = {
        _id: "empty",
        title: " ",
        content: " ",
        saved: false,
        index: notes.length
      };

      notes.push(newNote);
      let reorderedNotes = arrayMove(notes, notes.length - 1, 0);
      reorderedNotes = reorderedNotes.map((_Note, index) => {
        let prevIndex = _Note.index;
        if (_Note._id.includes("empty")) {
          prevIndex = reorderedNotes.length;
        }
        return {
          ..._Note,
          prevIndex: prevIndex,
          index: index + 1
        };
      });
      dispatch({
        type: noteStateAction.ADD_EMPTY_NOTE,
        payload: reorderedNotes
      });
      if (reorderedNotes.find((note) => note._id === "empty")) {
        dispatch(
          addNoteServer(
            reorderedNotes.find((note) => note._id === "empty"),
            dashboardId
          )
        );
      }
    } catch (error) {
      meaVanaConsole("createEmptyNote err", error);
    }
  };
};

export const toggleExpandedView = (expandedView = false) => {
  return async (dispatch) => {
    dispatch({
      type: noteStateAction.TOGGLE_EXPANDED_VIEW,
      payload: expandedView
    });
  };
};
export const updateNoteLocalAction = (note = {}) => {
  return async (dispatch) => {
    dispatch({
      type: noteStateAction.UPDATE_NOTE_IN_STATE,
      payload: { note: note }
    });
  };
};
export const deleteAllNoteAction = () => {
  return async (dispatch) => {
    dispatch({
      type: noteStateAction.DELETE_ALL_NOTE
    });
  };
};

export const setShowNoteDeleteConfirmation = (show = false) => {
  return async (dispatch) => {
    dispatch({
      type: noteStateAction.SHOW_NOTE_DELETE_CONFIRMATION,
      payload: {
        showNoteDeleteConfirmation: show
      }
    });
  };
};

export const getNotesAction = (notes) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.GET_DASHBOARD_NOTES,
      payload: notes
    });
  };
};

// action updateNote request
export const updateNoteRequestAction = (notes) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.UPDATE_NOTE_REQUEST,
      payload: { notes: notes }
    });
  };
};

// action updateNote success
export const updateNoteSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.UPDATE_NOTE_SUCCESS,
      payload: data
    });
  };
};

// action updateNote failure
export const updateNoteFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.UPDATE_NOTE_FAILURE,
      error: error
    });
  };
};

// action deleteNote request
export const deleteNoteRequestAction = (noteId) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.DELETE_NOTE_REQUEST,
      payload: { noteId: noteId }
    });
  };
};

// action deleteNote success
export const deleteNoteSuccessAction = (noteId) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.DELETE_NOTE_SUCCESS,
      payload: { noteId: noteId }
    });
  };
};

// action deleteNote failure
export const deleteNoteFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.DELETE_NOTE_FAILURE,
      error: error
    });
  };
};

// action addNote request
export const addNoteRequestAction = (note) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.ADD_NOTE_REQUEST,
      payload: { note: note }
    });
  };
};

// action addNote success
export const addNoteSuccessAction = (note) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.ADD_NOTE_SUCCESS,
      payload: { note: note }
    });
  };
};

// action addNote failure
export const addNoteFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.ADD_NOTE_FAILURE,
      error: error
    });
  };
};

// action deleteAllNote request
export const deleteAllNoteRequestAction = () => {
  return (dispatch) => {
    dispatch({ type: noteStateAction.DELETE_ALL_NOTE_REQUEST });
  };
};

// action deleteAllNote success
export const deleteAllNoteSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.DELETE_ALL_NOTE_SUCCESS,
      payload: data
    });
  };
};

// action deleteAllNote failure
export const deleteAllNoteFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: noteStateAction.DELETE_ALL_NOTE_FAILURE,
      error: error
    });
  };
};
