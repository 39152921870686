import React, { useState, useEffect } from "react";

export default function AudioPlayerTimeFeature(props) {
  const [audio] = useState(new Audio(props.url));

  useEffect(() => {
    if (props.playing) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [props.playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => props.setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => props.setPlaying(false));
    };
  }, []);
  return <div key="AudioPlayerTimeFeature" style={{ display: "none" }}></div>;
}
