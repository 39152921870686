import { appStateAction } from '../appReducer/constants';
import { initialState, unsplashStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const unsplashPersistConfig = {
  key: 'unsplash',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING unsplash :', error)
};
export const unsplashPersisted = persistReducer(unsplashPersistConfig, unsplashReducer);

export default function unsplashReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case unsplashStateAction.GET_RANDOM_PICTURE_REQUEST:
      return {
        ...state
      };
    case unsplashStateAction.GET_RANDOM_PICTURE_SUCCESS:
      return {
        ...state,
        randomPictures: action.payload.data
      };
    case unsplashStateAction.GET_RANDOM_PICTURE_FAILURE:
      return {
        ...state
      };
    case unsplashStateAction.GET_SEARCH_PICTURE_REQUEST:
      return {
        ...state,
        pictures: [],
        loadingPicture: true,
        errorPicture: null
      };
    case unsplashStateAction.GET_SEARCH_PICTURE_SUCESS:
      return {
        ...state,
        pictures: action.payload.data,

        loadingPicture: false,
        errorPicture: null
      };
    case unsplashStateAction.GET_SEARCH_PICUTURE_FAILURE:
      return {
        ...state,
        loadingPicture: false,
        errorPicture: action.payload.error
      };
    case unsplashStateAction.UPDATE_UNSPLASH_SELECTED_PICTURE:
      return {
        ...state,
        selectedPicture: action.payload,
        useUnspashForBackground: true,
        loadingUnspashBackground: true
      };
    case unsplashStateAction.UPDATE_UNSPLASH_BACKGROUND_STATE:
      return {
        ...state,
        useUnspashForBackground: action.payload,
        loadingUnspashBackground: false,
        loadingPicture: false,
        errorPicture: null
      };
    case unsplashStateAction.UPDATE_LOADING_UNSPLASH_BACKGROUND_STATE:
      return {
        ...state,
        loadingUnspashBackground: action.payload
      };

    default:
      return state;
  }
}
