import { appStateAction } from '../appReducer/constants';
import { coincapStateAction, initialState } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const coincapPersistConfig = {
  key: 'coincap',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING coincap :', error)
};
export const coincapPersisted = persistReducer(coincapPersistConfig, coincapReducer);

export default function coincapReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;
    case coincapStateAction.GET_COINCAP_ASSETS_REQUEST:
      return {
        ...state
      };
    case coincapStateAction.GET_COINCAP_ASSETS_SUCESS:
      return {
        ...state,
        assets: action.payload.data
      };
    case coincapStateAction.GET_COINCAP_ASSETS_FAILURE:
      return {
        ...state
      };
    case coincapStateAction.SHOW_COINCAP_WINDOW:
      return {
        ...state,
        showCoincapWindow: action.payload.showCoincapWindow
      };

    default:
      return state;
  }
}
