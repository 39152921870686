import { assetsIcons } from "../../../config/images";

export const initialState = {
  picturesApiSource: [
    {
      id: "unsplash",
      name: "Unsplash",
      icons: assetsIcons.integration.pictures.Unsplash,
      active: false,
      loaded: false,
      status: "emplty",
      error: null,
      pined: false,
      lastUpdate: null
    }
  ],
  activePictureId: null,
  lastUpdate: null
};

export const picturestateAction = {
  UPDATE_PICTURE_WINDOW_STATE: "pictures/UPDATE_PICTURE_WINDOW_STATE",
  UPDATE_PICTURE_PIN: "pictures/UPDATE_PICTURE_PIN"
};
