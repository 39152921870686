import React from "react";
import { updateCryptoPinAction } from "../../../store/reducers/cryptoReducer/action";
import { resetIntegrationPinStateAction } from "../../../store/reducers/integrationsReducer/action";
import { updateMusicPinAction } from "../../../store/reducers/musicReducer/action";
import { updateNewsPinAction } from "../../../store/reducers/newsReducer/action";
import { initialState } from "../../../store/reducers/newsReducer/constants";
import { updatePicturePinAction } from "../../../store/reducers/picturesReducer/action";
import { updateVideosPinAction } from "../../../store/reducers/VideoReducer/action";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function UpdateIntegration(integration) {
  return async (dispatch) => {
    try {
      dispatch(resetIntegrationPinStateAction());

      if (integration) {
        const musicCategory = integration.filter((int) => {
          return int.category === "music";
        });
        musicCategory.forEach((music) => {
          dispatch(updateMusicPinAction(music.name, true));
        });

        const newsCategory = integration.filter((int) => {
          return int.category === "news";
        });

        newsCategory.forEach((news) => {
          const index = initialState.newsSources.findIndex((value) => {
            return value.name.toLowerCase() === news.name.toLowerCase();
          });
          if (index >= 0) {
            dispatch(updateNewsPinAction(index, true));
          }
        });
        const picturesCategory = integration.filter((int) => {
          return int.category === "pictures";
        });
        picturesCategory.forEach((picture) => {
          dispatch(updatePicturePinAction(picture.name, true));
        });
        const videoCategory = integration.filter((int) => {
          return int.category === "video";
        });
        videoCategory.forEach((video) => {
          dispatch(updateVideosPinAction(video.name, true));
        });
        const cryptoCategory = integration.filter((int) => {
          return int.category === "crypto";
        });
        cryptoCategory.forEach((crypto) => {
          dispatch(updateCryptoPinAction(crypto.name, true));
        });
      }
      meaVanaConsole("UpdateIntegration success");
    } catch (error) {
      meaVanaConsole("UpdateIntegration error:", error);
    }
  };
}
