import React from "react";

import "./Settings.css";
import { useDispatch, useSelector } from "react-redux";

import RadioGroup from "@mui/material/RadioGroup";

import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import {
  resetHomeThemeAction,
  updateShowHideAllFeatureAction,
  updateShowHideBackgroundInfoFeatureAction,
  updateShowHideBookmarkFeatureAction,
  updateShowHideDateFeatureAction,
  updateShowHideIntegrationFeatureAction,
  updateShowHideNoteFeatureAction,
  updateShowHideQuoteFeatureAction,
  updateShowHideSearchEngineFeatureAction,
  updateShowHideTimeFeatureAction,
  updateShowHideWeatherFeatureAction
} from "../../store/reducers/homeReducer/action";
import MyFormControlLabel from "../../Utils/MyFormControlLabel";

export default function FeaturesSettings() {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.home);

  const updateSettingShowTime = (event, newValue) => {
    let v = newValue / 100;
    dispatch(updateShowHideTimeFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };

  const updateSettingShowAllFeatureSlider = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideAllFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };

  const updateSettingShowDate = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideDateFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };
  const updateSettingShowNote = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideNoteFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };
  const updateSettingShowSearch = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideSearchEngineFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };
  const updateSettingShowQuote = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideQuoteFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };
  const updateSettingShowWeather = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideWeatherFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };
  const updateSettingShowshowBackgroundInfo = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideBackgroundInfoFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };

  const updateSettingShowshowBookmark = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideBookmarkFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };
  const updateSettingShowshowIntegration = (event, newValue) => {
    let v = newValue / 100;

    dispatch(updateShowHideIntegrationFeatureAction(v));

    dispatch(resetHomeThemeAction());
  };
  return (
    <div className="settings-view">
      <div className="header-settings">
        <span className="header-settings-title">Features</span>
        <span className="header-settings-subtitle">
          Customize your features
        </span>
      </div>
      <div className="list-settings">
        <div className="list-settings-item">
          <p className="list-settings-item-txt">All</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="All"
              value={homeState.showAllFeature * 100}
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              onChange={updateSettingShowAllFeatureSlider}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Time</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Time"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showTime * 100}
              onChange={updateSettingShowTime}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Date</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Date"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showDate * 100}
              onChange={updateSettingShowDate}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Search bar</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Search bar"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showSearch * 100}
              onChange={updateSettingShowSearch}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Quote</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Quote"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showQuote * 100}
              onChange={updateSettingShowQuote}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Note</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Note"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showNote * 100}
              onChange={updateSettingShowNote}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Weather</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Weather"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showWeather * 100}
              onChange={updateSettingShowWeather}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Bookmarks</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Bookmarks"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showBookmark * 100}
              onChange={updateSettingShowshowBookmark}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          className="list-settings-item"
          style={
            homeState.showAllFeature > 0 ? { opacity: 1 } : { opacity: 0.5 }
          }
        >
          <p className="list-settings-item-txt">Integration</p>

          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "65%" }}
          >
            <RadioGroup
              aria-label="Bookmarks"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              value={homeState.showIntegration * 100}
              onChange={updateSettingShowshowIntegration}
            >
              <MyFormControlLabel
                value={0}
                control={<Radio color="success" />}
                label="Hide"
              />
              <MyFormControlLabel
                value={50}
                control={<Radio color="success" />}
                label="Dim"
              />
              <MyFormControlLabel
                value={100}
                control={<Radio color="success" />}
                label="Show"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
