import { assetsIcons } from "../../../config/images";

export const initialState = {
  musicapiSource: [
    {
      id: "spotify",
      name: "Spotify",
      accessToken: "",
      refreshToken: "",
      expiresIn: "",
      code: "",
      isPlaying: false,
      repeatMode: false,
      shuffleMode: false,
      currentTrackId: "",
      icons: {
        black: assetsIcons.integration.music.spotify.black,
        color: assetsIcons.integration.music.spotify.color,
        white: assetsIcons.integration.music.spotify.white
      },
      active: false,
      loaded: false,
      status: "empty",
      error: null,
      errorMessage: "",
      pined: false
    }
  ],
  activeMusicId: null
};

export const musicstateAction = {
  UPDATE_MUSIC_WINDOW_STATE: "music/UPDATE_MUSIC_WINDOW_STATE",
  UPDATE_MUSIC_PIN: "music/UPDATE_MUSIC_PIN",
  SET_MUSIC_ACCESS_TOKEN: "music/SET_MUSIC_ACCESS_TOKEN",
  SET_MUSIC_REFRESH_TOKEN: "music/SET_MUSIC_REFRESH_TOKEN",
  SET_MUSIC_EXPIRES_IN: "music/SET_MUSIC_EXPIRES_IN",
  SET_MUSIC_CODE_TOKEN: "music/SET_MUSIC_CODE_TOKEN",
  GET_MUSIC_API_REQUEST: "music/GET_MUSIC_API_REQUEST",
  GET_MUSIC_API_SUCESS: "music/GET_MUSIC_API_SUCESS",
  GET_MUSIC_API_FAILURE: "music/GET_MUSIC_API_FAILURE",
  SET_MUSIC_IS_PLAYING: "music/SET_MUSIC_IS_PLAYING",
  SET_MUSIC_CURRENT_TRACK_ID: "music/SET_MUSIC_CURRENT_TRACK_ID",
  GET_MUSIC_API_FAILURE_MESSAGE: "searchEngine/GET_MUSIC_API_FAILURE_MESSAGE",
  TOGGLE_MUSIC_TRACK_REPEAT_MODE: "searchEngine/TOGGLE_MUSIC_TRACK_REPEAT_MODE",
  TOGGLE_MUSIC_TRACK_SHUFFLE_MODE:
    "searchEngine/TOGGLE_MUSIC_TRACK_SHUFFLE_MODE"
};
