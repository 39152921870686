export const initialState = {
  selectedNote: {},
  notes: [],
  toggleExpandedView: false,
  updatedList: false,
  error: "",
  noteActiveMode: true,
  showNoteDeleteConfirmation: false
};

export const noteStateAction = {
  GET_DASHBOARD_NOTES: "note/GET_DASHBOARD_NOTES",
  ADD_NOTE_REQUEST: "note/ADD_NOTE_REQUEST",
  ADD_NOTE_SUCCESS: "note/ADD_NOTE_SUCCESS",
  ADD_NOTE_FAILURE: "note/ADD_NOTE_FAILURE",
  DELETE_NOTE_REQUEST: "note/DELETE_NOTE_REQUEST",
  DELETE_NOTE_SUCCESS: "note/DELETE_NOTE_SUCCESS",
  DELETE_NOTE_FAILURE: "note/DELETE_NOTE_FAILURE",
  DELETE_ALL_NOTE_REQUEST: "note/DELETE_ALL_NOTE_REQUEST",
  DELETE_ALL_NOTE_SUCCESS: "note/DELETE_ALL_NOTE_SUCCESS",
  DELETE_ALL_NOTE_FAILURE: "note/DELETE_ALL_NOTE_FAILURE",
  UPDATE_NOTE_REQUEST: "note/UPDATE_NOTE_REQUEST",
  UPDATE_NOTE_SUCCESS: "note/UPDATE_NOTE_SUCCESS",
  UPDATE_NOTE_FAILURE: "note/UPDATE_NOTE_FAILURE",
  SET_SELECTED_NOTE: "note/SET_SELECTED_NOTE",
  TOGGLE_EXPANDED_VIEW: "note/TOGGLE_EXPANDED_VIEW",
  TOGGLE_EDIT_MODE: "note/TOGGLE_EDIT_MODE",
  UPDATE_NOTE_LIST: "note/UPDATE_NOTE_LIST",
  SHOW_NOTE_DELETE_CONFIRMATION: "note/SHOW_NOTE_DELETE_CONFIRMATION",
  DELETE_ALL_NOTE: "note/DELETE_ALL_NOTE",
  ADD_EMPTY_NOTE: "note/ADD_EMPTY_NOTE",
  UPDATE_NOTE_IN_STATE: "note/UPDATE_NOTE_IN_STATE",
  DELETE_NOTE_IN_STATE: "note/DELETE_NOTE_IN_STATE",

  SET_ACTIVE_MODE: "note/SET_ACTIVE_MODE"
};
