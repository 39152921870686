import { appStateAction } from '../appReducer/constants';
import { initialState, timerStateAction } from './constants';

import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const timerPersistConfig = {
  key: 'timer',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING timer :', error)
};
export const timerPersisted = persistReducer(timerPersistConfig, timerReducer);

export default function timerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case timerStateAction.SET_HOURS:
      if (action.payload == null) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          hours: action.payload
        };
      }
    case timerStateAction.SET_MINUTES:
      if (action.payload == null) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          minutes: action.payload
        };
      }
    case timerStateAction.SET_SECONDS:
      if (action.payload == null) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          seconds: action.payload
        };
      }

    default:
      return state;
  }
}
