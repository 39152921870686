import { timerStateAction } from "./constants";

export const setHours = (hours) => {
  return (dispatch) => {
    dispatch({
      type: timerStateAction.SET_HOURS,
      payload: hours
    });
  };
};

export const setMinutes = (minutes) => {
  return (dispatch) => {
    dispatch({
      type: timerStateAction.SET_MINUTES,
      payload: minutes
    });
  };
};

export const setSeconds = (seconds) => {
  return (dispatch) => {
    dispatch({
      type: timerStateAction.SET_SECONDS,
      payload: seconds
    });
  };
};
