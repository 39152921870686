import React from 'react';
import { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { assetsIcons } from '../../../config/images';
import { BookmarkGetImageSrcUrl } from '../../../Utils/BookmarkGetImageSrcUrl';
import { defaultTopicStyling } from '../../../Utils/DefaultTopicStyling';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import ImageWithFallback from '../components/ImageWithFallback';

const TopicBookmakItem = ({ index, bookmarkItem }) => {
  const bookmarksState = useSelector((state) => state.bookmarks);
  const openBookmarkInNewWindow =
    bookmarksState && bookmarksState.openBookmarkInNewWindow
      ? bookmarksState.openBookmarkInNewWindow
      : false;

  const redirectToUrl = (e, url) => {
    //redirect only if bookmark is clicked not the remove/delete icon
    if (openBookmarkInNewWindow) {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_blank');
    } else {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_self');
    }
  };

  return (
    <Tooltip arrow key={'bookmark-tooltip-item-' + index} title={bookmarkItem.name} placement="top">
      <div
        className={
          bookmarksState.bookmarkShapeRectangle
            ? 'rectangular-shape bookmarks-item'
            : 'bookmarks-item'
        }
        key={'bookmark-item-container' + index}
      >
        <div
          className="bookmarks-item-container"
          onClick={(event) => {
            event.preventDefault();
            redirectToUrl(event, bookmarkItem.url);
          }}
        >
          <ImageWithFallback
            src={BookmarkGetImageSrcUrl(bookmarkItem.url)}
            fallback={GetImageUrl(assetsIcons.folder)}
            alt={bookmarkItem.name}
            className={`bookmark-img ${!bookmarksState.bookmarkColored && 'grayscale-filter'}`}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default function TopicBookmaks(props) {
  const bookmarksState = useSelector((state) => state.bookmarks);
  const bookmarksTopicStyling =
    bookmarksState && bookmarksState.bookmarksTopicStyling
      ? bookmarksState.bookmarksTopicStyling
      : defaultTopicStyling.defaultBookmarkList;

  useEffect(() => {
    meaVanaConsole('TopicBookmaks array :', bookmarksTopicStyling);
  });
  return (
    <div
      className={`bookmarks-container-sortable-list ${
        bookmarksState.bookmarkSizeBig ? 'bookmark-lg' : 'bookmark-sm'
      } ${'show-scroll-bar' /*props.showIcons ? "show-scroll-bar" : "hide-scroll-bar"*/}
      `}
    >
      {bookmarksTopicStyling.map((bookmarkItem, index) => {
        return bookmarkItem === null ? (
          <div>error item {index}</div>
        ) : (
          <TopicBookmakItem
            key={`bookmark-item-${index}`}
            index={index}
            bookmarkItem={bookmarkItem}
          />
        );
      })}
    </div>
  );
}
