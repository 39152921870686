import { appStateAction } from '../appReducer/constants';
import { initialState, newsStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { integrationStateAction } from '../integrationsReducer/constants';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const newsPersistConfig = {
  key: 'news',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING news :', error)
};
export const newsPersisted = persistReducer(newsPersistConfig, newsReducer);

export default function newsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT: {
      return initialState;
    }

    case integrationStateAction.RESET_INTEGRATION_PIN_STATE:
      const newsSources = [...state.newsSources];
      newsSources.forEach((element, index) => {
        newsSources[index].pinned = false;
      });

      return {
        ...state,
        newsSources
      };

    case newsStateAction.UPDATE_NEWS_WINDOW_STATE: {
      const { index } = action.payload;
      return {
        ...state,
        activeNewsIndex: index
      };
    }

    case newsStateAction.UPDATE_NEWS_PIN: {
      const { index, pin } = action.payload;

      if (index == null) {
        return { ...state };
      }

      const newsSources = [...state.newsSources];
      newsSources[index].pinned = pin;

      return {
        ...state,
        newsSources
      };
    }

    case newsStateAction.GET_NEWS_API_REQUEST: {
      const { index } = action.payload;
      const newsSources = [...state.newsSources];
      newsSources[index].status = 'loading';

      return {
        ...state,
        newsSources
      };
    }

    case newsStateAction.GET_NEWS_API_SUCCESS: {
      const { index, data } = action.payload;
      const newsSources = [...state.newsSources];

      newsSources[index] = {
        ...newsSources[index],
        data,
        status: 'success',
        lastUpdatedAt: new Date()
      };

      return {
        ...state,
        newsSources,
        lastUpdatedAt: new Date()
      };
    }

    case newsStateAction.GET_NEWS_API_FAILURE: {
      const { index, error } = action.payload;
      const newsSources = [...state.newsSources];

      newsSources[index] = {
        ...newsSources[index],
        error,
        status: 'error'
      };

      return {
        ...state,
        newsSources
      };
    }

    default: {
      return state;
    }
  }
}
