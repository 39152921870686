import React from 'react';
import InstallExtBtn2 from './Marketing/InstallExtBtn2';
import WeatherFeature from '../../features/WeatherFeature/WeatherFeature';
import MarketingTextContainer from './Marketing/MarketingTextContainer';
import IntegrationsGuestMode from './IntegrationGuestMode/IntegrationsGuestMode';
import WallpapersGuestMode from './WallpapersGuestMode/WallpapersGuestMode';

export default function GuestTopArea({ hideAllFeatures, showWeather, showIntegration }) {
  return (
    <>
      <div className="weather-area">
        <WeatherFeature display={hideAllFeatures} opacity={showWeather} />
      </div>
      <div className="integration-area guest-area grid-container-guest">
        {/* <InstallExtBtn display={hideAllFeatures} opacity={showIntegration} />
        <InstallPhoneAppBtn display={hideAllFeatures} opacity={showIntegration} /> */}
        <InstallExtBtn2 display={hideAllFeatures} opacity={showIntegration} />
        <WallpapersGuestMode display={hideAllFeatures} opacity={showIntegration} />
        <IntegrationsGuestMode display={hideAllFeatures} opacity={showIntegration} />
        {/* <InstallPhoneAppBtn2 display={hideAllFeatures} opacity={showIntegration} /> */}
        <MarketingTextContainer />
      </div>
    </>
  );
}
