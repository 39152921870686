const data = {
  bookmarks: [],
  bookmarksGuestMode: [],
  bookmarksTopicStyling: [],
  selectedBookmark: {},
  chromeBoomkmarks: [],
  topVisitedSites: []
};

export const params = {
  showUpdateDialog: false,

  bookmarkSizeBig: false,
  bookmarkColored: true,
  bookmarkShapeRectangle: false,
  openBookmarkInNewWindow: true,
  positionBOokmarksTop: false,
  showchromeBoomkmarks: false,
  showTopVisitedSites: false,
  showTopicStylingBookmark: false,

  deleteBoomarkLOading: false,

  errorDeleteBoomark: null,
  addBoomarkLoading: false,
  errorAddBoomark: null,

  loadingDeleteAllBookmarks: false,
  errorDeleteAllBookmarks: null,

  openDialogDeleteAllBookmark: false
};

export const initialState = {
  ...data,
  ...params
};

export const bookmarkStateAction = {
  GET_DASHBOARD_BOOKMARKS: 'bookmark/GET_DASHBOARD_BOOKMARKS',
  GET_CHROME_BOOKMARKS: 'bookmark/GET_CHROME_BOOKMARKS',
  GET_TOP_VISITED_SITES: 'bookmark/GET_TOP_VISITED_SITES',
  SET_DIALOG_DELETE_ALL_BOOKMARKS_VALUE: 'bookmark/SET_DIALOG_DELETE_ALL_BOOKMARKS_VALUE',
  SET_BOOKMARK_SIZE: 'bookmark/SET_BOOKMARK_SIZE',
  SHOW_CHROME_BOOKMARKS: 'bookmark/SHOW_CHROME_BOOKMARKS',
  SHOW_BOOKMARKS_TOPIC_STYLING: 'bookmark/SHOW_BOOKMARKS_TOPIC_STYLING',
  SHOW_TOP_VISITED_SITES: 'bookmark/SHOW_TOP_VISITED_SITES',
  SET_BOOKMARK_NEW_WINDOW: 'bookmark/SET_BOOKMARK_NEW_WINDOW',
  SET_BOOKMARK_POSITION_TOP: 'bookmark/SET_BOOKMARK_POSITION_TOP',
  SET_BOOKMARK_COLOR: 'bookmark/SET_BOOKMARK_COLOR',
  SET_BOOKMARK_SHAPE: 'bookmark/SET_BOOKMARK_SHAPE',
  DELETE_ALL_BOOKMARKS_REQUEST: 'bookmark/DELETE_ALL_BOOKMARKS_REQUEST',
  DELETE_ALL_BOOKMARKS_SUCCESS: 'bookmark/DELETE_ALL_BOOKMARKS_SUCCESS',
  DELETE_ALL_BOOKMARKS_FAILURE: 'bookmark/DELETE_ALL_BOOKMARKS_FAILURE',
  UPDATE_CHROME_BOOKMARKS_TAB: 'bookmark/UPDATE_CHROME_BOOKMARKS_TAB',
  UPDATE_BOOKMARK_LIST: 'bookmark/UPDATE_BOOKMARK_LIST',
  UPDATE_BOOKMARK_BULK_LIST_REQUEST: 'bookmark/UPDATE_BOOKMARK_BULK_LIST_REQUEST',
  UPDATE_BOOKMARK_BULK_LIST_SUCCESS: 'bookmark/UPDATE_BOOKMARK_BULK_LIST_SUCCESS',
  UPDATE_BOOKMARK_BULK_LIST_FAILURE: 'bookmark/UPDATE_BOOKMARK_BULK_LIST_FAILURE',
  SET_SELECTED_BOOKMARK: 'bookmark/SET_SELECTED_BOOKMARK',
  ADD_BOOKMARK_REQUEST: 'bookmark/ADD_BOOKMARK_REQUEST',
  ADD_BOOKMARK_SUCCESS: 'bookmark/ADD_BOOKMARK_SUCCESS',
  ADD_BOOKMARK_FAILURE: 'bookmark/ADD_BOOKMARK_FAILURE',
  UPDATE_BOOKMARK_REQUEST: 'bookmark/UPDATE_BOOKMARK_REQUEST',
  UPDATE_BOOKMARK_SUCCESS: 'bookmark/UPDATE_BOOKMARK_SUCCESS',
  UPDATE_BOOKMARK_FAILURE: 'bookmark/UPDATE_BOOKMARK_FAILURE',
  DELETE_BOOKMARK_REQUEST: 'bookmark/DELETE_BOOKMARK_REQUEST',
  DELETE_BOOKMARK_SUCCESS: 'bookmark/DELETE_BOOKMARK_SUCCESS',
  DELETE_BOOKMARK_FAILURE: 'bookmark/DELETE_BOOKMARK_FAILURE',
  TOGGLE_BOOKMARK_UPDATE_DIALOG: 'bookmark/TOGGLE_BOOKMARK_UPDATE_DIALOG',
  DELETE_ALL_BOOKMARKS_GUEST_MODE: 'bookmark/DELETE_ALL_BOOKMARKS_GUEST_MODE',
  UPDATE_BOOKMARKS_GUEST_MODE: 'bookmark/UPDATE_BOOKMARKS_GUEST_MODE',
  ADD_BOOKMARK_GUEST_MODE: 'bookmark/ADD_BOOKMARK_GUEST_MODE',

  UPDATE_BOOKMARK_TOPIC_STYLING_LIST: 'bookmark/UPDATE_BOOKMARK_TOPIC_STYLING_LIST'
};
