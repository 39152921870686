import { appStateAction } from '../appReducer/constants';
import {
  authParams,
  authStateAction,
  forgotPasswordParams,
  initialState,
  profileParams
} from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const authPersistConfig = {
  key: 'auth',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING auth :', error)
};
export const authPersisted = persistReducer(authPersistConfig, authReducer);

export default function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case authStateAction.RESET_FORGOT_PASSWORD_STATE:
      return {
        ...state,
        ...forgotPasswordParams
      };
    case authStateAction.RESET_AUTH_STATE:
      return {
        ...state,
        ...authParams,
        ...forgotPasswordParams
      };

    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case authStateAction.UPDATE_GUEST_MODE:
      return {
        ...state,
        guestMode: !state.guestMode,
        loadingGoogleSSO: false,
        loadingFacebookSSO: false
      };
    case authStateAction.LOGIN_REQUEST:
      return {
        ...state,
        user: action.user,
        loading: true,
        error: null
      };
    case authStateAction.LOGIN_SSO_GOOGLE_REQUEST:
      return {
        ...state,
        user: action.user,
        loadingGoogleSSO: true,
        errorGoogleSSO: null
      };
    case authStateAction.LOGIN_SSO_FACEBOOK_REQUEST:
      return {
        ...state,
        user: action.user,
        loadingFacebookSSO: true,
        errorFacebookSSO: null
      };
    case authStateAction.LOGIN_SSO_GOOGLE_ERROR:
      return {
        ...state,
        loadingGoogleSSO: false,
        errorGoogleSSO: action.error
      };
    case authStateAction.LOGIN_SSO_FACEBOOK_ERROR:
      return {
        ...state,
        loadingFacebookSSO: false,
        errorFacebookSSO: action.error
      };
    case authStateAction.LOGIN_SSO_GOOGLE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        oldUser: action.payload,
        ACCESS_TOKEN: action.payload.accessToken,
        accessTokenExpirationDate: action.payload.accessTokenExpirationDate,
        REFRESH_TOKEN: action.payload.refreshToken,

        loadingGoogleSSO: false,
        errorGoogleSSO: null,
        firstTimeUser: false,
        datefirstTimeUserLogin: new Date()
      };
    case authStateAction.LOGIN_SSO_FACEBOOK_SUCCESS:
      return {
        ...state,
        user: action.payload,
        oldUser: action.payload,
        ACCESS_TOKEN: action.payload.accessToken,
        accessTokenExpirationDate: action.payload.accessTokenExpirationDate,
        REFRESH_TOKEN: action.payload.refreshToken,

        loadingFacebookSSO: false,
        errorFacebookSSO: null,
        firstTimeUser: false,
        datefirstTimeUserLogin: new Date()
      };

    case authStateAction.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        oldUser: action.payload,
        ACCESS_TOKEN: action.payload.accessToken,
        accessTokenExpirationDate: action.payload.accessTokenExpirationDate,
        REFRESH_TOKEN: action.payload.refreshToken,

        loading: false,
        error: null,
        firstTimeUser: false,
        datefirstTimeUserLogin: new Date()
      };
    case authStateAction.UPDATE_USER_TOKEN:
      return {
        ...state,
        ACCESS_TOKEN: action.payload.accessToken,
        accessTokenExpirationDate: action.payload.accessTokenExpirationDate,
        REFRESH_TOKEN: action.payload.refreshToken
      };
    case authStateAction.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case authStateAction.LOGOUT:
      return {
        ...state,
        ...authParams,
        ...forgotPasswordParams
      };

    case authStateAction.REGISTER_REQUEST:
      return {
        ...state,
        user: action.user,
        loading: true,
        error: null
      };

    case authStateAction.REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        oldUser: action.payload,
        ACCESS_TOKEN: action.payload.accessToken,
        accessTokenExpirationDate: action.payload.accessTokenExpirationDate,
        REFRESH_TOKEN: action.payload.refreshToken,

        loading: false,
        error: null,
        firstTimeUser: false,
        datefirstTimeUserLogin: new Date()
      };
    case authStateAction.REGISTER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case authStateAction.ACTIVE_AUTHENTICATION_LOADING:
      return {
        ...state,
        loading: true
      };

    case authStateAction.DESACTIVE_AUTHENTICATION_LOADING:
      return {
        ...state,
        loading: false,
        loadingGoogleSSO: false,
        errorGoogleSSO: null,
        loadingFacebookSSO: false,
        errorFacebookSSO: null,
        error: null
      };

    case authStateAction.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loadingForgotPassword: true,
        successForgotPassword: false,
        emailForgotPassword: action.email
      };
    case authStateAction.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingForgotPassword: false,
        successForgotPassword: true,
        responseForgotPassword: action.payload
      };
    case authStateAction.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loadingForgotPassword: false,
        successForgotPassword: false,
        errorForgotPassword: action.error
      };

    case authStateAction.FORGOT_PASSWORD_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loadingForgotPasswordVerifyEmail: true,
        successForgotPasswordVerifyEmail: false,
        codeForgotPasswordVerifyEmail: action.code
      };
    case authStateAction.FORGOT_PASSWORD_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loadingForgotPasswordVerifyEmail: false,
        successForgotPasswordVerifyEmail: true,
        responseForgotPasswordVerifyEmail: action.payload
      };
    case authStateAction.FORGOT_PASSWORD_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loadingForgotPasswordVerifyEmail: false,
        successForgotPasswordVerifyEmail: false,
        errorForgotPasswordVerifyEmail: action.error
      };

    case authStateAction.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loadingResetPassword: true,
        successResetPassword: false,
        userResetPassword: action.user
      };
    case authStateAction.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingResetPassword: false,
        successResetPassword: true,
        responseResetPassword: action.payload
      };
    case authStateAction.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loadingResetPassword: false,
        successResetPassword: false,
        errorResetPassword: action.error
      };
    case authStateAction.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        loadingDeleteAccount: true,
        successDeleteAccount: false
      };
    case authStateAction.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingDeleteAccount: false,
        successDeleteAccount: true,
        responseDeleteAccount: action.payload
      };
    case authStateAction.DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        loadingDeleteAccount: false,
        successDeleteAccount: false,
        errorDeleteAccount: action.error
      };

    case authStateAction.DISABLE_ACCOUNT_REQUEST:
      return {
        ...state,
        loadingDisableAccount: true,
        successDisableAccount: false
      };
    case authStateAction.DISABLE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingDisableAccount: false,
        successDisableAccount: true,
        responseDisableAccount: action.payload
      };
    case authStateAction.DISABLE_ACCOUNT_FAILURE:
      return {
        ...state,
        loadingDisableAccount: false,
        successDisableAccount: false,
        errorDisableAccount: action.error
      };

    case authStateAction.UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        loadingUpdateUserInfo: true,
        successUpdateUserInfo: false
      };
    case authStateAction.UPDATE_USER_INFO_SUCCESS:
      let userUpdated = state.user;
      userUpdated.username = action.payload.newUser.username;
      userUpdated.firstName = action.payload.newUser.firstName;
      userUpdated.lastName = action.payload.newUser.lastName;
      return {
        ...state,
        user: userUpdated,
        loadingUpdateUserInfo: false,
        successUpdateUserInfo: true,
        responseUpdateUserInfo: action.payload.data
      };
    case authStateAction.UPDATE_USER_INFO_FAILURE:
      return {
        ...state,
        loadingUpdateUserInfo: false,
        successUpdateUserInfo: false,
        errorUpdateUserInfo: action.error
      };

    case authStateAction.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loadingUpdateUserPassword: true,
        successUpdateUserPassword: false
      };
    case authStateAction.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingUpdateUserPassword: false,
        successUpdateUserPassword: true,
        responseUpdateUserPassword: action.payload
      };
    case authStateAction.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loadingUpdateUserPassword: false,
        successUpdateUserPassword: false,
        errorUpdateUserPassword: action.error
      };

    case authStateAction.RESET_PROFILE_ACTIONS:
      return {
        ...state,
        ...profileParams
      };

    default:
      return state;
  }
}
