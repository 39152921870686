export const stateMigrations = {
  1: (state) => {
    console.log('update background store version 1 :');
    return {
      ...state
    };
  },
  2: (state) => {
    console.log('update background store version  :');
    return {
      ...state,
      backgroundIndexFromDB: false,
      backgroundIndexFromDBIndex: 1,
      backgroundIndexFromDBArray: [
        {
          id: 1,
          name: 'background_index_db_1',
          uploaded: false,
          created: null,
          updated: null,
          used: null
        },
        {
          id: 2,
          name: 'background_index_db_2',
          uploaded: false,
          created: null,
          updated: null,
          used: null
        },
        {
          id: 3,
          name: 'background_index_db_3',
          uploaded: false,
          created: null,
          updated: null,
          used: null
        },
        {
          id: 4,
          name: 'background_index_db_4',
          uploaded: false,
          created: null,
          updated: null,
          used: null
        }
      ]
    };
  }
};
