/* global chrome */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './BookmarkContainer.css';
import { MoreHoriz } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import Button from '@mui/material/Button';
import { CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import {
  setBookmarkNewWindow,
  setBookmarkPositionTop,
  setBookmarkShapeRectangle,
  setBookmarkSizeBig,
  setBookmarkToBlackWhite,
  setShowChromeBookmark,
  setShowTopVisitedSites,
  updateDialogDeleteAllBookmarkAction,
  updateShowBookmarkTopicStylingAction
} from '../../store/reducers/bookmarksReducer/action';
import { deleteAllBookmarksServer } from '../../server/Dashbord/bookmark.server';
import useWindowDimensions from '../../Utils/useWindowDimensions';
import ChromeBookmaks from './section/ChromeBookmaks';
import TopicBookmaks from './section/TopicBookmaks';
import TopVisitedSitesBookmarks from './section/TopVisitedSitesBookmarks';
import UserBookmarks from './section/UserBookmarks';

export default function BookmarkFeature(props) {
  const dispatch = useDispatch();

  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const bookmarksState = useSelector((state) => state.bookmarks);

  let dashboardId = dashboard && dashboard._id ? dashboard._id : null;
  const { height, width } = useWindowDimensions();

  const dialogDeleteAllBookmarkHundle = (value = false) => {
    dispatch(updateDialogDeleteAllBookmarkAction(value));
  };

  useEffect(() => {
    dialogDeleteAllBookmarkHundle(false);
  }, []);

  //click event function for Bookmark size switch
  const onChangeBookmarkLargeSwitch = (event) => {
    dispatch(setBookmarkSizeBig(event.target.checked));
  };
  //click event function for show chrome bookmarks
  const onChangeShowChromeBookmarks = (event) => {
    dispatch(setShowChromeBookmark(event.target.checked));
  };

  const onChangeBookmarkTopicStyling = (event) => {
    dispatch(updateShowBookmarkTopicStylingAction(event.target.checked));
  };

  const onChangeTopVisitedSites = (event) => {
    dispatch(setShowTopVisitedSites(event.target.checked));
  };

  const onChangeBookmarksNewWindow = (event) => {
    dispatch(setBookmarkNewWindow(event.target.checked));
  };
  const onChangeBookmarksPositionTop = (event) => {
    dispatch(setBookmarkPositionTop(event.target.checked));
  };

  //click event function for Bookmark Color/black and white switch
  const onChangeBlackAndWhiteSwitch = (event) => {
    dispatch(setBookmarkToBlackWhite(!event.target.checked));
  };

  //click event function for Bookmark shape(rectangle) switch
  const onChangeRectangleSwitch = (event) => {
    dispatch(setBookmarkShapeRectangle(event.target.checked));
  };

  const onClickRemoveAllBookmarks = (event) => {
    dispatch(deleteAllBookmarksServer(dashboardId));
  };

  const hideIcons = async () => {
    props.setshowIcons(false);
  };

  const divBookmaksRef = useRef();

  //the main bookmark container div to be rendered
  return (
    <div
      className={`bookmarks-container  ${
        bookmarksState.bookmarkSizeBig ? ' bookmarks-container-large' : ' bookmarks-container-small'
      }`}
      ref={divBookmaksRef}
      style={props.opacity === 0 ? { display: 'none' } : { opacity: props.opacity }}
    >
      {bookmarksState.showTopicStylingBookmark ? (
        <TopicBookmaks />
      ) : bookmarksState.showchromeBoomkmarks ? (
        <ChromeBookmaks />
      ) : bookmarksState.showTopVisitedSites ? (
        <TopVisitedSitesBookmarks />
      ) : (
        <UserBookmarks />
      )}

      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-bookmark-options"
        placement={width < 1250 ? 'top' : 'right'}
        rootClose={true}
        overlay={
          <Popover
            id={`popover-bookmark-options`}
            className={
              width < 1250
                ? 'popover-bookmark-options popover-bookmark-options-arrow-top'
                : 'popover-bookmark-options popover-bookmark-options-arrow-right'
            }
          >
            <Popover.Body className="popover-bookmark-options-body">
              <div className="popover-bookmark-options-item">
                Large
                <Switch
                  checked={bookmarksState.bookmarkSizeBig}
                  onChange={onChangeBookmarkLargeSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>
              <div className="popover-bookmark-options-item">
                Chrome Bookmarks
                <Switch
                  checked={bookmarksState.showchromeBoomkmarks}
                  onChange={onChangeShowChromeBookmarks}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>
              <div className="popover-bookmark-options-item">
                Topic Bookmarks
                <Switch
                  checked={bookmarksState.showTopicStylingBookmark}
                  onChange={onChangeBookmarkTopicStyling}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>
              <div className="popover-bookmark-options-item">
                Top Visited Sites
                <Switch
                  checked={bookmarksState.showTopVisitedSites}
                  onChange={onChangeTopVisitedSites}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>
              <div className="popover-bookmark-options-item">
                Black and White
                <Switch
                  checked={!bookmarksState.bookmarkColored}
                  onChange={onChangeBlackAndWhiteSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>
              <div className="popover-bookmark-options-item">
                Square
                <Switch
                  checked={bookmarksState.bookmarkShapeRectangle}
                  onChange={onChangeRectangleSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>
              <div className="popover-bookmark-options-item">
                Move to Center
                <Switch
                  checked={bookmarksState.positionBOokmarksTop}
                  onChange={onChangeBookmarksPositionTop}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>
              <div className="popover-bookmark-options-item">
                Open new Tab
                <Switch
                  checked={bookmarksState.openBookmarkInNewWindow}
                  onChange={onChangeBookmarksNewWindow}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                />
              </div>

              <Button
                className="bookmark-btn-delete-all"
                variant="contained"
                color="info"
                size="small"
                style={{ marginTop: '1rem' }}
                onClick={() => {
                  dialogDeleteAllBookmarkHundle(true);
                }}
              >
                Delete All
              </Button>
            </Popover.Body>
          </Popover>
        }
      >
        <IconButton
          className="bookmark-options-btn"
          style={props.showIcons ? { color: '#fff', opacity: 1 } : { opacity: 0 }}
          disabled={!props.showIcons}
          aria-label="Settings"
        >
          <MoreHoriz className="home-icon-setting-font-size" />
        </IconButton>
      </OverlayTrigger>

      <Dialog
        open={bookmarksState.openDialogDeleteAllBookmark}
        onClose={() => {
          dialogDeleteAllBookmarkHundle(false);
        }}
      >
        <DialogContent>
          <div className="form-group form-group-bookmark">
            <label>Are you sure you want to delete all your bookmarks ?</label>
          </div>
        </DialogContent>
        {bookmarksState.errorDeleteAllBookmarks && (
          <p className="error-msg-txt">{bookmarksState.errorDeleteAllBookmarks.message}</p>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              dialogDeleteAllBookmarkHundle(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={onClickRemoveAllBookmarks}
            disabled={bookmarksState.loadingDeleteAllBookmarks}
          >
            {bookmarksState.loadingDeleteAllBookmarks ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                  alignSelf: 'center'
                }}
              />
            ) : (
              'Delete All'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
