/*global chrome*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { Email, Visibility, VisibilityOff, Lock } from '@mui/icons-material';

import validator from 'validator';
import AuthBottomBar from '../AuthBottomBar/AuthBottomBar';
import {
  activeAuthenticationLoading,
  desactiveAuthenticationLoading,
  resetAuthAction
} from '../../../store/reducers/authReducer/action';
import { RegiterAuthServer } from '../../../server/Auth/auth.server';

export default function Register(props) {
  const { stateAuth } = props;
  // const stateAuth = useSelector((state) => state.auth);
  const appState = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const [showPassword, setshowPassword] = useState(false);

  const [inputFirstName, setinputFirstName] = useState('');
  const [inputDisplayName, setinputDisplayName] = useState('');
  const [inputDisplayNameTouched, setinputDisplayNameTouched] = useState(false);
  const [inputLastName, setinputLastName] = useState('');
  const [inputEmail, setinputEmail] = useState('');
  const [inputEmailTouched, setinputEmailTouched] = useState(false);
  const [inputPassword, setinputPassword] = useState('');
  const [inputPasswordTouched, setinputPasswordTouched] = useState(false);
  const [inputGender, setinputGender] = useState('');

  const [displayNameErrorMsg, setdispalyNameErrorMsg] = useState({
    message: '',
    error: false
  });
  const [firstNameErrorMsg, setfirstNameErrorMsg] = useState({
    message: '',
    error: false
  });
  const [lastNameErrorMsg, setlastNameErrorMsg] = useState({
    message: '',
    error: false
  });
  const [genderNameErrorMsg, setgenderNameErrorMsg] = useState({
    message: '',
    error: false
  });
  const [emailErrorMsg, setemailErrorMsg] = useState({
    message: '',
    error: false
  });
  const [passwordErrorMsg, setpasswordErrorMsg] = useState({
    message: '',
    error: false
  });

  useEffect(() => {
    dispatch(resetAuthAction());
  }, []);

  const registerErrorReset = () => {
    setdispalyNameErrorMsg({
      message: '',
      error: false
    });
    setpasswordErrorMsg({
      message: '',
      error: false
    });
    setemailErrorMsg({
      message: '',
      error: false
    });
    setgenderNameErrorMsg({
      message: '',
      error: false
    });
    setlastNameErrorMsg({
      message: '',
      error: false
    });
    setfirstNameErrorMsg({
      message: '',
      error: false
    });
  };

  const registerSubmit = (event) => {
    event.preventDefault();
    dispatch(activeAuthenticationLoading());
    validationFormRegister();

    if (
      !displayNameErrorMsg.error &&
      !emailErrorMsg.error &&
      !passwordErrorMsg.error &&
      inputDisplayNameTouched &&
      inputEmailTouched &&
      inputPasswordTouched
    ) {
      let user = {
        username: inputDisplayName,
        email: inputEmail,
        password: inputPassword,
        deviceId: appState.deviceId
      };
      dispatch(RegiterAuthServer(user));
    } else {
      dispatch(desactiveAuthenticationLoading());
    }
  };

  const validationFormRegister = () => {
    verifEmailFormat(inputEmail);

    verifPasswordFormat(inputPassword);
    verifDisplayNameFormat(inputDisplayName);
  };

  const verifDisplayNameFormat = (dispalyName = '') => {
    if (validator.isEmpty(dispalyName, { ignore_whitespace: true })) {
      setdispalyNameErrorMsg({
        error: true,
        message: 'Please enter your Username'
      });
    } else {
      if (!validator.isLength(dispalyName, { min: 1, max: 48 })) {
        setdispalyNameErrorMsg({
          error: true,
          message: 'Please enter a Username between 1 and 48 characters'
        });
      } else {
        setdispalyNameErrorMsg({ error: false, message: '' });
      }
    }
  };

  const verifEmailFormat = (email = '') => {
    if (validator.isEmpty(email, { ignore_whitespace: true })) {
      setemailErrorMsg({
        error: true,
        message: 'Please enter your email address'
      });
    } else {
      if (!validator.isEmail(email)) {
        setemailErrorMsg({
          error: true,
          message: 'Email must be a valid format'
        });
      } else if (email.indexOf(' ') >= 0) {
        setemailErrorMsg({
          error: true,
          message: 'Email must not contain white spaces'
        });
      } else {
        setemailErrorMsg({ error: false, message: '' });
      }
    }
  };
  const verifFirstNameFormat = (firstName = '') => {
    if (validator.isEmpty(firstName, { ignore_whitespace: true })) {
      setfirstNameErrorMsg({
        error: true,
        message: 'Please enter your first name'
      });
    } else {
      if (!validator.isLength(firstName, { min: 1, max: 48 })) {
        setfirstNameErrorMsg({
          error: true,
          message: 'Please enter a first name between 1 and 48 characters'
        });
      } else {
        setfirstNameErrorMsg({ error: false, message: '' });
      }
    }
  };
  const verifLastNameFormat = (lastName = '') => {
    if (validator.isEmpty(lastName, { ignore_whitespace: true })) {
      setlastNameErrorMsg({
        error: true,
        message: 'Please enter your last name'
      });
    } else {
      if (!validator.isLength(lastName, { min: 1, max: 48 })) {
        setlastNameErrorMsg({
          error: true,
          message: 'Please enter a last name between 1 and 48 characters'
        });
      } else {
        setlastNameErrorMsg({ error: false, message: '' });
      }
    }
  };
  const verifGender = (gender = '') => {
    if (validator.isEmpty(gender, { ignore_whitespace: true })) {
      setgenderNameErrorMsg({
        error: true,
        message: 'Please select your gender'
      });
    } else {
      setgenderNameErrorMsg({ error: false, message: '' });
    }
  };

  const verifPasswordFormat = (password = '') => {
    var passwordCorrectFormatNumber = /\d/;
    var passwordCorrectFormatLowercase = /[a-z]/;
    var passwordCorrectFormatUppercase = /[A-Z]/;
    var passwordCorrectFormat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setpasswordErrorMsg({ error: true, message: 'Please enter a password' });
    } else {
      if (password.length < 5) {
        setpasswordErrorMsg({
          error: true,
          message: 'Please enter a password longer than 5 characters'
        });
      } else if (password.length > 32) {
        setpasswordErrorMsg({
          error: true,
          message: 'Please enter a password shorter than 32 characters'
        });
      } /* else if (!passwordCorrectFormatNumber.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one numeric digit",
        });
      } else if (!passwordCorrectFormatLowercase.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one lowercase letter",
        });
      } else if (!passwordCorrectFormatUppercase.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one uppercase letter",
        });
      } else if (password.indexOf(" ") >= 0) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must not contain white spaces",
        });
      } else if (!passwordCorrectFormat.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Please enter a valid password",
        });
      }*/ else {
        setpasswordErrorMsg({ error: false, message: '' });
      }
    }
  };

  return (
    <div className="inner-subcontainer">
      <form onSubmit={registerSubmit} style={{ width: '100%' }}>
        <div className="form-group">
          <label>Username </label>
          <input
            type="text"
            id="displayname"
            className={
              displayNameErrorMsg.error != '' ? 'form-control input-error' : 'form-control'
            }
            placeholder="Username"
            value={inputDisplayName}
            onFocus={(e) => {
              setdispalyNameErrorMsg({ error: false, message: '' });
              if (stateAuth.error) {
                dispatch(resetAuthAction());
              }
            }}
            onBlur={(e) => {
              verifDisplayNameFormat(inputDisplayName);
            }}
            onChange={(event) => {
              setinputDisplayName(event.target.value);
              setinputDisplayNameTouched(true);
              //  verifDisplayNameFormat(event.target.value);
              if (stateAuth.error) {
                dispatch(resetAuthAction());
              }
            }}
          />
          <p className="error-msg-txt">{displayNameErrorMsg.message}</p>
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            autoComplete="username email"
            className={emailErrorMsg.error ? 'form-control input-error' : 'form-control'}
            placeholder="Enter email"
            value={inputEmail}
            onFocus={(e) => {
              setemailErrorMsg({ error: false, message: '' });
              if (stateAuth.error) {
                dispatch(resetAuthAction());
              }
            }}
            onBlur={(event) => {
              verifEmailFormat(inputEmail);
            }}
            onChange={(event) => {
              setinputEmail(event.target.value);
              setinputEmailTouched(true);
              //  verifEmailFormat(event.target.value);
              if (stateAuth.error) {
                dispatch(resetAuthAction());
              }
            }}
          />

          <p className="error-msg-txt">{emailErrorMsg.message}</p>
        </div>

        <div className="form-group" style={{ marginBottom: '18px' }}>
          <div className="container-label-password-register">
            <label>Password</label>
            <span>(Password should be 6 characters or more)</span>
          </div>

          <div className="container-input-icon-auth" style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              className={passwordErrorMsg.error ? 'form-control input-error' : 'form-control'}
              id="password"
              placeholder="Enter password"
              value={inputPassword}
              onFocus={(e) => {
                setpasswordErrorMsg({ error: false, message: '' });
                if (stateAuth.error) {
                  dispatch(resetAuthAction());
                }
              }}
              onBlur={(e) => {
                verifPasswordFormat(inputPassword);
              }}
              onChange={(event) => {
                setinputPassword(event.target.value);
                setinputPasswordTouched(true);
                //  verifPasswordFormat(event.target.value);
                if (stateAuth.error) {
                  dispatch(resetAuthAction());
                }
              }}
            />
            {!showPassword ? (
              <IconButton className="button-icon-auth" onClick={() => setshowPassword(true)}>
                <VisibilityOff fontSize="small" />
              </IconButton>
            ) : (
              <IconButton className="button-icon-auth" onClick={() => setshowPassword(false)}>
                <Visibility fontSize="small" />
              </IconButton>
            )}
          </div>

          <p className="error-msg-txt">{passwordErrorMsg.message}</p>
        </div>
        {stateAuth.error ? (
          <p className="error-msg-txt" style={{ textAlign: 'center', marginBottom: '5px' }}>
            {stateAuth.error.message}
          </p>
        ) : null}
        <button
          className="btn btn-auth btn-lg btn-block"
          disabled={
            stateAuth.loading ||
            !inputDisplayNameTouched ||
            !inputEmailTouched ||
            !inputPasswordTouched
          }
          onClick={registerSubmit}
        >
          {stateAuth.loading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
                alignSelf: 'center'
              }}
            />
          ) : (
            'Sign Up'
          )}
        </button>
      </form>
      {/* <LoginSSO />*/}
      <AuthBottomBar setshowContactForm={props.setshowContactForm()} />
    </div>
  );
}
