export default function MarketingTextContainer() {
  return (
    <div className="marketing-text-container">
      <ul>
        <li>Daily New Picture</li>
        <li>Daily New Quote</li>
        <li>Rich, customizable productivity features</li>
      </ul>
    </div>
  );
}
