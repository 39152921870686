import { weatherStateAction } from "./constants";

export const updateWeatherUSTempUnit = () => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.UPDATE_US_TEMP_UNIT
    });
  };
};
export const updateWeatherUSTempUnitToCelsius = () => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.UPDATE_TEMP_UNIT_C
    });
  };
};
export const updateWeatherUSTempUnitToFar = () => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.UPDATE_TEMP_UNIT_F
    });
  };
};

export const updatePinWeatherToHome = (state) => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.UPDATE_PIN_WEATHER_HOME,
      payload: state
    });
  };
};

export const updateSearchCity = (state) => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.UPDATE_SEARCH_CITY,
      payload: state
    });
  };
};
export const updateCoordsSearchCity = (coords) => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.UPDATE_COORDS_SEARCH_CITY,
      payload: coords
    });
  };
};

export const updateEditingCity = (state) => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.UPDATE_EDITING_CITY,
      payload: state
    });
  };
};

export const updateTodayWeatherAction = (
  todayWeather = {},
  pinWeatherToHomeFirstObject = {},
  geoAccess = false
) => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.GET_TODAY_WEATHER,
      payload: {
        todayWeather: todayWeather,
        pinWeatherToHomeFirstObject: pinWeatherToHomeFirstObject,
        geoAccess: geoAccess
      }
    });
  };
};

export const updateWeekWeatherAction = (
  dailyWeather = {},
  todayWeatherInfo = {},
  hourlyWeather = [],
  pinWeatherToHomeThreeDays = {},
  geoAccess = false
) => {
  return (dispatch) => {
    dispatch({
      type: weatherStateAction.GET_WEEK_WEATHER,
      payload: {
        dailyWeather: dailyWeather,
        todayWeatherInfo: todayWeatherInfo,
        hourlyWeather: hourlyWeather,
        pinWeatherToHomeThreeDays: pinWeatherToHomeThreeDays,
        geoAccess: geoAccess
      }
    });
  };
};

export const clearTodayWeatherErrorAction = () => {
  return {
    type: weatherStateAction.CLEAR_TODAY_WEATHER_ERROR
  };
};
export const updateTodayWeatherRequestAction = () => {
  return {
    type: weatherStateAction.UPDATE_TODAY_WEATHER_REQUEST
  };
};
export const updateTodayWeatherSuccessAction = (todayWeather) => {
  return {
    type: weatherStateAction.UPDATE_TODAY_WEATHER_SUCCESS,
    payload: todayWeather
  };
};
export const updateTodayWeatherFailureAction = (error) => {
  return {
    type: weatherStateAction.UPDATE_TODAY_WEATHER_FAILURE,
    error: error
  };
};

export const updateTodayWeatherInfoRequestAction = () => {
  return {
    type: weatherStateAction.UPDATE_TODAY_WEATHER_INFO_REQUEST
  };
};
export const updateTodayWeatherInfoSuccessAction = (todayWeatherInfo) => {
  return {
    type: weatherStateAction.UPDATE_TODAY_WEATHER_INFO_SUCCESS,
    payload: todayWeatherInfo
  };
};
export const updateTodayWeatherInfoFailureAction = (error) => {
  return {
    type: weatherStateAction.UPDATE_TODAY_WEATHER_INFO_FAILURE,
    error: error
  };
};

export const updateHourForcastWeatherRequestAction = () => {
  return {
    type: weatherStateAction.UPDATE_HOUR_FORCAST_WEATHER_REQUEST
  };
};
export const updateHourForcastWeatherSuccessAction = (hourForcastWeather) => {
  return {
    type: weatherStateAction.UPDATE_HOUR_FORCAST_WEATHER_SUCCESS,
    payload: hourForcastWeather
  };
};
export const updateHourForcastWeatherFailureAction = (error) => {
  return {
    type: weatherStateAction.UPDATE_HOUR_FORCAST_WEATHER_FAILURE,
    error: error
  };
};

export const updateWeekForcastWeatherRequestAction = () => {
  return {
    type: weatherStateAction.UPDATE_WEEK_FORCAST_WEATHER_REQUEST
  };
};
export const updateWeekForcastWeatherSuccessAction = (weekForcastWeather) => {
  return {
    type: weatherStateAction.UPDATE_WEEK_FORCAST_WEATHER_SUCCESS,
    payload: weekForcastWeather
  };
};
export const updateWeekForcastWeatherFailureAction = (error) => {
  return {
    type: weatherStateAction.UPDATE_WEEK_FORCAST_WEATHER_FAILURE,
    error: error
  };
};
