import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Settings, Logout, AccountCircleOutlined, InstallDesktop } from '@mui/icons-material';

import './Settings.css';
import { useDispatch, useSelector } from 'react-redux';

import AppearanceSettings from './AppearanceSettings';
import AboutSettings from './AboutSettings';
import ContactSettings from './ContactSettings';
import ProfileSettings from './ProfileSettings';
import { updateGuestAction } from '../../store/reducers/appReducer/action';
import ContactSettingsGuestMode from '../../containers/Guest/ContactSettingsGuestMode/ContactSettingsGuestMode';
import { LogoutAction } from '../../store/reducers/authReducer/action';
import FeaturesSettings from './FeaturesSettings';
import { meaVanaConsole } from '../../Utils/MeaVanaConsole';
import TopicSettings from './TopicSettings';

export default function SettingsFeature(props) {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.app);

  const [activeSettingItem, setactiveSettingItem] = useState(0);

  const [contactTopic, setcontactTopic] = useState('');

  const goToContactFromBackground = (topic) => {
    setactiveSettingItem(4);
    setcontactTopic(topic);
  };
  if (props.display) {
    return (
      <div
        className="container-btn-Settings"
        style={props.display ? { opacity: 0, cursor: 'inherit' } : {}}>
        <Settings className="home-icon-font-size" />
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-Settings"
        placement="top"
        rootClose={true}
        overlay={
          <Popover id={`popover-Settings`} className="popover-settings">
            <Popover.Body className="popover-settings-body">
              <div className="container-settings-left">
                <div className="container-settings-left-top-item">
                  <div className="setting-nav-list">
                    <div
                      className={
                        activeSettingItem === 0
                          ? 'setting-nav-list-item-active'
                          : 'setting-nav-list-item'
                      }
                      onClick={() => {
                        setactiveSettingItem(0);
                      }}>
                      Appearance
                    </div>
                    <div
                      className={
                        activeSettingItem === 1
                          ? 'setting-nav-list-item-active'
                          : 'setting-nav-list-item'
                      }
                      onClick={() => {
                        setactiveSettingItem(1);
                      }}>
                      Features
                    </div>
                    {/*appState.isLoggedIn && (
                      <div
                        className={
                          activeSettingItem === 2
                            ? "setting-nav-list-item-active"
                            : "setting-nav-list-item"
                        }
                        onClick={() => {
                          setactiveSettingItem(2);
                        }}
                      >
                        Topic
                      </div>
                      )*/}
                    <div
                      className={
                        activeSettingItem === 3
                          ? 'setting-nav-list-item-active'
                          : 'setting-nav-list-item'
                      }
                      onClick={() => {
                        setactiveSettingItem(3);
                      }}>
                      About
                    </div>
                    <div
                      className={
                        activeSettingItem === 4 || activeSettingItem === 6
                          ? 'setting-nav-list-item-active'
                          : 'setting-nav-list-item'
                      }
                      onClick={() => {
                        if (appState.guestMode) {
                          setactiveSettingItem(6);
                        } else {
                          setactiveSettingItem(4);
                        }
                      }}>
                      Contact
                    </div>
                  </div>
                </div>
                {appState.guestMode ? (
                  <div className="container-settings-left-bottom-item">
                    {/* <div
                      className="container-btn-logout container-btn-icons-setting"
                      onClick={() => {
                        dispatch(updateGuestAction(false));
                      }}
                    >
                      <Logout fontSize="medium" />

                      <span className="container-btn-icons-setting-txt">
                        Sign Up
                      </span>
                    </div> */}
                    <span className="txt-note-guest-mode">Get the Chrome Extension</span>
                    <div
                      className="container-btn-icons-setting"
                      style={{ width: 'auto', marginTop: '0.2rem' }}
                      onClick={() => {
                        window.location =
                          'https://chrome.google.com/webstore/detail/meavana-transform-your-ch/kgbcoelgfffkmkpfnldemdinmcbpjlaa';
                      }}>
                      <InstallDesktop fontSize="medium" />

                      <span className="container-btn-icons-setting-txt">Click To Install</span>
                    </div>
                  </div>
                ) : (
                  <div className="container-settings-left-bottom-item">
                    <div
                      className={
                        activeSettingItem === 5
                          ? 'container-btn-icons-setting container-btn-icons-setting-active'
                          : 'container-btn-icons-setting'
                      }
                      onClick={() => {
                        setactiveSettingItem(5);
                      }}>
                      <AccountCircleOutlined fontSize="medium" />

                      <span className="container-btn-icons-setting-txt">Profile</span>
                    </div>
                    <div
                      className="container-btn-logout container-btn-icons-setting"
                      onClick={() => {
                        meaVanaConsole('user clicked logout button');
                        meaVanaConsole('dispatch LogoutAction Logout Button');

                        dispatch(LogoutAction());
                      }}>
                      <Logout fontSize="medium" />

                      <span className="container-btn-icons-setting-txt">Logout</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="container-settings-right">
                {activeSettingItem === 0 ? <AppearanceSettings /> : null}
                {activeSettingItem === 1 ? <FeaturesSettings /> : null}
                {/*activeSettingItem === 2 ? <TopicSettings /> : null*/}
                {activeSettingItem === 3 ? (
                  <AboutSettings setactiveSettingItem={setactiveSettingItem} />
                ) : null}
                {activeSettingItem === 4 ? <ContactSettings contactTopic={contactTopic} /> : null}
                {activeSettingItem === 5 ? <ProfileSettings /> : null}
                {activeSettingItem === 6 ? <ContactSettingsGuestMode /> : null}
              </div>
            </Popover.Body>
          </Popover>
        }>
        <div className="container-btn-Settings">
          <Settings className="home-icon-font-size" /*fontSize="large"*/ />
        </div>
      </OverlayTrigger>
    );
  }
}
