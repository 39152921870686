import { appStateAction } from '../appReducer/constants';
import { initialState, googleStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { integrationStateAction } from '../integrationsReducer/constants';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const googlePersistConfig = {
  key: 'google',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING google :', error)
};
export const googlePersisted = persistReducer(googlePersistConfig, googleReducer);

export default function googleReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT: {
      return initialState;
    }

    case integrationStateAction.RESET_INTEGRATION_PIN_STATE:
      const googleSources = [...state.googleSources];
      googleSources.forEach((element, index) => {
        googleSources[index].pinned = false;
      });

      return {
        ...state,
        googleSources
      };

    case googleStateAction.UPDATE_GOOGLE_WINDOW_STATE: {
      const { index } = action.payload;
      return {
        ...state,
        activeGoogleIndex: index
      };
    }

    case googleStateAction.UPDATE_GOOGLE_PIN: {
      const { index, pin } = action.payload;

      if (index == null) {
        return { ...state };
      }

      const googleSources = [...state.googleSources];
      googleSources[index].pinned = pin;

      return {
        ...state,
        googleSources
      };
    }

    case googleStateAction.GET_GOOGLE_API_REQUEST: {
      const { index } = action.payload;
      const googleSources = [...state.googleSources];
      googleSources[index].status = 'loading';

      return {
        ...state,
        googleSources
      };
    }

    case googleStateAction.GET_GOOGLE_API_SUCCESS: {
      const { index, data } = action.payload;
      const googleSources = [...state.googleSources];

      googleSources[index] = {
        ...googleSources[index],
        data,
        status: 'success',
        lastUpdatedAt: new Date()
      };

      return {
        ...state,
        googleSources,
        lastUpdatedAt: new Date()
      };
    }

    case googleStateAction.GET_GOOGLE_API_FAILURE: {
      const { index, error } = action.payload;
      const googleSources = [...state.googleSources];

      googleSources[index] = {
        ...googleSources[index],
        error,
        status: 'error'
      };

      return {
        ...state,
        googleSources
      };
    }

    default: {
      return state;
    }
  }
}
