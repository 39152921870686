import React from 'react';
import {
  updateActiveSearchEngineAction,
  updateAllSecondarySearchEngineAction,
  updateSearchEngineBorderAction,
  updateSearchEngineColoredAction,
  updateSearchEngineLineAction,
  updateSearchEngineOpenNewTabAction,
  updateSearchEngineRectangleAction,
  updateSearchEngineSizeAction,
  updateShowMultipleSearchEngineAction
} from '../../../store/reducers/searchEngineReducer/action';
import { defaultTopicStyling } from '../../../Utils/DefaultTopicStyling';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { SearchEngineList } from '../../../Utils/SearchEngineList';

export default function UpdateSearchEngine(searchEngines) {
  const width = typeof window !== 'undefined' ? window.innerWidth : 900;
  const maxWidth = ((width * 8) / 10 - 100) / 100;

  return async (dispatch) => {
    try {
      dispatch(updateShowMultipleSearchEngineAction(false));
      meaVanaConsole('start update UpdateSearchEngine: ', searchEngines);
      if (searchEngines && Array.isArray(searchEngines)) {
        meaVanaConsole('searchEngines array true');

        if (searchEngines.length < 2) {
          meaVanaConsole('searchEngines length < 2');

          let searchEngine;
          if (searchEngines.length === 0) {
            searchEngine = defaultTopicStyling.searchEngines[0];
          } else {
            searchEngine = searchEngines[0];
          }
          const searchEngineValue = SearchEngineList.find((s) => {
            return s.name.toLowerCase() === searchEngine.searchEngine.toLowerCase();
          });
          if (searchEngineValue) {
            dispatch(updateActiveSearchEngineAction(searchEngineValue));
          } else {
            dispatch(updateActiveSearchEngineAction(SearchEngineList[0]));
          }

          dispatch(
            updateSearchEngineSizeAction(
              searchEngine.size < maxWidth ? searchEngine.size : maxWidth
            )
          );

          dispatch(updateSearchEngineOpenNewTabAction(searchEngine.openNewTab));

          dispatch(updateSearchEngineColoredAction(searchEngine.color));
          dispatch(updateSearchEngineLineAction(searchEngine.line));

          dispatch(updateSearchEngineBorderAction(searchEngine.border));
          dispatch(updateSearchEngineRectangleAction(searchEngine.rectangle));
        } else {
          const newSecondarySearchEngine = [];
          searchEngines.forEach((search) => {
            const searchEngineValue = SearchEngineList.find((s) => {
              return s.name.toLowerCase() === search.searchEngine.toLowerCase();
            });
            newSecondarySearchEngine.push({
              searchEngineRectangle: search.rectangle,
              searchEngineBorder: search.border,
              searchEngineLine: search.line,
              searchEnginSize: search.size < maxWidth ? search.size : maxWidth,
              searchEnginColored: search.color,
              searchEngineOpenNewTab: search.openNewTab,
              activeSearchEngine: searchEngineValue ? searchEngineValue : SearchEngineList[0]
            });
          });
          dispatch(updateShowMultipleSearchEngineAction(true));
          dispatch(updateAllSecondarySearchEngineAction(newSecondarySearchEngine));
        }
      } else {
        dispatch(updateActiveSearchEngineAction(SearchEngineList[0]));
      }

      meaVanaConsole('UpdateSearchEngine success');
    } catch (error) {
      meaVanaConsole('UpdateSearchEngine error:', error);
    }
  };
}
