import moment from 'moment';
import { appStateAction } from '../appReducer/constants';
import { backgroundStateAction, initialState } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const backgroundPersistConfig = {
  key: 'background',
  storage,
  version: 2,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING background :', error)
};
export const backgroundPersisted = persistReducer(backgroundPersistConfig, backgroundReducer);

export default function backgroundReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case backgroundStateAction.UPDATE_BACKGROUND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case backgroundStateAction.UPDATE_BACKGROUND_SUCCESS:
      return {
        ...state,
        background: action.payload.background,
        quote: action.payload.quote,
        backgroundBatch: action.payload.backgroundBatch,
        backgroundLastUpdate: new Date(),
        // backgroundLastUpdate: moment.utc(),
        loading: false
      };
    case backgroundStateAction.UPDATE_BACKGROUND_SUCCESS_FROM_LOGIN:
      return {
        ...state,
        background: action.payload.background,
        backgroundBatch: [],
        quote: action.payload.quote,
        backgroundLastUpdate: new Date(),
        //  backgroundLastUpdate: moment.utc(),
        loading: false
      };

    case backgroundStateAction.SET_QUOTE_SIZE:
      return {
        ...state,
        quoteSize: action.payload
      };

    case backgroundStateAction.UPDATE_BACKGROUND_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case backgroundStateAction.GET_ALL_TOPIC_FAILURE:
      return {
        ...state,
        loadingTopic: false,
        errorTopic: action.error
      };
    case backgroundStateAction.GET_ALL_TOPIC_REQUEST:
      return {
        ...state,
        loadingTopic: true,
        errorTopic: null,
        topics: []
      };
    case backgroundStateAction.GET_ALL_TOPIC_SUCCESS:
      return {
        ...state,
        topics:
          state.topics.length > 0 || state.topics != null
            ? [...state.topics, ...action.payload]
            : [...action.payload],
        topicLastUpdate: moment.utc(),
        loadingTopic: false,
        errorTopic: null
      };
    case backgroundStateAction.UPDATE_SELECTED_TOPIC:
      return {
        ...state,
        selectedTopic: action.payload,
        clickedTopicId: action.payload._id,
        loadingSelectedTopic: true,
        errorSelectedTopic: null,
        background: action.payload.dailyImage,
        quote: action.payload.dailyImage.quotes[0],
        backgroundBatch: action.payload.imagesBatch,
        backgroundLastUpdate: new Date(),
        //backgroundLastUpdate: moment.utc(),
        loading: false
      };
    case backgroundStateAction.UPDATE_SELECTED_TOPIC_FROM_SERVER:
      let selectedTopic = action.payload.find((a) => a._id === state.selectedTopic._id);
      if (selectedTopic === undefined) {
        selectedTopic = action.payload[0];
      }
      return {
        ...state,
        selectedTopic: selectedTopic,
        clickedTopicId: selectedTopic._id,
        loadingSelectedTopic: true,
        errorSelectedTopic: null,
        background: selectedTopic.dailyImage,
        quote: selectedTopic.dailyImage.quotes[0],
        backgroundBatch: selectedTopic.imagesBatch,
        backgroundLastUpdate: new Date(),
        // backgroundLastUpdate: moment.utc(),
        loading: false
      };

    case backgroundStateAction.UPDATE_SELECTED_TOPIC_REQUEST:
      return {
        ...state,
        clickedTopicId: action.payload,
        loadingSelectedTopic: true,
        errorSelectedTopic: null
      };

    case backgroundStateAction.UPDATE_SELECTED_TOPIC_SUCCESS:
      return {
        ...state,
        loadingSelectedTopic: false,
        errorSelectedTopic: null
      };

    case backgroundStateAction.UPDATE_SELECTED_TOPIC_FAILURE:
      return {
        ...state,
        loadingSelectedTopic: false,
        errorSelectedTopic: action.error
      };

    case backgroundStateAction.UPDATE_BACKGROUND_INDEXDB_STATE:
      return {
        ...state,
        backgroundIndexFromDB: action.payload
      };

    case backgroundStateAction.UPDATE_BACKGROUND_INDEXDB_INDEX:
      return {
        ...state,
        backgroundIndexFromDBIndex: action.payload
      };

    case backgroundStateAction.UPDATE_BACKGROUND_INDEXDB_ARRAY:
      const { index, item } = action.payload;

      if (index == null) {
        return { ...state };
      }

      const backgroundIndexFromDBArray = [...state.backgroundIndexFromDBArray];
      backgroundIndexFromDBArray[index] = item;

      return {
        ...state,
        backgroundIndexFromDBArray
      };

    default:
      return state;
  }
}
