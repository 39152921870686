import { initialState } from './constants';

export const stateMigrations = {
  1: (state) => {
    console.log('update google store version 1 :');
    return {
      ...state
    };
  }
};
