export const forgotPasswordParams = {
  loadingForgotPassword: false,
  successForgotPassword: false,
  emailForgotPassword: null,
  responseForgotPassword: null,
  errorForgotPassword: null,

  loadingForgotPasswordVerifyEmail: false,
  successForgotPasswordVerifyEmail: false,
  codeForgotPasswordVerifyEmail: null,
  responseForgotPasswordVerifyEmail: null,
  errorForgotPasswordVerifyEmail: null,

  loadingResetPassword: false,
  successResetPassword: false,
  userResetPassword: null,
  responseResetPassword: null,
  errorResetPassword: null
};

export const profileParams = {
  loadingUpdateUserInfo: false,
  successUpdateUserInfo: false,
  responseUpdateUserInfo: null,
  errorUpdateUserInfo: null,

  loadingUpdateUserPassword: false,
  successUpdateUserPassword: false,
  responseUpdateUserPassword: null,
  errorUpdateUserPassword: null,

  successDeleteAccount: false,
  loadingDeleteAccount: false,
  responseDeleteAccount: null,
  errorDeleteAccount: null,

  loadingDisableAccount: false,
  successDisableAccount: false,
  responseDisableAccount: null,
  errorDisableAccount: null
};

export const authParams = {
  user: null,
  ACCESS_TOKEN: null,
  accessTokenExpirationDate: null,
  REFRESH_TOKEN: null,

  error: null,
  loading: false,

  loadingGoogleSSO: false,
  errorGoogleSSO: null,

  loadingFacebbokSSO: false,
  errorFacebookSSO: null
};

export const initialState = {
  firstTimeUser: true,
  datefirstTimeUserLogin: null,
  oldUser: null,
  historyUsers: [],
  ...authParams,
  ...forgotPasswordParams,
  ...profileParams
};

export const authStateAction = {
  LOGIN_REQUEST: "auth/LOGIN_REQUEST",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  LOGIN_FAILURE: "auth/LOGIN_FAILURE",
  LOGOUT: "auth/LOGOUT",
  REGISTER_REQUEST: "auth/REGISTER_REQUEST",
  REGISTER_SUCCESS: "auth/REGISTER_SUCCESS",
  REGISTER_FAILURE: "auth/REGISTER_FAILURE",
  ACTIVE_AUTHENTICATION_LOADING: "auth/ACTIVE_AUTHENTICATION_LOADING",
  DESACTIVE_AUTHENTICATION_LOADING: "auth/DESACTIVE_AUTHENTICATION_LOADING",
  UPDATE_USER_TOKEN: "auth/UPDATE_USER_TOKEN",
  FORGOT_PASSWORD_REQUEST: "auth/FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "auth/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "auth/FORGOT_PASSWORD_FAILURE",
  FORGOT_PASSWORD_VERIFY_EMAIL_REQUEST:
    "auth/FORGOT_PASSWORD_VERIFY_EMAIL_REQUEST",
  FORGOT_PASSWORD_VERIFY_EMAIL_SUCCESS:
    "auth/FORGOT_PASSWORD_VERIFY_EMAIL_SUCCESS",
  FORGOT_PASSWORD_VERIFY_EMAIL_FAILURE:
    "auth/FORGOT_PASSWORD_VERIFY_EMAIL_FAILURE",
  RESET_PASSWORD_REQUEST: "auth/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "auth/RESET_PASSWORD_FAILURE",
  DELETE_ACCOUNT_REQUEST: "auth/DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS: "auth/DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_FAILURE: "auth/DELETE_ACCOUNT_FAILURE",
  UPDATE_USER_INFO_REQUEST: "auth/UPDATE_USER_INFO_REQUEST",
  UPDATE_USER_INFO_SUCCESS: "auth/UPDATE_USER_INFO_SUCCESS",
  UPDATE_USER_INFO_FAILURE: "auth/UPDATE_USER_INFO_FAILURE",
  RESET_PROFILE_ACTIONS: "auth/RESET_PROFILE_ACTIONS",
  UPDATE_USER_PASSWORD_REQUEST: "auth/UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_SUCCESS: "auth/UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_FAILURE: "auth/UPDATE_USER_PASSWORD_FAILURE",
  DISABLE_ACCOUNT_REQUEST: "auth/DISABLE_ACCOUNT_REQUEST",
  DISABLE_ACCOUNT_SUCCESS: "auth/DISABLE_ACCOUNT_SUCCESS",
  DISABLE_ACCOUNT_FAILURE: "authStateAction/",
  LOGIN_SSO_GOOGLE_REQUEST: "auth/LOGIN_SSO_GOOGLE_REQUEST",
  LOGIN_SSO_GOOGLE_ERROR: "auth/LOGIN_SSO_GOOGLE_ERROR",
  LOGIN_SSO_GOOGLE_SUCCESS: "auth/LOGIN_SSO_GOOGLE_SUCCESS",
  LOGIN_SSO_FACEBOOK_REQUEST: "auth/LOGIN_SSO_FACEBOOK_REQUEST",
  LOGIN_SSO_FACEBOOK_ERROR: "auth/LOGIN_SSO_FACEBOOK_ERROR",
  LOGIN_SSO_FACEBOOK_SUCCESS: "auth/LOGIN_SSO_FACEBOOK_SUCCESS",
  UPDATE_GUEST_MODE: "auth/UPDATE_GUEST_MODE",
  RESET_FORGOT_PASSWORD_STATE: "auth/RESET_FORGOT_PASSWORD_STATE",
  RESET_AUTH_STATE: "auth/RESET_AUTH_STATE"
};
