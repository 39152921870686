import { appStateAction } from '../appReducer/constants';
import { homeStateAction } from '../homeReducer/constants';
import { clockStateAction, initialState } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const clockPersistConfig = {
  key: 'clock',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING clock :', error)
};
export const clockPersisted = persistReducer(clockPersistConfig, clockReducer);

export default function clockReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case homeStateAction.UPDATE_HOME_THEME:
      return {
        ...state,
        ...action.theme.clock
      };

    case clockStateAction.UPDATE_TIME_FORMAT:
      return {
        ...state,
        hour24: action.payload
      };
    case clockStateAction.UPDATE_AM_PM:
      return {
        ...state,
        showAmPm: action.payload
      };
    case clockStateAction.UPDATE_THEME_MODE:
      return {
        ...state,
        themeMode: action.payload
      };
    case clockStateAction.UPDATE_TIME_SECONDS:
      return {
        ...state,
        showSeconds: action.payload
      };
    case clockStateAction.UPDATE_ANALOG_CLOCK:
      return {
        ...state,
        analogClock: action.payload
      };
    case clockStateAction.SHOW_HIDE_TIMEFEATURE:
      return {
        ...state,
        showTimeFeature: !state.showTimeFeature
      };
    case clockStateAction.SHOW_ANALOG:
      return {
        ...state,
        showAnalog: !state.showAnalog
      };
    case clockStateAction.UPDATE_SHOW_ANALOG:
      return {
        ...state,
        showAnalog: action.payload
      };
    case clockStateAction.SET_ANALOG_CLOCK_SIZE:
      return {
        ...state,
        analogClockSize: action.payload
      };

    case clockStateAction.SET_TIME_DATE_FONT_SIZE:
      return {
        ...state,
        timeDateFontSize: action.payload
      };
    case clockStateAction.SET_TIME_DATE_FONT_WEIGHT:
      return {
        ...state,
        timeDateFontWeight: action.payload
      };

    case clockStateAction.SHOW_HIDE_STOPWATCH:
      return {
        ...state,
        showStopWatch: !state.showStopWatch,
        showTimeFeature: !state.showStopWatch,
        showAlarm: false,
        showTimer: false
      };

    case clockStateAction.SHOW_HIDE_ALARM:
      return {
        ...state,
        showStopWatch: false,
        showTimeFeature: !state.showAlarm,
        showAlarm: !state.showAlarm,
        showTimer: false
      };

    case clockStateAction.SHOW_HIDE_TIMER:
      return {
        ...state,
        showStopWatch: false,
        showTimeFeature: !state.showTimer,
        showAlarm: false,
        showTimer: !state.showTimer
      };
    default:
      return state;
  }
}
