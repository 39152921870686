import { appStateAction } from '../appReducer/constants';
import { animBackgroundStateAction, initialState } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const animationPersistConfig = {
  key: 'animation',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING animation :', error)
};
export const animationPersisted = persistReducer(animationPersistConfig, animationReducer);

export default function animationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case animBackgroundStateAction.UPDATE_ANIMATION_BACKGROUND:
      return {
        ...state,
        active: true,
        currentAnimation: state.animations.find((e) => {
          return e.name === action.payload;
        })
      };

    case animBackgroundStateAction.UPDATE_ACTIVE_ANIMATION_BACKGROUND:
      if (action.payload) {
        return {
          ...state,
          active: true
        };
      } else {
        return {
          ...state,
          active: false,
          currentAnimation: null
        };
      }

    default:
      return state;
  }
}
