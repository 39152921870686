import { picturestateAction } from "./constants";

export const updatePictureWindowStateAction = (pictureId = null) => {
  return (dispatch) => {
    dispatch({
      type: picturestateAction.UPDATE_PICTURE_WINDOW_STATE,
      payload: pictureId
    });
  };
};

export const updatePicturePinAction = (pictureId = null, pin = false) => {
  return (dispatch) => {
    dispatch({
      type: picturestateAction.UPDATE_PICTURE_PIN,
      payload: { pictureId: pictureId, pin: pin }
    });
  };
};
