import { assetsIcons } from "../../../config/images";

export const initialState = {
  cryptoApiSource: [
    {
      id: "coincap",
      name: "CoinCap",
      icons: {
        black: assetsIcons.integration.crypto.coinCap.color,
        color: assetsIcons.integration.crypto.coinCap.color,
        white: assetsIcons.integration.crypto.coinCap.color
      },
      active: false,
      loaded: false,
      status: "emplty",
      error: null,
      pined: false,
      lastUpdate: null
    }
  ],
  activeCryptoId: null,
  lastUpdate: null
};

export const cryptoStateAction = {
  UPDATE_CRYPTO_WINDOW_STATE: "crypto/UPDATE_CRYPTO_WINDOW_STATE",
  UPDATE_CRYPTO_PIN: "crypto/UPDATE_CRYPTO_PIN"
};
