export const initialState = {
  homeTheme: "standard",

  hideAllFeatures: false,

  showTime: 1,
  showAllFeature: 1,
  showDate: 1,
  showNote: 1,
  showSearch: 0.5,
  showBookmark: 1,
  showQuote: 1,
  showBackgroundInfo: 1,
  showWeather: 1,
  showIntegration: 1
};

export const homeStateAction = {
  RESET_HOME_THEME: "home/RESET_HOME_THEME",
  UPDATE_HOME_THEME: "home/UPDATE_HOME_THEME",
  HIDE_ALL_FEATURES: "home/HIDE_ALL_FEATURES",
  UPDATE_HOME_SHOW_TIME: "home/UPDATE_HOME_SHOW_TIME",
  UPDATE_HOME_SHOW_DATE: "home/UPDATE_HOME_SHOW_DATE",
  UPDATE_HOME_SHOW_NOTE: "home/UPDATE_HOME_SHOW_NOTE",
  UPDATE_HOME_SHOW_SEARCH: "home/UPDATE_HOME_SHOW_SEARCH",
  UPDATE_HOME_SHOW_QUOTE: "home/UPDATE_HOME_SHOW_QUOTE",
  UPDATE_HOME_SHOW_WEATHER: "home/UPDATE_HOME_SHOW_WEATHER",
  UPDATE_HOME_SHOW_BACKGROUND_INFO: "home/UPDATE_HOME_SHOW_BACKGROUND_INFO",
  UPDATE_HOME_SHOW_BOOKMARK: "home/UPDATE_HOME_SHOW_BOOKMARK",
  UPDATE_HOME_SHOW_INTEGRATION: "home/UPDATE_HOME_SHOW_INTEGRATION",
  UPDATE_HOME_SHOW_ALL_FEATURE: "home/UPDATE_HOME_SHOW_ALL_FEATURE"
};
