import { Close, PushPin, PushPinOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePicturePinAction,
  updatePictureWindowStateAction
} from "../../../store/reducers/picturesReducer/action";
import { GetImageUrl } from "../../../Utils/GetImageUrl";

export default function PicturesPin({ whiteIcon }) {
  const picturesState = useSelector((state) => state.pictures);
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  return (
    <>
      {picturesState.picturesApiSource.map((picture, index) => {
        if (picture.pined) {
          return (
            <div
              key={"pictures-pin-grid-item-" + picture.id}
              className={
                picture.active
                  ? "news-pin-grid-item-active news-pin-grid-item"
                  : "news-pin-grid-item"
              }
            >
              <Close
                className="news-pin-button-pin"
                onClick={() => {
                  dispatch(updatePicturePinAction(picture.id, false));
                }}
              />

              <div
                className="news-pin-grid-item-container"
                onClick={() => {
                  if (picture.active) {
                    dispatch(updatePictureWindowStateAction());
                  } else {
                    dispatch(updatePictureWindowStateAction(picture.id));
                  }
                }}
              >
                <img
                  src={
                    whiteIcon
                      ? GetImageUrl(picture.icons.white)
                      : GetImageUrl(picture.icons.black)
                  }
                  style={!whiteIcon ? { opacity: 0.6 } : {}}
                  alt={picture.id + " icon"}
                />
                <span>{picture.name}</span>
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
