import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { updateChromeBookmarkAction } from '../../../store/reducers/bookmarksReducer/action';
import { BookmarkGetImageSrcUrl } from '../../../Utils/BookmarkGetImageSrcUrl';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import { assetsIcons } from '../../../config/images';

export default function ChromeBookmaks(props) {
  const dispatch = useDispatch();
  const bookmarksState = useSelector((state) => state.bookmarks);

  const chromeBoomkmarkList =
    bookmarksState && bookmarksState.chromeBoomkmarks ? bookmarksState.chromeBoomkmarks : [];

  const redirectToUrl = (e, url) => {
    if (bookmarksState.openBookmarkInNewWindow) {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_blank');
    } else {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_self');
    }
  };

  const onBookmarkDragEnd = (result) => {
    //return without processing further if no result
    //or return if result exists but no drag happened i.e bookmark is at same place where it was()
    if (!result || (result && result.oldIndex === result.newIndex)) {
      meaVanaConsole('result issue :', result);
      return;
    }

    //update the index of bookmark dragged/dropped within Array
    let reorderedBookmarks = arrayMove(chromeBoomkmarkList, result.oldIndex, result.newIndex);
    //update the index property of Bookmark objects as well to be saved in Backend/DB
    reorderedBookmarks = reorderedBookmarks.map((_bookmark, index) => {
      return {
        ..._bookmark,
        prevIndex: _bookmark.index,
        index: index + 1
      };
    });

    dispatch(updateChromeBookmarkAction(reorderedBookmarks));
  };

  /**
   * A Bookmark Component for showing a single Bookmark
   * @param {object} an object containing index value and a bookmark object(props:name and value)
   * @returns a single Bookmark Div
   */
  const Bookmark = SortableElement(({ index, bookmark }) => {
    if (bookmark === null) return null;
    if (bookmark.url) {
      const bookmarkImgSrc = BookmarkGetImageSrcUrl(bookmark.url);
      return (
        <Tooltip
          arrow
          // followCursor
          key={'bookmark-tooltip-item-' + index}
          title={bookmark.title}
          placement="top"
        >
          <div
            className={
              bookmarksState.bookmarkShapeRectangle
                ? 'rectangular-shape bookmarks-item'
                : 'bookmarks-item'
            }
            key={index}
            onClick={(event) => {
              redirectToUrl(event, bookmark.url);
            }}
          >
            <div className="bookmarks-item-container">
              <img
                /*src={
                "https://s2.googleusercontent.com/s2/favicons?sz=64&domain_url=" +
                bookmark.url
              }*/
                src={bookmarkImgSrc}
                alt={bookmark.title}
                className={`bookmark-img ${!bookmarksState.bookmarkColored && 'grayscale-filter'}`}
              ></img>
            </div>
          </div>
        </Tooltip>
      );
    }
    if (bookmark.children) {
      return (
        <div
          className={
            bookmarksState.bookmarkShapeRectangle
              ? 'rectangular-shape bookmarks-item'
              : 'bookmarks-item'
          }
          key={index}
        >
          <PopoverBookmarkFolder
            bookmarks={bookmark.children}
            title={bookmark.title}
            openBookmarkInNewWindow={bookmarksState.openBookmarkInNewWindow}
          >
            <Tooltip
              arrow
              //followCursor
              key={'bookmark-tooltip-item-' + index}
              title={bookmark.title}
              placement="top"
            >
              <div className="bookmarks-item-container">
                <img
                  src={GetImageUrl(assetsIcons.folder)}
                  alt={bookmark.title}
                  className={`bookmark-img ${
                    !bookmarksState.bookmarkColored && 'grayscale-filter'
                  }`}
                ></img>
                <span>{bookmark.title[0] + bookmark.title[1]}</span>
              </div>
            </Tooltip>
          </PopoverBookmarkFolder>
        </div>
      );
    }
  });

  const SortableBookmarkList = SortableContainer(({ bookmarks }) => {
    return (
      <div
        className={`bookmarks-container-sortable-list  ${
          bookmarksState.bookmarkSizeBig ? 'bookmark-lg' : 'bookmark-sm'
        } ${'show-scroll-bar' /*props.showIcons ? "show-scroll-bar" : "hide-scroll-bar"*/}
        `}
      >
        {bookmarks.map((bookmark, index) => {
          return bookmark === null ? null : (
            <Bookmark key={`item-${index}`} index={index} bookmark={bookmark} />
          );
        })}
      </div>
    );
  });

  return (
    <>
      <SortableBookmarkList
        bookmarks={chromeBoomkmarkList}
        axis="xy"
        distance={1}
        onSortEnd={onBookmarkDragEnd}
      />
    </>
  );
}

function PopoverBookmarkFolder(props) {
  const [bookmarks, setbookmarks] = useState(props.bookmarks);
  //get the image src as for some bookmarks the righ favicon(image) is not generated.

  const redirectToUrl = (e, url) => {
    if (props.openBookmarkInNewWindow) {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_blank');
    } else {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_self');
    }
  };
  return (
    <OverlayTrigger
      trigger="click"
      key="OverlayTrigger-bookmark-folder"
      placement="top"
      rootClose={true}
      overlay={
        <Popover id={`popover-bookmark-folder`} className="bookmark-folder-popover">
          <Popover.Header as="h3">{props.title}</Popover.Header>

          <Popover.Body className="bookmark-folder-popover-body">
            <div className="bookmark-folder-container">
              {bookmarks.map((bookmark, index) => {
                if (bookmark.url) {
                  return (
                    <Tooltip
                      arrow
                      //   followCursor
                      key={'bookmark-tooltip-item-' + index}
                      title={bookmark.title}
                      placement="top"
                    >
                      <div
                        className="bookmark-folder"
                        onClick={(event) => {
                          redirectToUrl(event, bookmark.url);
                        }}
                      >
                        <img
                          /*src={
                          "https://s2.googleusercontent.com/s2/favicons?sz=64&domain_url=" +
                          bookmark.url
                        }*/
                          src={BookmarkGetImageSrcUrl(bookmark.url)}
                        />
                        <span>{bookmark.title}</span>
                      </div>
                    </Tooltip>
                  );
                }
                if (bookmark.children) {
                  return (
                    <PopoverBookmarkFolder bookmarks={bookmark.children} title={bookmark.title}>
                      <Tooltip
                        arrow
                        //  followCursor
                        key={'bookmark-tooltip-item-' + index}
                        title={bookmark.title}
                        placement="top"
                      >
                        <div className="bookmark-folder">
                          <img src={GetImageUrl(assetsIcons.folder)} />
                          <span>{bookmark.title}</span>
                        </div>
                      </Tooltip>
                    </PopoverBookmarkFolder>
                  );
                }
              })}
            </div>{' '}
          </Popover.Body>
        </Popover>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}
