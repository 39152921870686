export const stateMigrations = {
  1: (state) => {
    console.log('update app store version 1 :');
    return {
      ...state,
      appVersion: '0.1.22'
    };
  },
  2: (state) => {
    console.log('update app store version 2 :');
    return {
      ...state,
      appVersion: '0.1.23'
    };
  }
};
