const data = {
  assets: []
};

export const params = {
  showCoincapWindow: false
};

export const initialState = {
  ...data,
  ...params
};

export const coincapStateAction = {
  GET_COINCAP_ASSETS_REQUEST: "coincap/GET_COINCAP_ASSETS_REQUEST",
  GET_COINCAP_ASSETS_SUCESS: "coincap/GET_SCOINCAP_ASSETS_SUCESS",
  GET_COINCAP_ASSETS_FAILURE: "coincap/GET_COINCAP_ASSETS_FAILURE",
  SHOW_COINCAP_WINDOW: "coincap/SHOW_COINCAP_WINDOW"
};
