import { appStateAction } from '../appReducer/constants';
import { dashboardStateAction, initialState } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const dashboardPersistConfig = {
  key: 'dashboard',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING dashboard :', error)
};
export const dashboardPersisted = persistReducer(dashboardPersistConfig, dashboardReducer);

export default function dashboardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case dashboardStateAction.GET_CURRENT_DASHBORD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardStateAction.GET_CURRENT_DASHBORD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        loading: false
      };
    case dashboardStateAction.GET_CURRENT_DASHBORD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}
