import React, { useState } from 'react';
import Login from './Login/Login';
import Register from './Register/Register';
import './Authentification.css';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { useSelector } from 'react-redux';
import ContactForm from './ContactForm/ContactForm';

export default function Auth() {
  const stateAuth = useSelector((state) => state.auth);
  const [showRegister, setshowRegister] = useState(
    stateAuth.firstTimeUser ? stateAuth.firstTimeUser : false
  );

  const [showForgotPassword, setshowForgotPassword] = useState(false);
  const [showContactForm, setshowContactForm] = useState(false);
  return (
    <div className="outer">
      {showForgotPassword ? (
        <ForgotPassword showForgotPassword={() => setshowForgotPassword(!showForgotPassword)} />
      ) : showContactForm ? (
        <ContactForm setshowContactForm={setshowContactForm} />
      ) : (
        <div className="inner">
          <div className="tab">
            <button
              className={!showRegister ? 'tablinks-login active' : 'tablinks-login'}
              onClick={() => {
                setshowRegister(false);
              }}
            >
              Login
            </button>

            <button
              className={showRegister ? 'tablinks-signin active' : 'tablinks-signin'}
              onClick={() => {
                setshowRegister(true);
              }}
            >
              Sign Up
            </button>
          </div>
          {showRegister ? (
            <Register setshowContactForm={setshowContactForm} stateAuth={stateAuth} />
          ) : (
            <Login
              showForgotPassword={() => setshowForgotPassword(!showForgotPassword)}
              setshowContactForm={setshowContactForm}
              stateAuth={stateAuth}
            />
          )}
        </div>
      )}
    </div>
  );
}
