/*global chrome*/

import { checkErrorResponseFormat } from "../../server/Middleware/utils.server";
import {
  getMusicDataFailureAction,
  setMusiccodeAction
} from "../../store/reducers/musicReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

const SPOTIFY_CLIENT_ID = encodeURIComponent(
    "adad602da4574c92889c6b63b8bd71ff"
  ),
  SPOTIFY_RESPONSE_TYPE = encodeURIComponent("code"),
  //SPOTIFY_REDIRECT_URI = chrome.identity.getRedirectURL(), // This redirect URI is something like https://<extension-id>.chromeapp.org
  SPOTIFY_SCOPE = encodeURIComponent(
    "streaming user-read-email user-read-private user-library-read user-library-modify user-read-currently-playing user-read-recently-played user-read-playback-state user-top-read  user-modify-playback-state"
  ),
  SPOTIFY_SHOW_DIALOG = encodeURIComponent("true");

let SPOTIFY_STATE = "";

function Spotify_create_auth_endpoint(SPOTIFY_REDIRECT_URI) {
  SPOTIFY_STATE = encodeURIComponent(
    "jsksf3" + Math.random().toString(36).substring(2, 12)
  );

  meaVanaConsole("SPOTIFY_REDIRECT_URI >>> ", SPOTIFY_REDIRECT_URI);

  let code_endpoint = `https://accounts.spotify.com/authorize
?client_id=${SPOTIFY_CLIENT_ID}
&response_type=${SPOTIFY_RESPONSE_TYPE}
&redirect_uri=${SPOTIFY_REDIRECT_URI}
&state=${SPOTIFY_STATE}
&scope=${SPOTIFY_SCOPE}
&show_dialog=${SPOTIFY_SHOW_DIALOG}
`;

  meaVanaConsole("openId_endpoint_url", code_endpoint);
  return code_endpoint;
}

export const SpotifyChromeAuth = (musicId) => {
  return async (dispatch) => {
    try {
      meaVanaConsole("start SpotifyChromeAuth");
      if (typeof chrome != "undefined") {
        chrome.identity.launchWebAuthFlow(
          {
            url: Spotify_create_auth_endpoint(chrome.identity.getRedirectURL()),
            interactive: true
          },
          function (redirect_url) {
            meaVanaConsole("Spotify redirect_url ", redirect_url);
            if (redirect_url && redirect_url?.includes("code")) {
              let code = redirect_url.substring(
                redirect_url.indexOf("code=") + 5
              );
              code = code.substring(0, code.indexOf("&"));
              const state = redirect_url.substring(
                redirect_url.indexOf("state=") + 6
              );
              meaVanaConsole("state >>> ", state);
              meaVanaConsole("SPOTIFY_STATE >>> ", SPOTIFY_STATE);

              if (state === SPOTIFY_STATE) {
                meaVanaConsole("state match success");
                meaVanaConsole("code", code);
                meaVanaConsole("google sso success");

                dispatch(setMusiccodeAction(musicId, code));
                dispatch(getMusicDataFailureAction("spotify", null));
              } else {
                meaVanaConsole("state doesn't match");
              }
            } else {
              meaVanaConsole("spotify sso error in redirect url");
              let error = redirect_url.substring(
                redirect_url.indexOf("error=") + 6
              );
              error = error.substring(0, error.indexOf("&"));
              meaVanaConsole("error", error);

              let response = {
                result: "error",
                status: 401,
                message: error
              };
              dispatch(getMusicDataFailureAction(musicId, response.status));
            }
          }
        );
      } else {
        meaVanaConsole("chrome undefined");
      }
    } catch (error) {
      meaVanaConsole(
        "SpotifyChromeAuth catch err",
        checkErrorResponseFormat(error)
      );
      dispatch(
        getMusicDataFailureAction(musicId, checkErrorResponseFormat(error))
      );
    }
  };
};
