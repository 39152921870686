export const data = {
  searchCity: "",
  editingCity: false,

  coordsSearchCity: null,
  lastUpdate: null,

  todayWeather: {},
  errorTodayWeather: null,
  loadingTodayWeather: false,

  todayWeatherInfo: {},
  errorTodayWeatherInfo: null,
  loadingTodayWeatherInfo: false,

  hourForcastWeather: [],
  errorhourForcastWeather: null,
  loadinghourForcastWeather: false,

  weekForcastWeather: [],
  errorweekForcastWeather: null,
  loadingweekForcastWeather: false
};

export const params = {
  geoAccess: false,
  tempUnitCel: true,
  firstTimeUsingWeather: true,
  pinWeatherToHomeState: false
};

export const initialState = {
  ...data,
  ...params
};

export const weatherStateAction = {
  UPDATE_US_TEMP_UNIT: "weather/UPDATE_US_TEMP_UNIT",
  UPDATE_TEMP_UNIT_F: "weather/UPDATE_TEMP_UNIT_F",
  UPDATE_TEMP_UNIT_C: "weather/UPDATE_TEMP_UNIT_C",
  GET_WEEK_WEATHER: "weather/GET_WEEK_WEATHER",
  GET_TODAY_WEATHER: "weather/GET_TODAY_WEATHER",
  UPDATE_PIN_WEATHER_HOME: "weather/UPDATE_PIN_WEATHER_HOME",

  UPDATE_SEARCH_CITY: "weather/UPDATE_SEARCH_CITY",
  UPDATE_COORDS_SEARCH_CITY: "weather/UPDATE_COORDS_SEARCH_CITY",
  UPDATE_EDITING_CITY: "weather/UPDATE_EDITING_CITY",

  UPDATE_TODAY_WEATHER_REQUEST: "weather/UPDATE_TODAY_WEATHER_REQUEST",
  UPDATE_TODAY_WEATHER_SUCCESS: "weather/UPDATE_TODAY_WEATHER_SUCCESS",
  UPDATE_TODAY_WEATHER_FAILURE: "weather/UPDATE_TODAY_WEATHER_FAILURE",
  CLEAR_TODAY_WEATHER_ERROR: "weather/CLEAR_TODAY_WEATHER_ERROR",

  UPDATE_TODAY_WEATHER_INFO_REQUEST:
    "weather/UPDATE_TODAY_WEATHER_INFO_REQUEST",
  UPDATE_TODAY_WEATHER_INFO_SUCCESS:
    "weather/UPDATE_TODAY_WEATHER_INFO_SUCCESS",
  UPDATE_TODAY_WEATHER_INFO_FAILURE:
    "weather/UPDATE_TODAY_WEATHER_INFO_FAILURE",

  UPDATE_HOUR_FORCAST_WEATHER_REQUEST:
    "weather/UPDATE_HOUR_FORCAST_WEATHER_REQUEST",
  UPDATE_HOUR_FORCAST_WEATHER_SUCCESS:
    "weather/UPDATE_HOUR_FORCAST_WEATHER_SUCCESS",
  UPDATE_HOUR_FORCAST_WEATHER_FAILURE:
    "weather/UPDATE_HOUR_FORCAST_WEATHER_FAILURE",

  UPDATE_WEEK_FORCAST_WEATHER_REQUEST:
    "weather/UPDATE_WEEK_FORCAST_WEATHER_REQUEST",
  UPDATE_WEEK_FORCAST_WEATHER_SUCCESS:
    "weather/UPDATE_WEEK_FORCAST_WEATHER_SUCCESS",
  UPDATE_WEEK_FORCAST_WEATHER_FAILURE:
    "weather/UPDATE_WEEK_FORCAST_WEATHER_FAILURE"
};
