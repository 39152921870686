import { assetsIcons } from '../../../config/images';
import { SearchEngineList } from '../../../Utils/SearchEngineList';

export const data = {
  activeSearchEngine: SearchEngineList[0],
  searchEngines: SearchEngineList.slice(0, 2)
};

export const params = {
  searchEngineRectangle: false,
  searchEngineBorder: false,
  searchEngineLine: false,
  searchEnginSize: 35,
  searchEnginColored: false,
  searchEngineOpenNewTab: true,
  showMultipleSeachEngine: true
};

export const secondarySearchEngineitem = (activeSearchEngine) => {
  return {
    searchEngineRectangle: false,
    searchEngineBorder: false,
    searchEngineLine: false,
    searchEnginSize: 35,
    searchEnginColored: true,
    searchEngineOpenNewTab: true,
    activeSearchEngine: activeSearchEngine
  };
};

export const initialState = {
  ...data,
  ...params,
  resetState: null,
  secondarySearchEngine: [
    secondarySearchEngineitem(SearchEngineList[0]),
    secondarySearchEngineitem(SearchEngineList[1])
    //commented following to only have 2 search engines for now
    // secondarySearchEngineitem(SearchEngineList[2]),
    // secondarySearchEngineitem(SearchEngineList[3])
  ]
};

export const searchEngineStateAction = {
  RESET_SEARCH_ENGINE_STATE: 'searchEngine/RESET_SEARCH_ENGINE_STATE',
  ADD_SEARCH_ENGINE: 'searchEngine/ADD_SEARCH_ENGINE',
  DELETE_SEARCH_ENGINE: 'searchEngine/DELETE_SEARCH_ENGINE',
  UPDATE_SEARCH_ENGINE: 'searchEngine/UPDATE_SEARCH_ENGINE',
  UPDATE_SEARCH_ENGINE_BORDER: 'searchEngine/UPDATE_SEARCH_ENGINE_BORDER',
  UPDATE_SEARCH_ENGINE_OPEN_NEW_TAB: 'searchEngine/UPDATE_SEARCH_ENGINE_OPEN_NEW_TAB',
  UPDATE_SEARCH_ENGINE_ORDER: 'searchEngine/UPDATE_SEARCH_ENGINE_ORDER',
  UPDATE_SEARCH_ENGINE_REACTANGLE: 'searchEngine/UPDATE_SEARCH_ENGINE_REACTANGLE',
  UPDATE_SEARCH_SIZE: 'searchEngine/UPDATE_SEARCH_SIZE',

  UPDATE_SEARCH_COLORED: 'searchEngine/UPDATE_SEARCH_COLORED',
  UPDATE_SHOW_MULTIPLE_SEARCH: 'searchEngine/UPDATE_SHOW_MULTIPLE_SEARCH',

  UPDATE_SECONDARY_SEARCH_ENGINE_BORDER: 'searchEngine/UPDATE_SECONDARY_SEARCH_ENGINE_BORDER',
  UPDATE_SECONDARY_SEARCH_ENGINE_OPEN_NEW_TAB:
    'searchEngine/UPDATE_SECONDARY_SEARCH_ENGINE_OPEN_NEW_TAB',
  UPDATE_SECONDARY_SEARCH_COLORED: 'searchEngine/UPDATE_SECONDARY_SEARCH_COLORED',
  UPDATE_SECONDARY_SEARCH_ENGINE_REACTANGLE:
    'searchEngine/UPDATE_SECONDARY_SEARCH_ENGINE_REACTANGLE',
  UPDATE_SECONDARY_SEARCH_SIZE: 'searchEngine/UPDATE_SECONDARY_SEARCH_SIZE',
  UPDATE_SECONDARY_SEARCH_LINE: 'searchEngine/UPDATE_SECONDARY_SEARCH_LINE',
  UPDATE_ACTIVE_SECONDARY_SEARCH_ENGINE: 'searchEngine/UPDATE_ACTIVE_SECONDARY_SEARCH_ENGINE',
  ADD_SECONDARY_SEARCH_ENGINE: 'searchEngine/ADD_SECONDARY_SEARCH_ENGINE',
  DELETE_SECONDARY_SEARCH_ENGINE: 'searchEngine/DELETE_SECONDARY_SEARCH_ENGINE',
  DELETE_ALL_SECONDARY_SEARCH_ENGINE: 'searchEngine/DELETE_ALL_SECONDARY_SEARCH_ENGINE',
  UPDATE_ALL_SECONDARY_SEARCH_ENGINE: 'searchEngine/UPDATE_ALL_SECONDARY_SEARCH_ENGINE',
  UPDATE_SEARCH_ENGINE_LINE: 'searchEngine/UPDATE_SEARCH_ENGINE_LINE'
};
