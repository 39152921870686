export const sameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};
export const sameHour = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate() &&
    d1.getHours() === d2.getHours()
  );
};

export const getDifferenceInDays = (date1, date2) => {
  const diffInMs = date2 - date1;
  return diffInMs / (1000 * 60 * 60 * 24);
};

export const getDifferenceInHours = (date1, date2) => {
  const diffInMs = date2 - date1;
  return diffInMs / (1000 * 60 * 60);
};

export const getDifferenceInMinutes = (date1, date2) => {
  const diffInMs = date2 - date1;
  return diffInMs / (1000 * 60);
};

export const getDifferenceInSeconds = (date1, date2) => {
  const diffInMs = date2 - date1;
  return diffInMs / 1000;
};
