import React from "react";
import IntegrationPin from "../../../features/IntegrationsFeature/IntegrationPin";

export default function RightArea() {
  return (
    <>
      <IntegrationPin />
    </>
  );
}
