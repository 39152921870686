import { Close, PushPin, PushPinOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCryptoPinAction,
  updateCryptoWindowStateAction
} from "../../../store/reducers/cryptoReducer/action";
import { GetImageUrl } from "../../../Utils/GetImageUrl";

export default function CryptoPin({ whiteIcon }) {
  const cryptoState = useSelector((state) => state.crypto);
  const dispatch = useDispatch();

  return (
    <>
      {cryptoState.cryptoApiSource.map((crypto, index) => {
        if (crypto.pined) {
          return (
            <div
              key={"crypto-pin-grid-item-" + crypto.id}
              className={
                crypto.active
                  ? "news-pin-grid-item-active news-pin-grid-item"
                  : "news-pin-grid-item"
              }
            >
              <Close
                className="news-pin-button-pin"
                onClick={() => {
                  dispatch(updateCryptoPinAction(crypto.id, false));
                }}
              />

              <div
                className="news-pin-grid-item-container"
                onClick={() => {
                  if (crypto.active) {
                    dispatch(updateCryptoWindowStateAction());
                  } else {
                    dispatch(updateCryptoWindowStateAction(crypto.id));
                  }
                }}
              >
                <img
                  src={
                    whiteIcon
                      ? GetImageUrl(crypto.icons.white)
                      : GetImageUrl(crypto.icons.black)
                  }
                  style={!whiteIcon ? { opacity: 0.6 } : {}}
                  alt={crypto.id + " icon"}
                />
                <span>{crypto.name}</span>
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
