import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CoincapContainer from "./CoincapContainer";
import WidgetWindow from "../../../../components/WidgetWindow/WidgetWindow";
import { updateCryptoWindowStateAction } from "../../../../store/reducers/cryptoReducer/action";
import { GetImageUrl } from "../../../../Utils/GetImageUrl";

export default function CoincapWindow() {
  const cryptoState = useSelector((state) => state.crypto);
  const coincapCryptoState = cryptoState.cryptoApiSource.find(
    (item) => item.id == "coincap"
  );

  const dispatch = useDispatch();

  const onClickWindowCloseButton = () => {
    dispatch(updateCryptoWindowStateAction());
  };
  return (
    <WidgetWindow
      title={coincapCryptoState.name}
      isActive={coincapCryptoState.active}
      onClickWindowCloseButton={onClickWindowCloseButton}
      //titleImg={titleImg}
    >
      <CoincapContainer />
    </WidgetWindow>
  );
}
