import React, { useEffect, useState } from "react";
import "./Loading.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import { GetImageUrl } from "../../Utils/GetImageUrl";
import { updateAppStateLoadingAction } from "../../store/reducers/appReducer/action";
import Sleep from "../../Utils/Sleep";

export default function Loading(props) {
  const appState = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(async () => {
    if (appState.showLoading) {
      if (appState.guestMode) {
        await Sleep(1000);
        dispatch(updateAppStateLoadingAction(false));
        meaVanaConsole("hide loading active guest mode after 1s");
      } else {
        await Sleep(3000);
        dispatch(updateAppStateLoadingAction(false));
        meaVanaConsole("hide loading  after 3s");
      }
    }
  }, [appState.guestMode, appState.showLoading]);

  return (
    <div
      className={
        appState.showLoading
          ? "container-loading fadeIn"
          : "container-loading fadeOut"
      }
    >
      <div className="container-loading-company-info">
        <img
          className="container-loading-company-logo"
          src={GetImageUrl("/assets/logo/meavana-logo-purple-border.png")}
        />
        <span className="container-loading-company-title">Welcome</span>
        <span className="container-loading-txt-welcome">
          Loading Up your MeaVana
        </span>
      </div>
      <Box sx={{ width: "25%", marginTop: "1rem" }}>
        <LinearProgress color="inherit" />
      </Box>
    </div>
  );
}
