import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CryptoPin from "./Crypto/CryptoPin";
import MusicPin from "./Music/MusicPin";
import NewsPin from "./News/NewsPin";
import PicturesPin from "./Pictures/PicturesPin";
import VideosPin from "./Videos/VideosPin";

export default function IntegrationPin() {
  const appState = useSelector((state) => state.app);
  const [whiteIconColor, setWhiteIconColor] = useState(true);

  useEffect(() => {
    switch (appState.backgroundMode) {
      case "background-home-picture":
        setWhiteIconColor(true);
        break;
      case "background-home-dark":
        setWhiteIconColor(true);
        break;
      case "background-home-light":
        setWhiteIconColor(false);
        break;
      default:
        setWhiteIconColor(true);
        break;
    }
  }, [appState.backgroundMode]);

  return (
    <>
      <CryptoPin whiteIcon={whiteIconColor} />
      {/**   <MusicPin whiteIcon={whiteIconColor} /> */}
      <NewsPin whiteIcon={whiteIconColor} />
      <PicturesPin whiteIcon={whiteIconColor} />
      {/**  <VideosPin whiteIcon={whiteIconColor}/>*/}
    </>
  );
}
