import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import validator from "validator";
import { IconButton, CircularProgress } from "@mui/material";

import {
  DeleteAccountUserServer,
  DisableAccountUserServer
} from "../../../server/Auth/user.server";

export default function DeleteAccountComponent(props) {
  const stateAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [showDeleteAccountPassword, setshowDeleteAccountPassword] =
    useState(false);

  const [inputDeleteAccountPassword, setinputDeleteAccountPassword] =
    useState("");
  const [
    inputDeleteAccountPasswordTouched,
    setinputDeleteAccountPasswordTouched
  ] = useState(false);
  const [DeleteAccountPasswordErrorMsg, setDeleteAccountPasswordErrorMsg] =
    useState({
      message: "",
      error: false
    });

  useEffect(() => {
    setinputDeleteAccountPassword("");
    setinputDeleteAccountPasswordTouched(false);
    setshowDeleteAccountPassword(false);
    setDeleteAccountPasswordErrorMsg({
      message: "",
      error: false
    });
  }, []);

  const deleteAccountActionButton = (event) => {
    event.preventDefault();
    verifDeleteAccountPasswordFormat(inputDeleteAccountPassword);
    if (
      (inputDeleteAccountPasswordTouched &&
        !DeleteAccountPasswordErrorMsg.error) ||
      stateAuth.user.isSocial
    ) {
      dispatch(DeleteAccountUserServer(inputDeleteAccountPassword));
    }
  };
  const DisableAccountActionButton = (event) => {
    event.preventDefault();
    verifDeleteAccountPasswordFormat(inputDeleteAccountPassword);
    if (
      (inputDeleteAccountPasswordTouched &&
        !DeleteAccountPasswordErrorMsg.error) ||
      stateAuth.user.isSocial
    ) {
      dispatch(DisableAccountUserServer(inputDeleteAccountPassword));
    }
  };

  const verifDeleteAccountPasswordFormat = (password = "") => {
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setDeleteAccountPasswordErrorMsg({
        error: true,
        message: "Please enter a password"
      });
    } else {
      if (password.length < 5) {
        setDeleteAccountPasswordErrorMsg({
          error: true,
          message: "Please enter a password longer than 5 characters"
        });
      } else if (password.length > 32) {
        setDeleteAccountPasswordErrorMsg({
          error: true,
          message: "Please enter a password shorter than 32 characters"
        });
      } else {
        setDeleteAccountPasswordErrorMsg({ error: false, message: "" });
      }
    }
  };

  return (
    <div className="list-settings-profile">
      <div className="setting-profile-list-groupe-header">
        <p className="setting-list-groupe-header-title">Delete Account</p>
      </div>
      <form className="container-profile-form">
        <label style={{ fontSize: "1.1em" }}>
          Are you sure that you want to delete your account?
        </label>
        <div className="profile-form-group" style={{ paddingLeft: "1.3rem" }}>
          <label style={{ display: "list-item" }}>
            <strong
              style={{
                textDecorationLine: "underline",
                marginBottom: "0.5rem"
              }}
            >
              Delete Now
            </strong>{" "}
            Remove Your Account Immediately. You can create an account with the
            same credentials after you delete
          </label>
          <label style={{ display: "list-item", marginTop: "0.5rem" }}>
            <strong style={{ textDecorationLine: "underline" }}>
              Delete After 30 Days
            </strong>{" "}
            Will log you out of MeaVana, and if you do not login within 30 days,
            your account will then be deleted
          </label>
        </div>
        {stateAuth.user.isSocial ? null : (
          <div className="profile-form-group">
            <label>Enter Password</label>
            <div className="container-input-icon-auth">
              <input
                type={showDeleteAccountPassword ? "text" : "password"}
                className={
                  DeleteAccountPasswordErrorMsg.error
                    ? "form-control input-error"
                    : "form-control"
                }
                id="password"
                placeholder="Enter password"
                value={inputDeleteAccountPassword}
                onFocus={(e) => {
                  setDeleteAccountPasswordErrorMsg({
                    error: false,
                    message: ""
                  });
                }}
                onBlur={(e) => {
                  verifDeleteAccountPasswordFormat(inputDeleteAccountPassword);
                }}
                onChange={(event) => {
                  setinputDeleteAccountPassword(event.target.value);
                  setinputDeleteAccountPasswordTouched(true);
                  verifDeleteAccountPasswordFormat(event.target.value);
                }}
              />
              {!showDeleteAccountPassword ? (
                <IconButton
                  className="button-icon-auth"
                  onClick={() => setshowDeleteAccountPassword(true)}
                >
                  <VisibilityOff size="small" />
                </IconButton>
              ) : (
                <IconButton
                  className="button-icon-auth"
                  onClick={() => setshowDeleteAccountPassword(false)}
                >
                  <Visibility size="small" />
                </IconButton>
              )}
            </div>

            <p className="error-msg-txt">
              {DeleteAccountPasswordErrorMsg.message}
            </p>
          </div>
        )}

        <p className="error-msg-txt">
          {stateAuth.errorDeleteAccount && stateAuth.errorDeleteAccount.message
            ? stateAuth.errorDeleteAccount.message
            : ""}
        </p>

        <div className="profile-form-submit-area">
          <Button
            className="contact-form-btn-send button-setting-popup"
            variant="contained"
            color="info"
            onClick={(event) => {
              event.preventDefault();
              props.goBackToProfileInfo();
            }}
          >
            Cancel
          </Button>
          <div className="profile-form-submit-area-action-btn">
            <Button
              className="contact-form-btn-send button-setting-popup"
              variant="contained"
              color="info"
              onClick={DisableAccountActionButton}
              disabled={stateAuth.loadingDisableAccount}
            >
              {stateAuth.loadingDisableAccount ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#fff",
                    alignSelf: "center"
                  }}
                />
              ) : (
                "Delete After 30 Days"
              )}
            </Button>

            <Button
              className="contact-form-btn-send button-setting-popup"
              variant="contained"
              color="info"
              style={{ marginLeft: "1rem" }}
              onClick={deleteAccountActionButton}
              disabled={stateAuth.loadingDeleteAccount}
            >
              {stateAuth.loadingDeleteAccount ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#fff",
                    alignSelf: "center"
                  }}
                />
              ) : (
                "Delete Now"
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
