import { appStateAction } from '../appReducer/constants';
import { initialState, alarmStateAction } from './constants';

import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import { stateMigrations } from './stateMigration';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const alarmPersistConfig = {
  key: 'alarms',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING alarm :', error)
};
export const alarmPersisted = persistReducer(alarmPersistConfig, alarmReducer);

export default function alarmReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case alarmStateAction.ADD_ALARM:
      if (action.payload == null) {
        return state;
      } else {
        return { ...state, alarms: [...state.alarms, action.payload] };
      }

    case alarmStateAction.REMOVE_ALARM:
      if (action.payload.time == null) {
        return state;
      } else {
        return {
          ...state,
          alarms: state.alarms.filter(
            (alarm) =>
              new String(alarm.time).valueOf() !== new String(action.payload.time).valueOf()
          )
        };
      }

    case alarmStateAction.SET_ALARM_AUDIO:
      if (action.payload == null) {
        return state;
      } else {
        meaVanaConsole('isAudioPlaying >>> ', action.payload);
        return { ...state, isAudioPlaying: action.payload };
      }

    case alarmStateAction.SET_ALARM_HAS_MESSAGE:
      if (action.payload == null) {
        return state;
      } else {
        meaVanaConsole('hasMessage >>> ', action.payload);
        return { ...state, hasMessage: action.payload };
      }

    case alarmStateAction.SET_ALARM_MESSAGE:
      if (action.payload == null) {
        return state;
      } else {
        meaVanaConsole('message >>> ', action.payload);
        return { ...state, message: action.payload };
      }

    case alarmStateAction.CLEAR_ALARM:
      return {
        alarms: [],
        error: ''
      };

    case alarmStateAction.SET_ALARM_ERROR:
      if (action.payload == null) {
        return state;
      } else {
        return {
          ...state,
          error: action.payload
        };
      }

    default:
      return state;
  }
}
