/* global chrome */
import {
  SPOTIFY_API_URL,
  SPOTIFY_CLIENT_ID,
  SPOTIFY_CLIENT_SECRET
} from "../../config/constants";
import {
  getMusicDataFailureAction,
  getMusicDataFailureMessageAction,
  setMusicAccessTokenAction,
  setMusicExpiresInAction,
  setMusicRefreshTokenAction
} from "../../store/reducers/musicReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

export const getSpotifyAccessToken = (code) => {
  return async (dispatch) => {
    try {
      meaVanaConsole("Access token Request made");
      fetch("https://accounts.spotify.com/api/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic " +
            Buffer.from(
              SPOTIFY_CLIENT_ID + ":" + SPOTIFY_CLIENT_SECRET
            ).toString("base64")
        },
        body: `grant_type=authorization_code&code=${code}&redirect_uri=${chrome.identity.getRedirectURL()}`
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error("could not get token");
          }
        })
        .then((res) => {
          dispatch(setMusicAccessTokenAction("spotify", res.access_token));
          dispatch(setMusicRefreshTokenAction("spotify", res.refresh_token));
          dispatch(setMusicExpiresInAction("spotify", res.expires_in));
        })
        .catch((err) => {
          meaVanaConsole("getMusicDataFailureAction error: ", err);
          dispatch(
            getMusicDataFailureMessageAction(
              "spotify",
              err?.body?.error?.reason || ""
            )
          );
          dispatch(getMusicDataFailureAction("spotify", err.statusCode));
        });

      dispatch(getMusicDataFailureAction("spotify", null));
      dispatch(getMusicDataFailureMessageAction("spotify", ""));
    } catch (error) {
      meaVanaConsole("getMusicDataFailureAction error: ", error);
      dispatch(
        getMusicDataFailureMessageAction(
          "spotify",
          error?.body?.error?.reason || ""
        )
      );
      dispatch(getMusicDataFailureAction("spotify", error.statusCode));
    }
  };
};

export const getSpotifyRefreshToken = (refreshToken) => {
  return async (dispatch) => {
    try {
      meaVanaConsole("Refresh token Request made");
      fetch("https://accounts.spotify.com/api/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic " +
            Buffer.from(
              SPOTIFY_CLIENT_ID + ":" + SPOTIFY_CLIENT_SECRET
            ).toString("base64")
        },
        body: `client_id=${SPOTIFY_CLIENT_ID}&grant_type=refresh_token&refresh_token=${refreshToken}`
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error("could not get token");
          }
        })
        .then((res) => {
          meaVanaConsole("Got new access token");
          dispatch(setMusicAccessTokenAction("spotify", res.access_token));
          dispatch(setMusicExpiresInAction("spotify", res.expires_in));
        })
        .catch((err) => {
          meaVanaConsole("getMusicDataFailureAction error: ", err);
          dispatch(
            getMusicDataFailureMessageAction(
              "spotify",
              err?.body?.error?.reason || ""
            )
          );
          dispatch(getMusicDataFailureAction("spotify", err.statusCode));
        });
      dispatch(getMusicDataFailureAction("spotify", null));
      dispatch(getMusicDataFailureMessageAction("spotify", ""));
    } catch (error) {
      meaVanaConsole("getMusicDataFailureAction error: ", error);
      dispatch(
        getMusicDataFailureMessageAction(
          "spotify",
          error?.body?.error?.reason || ""
        )
      );
      dispatch(getMusicDataFailureAction("spotify", error.statusCode));
    }
  };
};
