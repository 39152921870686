import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ShareSendEmail from '../../components/ShareSendEmail/ShareSendEmail';
import BackgroundInfoFeature from '../../features/BackgroundInfoFeature/BackgroundInfoFeature';
import DateAndTime from '../../features/ClockFeature/DateAndTime';
import TimeFeaturesComponents from '../../features/ClockFeature/TimeFeaturesComponents/TimeFeaturesComponents';
import HideAllFeatures from '../../features/HideAllFeatures/HideAllFeatures';
import QuoteFeature from '../../features/QuoteFeature/QuoteFeature';
import SearchEngineFeature from '../../features/SearchEngineFeature/SearchEngineFeature';
import SettingsFeature from '../../features/SettingsFeature/SettingsFeature';
import WeatherFeature from '../../features/WeatherFeature/WeatherFeature';
import { meaVanaConsole } from '../../Utils/MeaVanaConsole';
import useWindowDimensions from '../../Utils/useWindowDimensions';
import LeftArea from '../Home/components/LeftArea';
import RightArea from '../Home/components/RightArea';
import GuestTopArea from './GuestTopArea';
import WindowContainer from '../Windows/WindowContainer';

import NoteGuestMode from './NoteGuestMode/NoteGuestMode';

import './Guest.css';

export default function Guest() {
  const bookmarksState = useSelector((state) => state.bookmarks);

  const [showTimeFeature, setshowTimeFeature] = useState(false);
  const [showTimer, setshowTimer] = useState(false);
  const [showAlarm, setshowAlarm] = useState(false);
  const [showAlarmFeature, setshowAlarmFeature] = useState(false);
  const [showIconBookmakSetting, setshowIconBookmakSetting] = useState(false);
  const [showStopWatch, setshowStopWatch] = useState(false);

  const homeState = useSelector((state) => state.home);

  const clockState = useSelector((state) => state.clock);
  const { height, width } = useWindowDimensions();

  return (
    <div className="home-grid-container">
      <div className="top-area">
        <GuestTopArea
          hideAllFeatures={homeState.hideAllFeatures}
          showWeather={homeState.showWeather}
          showIntegration={homeState.showIntegration}
        />
      </div>
      <div className="left-area"></div>
      <div className="center-area">
        <div className="Date-time-area">
          <DateAndTime
            display={homeState.hideAllFeatures}
            opacityTime={homeState.showTime}
            showTimeFeature={showTimeFeature}
            setshowTimeFeature={setshowTimeFeature}
            showTimer={showTimer}
            setshowTimer={setshowTimer}
            showAlarm={showAlarm}
            setshowAlarm={setshowAlarm}
            setshowAlarmFeature={setshowAlarmFeature}
            showAlarmFeature={showAlarmFeature}
            showStopWatch={showStopWatch}
            setshowStopWatch={setshowStopWatch}
            opacityDate={homeState.showDate}
          />
          <TimeFeaturesComponents
            showTimeFeature={showTimeFeature}
            setshowTimeFeature={setshowTimeFeature}
            showTimer={showTimer}
            setshowTimer={setshowTimer}
            showAlarm={showAlarm}
            setshowAlarm={setshowAlarm}
            setshowAlarmFeature={setshowAlarmFeature}
            showAlarmFeature={showAlarmFeature}
            showStopWatch={showStopWatch}
            setshowStopWatch={setshowStopWatch}
            display={homeState.hideAllFeatures}
          />
        </div>
        <div className="search-engine-area">
          <SearchEngineFeature display={homeState.hideAllFeatures} opacity={homeState.showSearch} />
        </div>
        <div
          className="bookmarks-area"
          onMouseOver={() => setshowIconBookmakSetting(true)}
          onMouseLeave={() => setshowIconBookmakSetting(false)}
          style={
            homeState.hideAllFeatures
              ? { display: 'none' }
              : bookmarksState.positionBOokmarksTop
              ? homeState.showBookmark === 0
                ? { justifyContent: 'flex-end' }
                : { justifyContent: 'space-between' }
              : { justifyContent: 'flex-end' }
          }></div>
      </div>
      <div className="right-area"></div>
      <div className="bottom-area">
        <div className="bottom-right-area">
          <ShareSendEmail display={homeState.hideAllFeatures} opacity={homeState.showNote} />
          <NoteGuestMode display={homeState.hideAllFeatures} opacity={homeState.showNote} />
        </div>
        <div className="bottom-left-area">
          <SettingsFeature display={homeState.hideAllFeatures} />
          <BackgroundInfoFeature display={homeState.hideAllFeatures} />
          <HideAllFeatures />
        </div>
        <div className="Quote-area">
          <QuoteFeature opacity={homeState.showQuote} display={homeState.hideAllFeatures} />
        </div>
      </div>
    </div>
  );
}
