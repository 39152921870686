import { appStateAction } from '../appReducer/constants';
import { initialState, integrationStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const integrationPersistConfig = {
  key: 'integration',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING integration :', error)
};
export const integrationPersisted = persistReducer(integrationPersistConfig, integrationReducer);

export default function integrationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;
    case integrationStateAction.RESET_INTEGRATION_STATE:
      return initialState;

    default:
      return state;
  }
}
