import React from 'react';
import AppContainer from './containers/AppContainer';
import './App.css';

import { initDB } from 'react-indexed-db';
import { IndexDBConfig } from './config/IndexDBConfig';

initDB(IndexDBConfig);

export default function App() {
  return (
    <div className="App">
      <AppContainer />
    </div>
  );
}
