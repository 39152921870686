import { meaVanaConsole } from '../../Utils/MeaVanaConsole';
import UpdateAppearances from './Functions/UpdateAppearances';
import UpdateBookmarksSettigs from './Functions/UpdateBookmarksSettigs';
import UpdateClock from './Functions/UpdateClock';
import UpdateFeatures from './Functions/UpdateFeatures';
import UpdateIntegration from './Functions/UpdateIntegration';
import UpdateSearchEngine from './Functions/UpdateSearchEngine';

export default function UpdatingTopicStyling(style) {
  return async (dispatch) => {
    try {
      meaVanaConsole(' start UpdatingTopicStyling :', style);

      dispatch(UpdateAppearances(style));
      dispatch(UpdateBookmarksSettigs(style.bookmarks, style.defaultBookmarkList));
      dispatch(UpdateClock(style.clock));
      dispatch(UpdateFeatures(style.features));
      dispatch(UpdateIntegration(style.integrations));
      dispatch(UpdateSearchEngine(style.searchEngines));
      meaVanaConsole(' end UpdatingTopicStyling :');
    } catch (error) {
      meaVanaConsole(' UpdatingTopicStyling error :', error);
    }
  };
}
