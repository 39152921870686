const data = {
  pictures: [],

  selectedPicture: {}
};

export const params = {
  showSearchedPictures: false,
  useUnspashForBackground: false,
  loadingUnspashBackground: false,

  loadingPicture: false,
  errorPicture: null
};

export const initialState = {
  ...data,
  ...params
};

export const unsplashStateAction = {
  SHOW_UNSPLASH_WINDOW: "unsplash/SHOW_UNSPLASH_WINDOW",
  GET_SEARCH_PICTURE_REQUEST: "unsplash/GET_SEARCH_PICTURE_REQUEST",
  GET_SEARCH_PICTURE_SUCESS: "unsplash/GET_SEARCH_PICTURE_SUCESS",
  GET_SEARCH_PICUTURE_FAILURE: "unsplash/GET_SEARCH_PICTURE_FAILURE",
  SET_SELECTED_PICTURE: "unsplash/SET_SELECTED_PICTURE",
  SHOW_SEARCHED_PICTURES: "unsplash/SHOW_SEARCHED_PICTURES",
  GET_RANDOM_PICTURE_REQUEST: "unsplash/GET_RANDOM_PICTURE_REQUEST",
  GET_RANDOM_PICTURE_SUCCESS: "unsplash/GET_RANDOM_PICTURE_SUCCESS",
  GET_RANDOM_PICTURE_FAILURE: "unsplash/GET_RANDOM_PICTURE_FAILURE",
  UPDATE_UNSPLASH_SELECTED_PICTURE: "unsplash/UPDATE_UNSPLASH_SELECTED_PICTURE",
  UPDATE_UNSPLASH_BACKGROUND_STATE: "unsplash/UPDATE_UNSPLASH_BACKGROUND_STATE",
  UPDATE_LOADING_UNSPLASH_BACKGROUND_STATE:
    "unsplash/UPDATE_LOADING_UNSPLASH_BACKGROUND_STATE"
};
