/*global chrome*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./TimeFeatures.css";
import TimerComponent from "./TimerComponent";
import StopwatchComponent from "./StopwatchComponent";
import AlarmComponent from "./AlarmComponent";
import AddAlarmForm from "./AddAlarmForm";
import { setAlarmAudioPlayingAction } from "../../../store/reducers/alarmReducer/action";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import getCurrentTab from "../../../Utils/getCurrentTab";
import AudioPlayerTimeFeature from "./AudioPlayerTimeFeature";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function TimeFeaturesComponents(props) {
  const wrapperRef = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const alarmState = useSelector((state) => state.alarms);
  const dispatch = useDispatch();
/*
  const alarmListener = useCallback(async (request, sender, sendResponse) => {
    const currentTab = await getCurrentTab();
    meaVanaConsole("request.tabId >>> ", request.tabId);
    meaVanaConsole("current tabId >>> ", currentTab.id);
    if (
      request.message === "startAlarmBell" &&
      request.tabId === currentTab.id
    ) {
      props.setshowStopWatch(false);
      props.setshowTimer(false);
      props.setshowAlarm(false);
      setShowForm(false);
      dispatch(setAlarmAudioPlayingAction(true));
      sendResponse("Meavana Alarm rung");
    } else if (
      request.message === "stopAlarmBell" &&
      request.tabId === currentTab.id
    ) {
      dispatch(setAlarmAudioPlayingAction(false));
      sendResponse("Meavana Alarm stopped");
    }
    return;
  }, []);

  useEffect(() => {
      if (typeof chrome != "undefined" && chrome.runtime) {
    chrome.runtime.onMessage.addListener(alarmListener);
    return () => {
      chrome.runtime.onMessage.removeListener(alarmListener);
    };
    } else {
      meaVanaConsole("chrome undefined");
    }
  }, [alarmListener]);

  const StopAlarm = () => {
    return (
      <div
        className="Alarm-container"
        style={!alarmState.isAudioPlaying ? { display: "none" } : {}}
      >
        <h2
          onClick={() => {
            dispatch(setAlarmAudioPlayingAction(false));
          }}
          className="stop-alarm"
        >
          Stop Alarm
          <span className="">
            <Close fontSize="medium" />
          </span>
        </h2>
      </div>
    );
  };

  const handleSetPlaying = (isPlaying) => {
    dispatch(setAlarmAudioPlayingAction(isPlaying));
  };
*/
  return (
    <div
      className="container-TimeFeaturesComponents"
      style={!props.showTimeFeature && props.display ? { display: "none" } : {}}
      ref={wrapperRef}
    >
      <StopwatchComponent
        showStopWatch={props.showStopWatch}
        setshowStopWatch={props.setshowStopWatch}
        showTimeFeature={props.showTimeFeature}
        setshowTimeFeature={props.setshowTimeFeature}
      />
      <TimerComponent
        showTimer={props.showTimer}
        setshowTimer={props.setshowTimer}
        showTimeFeature={props.showTimeFeature}
        setshowTimeFeature={props.setshowTimeFeature}
      />
      {/*
      <AlarmComponent
        showAlarm={props.showAlarm}
        setshowAlarm={props.setshowAlarm}
        showTimeFeature={props.showTimeFeature}
        setshowTimeFeature={props.setshowTimeFeature}
        setShowForm={setShowForm}
      />
      <AddAlarmForm
        showForm={showForm}
        setShowForm={setShowForm}
        setshowAlarm={props.setshowAlarm}
      />
      <StopAlarm />

      <AudioPlayerTimeFeature
        url={GetImageUrl("/assets/audio/meavana-alarm.mp3")}
        playing={alarmState.isAudioPlaying}
        setPlaying={handleSetPlaying}
  />*/}
    </div>
  );
}
