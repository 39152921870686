import { appStateAction } from '../appReducer/constants';
import { initialState, cryptoStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { integrationStateAction } from '../integrationsReducer/constants';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const cryptoPersistConfig = {
  key: 'crypto',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING crypto :', error)
};
export const cryptoPersisted = persistReducer(cryptoPersistConfig, cryptoReducer);

export default function cryptoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;
    case integrationStateAction.RESET_INTEGRATION_PIN_STATE:
      return {
        ...state,
        cryptoApiSource: state.cryptoApiSource.map((crypto) => {
          return { ...crypto, pined: false };
        })
      };

    case cryptoStateAction.UPDATE_CRYPTO_WINDOW_STATE:
      if (action.payload == null) {
        return {
          ...state,
          activeCryptoId: null,
          cryptoApiSource: state.cryptoApiSource.map((crypto) => {
            return { ...crypto, active: false };
          })
        };
      } else {
        return {
          ...state,
          activeCryptoId: action.payload,
          cryptoApiSource: state.cryptoApiSource.map((crypto) => {
            if (action.payload === crypto.id) {
              return { ...crypto, active: true };
            }
            return { ...crypto, active: false };
          })
        };
      }
    case cryptoStateAction.UPDATE_CRYPTO_PIN:
      if (action.payload.cryptoId == null) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          cryptoApiSource: state.cryptoApiSource.map((crypto) => {
            if (action.payload.cryptoId === crypto.id) {
              return { ...crypto, pined: action.payload.pin };
            }
            return { ...crypto };
          })
        };
      }

    default:
      return state;
  }
}
