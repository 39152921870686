import React from "react";
import Google from "./Google";
import Facebook from "./Facebook";

export default function LoginSSO() {
  return (
    <div>
      <Google />
      <Facebook />
    </div>
  );
}
