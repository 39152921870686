import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { init, send } from "emailjs-com";
import {
  Emailjs_Service_ID,
  Emailjs_Template,
  Emailjs_User_ID
} from "../../config/constants";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

init(Emailjs_User_ID);

export default function ContactSettings(props) {
  const [inputTitle, setinputTitle] = useState("");
  const [inputMessage, setinputMessage] = useState("");
  const [inputType, setinputType] = useState(
    props.contactTopic ? props.contactTopic : "Feedback"
  );

  const [loadingBtn, setloadingBtn] = useState(false);
  const [submitMessage, setsubmitMessage] = useState("");

  const stateAuth = useSelector((state) => state.auth);

  const sendEmail = (e) => {
    if (inputMessage != "" && inputTitle != "" && inputType != "") {
      e.preventDefault();
      setloadingBtn(true);
      send(Emailjs_Service_ID, Emailjs_Template, {
        title: "Type: " + inputType + ", Subject: " + inputTitle,
        message: inputMessage,
        user_name: stateAuth.user.username,
        user_email: stateAuth.user.email
      })
        .then((response) => {
          setloadingBtn(false);
          setinputTitle("");
          setinputMessage("");
          setinputType(inputType);
          setsubmitMessage("Your message has been sent");
          meaVanaConsole("send email SUCCESS! :", response);
        })
        .catch((err) => {
          setloadingBtn(false);
          setsubmitMessage("Sorry, you message have not been sent");
          meaVanaConsole("FAILED...", err);
        });
    } else {
      setsubmitMessage("You need to fill the contact form to send a message");
    }
  };
  const refContactView = useRef();
  const linkToTopic = (topic) => {
    setinputType(topic);
    refContactView.current.scrollTop = 0;
  };

  return (
    <div className="settings-view" ref={refContactView}>
      <div className="header-settings">
        <span className="header-settings-title">Contact</span>
        <span className="header-settings-subtitle">Connect with us</span>
      </div>
      <form className="container-contact-form">
        <div className="contact-form-group form-group">
          <label>Select Topic</label>
          <select
            type="select"
            id="Topic"
            value={inputType}
            onFocus={() => {
              setsubmitMessage("");
            }}
            onChange={(event) => {
              setinputType(event.target.value);
              setsubmitMessage("");
            }}
            className="contact-form-control select-arrow-contact form-control"
          >
            <option disabled>-- Select Topic --</option>
            <option value="Feedback">Feedback</option>
            <option value="Suggestions">Suggestions</option>
            <option value="Report a Bug">Report a Bug</option>
            <option value="Question">Question</option>
            <option value="Suggest a Topic">Suggest a Topic</option>
            {/*  <option value="Run Your Own Topic">Run Your Own Topic</option>
           <option value="Submit Photo">Submit Photo</option>*/}
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="contact-form-group form-group">
          <label>Subject</label>
          <input
            type="text"
            value={inputTitle}
            onFocus={() => {
              setsubmitMessage("");
            }}
            onChange={(event) => {
              setinputTitle(event.target.value);
              setsubmitMessage("");
            }}
            id="title"
            className="contact-form-control form-control"
          />
        </div>

        <div className="contact-form-group">
          <label>Message</label>
          <textarea
            className="contact-form-control form-control contact-form-control-message"
            type="textarea"
            value={inputMessage}
            onFocus={() => {
              setsubmitMessage("");
            }}
            onChange={(event) => {
              setinputMessage(event.target.value);
              setsubmitMessage("");
            }}
            aria-multiline={true}
          />
        </div>
        <div className="contact-form-submit-area">
          <span>{submitMessage}</span>
          <Button
            className="contact-form-btn-send button-setting-popup"
            variant="contained"
            disabled={loadingBtn}
            onClick={sendEmail}
            color="info"
          >
            {loadingBtn ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#fff",
                  alignSelf: "center"
                }}
              />
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}
