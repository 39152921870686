import { appStateAction } from '../appReducer/constants';
import { analyticsStateAction, initialState } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const analyticsPersistConfig = {
  key: 'analytics',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING analytics :', error)
};
export const analyticsPersisted = persistReducer(analyticsPersistConfig, analyticsReducer);

function analyticsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case analyticsStateAction.ADD_DAILY_USAGE_HISTORY:
      return {
        ...state,
        dailyUsageHistory: [...state.dailyUsageHistory, action.payload]
      };
    default:
      return state;
  }
}
