import React, { useState } from "react";
import {
  Photo,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ThumbUpOffAlt,
  ThumbUpAlt,
  ThumbDownAlt,
  ThumbDownOffAlt,
  Info
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "./BackgroundInfo.css";
import { useSelector } from "react-redux";
import { GetImageUrl } from "../../Utils/GetImageUrl";

export default function BackgroundInfoFeature(props) {
  const appState = useSelector((state) => state.app);
  const backgroundState = useSelector((state) => state.background);

  const [liked, setliked] = useState(false);
  const [disLiked, setdisLiked] = useState(false);

  const likeBackground = () => {
    if (liked) {
      setliked(false);
    } else {
      if (disLiked) {
        setdisLiked(false);
      }
      setliked(true);
    }
  };
  const disLikeBackground = () => {
    if (disLiked) {
      setdisLiked(false);
    } else {
      if (liked) {
        setliked(false);
      }
      setdisLiked(true);
    }
  };

  if (props.display) {
    return (
      <div
        className="container-btn-BackgroundInfo"
        style={props.display ? { opacity: 0, cursor: "inherit" } : {}}
      >
        <Info className="home-icon-font-size" />
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-BackgroundInfo"
        placement="top"
        rootClose={true}
        overlay={
          <Popover
            id={`popover-BackgroundInfo`}
            className="popover-BackgroundInfo"
          >
            <Popover.Body className="popover-BackgroundInfo-body">
              {backgroundState.background || appState.guestMode ? (
                <div className="container-active-backgound-info">
                  {appState.guestMode ? (
                    <span className="active-backgound-title">
                      Gnarly Tree in a Deep, Dense Forest
                    </span>
                  ) : backgroundState.background &&
                    backgroundState.background.title ? (
                    <span className="active-backgound-title">
                      {backgroundState.background
                        ? backgroundState.background.title
                        : null}
                    </span>
                  ) : null}
                  {appState.guestMode ? (
                    <span className="active-backgound-subtitle">
                      A Branched Tree standing out in a Forest
                    </span>
                  ) : backgroundState.background &&
                    backgroundState.background.description ? (
                    <span className="active-backgound-subtitle">
                      {backgroundState.background
                        ? backgroundState.background.description
                        : null}
                    </span>
                  ) : null}

                  <div className="container-image-background">
                    {appState.guestMode ? (
                      <img
                        src={GetImageUrl(
                          "/assets/backgrounds/authBackground.jpg"
                        )}
                        className="backgound-info-image"
                      ></img>
                    ) : (
                      <img
                        src={backgroundState.background.url}
                        className="backgound-info-image"
                      ></img>
                    )}
                    <div className="container-btn-like">
                      <IconButton
                        className="icon-like-background-image"
                        onClick={() => {
                          disLikeBackground();
                        }}
                      >
                        {disLiked ? (
                          <ThumbDownAlt fontSize="medium" />
                        ) : (
                          <ThumbDownOffAlt fontSize="medium" />
                        )}
                      </IconButton>

                      <IconButton
                        className="icon-like-background-image"
                        onClick={() => {
                          likeBackground();
                        }}
                      >
                        {liked ? (
                          <ThumbUpAlt fontSize="medium" />
                        ) : (
                          <ThumbUpOffAlt fontSize="medium" />
                        )}
                      </IconButton>
                    </div>
                  </div>

                  <div className="container-backround-under-background ">
                    <span className="active-backgound-subtitle">
                      Photo Credit:
                    </span>
                    {appState.guestMode ? (
                      <span className="active-backgound-subtitle txt-date">
                        January 26, 2016
                      </span>
                    ) : backgroundState.background &&
                      backgroundState.background.date ? (
                      <span className="active-backgound-subtitle txt-date">
                        {backgroundState.background.date}
                      </span>
                    ) : null}
                  </div>
                  {appState.guestMode ? (
                    <span className="active-backgound-subtitle-photographe">
                      veeterzy
                    </span>
                  ) : backgroundState.background &&
                    backgroundState.background.photographer ? (
                    <span className="active-backgound-subtitle-photographe">
                      {backgroundState.background
                        ? backgroundState.background.photographer
                        : null}
                    </span>
                  ) : null}
                </div>
              ) : (
                <div className="container-active-backgound-info">
                  <span className="active-backgound-subtitle">
                    Hang in there! Issue getting background picture information
                  </span>
                </div>
              )}
            </Popover.Body>
          </Popover>
        }
      >
        <div className="container-btn-BackgroundInfo">
          <Info className="home-icon-font-size" />
        </div>
      </OverlayTrigger>
    );
  }
}
