import React, { useEffect } from "react";
import "./SearchEngine.css";

import PrimarySearchEngine from "./components/PrimarySearchEngine";
import SecondarySearchEngine from "./components/SecondarySearchEngine";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { addSecondarySearchEngineAction, resetSearchEngineStateAction } from "../../store/reducers/searchEngineReducer/action";

export default function SearchEngineFeature(props) {
  const searchEngine = useSelector((state) => state.searchEngine);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!searchEngine.resetState){
      dispatch(resetSearchEngineStateAction())
    }
  })
  


  if (searchEngine.showMultipleSeachEngine) {
    return (
      <>
        {searchEngine.secondarySearchEngine.map((searchItem, index) => (
          <SecondarySearchEngine
            searchEngine={searchItem}
            index={index}
            key={"multiple-search-engine-" + index}
            {...props}
          />
        ))}
        <div
          className="bookmarks-item bookmarks-add-item"
          key="add-new-secondary-search-engine"
          style={
            props.display || props.opacity === 0 ? { display: "none" } : {}
          }
        >
          <IconButton
            className="bookmark-add-btn "
            aria-label="Add"
            onClick={(event) => {
              dispatch(addSecondarySearchEngineAction());
            }}
          >
            <Add className="home-icon-setting-font-size" />
          </IconButton>
        </div>
      </>
    );
  } else {
    return <PrimarySearchEngine {...props} />;
  }
}
