import React, { useState } from "react";
import { useSelector } from "react-redux";
import BookmarkFeature from "../../../features/BookmarkFeature/BookmarkFeature";
import DateAndTime from "../../../features/ClockFeature/DateAndTime";
import TimeFeaturesComponents from "../../../features/ClockFeature/TimeFeaturesComponents/TimeFeaturesComponents";
import SearchEngineFeature from "../../../features/SearchEngineFeature/SearchEngineFeature";

export default function CenterArea({ homeState }) {
  const bookmarksState = useSelector((state) => state.bookmarks);

  const [showTimeFeature, setshowTimeFeature] = useState(false);
  const [showTimer, setshowTimer] = useState(false);
  const [showAlarm, setshowAlarm] = useState(false);
  const [showAlarmFeature, setshowAlarmFeature] = useState(false);
  const [showStopWatch, setshowStopWatch] = useState(false);

  const [showIconBookmakSetting, setshowIconBookmakSetting] = useState(false);

  return (
    <>
      <div className="Date-time-area">
        <DateAndTime
          display={homeState.hideAllFeatures}
          opacityTime={homeState.showTime}
          showTimeFeature={showTimeFeature}
          setshowTimeFeature={setshowTimeFeature}
          showTimer={showTimer}
          setshowTimer={setshowTimer}
          showAlarm={showAlarm}
          setshowAlarm={setshowAlarm}
          setshowAlarmFeature={setshowAlarmFeature}
          showAlarmFeature={showAlarmFeature}
          showStopWatch={showStopWatch}
          setshowStopWatch={setshowStopWatch}
          opacityDate={homeState.showDate}
        />
        <TimeFeaturesComponents
          showTimeFeature={showTimeFeature}
          setshowTimeFeature={setshowTimeFeature}
          showTimer={showTimer}
          setshowTimer={setshowTimer}
          showAlarm={showAlarm}
          setshowAlarm={setshowAlarm}
          setshowAlarmFeature={setshowAlarmFeature}
          showAlarmFeature={showAlarmFeature}
          showStopWatch={showStopWatch}
          setshowStopWatch={setshowStopWatch}
          display={homeState.hideAllFeatures}
        />
      </div>
      <div className="search-engine-area">
        <SearchEngineFeature
          display={homeState.hideAllFeatures}
          opacity={homeState.showSearch}
        />
      </div>
      <div
        className="bookmarks-area"
        onMouseOver={() => setshowIconBookmakSetting(true)}
        onMouseLeave={() => setshowIconBookmakSetting(false)}
        style={
          homeState.hideAllFeatures
            ? { display: "none" }
            : bookmarksState.positionBOokmarksTop
            ? homeState.showBookmark === 0
              ? { justifyContent: "flex-end" }
              : { justifyContent: "space-between" }
            : { justifyContent: "flex-end" }
        }
      >
        <BookmarkFeature
          opacity={homeState.showBookmark}
          showIcons={showIconBookmakSetting}
          setshowIcons={setshowIconBookmakSetting}
        />
      </div>
    </>
  );
}
