import { initialState, params, wallpaperStateAction } from './constants';
import { appStateAction } from '../appReducer/constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const wallpaperPersistConfig = {
  key: 'wallpaper',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING wallpaper :', error)
};
export const wallpaperPersisted = persistReducer(wallpaperPersistConfig, wallpaperReducer);

export default function wallpaperReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case wallpaperStateAction.UPDATE_WALLPAPER_WINDOW_STATE:
      return {
        ...state,
        active: !state.active
      };

    default:
      return state;
  }
}
