import { musicstateAction } from "./constants";

export const updateMusicWindowStateAction = (musicId = null) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.UPDATE_MUSIC_WINDOW_STATE,
      payload: musicId
    });
  };
};

export const updateMusicPinAction = (musicId = null, pin = false) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.UPDATE_MUSIC_PIN,
      payload: { musicId: musicId, pin: pin }
    });
  };
};

export const setMusicAccessTokenAction = (musicId, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.SET_MUSIC_ACCESS_TOKEN,
      payload: { musicId, accessToken }
    });
  };
};

export const setMusiccodeAction = (musicId, code) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.SET_MUSIC_CODE_TOKEN,
      payload: { musicId, code }
    });
  };
};

export const setMusicRefreshTokenAction = (musicId, refreshToken) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.SET_MUSIC_REFRESH_TOKEN,
      payload: { musicId, refreshToken }
    });
  };
};

export const setMusicExpiresInAction = (musicId, expiresIn) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.SET_MUSIC_EXPIRES_IN,
      payload: { musicId, expiresIn }
    });
  };
};

export const setIsPlayingAction = (musicId, isPlaying) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.SET_MUSIC_IS_PLAYING,
      payload: { musicId, isPlaying }
    });
  };
};

export const setCurrentTrackIdAction = (musicId, currentTrackId) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.SET_MUSIC_CURRENT_TRACK_ID,
      payload: { musicId, currentTrackId }
    });
  };
};

// action GetMusicData request
export const getMusicDataRequestAction = (musicId) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.GET_MUSIC_API_REQUEST,
      payload: { musicId }
    });
  };
};

// action GetMusicData success
export const getMusicDataSuccessAction = (musicId, data) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.GET_MUSIC_API_SUCESS,
      payload: { musicId, data }
    });
  };
};

// action GetMusicData failure
export const getMusicDataFailureAction = (musicId, error) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.GET_MUSIC_API_FAILURE,
      payload: { musicId, error }
    });
  };
};

// action GetMusicData failure
export const getMusicDataFailureMessageAction = (musicId, errorMessage) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.GET_MUSIC_API_FAILURE_MESSAGE,
      payload: { musicId, errorMessage }
    });
  };
};

export const toggleRepeatTrackModeAction = (musicId, repeatMode) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.TOGGLE_MUSIC_TRACK_REPEAT_MODE,
      payload: { musicId, repeatMode }
    });
  };
};

export const toggleShuffleModeAction = (musicId, shuffleMode) => {
  return (dispatch) => {
    dispatch({
      type: musicstateAction.TOGGLE_MUSIC_TRACK_SHUFFLE_MODE,
      payload: { musicId, shuffleMode }
    });
  };
};
