import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import validator from "validator";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";
import { UpdateUserInfoUserServer } from "../../../server/Auth/user.server";

export default function UpdateProfileInfoComponent(props) {
  const stateAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [inputUsernameUpdate, setinputUsernameUpdate] = useState(
    stateAuth.user.username ? stateAuth.user.username : null
  );
  const [inputUsernameTouched, setinputUsernameTouched] = useState(false);
  const [usernameErrorMsg, setUsernameErrorMsg] = useState({
    message: "",
    error: false
  });
  const [inputFirstNameUpdate, setinputFirstNameUpdate] = useState(
    stateAuth.user.firstName ? stateAuth.user.firstName : ""
  );
  const [inputFirstNameTouched, setinputFirstNameTouched] = useState(false);
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState({
    message: "",
    error: false
  });
  const [inputLastNameUpdate, setinputLastNameUpdate] = useState(
    stateAuth.user.lastName ? stateAuth.user.lastName : ""
  );
  const [inputLastNameTouched, setinputLastNameTouched] = useState(false);
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState({
    message: "",
    error: false
  });

  useEffect(() => {
    setinputUsernameUpdate(stateAuth.user.username);
    setinputUsernameTouched(false);
    setinputFirstNameUpdate(
      stateAuth.user.firstName ? stateAuth.user.firstName : ""
    );
    setinputFirstNameTouched(false);
    setinputLastNameUpdate(
      stateAuth.user.lastName ? stateAuth.user.lastName : ""
    );
    setinputLastNameTouched(false);

    setUsernameErrorMsg({
      message: "",
      error: false
    });

    setFirstNameErrorMsg({
      message: "",
      error: false
    });

    setLastNameErrorMsg({
      message: "",
      error: false
    });
  }, []);

  const updateUserInfoButtonAction = (event) => {
    event.preventDefault();
    validateFormUpdateUserInfo();

    if (
      !usernameErrorMsg.error &&
      !firstNameErrorMsg.error &&
      !lastNameErrorMsg.error &&
      inputFirstNameTouched &&
      inputLastNameTouched
    ) {
      let newUser = {
        firstName: inputFirstNameUpdate,
        lastName: inputLastNameUpdate,
        username: inputUsernameUpdate
      };
      meaVanaConsole("updateUserInfoButtonAction newuser:", newUser);
      dispatch(UpdateUserInfoUserServer(newUser));
    } else {
    }
  };

  const validateFormUpdateUserInfo = () => {
    verifFirstNameFormat(inputFirstNameUpdate);
    verifLastNameFormat(inputLastNameUpdate);
    verifusernameFormat(inputUsernameUpdate);
  };

  const verifusernameFormat = (username = "") => {
    if (validator.isEmpty(username, { ignore_whitespace: true })) {
      setUsernameErrorMsg({
        error: true,
        message: "Please enter your Username"
      });
    } else {
      if (!validator.isLength(username, { min: 1, max: 48 })) {
        setUsernameErrorMsg({
          error: true,
          message: "Please enter a Username between 1 and 48 characters"
        });
      } else {
        setUsernameErrorMsg({ error: false, message: "" });
      }
    }
  };
  const verifFirstNameFormat = (firstName = "") => {
    if (validator.isEmpty(firstName, { ignore_whitespace: true })) {
      setFirstNameErrorMsg({
        error: true,
        message: "Please enter your first name"
      });
    } else {
      if (!validator.isLength(firstName, { min: 1, max: 48 })) {
        setFirstNameErrorMsg({
          error: true,
          message: "Please enter a first name between 1 and 48 characters"
        });
      } else {
        setFirstNameErrorMsg({ error: false, message: "" });
      }
    }
  };
  const verifLastNameFormat = (LastName = "") => {
    if (validator.isEmpty(LastName, { ignore_whitespace: true })) {
      setLastNameErrorMsg({
        error: true,
        message: "Please enter your Last Name"
      });
    } else {
      if (!validator.isLength(LastName, { min: 1, max: 48 })) {
        setLastNameErrorMsg({
          error: true,
          message: "Please enter a Last Name between 1 and 48 characters"
        });
      } else {
        setLastNameErrorMsg({ error: false, message: "" });
      }
    }
  };

  return (
    <div className="list-settings-profile">
      <div className="setting-profile-list-groupe-header">
        <p className="setting-list-groupe-header-title">Update Informations</p>
      </div>
      {stateAuth.successUpdateUserInfo ? (
        <div className="container-profile-form">
          <div className="profile-form-group">
            <label>{stateAuth.responseUpdateUserInfo.message}</label>
          </div>

          <div className="profile-form-submit-area">
            <Button
              className="contact-form-btn-send"
              variant="contained"
              color="info"
              onClick={(event) => {
                event.preventDefault();
                props.goBackToProfileInfo();
              }}
            >
              go back to profile
            </Button>
          </div>
        </div>
      ) : (
        <form
          onSubmit={updateUserInfoButtonAction}
          className="container-profile-form"
        >
          <div className="profile-form-group">
            <label>Username</label>
            <input
              className={
                usernameErrorMsg.error
                  ? "profile-form-control form-control input-error"
                  : "profile-form-control form-control"
              }
              type="text"
              id="username"
              value={inputUsernameUpdate}
              onFocus={(e) => {
                setUsernameErrorMsg({ error: false, message: "" });
              }}
              onBlur={(e) => {
                verifusernameFormat(inputUsernameUpdate);
              }}
              onChange={(event) => {
                setinputUsernameUpdate(event.target.value);
                setinputUsernameTouched(true);
                verifusernameFormat(event.target.value);
              }}
            />
            <p className="error-msg-txt">{usernameErrorMsg.message}</p>
          </div>
          <div className="profile-form-group">
            <label>First Name</label>
            <input
              className="profile-form-control form-control"
              type="text"
              id="first name"
              value={inputFirstNameUpdate}
              onFocus={(e) => {
                setFirstNameErrorMsg({ error: false, message: "" });
              }}
              onBlur={(e) => {
                verifFirstNameFormat(inputFirstNameUpdate);
              }}
              onChange={(event) => {
                setinputFirstNameUpdate(event.target.value);
                setinputFirstNameTouched(true);
                verifFirstNameFormat(event.target.value);
              }}
            />
            <p className="error-msg-txt">{firstNameErrorMsg.message}</p>
          </div>
          <div className="profile-form-group">
            <label>Last Name</label>
            <input
              className="profile-form-control form-control"
              type="text"
              id="last name"
              value={inputLastNameUpdate}
              onFocus={(e) => {
                setLastNameErrorMsg({ error: false, message: "" });
              }}
              onBlur={(e) => {
                verifLastNameFormat(inputLastNameUpdate);
              }}
              onChange={(event) => {
                setinputLastNameUpdate(event.target.value);
                setinputLastNameTouched(true);
                verifLastNameFormat(event.target.value);
              }}
            />
            <p className="error-msg-txt">{lastNameErrorMsg.message}</p>
          </div>
          <p className="error-msg-txt">
            {stateAuth.errorUpdateUserInfo
              ? stateAuth.errorUpdateUserInfo.message
              : ""}
          </p>

          <div className="profile-form-submit-area">
            <Button
              className="contact-form-btn-send button-setting-popup"
              variant="contained"
              color="info"
              onClick={(event) => {
                event.preventDefault();
                props.goBackToProfileInfo();
              }}
            >
              Cancel
            </Button>
            <Button
              className="contact-form-btn-send button-setting-popup"
              variant="contained"
              color="info"
              style={{ marginLeft: "1rem" }}
              onClick={updateUserInfoButtonAction}
              disabled={stateAuth.loadingUpdateUserInfo}
              type="submit"
            >
              {stateAuth.loadingUpdateUserInfo ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#fff",
                    alignSelf: "center"
                  }}
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
