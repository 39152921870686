/* global chrome */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Close,
  KeyboardArrowDown,
  PauseCircleOutlined,
  PlayCircleOutline,
  StopCircleOutlined
} from "@mui/icons-material";
import "./TimeFeatures.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AudioPlayerTimeFeature from "./AudioPlayerTimeFeature";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import {
  setHours,
  setMinutes,
  setSeconds
} from "../../../store/reducers/timerReducer/action";
import { APP_ENV } from "../../../config/constants";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function TimerComponent(props) {
  const appState = useSelector((state) => state.app);

  const [timerOn, settimerOn] = useState(false);
  const [audioArray, setaudioArray] = useState([
    { name: "Desk Bell", audio: GetImageUrl("/assets/audio/Desk_Bell.mp3") }
  ]);
  const [activeAudio, setactiveAudio] = useState(0);
  const [timerPlyerOn, settimerPlyerOn] = useState(false);
  const [keyTimer, setKeyTimer] = useState(0);
  const [audioPlaying, setaudioPlaying] = useState(false);
  const timer = useSelector((state) => state.timer);
  const hours = useSelector((state) => state.timer.hours);
  const minutes = useSelector((state) => state.timer.minutes);
  const seconds = useSelector((state) => state.timer.seconds);
  const [timerValueSeconds, settimerValueSeconds] = useState(
    hours * 60 * 60 + minutes * 60 + seconds
  );
  const dispatch = useDispatch();
  let time, hoursRemaining, minutesRemaining, secondsRemaining;

  const getTime = (hours, minutes, seconds) => {
    const minutesFromHours = hours * 60;
    const sec = seconds / 60;
    time = minutesFromHours + minutes + sec;
    return time;
  };

  const timerListener = useCallback((request, sender, sendResponse) => {
    meaVanaConsole(
      "inside chrome.runtime.onMessage.addListener at newtab script"
    );
    if (request.message === "timerFired") {
      setaudioPlaying(true);
      sendResponse("Desk_Bell rung");
    }
    return;
  }, []);

  useEffect(() => {
    if (
      typeof chrome != "undefined" &&
      chrome.runtime != undefined &&
      chrome.runtime.onMessage != undefined
    ) {
      chrome.runtime.onMessage.addListener(timerListener);
      return () => {
        chrome.runtime.onMessage.removeListener(timerListener);
      };
    } else {
      meaVanaConsole("chrome undefined");
    }
  }, [timerListener]);

  return (
    <div
      className="Timer-container"
      style={!props.showTimer ? { display: "none" } : {}}
    >
      <div
        className="btn-close-time-feature"
        onClick={() => {
          props.setshowTimer(!props.showTimer);
          props.setshowTimeFeature(!props.showTimeFeature);
        }}
      >
        <Close fontSize="medium" />
      </div>
      <h3>Timer</h3>
      <CountdownCircleTimer
        key={keyTimer}
        isPlaying={timerOn}
        duration={timerValueSeconds}
        size={150}
        // trailColor="#fff"
        onComplete={() => {
          // do your stuff here
          if (typeof chrome != "undefined" && chrome.runtime) {
            chrome.runtime.sendMessage(
              {
                message: "deleteAlarm",
                alarmName: "timer"
              },
              function (res) {
                meaVanaConsole(res);
              }
            );
          } else {
            meaVanaConsole("chrome undefined");
            setaudioPlaying(true);
          }
          settimerValueSeconds(0);
          dispatch(setHours(0));
          dispatch(setMinutes(0));
          dispatch(setSeconds(0));
          // setaudioPlaying(true);
          settimerPlyerOn(false);
          settimerOn(false); // repeat animation in 1.5 seconds
          return [true, 100];
        }}
        colors={
          appState.themeColor == "dark" ? [["#ffffff", 1]] : [["#313845", 1]]
        }
      >
        {({ remainingTime }) => {
          hoursRemaining = Math.floor(remainingTime / 3600);
          minutesRemaining = Math.floor((remainingTime % 3600) / 60);
          secondsRemaining = remainingTime % 60;

          if (!timerOn && !timerPlyerOn) {
            return (
              <div
                className="container-input-group-timer"
                onClick={() => settimerOn(false)}
              >
                <div className="input-timer-container">
                  <button
                    className="btn-icons-arrow-timer "
                    disabled={hours > 11}
                    onClick={() => {
                      settimerOn(false);
                      settimerPlyerOn(false);
                      settimerValueSeconds(
                        (hours + 1) * 60 * 60 + minutes * 60 + seconds
                      );
                      setKeyTimer((prevKey) => prevKey + 1);
                      dispatch(setHours(hours + 1));
                    }}
                  >
                    <KeyboardArrowDown
                      className="btn-icons-arrow-timer-rotated"
                      fontSize="medium"
                    />
                  </button>
                  <input
                    className="input-timer"
                    value={hours}
                    onChange={(event) => {
                      const val = parseInt(event.target.value)
                        ? parseInt(event.target.value)
                        : 0;
                      if (val > 11) {
                        dispatch(setHours(12));
                        settimerValueSeconds(
                          12 * 60 * 60 + minutes * 60 + seconds
                        );
                      } else if (val < 1) {
                        dispatch(setHours(0));
                        settimerValueSeconds(minutes * 60 + seconds);
                      } else {
                        dispatch(setHours(val));
                        settimerValueSeconds(
                          val * 60 * 60 + minutes * 60 + seconds
                        );
                      }

                      settimerOn(false);
                      settimerPlyerOn(false);

                      setKeyTimer((prevKey) => prevKey + 1);
                    }}
                  />
                  <button
                    className="btn-icons-arrow-timer"
                    disabled={hours < 1}
                    onClick={() => {
                      settimerOn(false);
                      settimerPlyerOn(false);
                      settimerValueSeconds(
                        (hours - 1) * 60 * 60 + minutes * 60 + seconds
                      );
                      setKeyTimer((prevKey) => prevKey + 1);
                      dispatch(setHours(hours - 1));
                    }}
                  >
                    <KeyboardArrowDown fontSize="medium" />
                  </button>
                </div>
                :
                <div className="input-timer-container">
                  <button
                    className="btn-icons-arrow-timer"
                    disabled={minutes > 58}
                    onClick={() => {
                      settimerOn(false);
                      settimerPlyerOn(false);
                      settimerValueSeconds(
                        hours * 60 * 60 + (minutes + 1) * 60 + seconds
                      );
                      setKeyTimer((prevKey) => prevKey + 1);
                      dispatch(setMinutes(minutes + 1));
                    }}
                  >
                    <KeyboardArrowDown
                      className="btn-icons-arrow-timer-rotated"
                      fontSize="medium"
                    />
                  </button>
                  <input
                    className="input-timer"
                    value={minutes}
                    onChange={(event) => {
                      const val = parseInt(event.target.value)
                        ? parseInt(event.target.value)
                        : 0;
                      if (val > 58) {
                        dispatch(setMinutes(59));
                        settimerValueSeconds(
                          hours * 60 * 60 + 59 * 60 + seconds
                        );
                      } else if (val < 1) {
                        dispatch(setMinutes(0));
                        settimerValueSeconds(hours * 60 * 60 + seconds);
                      } else {
                        dispatch(setMinutes(val));
                        settimerValueSeconds(
                          hours * 60 * 60 + val * 60 + seconds
                        );
                      }

                      settimerOn(false);
                      settimerPlyerOn(false);

                      setKeyTimer((prevKey) => prevKey + 1);
                    }}
                  />
                  <button
                    className="btn-icons-arrow-timer"
                    disabled={minutes < 1}
                    onClick={() => {
                      settimerOn(false);
                      settimerPlyerOn(false);
                      settimerValueSeconds(
                        hours * 60 * 60 + (minutes - 1) * 60 + seconds
                      );
                      setKeyTimer((prevKey) => prevKey + 1);
                      dispatch(setMinutes(minutes - 1));
                    }}
                  >
                    <KeyboardArrowDown fontSize="medium" />
                  </button>
                </div>
                :
                <div className="input-timer-container">
                  <button
                    className="btn-icons-arrow-timer"
                    disabled={seconds > 58}
                    onClick={() => {
                      settimerOn(false);
                      settimerPlyerOn(false);
                      settimerValueSeconds(
                        hours * 60 * 60 + minutes * 60 + (seconds + 1)
                      );
                      setKeyTimer((prevKey) => prevKey + 1);
                      dispatch(setSeconds(seconds + 1));
                    }}
                  >
                    <KeyboardArrowDown
                      className="btn-icons-arrow-timer-rotated"
                      fontSize="medium"
                    />
                  </button>
                  <input
                    className="input-timer"
                    value={seconds}
                    onChange={(event) => {
                      const val = parseInt(event.target.value)
                        ? parseInt(event.target.value)
                        : 0;
                      if (val > 58) {
                        dispatch(setSeconds(59));
                        settimerValueSeconds(
                          hours * 60 * 60 + minutes * 60 + 59
                        );
                      } else if (val < 1) {
                        dispatch(setSeconds(0));
                        settimerValueSeconds(hours * 60 * 60 + minutes * 60);
                      } else {
                        dispatch(setSeconds(val));
                        settimerValueSeconds(
                          hours * 60 * 60 + minutes * 60 + val
                        );
                      }

                      settimerOn(false);
                      settimerPlyerOn(false);

                      setKeyTimer((prevKey) => prevKey + 1);
                    }}
                  />
                  <button
                    className="btn-icons-arrow-timer"
                    disabled={seconds < 1}
                    onClick={() => {
                      settimerOn(false);
                      settimerPlyerOn(false);
                      settimerValueSeconds(
                        hours * 60 * 60 + minutes * 60 + (seconds - 1)
                      );
                      setKeyTimer((prevKey) => prevKey + 1);
                      dispatch(setSeconds(seconds - 1));
                    }}
                  >
                    <KeyboardArrowDown fontSize="medium" />
                  </button>
                </div>
              </div>
            );
          } else {
            return (
              <div className="txt-countdown-container">
                {hoursRemaining < 10 ? "0" + hoursRemaining : hoursRemaining}:
                {minutesRemaining < 10
                  ? "0" + minutesRemaining
                  : minutesRemaining}
                :
                {secondsRemaining < 10
                  ? "0" + secondsRemaining
                  : secondsRemaining}
              </div>
            );
          }
        }}
      </CountdownCircleTimer>

      <div className="Timer-buttons">
        {!timerOn && !timerPlyerOn && (
          //play
          <div
            className="btn-icons-timer"
            onClick={() => {
              if (timerValueSeconds > 0) {
                if (typeof chrome != "undefined" && chrome.runtime) {
                  chrome.runtime.sendMessage(
                    {
                      message: "setAlarm",
                      time: getTime(hours, minutes, seconds),
                      alarmName: "timer"
                    },
                    function (res) {
                      meaVanaConsole(res);
                    }
                  );
                } else {
                  meaVanaConsole("chrome undefined");
                }
                settimerOn(true);
                settimerPlyerOn(true);
              }
            }}
          >
            <PlayCircleOutline fontSize="large" />
          </div>
        )}
        {timerOn && (
          //stop
          <div
            className="btn-icons-timer"
            onClick={() => {
              dispatch(setHours(hoursRemaining));
              dispatch(setMinutes(minutesRemaining));
              dispatch(setSeconds(secondsRemaining));
              if (typeof chrome != "undefined" && chrome.runtime) {
                chrome.runtime.sendMessage(
                  {
                    message: "clearAlarm",
                    alarmName: "timer"
                  },
                  function (res) {
                    meaVanaConsole(res);
                  }
                );
              } else {
                meaVanaConsole("chrome undefined");
              }
              settimerOn(false);
              settimerPlyerOn(true);
            }}
          >
            <PauseCircleOutlined fontSize="large" />
          </div>
        )}
        {!timerOn && timerPlyerOn && (
          //reset
          <div
            className="btn-icons-timer"
            onClick={() => {
              settimerPlyerOn(false);
              setKeyTimer((prevKey) => prevKey + 1);
            }}
          >
            <StopCircleOutlined fontSize="large" />
          </div>
        )}
        {!timerOn && timerPlyerOn && (
          //Resume
          <div
            className="btn-icons-timer"
            onClick={() => {
              if (typeof chrome != "undefined" && chrome.runtime) {
                chrome.runtime.sendMessage(
                  {
                    message: "setAlarm",
                    time: getTime(
                      hoursRemaining,
                      minutesRemaining,
                      secondsRemaining
                    ),
                    alarmName: "timer"
                  },
                  function (res) {
                    meaVanaConsole(res);
                  }
                );
              } else {
                meaVanaConsole("chrome undefined");
              }
              settimerOn(true);
              settimerPlyerOn(true);
            }}
          >
            <PlayCircleOutline fontSize="large" />
          </div>
        )}
      </div>
      <div className="form-group">
        {/*  <select
          type="select"
          value={activeAudio}
          className="form-control input-select-timer-audio"
          onChange={(event) => {
            setactiveAudio(event.target.value);
            setaudioPlaying(true);
          }}
        >
          <option key="novalue" value="" disabled>
            -- Select your audio --
          </option>
          {audioArray.map((audio, index) => (
            <option key={index} value={index}>{audio.name}</option>
          ))}
        </select>
          */}
      </div>

      <AudioPlayerTimeFeature
        url={audioArray[activeAudio].audio}
        playing={audioPlaying}
        setPlaying={setaudioPlaying}
      />
    </div>
  );
}
