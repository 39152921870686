import React from "react";
import { useSelector } from "react-redux";
import "./AnalogClock.css";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
export default function AnalogClock(props) {
  const clockState = useSelector((state) => state.clock);

  return (
    <div
      style={
        props.display
          ? { display: "none" }
          : props.opacity === 0
          ? { display: "none" }
          : {
              opacity: props.opacity,
              cursor: "pointer",
              fontSize: clockState.analogClockSize / 10 + "px"
            }
      }
    >
      <Clock
        size={clockState.analogClockSize}
        renderSecondHand={clockState.showSeconds}
        value={props.time}
        className={
          clockState.themeMode === "light"
            ? "analog-clock light-theme"
            : "analog-clock dark-theme"
        }
        renderNumbers={true}
      />
    </div>
  );
}
