import { homeStateAction } from "./constants";

export const resetHomeThemeAction = () => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.RESET_HOME_THEME
    });
  };
};

export const hideAllFeatureAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.HIDE_ALL_FEATURES,
      payload: state
    });
  };
};
export const updateShowHideAllFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_ALL_FEATURE,
      value: value
    });
  };
};

export const updateShowHideTimeFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_TIME,
      value: value
    });
  };
};
export const updateShowHideDateFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_DATE,
      value: value
    });
  };
};
export const updateShowHideNoteFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_NOTE,
      value: value
    });
  };
};
export const updateShowHideSearchEngineFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_SEARCH,
      value: value
    });
  };
};
export const updateShowHideQuoteFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_QUOTE,
      value: value
    });
  };
};
export const updateShowHideWeatherFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_WEATHER,
      value: value
    });
  };
};
export const updateShowHideBackgroundInfoFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_BACKGROUND_INFO,
      value: value
    });
  };
};

export const updateShowHideBookmarkFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_BOOKMARK,
      value: value
    });
  };
};

export const updateShowHideIntegrationFeatureAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_SHOW_INTEGRATION,
      value: value
    });
  };
};

// action update theme text size in state
export const updateHomeThemeAction = (theme) => {
  return (dispatch) => {
    dispatch({
      type: homeStateAction.UPDATE_HOME_THEME,
      theme: theme
    });
  };
};
