import { appStateAction } from '../appReducer/constants';
import { initialState, youtubeStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const youtubePersistConfig = {
  key: 'youtube',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING youtube :', error)
};
export const youtubePersisted = persistReducer(youtubePersistConfig, youtubeReducer);

export default function youtubeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case youtubeStateAction.SET_SELECTED_VIDEO:
      return {
        ...state,
        selectedVideo: action.payload.selectedVideo
      };
    case youtubeStateAction.GET_SEARCH_API_REQUEST:
      return {
        ...state
      };
    case youtubeStateAction.GET_SEARCH_API_SUCESS:
      return {
        ...state,
        videos: action.payload.data.items
      };
    case youtubeStateAction.GET_SEARCH_API_FAILURE:
      return {
        ...state
      };
    case youtubeStateAction.SHOW_SEARCHED_VIDEOS:
      return {
        ...state,
        showSearchedVideos: action.payload.showSearchedVideos
      };

    case youtubeStateAction.UPDATE_SHOW_YOUTUBE_WINDOW:
      return {
        ...state,
        active: action.payload
      };
    case youtubeStateAction.UPDATE_PIN_YOUTUBE_HOME:
      return {
        ...state,
        pined: action.payload
      };

    default:
      return state;
  }
}
