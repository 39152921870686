import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import ImageWithFallback from '../components/ImageWithFallback';
import { BookmarkGetImageSrcUrl } from '../../../Utils/BookmarkGetImageSrcUrl';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import { assetsIcons } from '../../../config/images';

const TopVisited = ({ index, topVisited }) => {
  const bookmarksState = useSelector((state) => state.bookmarks);
  const openBookmarkInNewWindow =
    bookmarksState && bookmarksState.openBookmarkInNewWindow
      ? bookmarksState.openBookmarkInNewWindow
      : false;

  const redirectToUrl = (e, url) => {
    //redirect only if bookmark is clicked not the remove/delete icon
    if (openBookmarkInNewWindow) {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_blank');
    } else {
      window.open(url.match(/^http[s]?:\/\//) ? url : 'http://' + url, '_self');
    }
  };

  return (
    <Tooltip arrow key={'bookmark-tooltip-item-' + index} title={topVisited.title} placement="top">
      <div
        className={
          bookmarksState.bookmarkShapeRectangle
            ? 'rectangular-shape bookmarks-item'
            : 'bookmarks-item'
        }
        key={'bookmark-item-container' + index}
      >
        <div
          className="bookmarks-item-container"
          onClick={(event) => {
            event.preventDefault();
            redirectToUrl(event, topVisited.url);
          }}
        >
          <ImageWithFallback
            src={BookmarkGetImageSrcUrl(topVisited.url)}
            fallback={GetImageUrl(assetsIcons.folder)}
            alt={topVisited.title}
            className={`bookmark-img ${!bookmarksState.bookmarkColored && 'grayscale-filter'}`}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default function TopVisitedSitesBookmarks(props) {
  const bookmarksState = useSelector((state) => state.bookmarks);
  const topVisitedSitesList =
    bookmarksState && bookmarksState.topVisitedSites ? bookmarksState.topVisitedSites : [];
  return (
    <div
      className={`bookmarks-container-sortable-list ${
        bookmarksState.bookmarkSizeBig ? 'bookmark-lg' : 'bookmark-sm'
      } ${'show-scroll-bar' /*props.showIcons ? "show-scroll-bar" : "hide-scroll-bar"*/}
      `}
    >
      {topVisitedSitesList.map((topVisited, index) => {
        return topVisited === null ? null : (
          <TopVisited key={`bookmark-item-${index}`} index={index} topVisited={topVisited} />
        );
      })}
      {/* <SortableBookmarkList
        topVisitedSites={topVisitedSitesList}
        axis="xy"
        distance={1}
        onSortEnd={onBookmarkDragEnd}
      /> */}
    </div>
  );
}
