import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import useWindowDimensions from "../../Utils/useWindowDimensions";
import WindowContainer from "../Windows/WindowContainer";
import TopArea from "./components/TopArea";
import LeftArea from "./components/LeftArea";
import "./Home.css";
import CenterArea from "./components/CenterArea";
import RightArea from "./components/RightArea";
import BottomArea from "./components/BottomArea";

export default function Home() {
  const homeState = useSelector((state) => state.home);

  return (
    <div className="home-grid-container">
      <div className="left-area" style={{ opacity: homeState.showAllFeature }}>
        <LeftArea />
      </div>
      <div className="top-area">
        <TopArea
          hideAllFeatures={homeState.hideAllFeatures}
          showWeather={homeState.showWeather}
          showIntegration={homeState.showIntegration}
        />
      </div>

      <div className="center-area">
        <CenterArea homeState={homeState} />
      </div>

      <div className="bottom-area">
        <BottomArea homeState={homeState} />
      </div>
      <div className="right-area" style={{ opacity: homeState.showAllFeature }}>
        <RightArea />
      </div>
      <WindowContainer />
    </div>
  );

  /* 
   const clockState = useSelector((state) => state.clock);

  const { height, width } = useWindowDimensions();
 const [maxHeightCenterArea, setmaxHeightCenterArea] = useState("80vh");

  const topAreaRef = useRef();
  const centerAreaRef = useRef();
  const bottomAreaRef = useRef();

  useEffect(() => {
    meaVanaConsole("height :", height);
    meaVanaConsole("topAreaRef :", topAreaRef.current.clientHeight);
    meaVanaConsole("centerAreaRef :", centerAreaRef.current.clientHeight);
    meaVanaConsole("bottomAreaRef :", bottomAreaRef.current.clientHeight);

    if (
      height &&
      topAreaRef.current.clientHeight &&
      centerAreaRef.current.clientHeight &&
      bottomAreaRef.current.clientHeight
    ) {
      setmaxHeightCenterArea(
        height -
          topAreaRef.current.clientHeight -
          bottomAreaRef.current.clientHeight
      );
    }
  }, [clockState, height]);

  return (
    <div className="home-grid-container">
      <div className="left-area" style={{opacity:homeState.showAllFeature}}>
        <LeftArea />
      </div>
      <div className="top-area" ref={topAreaRef}>
        <TopArea
          hideAllFeatures={homeState.hideAllFeatures}
          showWeather={homeState.showWeather}
          showIntegration={homeState.showIntegration}
        />
      </div>
      
      <div
        className="center-area"
        ref={centerAreaRef}
       // style={{ maxHeight: maxHeightCenterArea }}
      >
        <CenterArea homeState={homeState} />
      </div>
      
      <div className="bottom-area" ref={bottomAreaRef}>
        <BottomArea homeState={homeState} />
      </div>
      <div className="right-area" style={{opacity:homeState.showAllFeature,maxHeight: maxHeightCenterArea}} >
        <RightArea />
      </div>
      <WindowContainer />
    </div>
  );
  */
}
