import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Description } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import "./NotesContainer.css";
import ToDoSection from "./ToDoSection/ToDoSection";
import NotesSection from "./NotesSection/NotesSection";
import { toggleExpandedView } from "../../store/reducers/notesReducer/action";
import { updateToDoBulkListServer } from "../../server/Dashbord/todos.server";
import { updateNoteServer } from "../../server/Dashbord/note.server";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

export default function NoteFeature(props) {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const notesState = useSelector((state) => state.notes);
  const todosState = useSelector((state) => state.todos);

  let dashboardId = dashboard && dashboard._id ? dashboard._id : null;
  let activeMode = notesState.noteActiveMode
    ? notesState.noteActiveMode
    : false;
  let expandedView =
    notesState && notesState.toggleExpandedView
      ? notesState.toggleExpandedView
      : false;

  //the click event for expanding/collapsing todo
  const resetExpandedView = () => {
    dispatch(toggleExpandedView(false));
  };

  const syncNotesList = () => {
    meaVanaConsole("dispatch updateNoteAction");
    let bulkNotes = notesState.notes.map((note, index) => {
      return {
        ...note,
        prevIndex: note.prevIndex ? note.prevIndex : notesState.notes.length
      };
    });
    dispatch(updateNoteServer(bulkNotes, dashboardId));
  };
  const syncToDosList = () => {
    meaVanaConsole("dispatch updateToDoAction");
    let bulkTodos = todosState.todos.map((todo, index) => {
      return {
        ...todo,
        prevIndex: todo.prevIndex ? todo.prevIndex : todosState.todos.length
      };
    });
    dispatch(updateToDoBulkListServer(bulkTodos, dashboardId));
  };

  if (props.display || props.opacity === 0) {
    return (
      <div
        className="notes-container-btn"
        style={{ opacity: 0, cursor: "inherit" }}
      >
        <Description className="home-icon-font-size" />
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        onExit={() => {
          meaVanaConsole("hide popover notes");
          if (notesState.updatedList) {
            syncNotesList();
          }
          if (todosState.updatedList) {
            syncToDosList();
          }
        }}
        onToggle={() => {
          resetExpandedView();
        }}
        placement="top"
        overlay={
          <Popover
            id="popover-notes"
            className={
              expandedView
                ? "notes-expanded-popover popover-notes"
                : "popover-notes"
            }
          >
            <Popover.Body
              className={
                expandedView
                  ? "notes-expanded-popover-body popover-notes-body"
                  : "popover-notes-body"
              }
            >
              {activeMode ? <NotesSection /> : <ToDoSection />}
            </Popover.Body>
          </Popover>
        }
      >
        {/* the actual note icon that shows up the notes */}
        <div className="notes-container-btn" style={{ opacity: props.opacity }}>
          <Description className="home-icon-font-size" /*fontSize="large"*/ />
        </div>
      </OverlayTrigger>
    );
  }
}
