/* global chrome */
import React, { useState, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import validator from 'validator';

import AuthBottomBar from '../AuthBottomBar/AuthBottomBar';
import {
  activeAuthenticationLoading,
  desactiveAuthenticationLoading,
  resetAuthAction
} from '../../../store/reducers/authReducer/action';
import { LoginAuthServer } from '../../../server/Auth/auth.server';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { APP_ENV } from '../../../config/constants';

export default function Login(props) {
  const [showPassword, setshowPassword] = useState(false);
  const [inputEmail, setinputEmail] = useState('');
  const [inputEmailTouched, setinputEmailTouched] = useState(false);
  const [inputPassword, setinputPassword] = useState('');
  const [inputPasswordTouched, setinputPasswordTouched] = useState(false);
  const { stateAuth } = props;
  //const stateAuth = useSelector((state) => state.auth);
  const appState = useSelector((state) => state.app);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetAuthAction());
  }, []);

  const [emailErrorMsg, setemailErrorMsg] = useState({
    message: '',
    error: false
  });
  const [passwordErrorMsg, setpasswordErrorMsg] = useState({
    message: '',
    error: false
  });

  const loginErrorMsgReset = () => {
    setemailErrorMsg({
      message: '',
      error: false
    });
    setpasswordErrorMsg({
      message: '',
      error: false
    });
  };

  const loginSubmit = async (event) => {
    event.preventDefault();
    dispatch(activeAuthenticationLoading());
    verifEmailFormat(inputEmail);
    verifPasswordFormat(inputPassword);

    if (
      !emailErrorMsg.error &&
      !passwordErrorMsg.error &&
      ((inputEmailTouched && inputPasswordTouched) || APP_ENV === 'development')
    ) {
      let user = {
        email: inputEmail,
        password: inputPassword,
        deviceId: appState.deviceId
      };
      dispatch(LoginAuthServer(user));
      // dispatch(desactiveAuthenticationLoading());
    } else {
      dispatch(desactiveAuthenticationLoading());
    }
  };

  const validationFormLogin = () => {};

  const verifEmailFormat = (email = '') => {
    if (validator.isEmpty(email, { ignore_whitespace: true })) {
      setemailErrorMsg({
        error: true,
        message: 'Please enter your email address'
      });
    } else {
      if (!validator.isEmail(email)) {
        setemailErrorMsg({
          error: true,
          message: 'Email must be a valid format'
        });
      } else if (email.indexOf(' ') >= 0) {
        setemailErrorMsg({
          error: true,
          message: 'Email must not contain white spaces'
        });
      } else {
        setemailErrorMsg({ error: false, message: '' });
      }
    }
  };

  const verifPasswordFormat = (password = '') => {
    var passwordCorrectFormatNumber = /\d/;
    var passwordCorrectFormatLowercase = /[a-z]/;
    var passwordCorrectFormatUppercase = /[A-Z]/;
    var passwordCorrectFormat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setpasswordErrorMsg({ error: true, message: 'Please enter a password' });
    } else {
      if (password.length < 5) {
        setpasswordErrorMsg({
          error: true,
          message: 'Please enter a password longer than 5 characters'
        });
      } else if (password.length > 32) {
        setpasswordErrorMsg({
          error: true,
          message: 'Please enter a password shorter than 32 characters'
        });
      } /*else if (!passwordCorrectFormatNumber.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one numeric digit",
        });
      } else if (!passwordCorrectFormatLowercase.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one lowercase letter",
        });
      } else if (!passwordCorrectFormatUppercase.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must contain at least one uppercase letter",
        });
      } else if (password.indexOf(" ") >= 0) {
        setpasswordErrorMsg({
          error: true,
          message: "Your password must not contain white spaces",
        });
      } else if (!passwordCorrectFormat.test(password)) {
        setpasswordErrorMsg({
          error: true,
          message: "Please enter a valid password",
        });
      }*/ else {
        setpasswordErrorMsg({ error: false, message: '' });
      }
    }
  };

  return (
    <div className="inner-subcontainer">
      <form onSubmit={loginSubmit} style={{ width: '100' }} autoComplete="on">
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            autoComplete="username email"
            className={emailErrorMsg.error ? 'form-control input-error' : 'form-control'}
            placeholder="Enter email"
            value={inputEmail}
            onFocus={(e) => {
              setemailErrorMsg({ error: false, message: '' });
              if (stateAuth.error) {
                dispatch(resetAuthAction());
              }
            }}
            onBlur={(event) => {
              verifEmailFormat(inputEmail);
            }}
            onChange={(event) => {
              setinputEmail(event.target.value);
              setinputEmailTouched(true);
              //     verifEmailFormat(event.target.value);
              if (stateAuth.error) {
                dispatch(resetAuthAction());
              }
            }}
          />
          <p className="error-msg-txt">{emailErrorMsg.message}</p>
        </div>

        <div className="form-group" style={{ marginBottom: '18px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <label>Password</label>
            <label
              className="txt-redirect-action"
              onClick={() => {
                dispatch(resetAuthAction());
                loginErrorMsgReset();
                meaVanaConsole('show Forgot Password ');
                props.showForgotPassword();
              }}
              style={{ textAlign: 'left' }}
            >
              Forgot password?
            </label>
          </div>

          <div className="container-input-icon-auth" style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              autoComplete="new-password password"
              className={passwordErrorMsg.error ? 'form-control input-error' : 'form-control'}
              placeholder="Enter password"
              onFocus={(e) => {
                setpasswordErrorMsg({ error: false, message: '' });
                if (stateAuth.error) {
                  dispatch(resetAuthAction());
                }
              }}
              value={inputPassword}
              onBlur={(e) => {
                verifPasswordFormat(inputPassword);

                //   setshowPassword(false);
              }}
              onChange={(event) => {
                setinputPassword(event.target.value);
                setinputPasswordTouched(true);
                // verifPasswordFormat(event.target.value);
                if (stateAuth.error) {
                  dispatch(resetAuthAction());
                }
              }}
            />
            {!showPassword ? (
              <IconButton className="button-icon-auth" onClick={() => setshowPassword(true)}>
                <VisibilityOff fontSize="small" />
              </IconButton>
            ) : (
              <IconButton className="button-icon-auth" onClick={() => setshowPassword(false)}>
                <Visibility fontSize="small" />
              </IconButton>
            )}
          </div>
          <p className="error-msg-txt">{passwordErrorMsg.message}</p>
        </div>

        {stateAuth.error ? (
          <p className="error-msg-txt" style={{ textAlign: 'center', marginBottom: '5px' }}>
            {stateAuth.error.message}
          </p>
        ) : null}

        <button
          className="btn btn-auth btn-lg btn-block"
          type="submit"
          disabled={stateAuth.loading || !inputEmailTouched || !inputPasswordTouched}
          onClick={loginSubmit}
        >
          {stateAuth.loading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
                alignSelf: 'center'
              }}
            />
          ) : (
            'Login'
          )}
        </button>
      </form>
      {/*  <LoginSSO />*/}

      <AuthBottomBar setshowContactForm={props.setshowContactForm} />
    </div>
  );
}
