export const STORAGE_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  USER: 'USER_DATA',
  DEVICE_ID: 'DEVICE_ID'
};
export const Authorization = 'Bearer ';
export const DebugPersistConfig = false;
export const DebugStoreMigrate = true;

// emailjs params
export const Emailjs_Service_ID = 'shiv_service';
export const Emailjs_User_ID = 'user_cddKXBNLokbTPSwnkwsHW';
export const Emailjs_Template = 'template_7owv1ak';

export const SERVER_URL = process.env.REACT_APP_BACK_END_SERVER_URL;
export const OPENWEATHERMAP_API_KEY = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
// export const NEWS_API_KEY = "6e5cbdb7528d477a9aaeac8d9708721a";
// export const NEWS_API_URL = "https://newsapi.org/v2/everything";
// export const NYTIMES_API_KEY = "x9dlGKI6YzwWcVPnrZOG441kYHo0TnCP";
// export const NYTIMES_API_URL =
//   "https://api.nytimes.com/svc/topstories/v2/home.json";
export const PROJECT_VERSION = process.env.REACT_APP_PROJECT_VERSION;
export const STORAGE_NAME_VERSION = process.env.REACT_APP_STORAGE_NAME_VERSION;
export const STORE_VERSION = process.env.REACT_APP_STORE_VERSION;

export const APP_ENV = process.env.REACT_APP_ENV;

export const CHROME_EXTENTION_URL =
  'https://chrome.google.com/webstore/detail/meavana/kgbcoelgfffkmkpfnldemdinmcbpjlaa';

//export const CHROME_EXTENTION_URL = "https://chrome.google.com/webstore/detail/meavana/kgbcoelgfffkmkpfnldemdinmcbpjlaa";

//Google Integrations
export const GOOGLE_API_KEY = 'AIzaSyBoJeeI5Yd57A8Vzi0s2ual1cNkgiy6Ydg';
export const YOUTUBE_API_BASE_URL = 'https://www.googleapis.com/youtube/v3';
export const GCALENDAR_API_BASE_URL = 'https://www.googleapis.com/calendar/v3';

//Crypto Integrations
export const COINCAP_API_BASE_URL = 'https://api.coincap.io/v2';

//Pictures Integrations
export const UNSPLASH_CLIENT_ID = 'u-jz4Wl4yrzV_4czw-79iML-A9sSt5OlnTDZQIsd8l4';
export const UNSPLASH_API_BASE_URL = 'https://api.unsplash.com';
// spotify params
export const SPOTIFY_API_URL = 'https://accounts.spotify.com/api/token';
export const SPOTIFY_CLIENT_ID = 'adad602da4574c92889c6b63b8bd71ff';
export const SPOTIFY_CLIENT_SECRET = 'ad4bee06886e46f3976e56d576fa0dd8';

//Google Analytics
export const GOOGLE_ANALYTICS_TATRACKING_ID = 'G-3R0QYVHTDX';
