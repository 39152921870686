export const initialState = {
  error: null,

  dashboard: null,
  loading: false
};

export const dashboardStateAction = {
  GET_CURRENT_DASHBORD_SUCCESS: " dashboard/GET_CURRENT_DASHBORD_SUCCESS",
  GET_CURRENT_DASHBORD_REQUEST: " dashboard/GET_CURRENT_DASHBORD_REQUEST",
  GET_CURRENT_DASHBORD_FAILURE: " dashboard/GET_CURRENT_DASHBORD_FAILURE"
};
