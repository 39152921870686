import { unsplashStateAction } from "./constants";

//action show Unsplash Window
export const setShowUnsplashWindow = (isShow) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.SHOW_UNSPLASH_WINDOW,
      payload: {
        showUnsplashWindow: isShow
      }
    });
  };
};
// action get Unsplash Random Picture:  request initiated
export const getUnsplashRandomPictureRequestAction = () => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.GET_RANDOM_PICTURE_FAILURE,
      payload: {}
    });
  };
};

// action get Unsplash Random Picture: success
export const getUnsplashRandomPictureSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.GET_RANDOM_PICTURE_SUCCESS,
      payload: {
        data: data
      }
    });
  };
};

// action get Unsplash Random Picture: failure
export const getUnsplashRandomPictureFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.GET_RANDOM_PICTURE_FAILURE,
      payload: { error: error }
    });
  };
};

// action get searched picture:  request initiated
export const getSearchedPictureRequestAction = (searchTerm) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.GET_SEARCH_PICTURE_REQUEST,
      payload: { searchTerm: searchTerm }
    });
  };
};

// action get searched picture: success
export const getSearchedPictureSuccessAction = (searchTerm, data) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.GET_SEARCH_PICTURE_SUCESS,
      payload: {
        searchTerm: searchTerm,
        data: data
      }
    });
  };
};

// action get searched picture: failure
export const getSearchedPictureFailureAction = (searchTerm, error) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.GET_SEARCH_PICUTURE_FAILURE,
      payload: { searchTerm: searchTerm, error: error }
    });
  };
};

//action set selected pictures
export const setSelectedPicture = (picture) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.SET_SELECTED_PICTURE,
      payload: {
        selectedPicture: picture
      }
    });
  };
};

//action show searched pictures
export const setShowSearchedPictures = (isShow) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.SHOW_SEARCHED_PICTURES,
      payload: {
        showSearchedPictures: isShow
      }
    });
  };
};

export const setUnsplashSelectedPinctureAction = (selectedPicture) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.UPDATE_UNSPLASH_SELECTED_PICTURE,
      payload: selectedPicture
    });
  };
};

export const setUnsplashUseBackgroundAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.UPDATE_UNSPLASH_BACKGROUND_STATE,
      payload: state
    });
  };
};
export const setLoadingUnsplashUseBackgroundAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: unsplashStateAction.UPDATE_LOADING_UNSPLASH_BACKGROUND_STATE,
      payload: state
    });
  };
};
