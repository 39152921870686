import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './IntegrationsGuestMode.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import { InstallDesktop, KeyboardBackspace, WidgetsRounded } from '@mui/icons-material';

import { assetsIcons } from '../../../config/images';

export default function IntegrationsGuestMode(props) {
  const appState = useSelector((state) => state.app);
  const integrationState = useSelector((state) => state.integration);

  const [integrationAppLevel, setIntegrationAppLevel] = useState(0);
  const [integrationAppIndex, setIntegrationAppIndex] = useState(0);

  const [iconSrc, setIconSrc] = useState(assetsIcons.integration.white);
  const [iconOpacity, setIconOpacity] = useState(1);

  useEffect(() => {
    switch (appState.backgroundMode) {
      case 'background-home-picture':
        setIconSrc(assetsIcons.integration.white);

        setIconOpacity(1);
        break;
      case 'background-home-dark':
        setIconSrc(assetsIcons.integration.white);
        setIconOpacity(1);
        break;
      case 'background-home-light':
        setIconSrc(assetsIcons.integration.black);
        setIconOpacity(0.6);
        break;
      default:
        setIconSrc(assetsIcons.integration.white);
        setIconOpacity(1);
        break;
    }
  }, [appState.backgroundMode]);
  if (props.display || props.opacity === 0) {
    return (
      <div className="container-btn-integration" style={{ opacity: 0, cursor: 'inherit' }}>
        <img
          className="home-icon-font-size icon-integration"
          style={{ opacity: iconOpacity }}
          src={iconSrc}
          alt="integration icon"
        />
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-integration"
        placement="bottom"
        rootClose={true}
        overlay={
          <Popover id={`popover-integration`} className="popover-integration">
            <Popover.Header className="popover-integration-header">
              {integrationAppLevel !== 0 ? (
                <IconButton
                  className="popover-integration-button-back"
                  onClick={() => {
                    setIntegrationAppLevel(0);
                    setIntegrationAppIndex(0);
                  }}>
                  <KeyboardBackspace fontSize="medium" />
                </IconButton>
              ) : null}
              {integrationAppLevel === 0
                ? integrationState.integrationApp.name
                : integrationState.integrationApp.pages[integrationAppIndex].name}
            </Popover.Header>
            <Popover.Body className="popover-integration-body">
              <span className="txt-note-guest-mode">
                {/* To use the Notes and Todos, please Sign In */}
                Use a range of features, including Notes, Todos, Stickies, etc
                <br />
                Get the Chrome Extension
              </span>
              <div
                className="container-btn-icons-setting"
                style={{ width: 'auto', marginTop: '0.2rem' }}
                onClick={() => {
                  window.location =
                    'https://chrome.google.com/webstore/detail/meavana-transform-your-ch/kgbcoelgfffkmkpfnldemdinmcbpjlaa';
                }}>
                <InstallDesktop fontSize="medium" />

                <span className="container-btn-icons-setting-txt">Click To Install</span>
              </div>
            </Popover.Body>
          </Popover>
        }>
        <div
          className="container-btn-integration"
          style={props.display ? { display: 'none' } : { opacity: props.opacity }}>
          <img
            className="home-icon-font-size icon-integration"
            style={{ opacity: iconOpacity }}
            src={iconSrc}
            alt="integration icon"
          />
        </div>
      </OverlayTrigger>
    );
  }
}
