import { SERVER_URL } from "../../config/constants";
import UpdatingTopicStyling from "../../hooks/TopicStyling/UpdatingTopicStyling";
import {
  updateSelectedTopicAction,
  updateSelectedTopicFromServerAction
} from "../../store/reducers/backgroundReducer/action";
import { getBookmarksAction } from "../../store/reducers/bookmarksReducer/action";
import {
  getDashboardAction,
  getDashboardFailueAction,
  getDashboardRequestAction
} from "../../store/reducers/dashboardReducer/action";
import { getNotesAction } from "../../store/reducers/notesReducer/action";
import { getTodosAction } from "../../store/reducers/todoReducer/action";
import { defaultTopicStyling } from "../../Utils/DefaultTopicStyling";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import axiosInstance from "../Middleware/axiosInstance";
import { checkErrorResponseFormat } from "../Middleware/utils.server";

export const getCurrentDashboardAction = () => {
  return async (dispatch) => {
    dispatch(getDashboardRequestAction());
    try {
      axiosInstance()
        .get(SERVER_URL + "dashboard/current/")
        .then((response) => {
          meaVanaConsole("getCurrentDashboardAction response: ", response.data);

          dispatch(dashbordDataSetUpMultipleDashbords(response.data[0]));
        })
        .catch((error1) => {
          meaVanaConsole(
            "getCurrentDashboardAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(getDashboardFailueAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole(
        "getCurrentDashboardAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(getDashboardFailueAction(checkErrorResponseFormat(error)));
    }
  };
};

export const dashbordDataSetUpMultipleDashbords = (
  dashbordData,
  topicId = null,
  topicStyling = false
) => {
  return async (dispatch) => {
    dispatch(getDashboardAction(dashbordData));
    meaVanaConsole("dispatch getDashboardAction: ", dashbordData);

    if (dashbordData.selectedTopics) {
      if (topicId) {
        let indexTopic = dashbordData.selectedTopics.findIndex(
          (item) => item._id === topicId
        );
        if (indexTopic > -1) {
          dispatch(
            updateSelectedTopicAction(dashbordData.selectedTopics[indexTopic])
          );
          if (topicStyling) {
            if ("style" in dashbordData.selectedTopics[indexTopic]) {
              dispatch(
                UpdatingTopicStyling(
                  dashbordData.selectedTopics[indexTopic].style
                )
              );
            } else {
              dispatch(UpdatingTopicStyling(defaultTopicStyling));
            }
          }
        } else {
          dispatch(
            updateSelectedTopicFromServerAction(dashbordData.selectedTopics)
          );
        }
      } else {
        dispatch(
          updateSelectedTopicFromServerAction(dashbordData.selectedTopics)
        );
      }
    }

    //get initial bookmarks
    if (dashbordData.bookmarkList) {
      dispatch(getBookmarksAction(dashbordData.bookmarkList));
      meaVanaConsole("dispatch getBookmarksAction: ");
    }
    //get initial notes
    if (dashbordData.notes) {
      dispatch(getNotesAction(dashbordData.notes));
      meaVanaConsole("dispatch getNotesAction: ");
    }
    //get initial todos
    if (dashbordData.todoList) {
      dispatch(getTodosAction(dashbordData.todoList));
      meaVanaConsole("dispatch getTodosAction: ");
    }
  };
};
