import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import { KeyboardArrowDown, MoreHoriz, Search } from '@mui/icons-material';

import { Button, Slider, Switch } from '@mui/material';
import useWindowDimensions from '../../../Utils/useWindowDimensions';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import {
  deleteSecondarySearchEngineAction,
  updateActiveSecondarySearchEngineAction,
  updateSecondarySearchEngineBorderAction,
  updateSecondarySearchEngineCloredAction,
  updateSecondarySearchEngineLineAction,
  updateSecondarySearchEngineOpenNewTabAction,
  updateSecondarySearchEngineRectangleAction,
  updateSecondarySearchEngineSizeAction,
  updateShowMultipleSearchEngineAction
} from '../../../store/reducers/searchEngineReducer/action';
import { resetHomeThemeAction } from '../../../store/reducers/homeReducer/action';
import { SearchEngineList } from '../../../Utils/SearchEngineList';

export default function SecondarySearchEngine(props) {
  const searchEngineState = useSelector((state) => state.searchEngine);
  const clockState = useSelector((state) => state.clock);
  const { height, width } = useWindowDimensions();

  const [valueSearch, setvalueSearch] = useState('');
  const [maxWidth, setmaxWidth] = useState(((width * 8) / 10 - 100) / 100);

  const dispatch = useDispatch();

  const searchEngine = props.searchEngine;

  const [inputSearchActive, setinputSearchActive] = useState(false);

  const [searchEngineSizeValue, setsearchEngineSizeValue] = useState(searchEngine.searchEnginSize);

  const [showIcons, setshowIcons] = useState(false);
  const refInputSearch = useRef();
  const searchEngineContainerRef = useRef();

  useEffect(() => {
    setvalueSearch('');
    if (refInputSearch.current) {
      refInputSearch.current.focus();
    }
  }, [valueSearch, searchEngine.activeSearchEngine]);

  useEffect(() => {
    setvalueSearch('');
    if (searchEngineContainerRef.current && searchEngineContainerRef.current.clientWidth) {
      meaVanaConsole('searchEngineContainerRef.current :', searchEngineContainerRef.current);
      meaVanaConsole(
        'searchEngineContainerRef.current.clientWidth:',
        searchEngineContainerRef.current.clientWidth
      );
      setmaxWidth(((searchEngineContainerRef.current.clientWidth - 100) / width) * 100);
      meaVanaConsole(
        'maxWidth :',
        ((searchEngineContainerRef.current.clientWidth - 100) / width) * 100
      );
    } else {
      setmaxWidth(((width * 8) / 10 - 100) / 100);
      meaVanaConsole('not ref maxWidth :', ((width * 8) / 10 - 100) / 100);
    }
  }, [width]);

  const hideIcons = async () => {
    setshowIcons(false);
  };

  return (
    <div
      className="search-engine-container"
      ref={searchEngineContainerRef}
      onMouseOver={() => setshowIcons(true)}
      onMouseLeave={() => {
        hideIcons();
      }}
      key={'multiple-search-engine-' + props.index + '-container'}
    >
      <div
        className={`container-search 
        ${
          !searchEngine.searchEngineLine &&
          searchEngine.searchEngineRectangle &&
          'rectangle-search-engine'
        }
      ${!searchEngine.searchEngineLine && searchEngine.searchEngineBorder && 'border-search-engine'}
      ${searchEngine.searchEngineLine && 'border-bottom-search-engine'}
      `}
        style={
          props.display || props.opacity === 0
            ? { display: 'none' }
            : inputSearchActive
            ? {
                opacity: 1,
                background: `${!searchEngine.searchEngineLine && '#fff'}`,
                width: searchEngine.searchEnginSize + 'vw',
                maxWidth: maxWidth + 'vw'
              }
            : {
                background: `${
                  searchEngine.searchEngineLine
                    ? 'transparent'
                    : 'rgb(255, 255, 255,' + props.opacity + ')'
                }`,
                width: searchEngine.searchEnginSize + 'vw',
                maxWidth: maxWidth + 'vw'
              }
        }
      >
        <div className="icon-search-container">
          <Search
            onClick={() =>
              window.open(
                `${searchEngine.activeSearchEngine.url}?${searchEngine.activeSearchEngine.searchKey}=${refInputSearch.current.value}`,
                `${searchEngine.searchEngineOpenNewTab ? '_blank' : '_self'}`
              )
            }
            style={{
              color: `${searchEngine.searchEngineLine ? 'white' : '#686464'}`,
              cursor: 'pointer'
            }}
            fontSize="medium"
          />
        </div>
        <form
          className="form-search-google"
          action={searchEngine.activeSearchEngine.url}
          target={searchEngine.searchEngineOpenNewTab ? '_blank' : '_self'}
          type="GET"
          id="form"
        >
          <input
            // type="search"
            id="input"
            style={{
              color: searchEngine.searchEngineLine ? 'white' : 'black',
              textShadow: searchEngine.searchEngineLine
                ? '1.5px 1.5px 1px gray, 0 0 0.05em whitesmoke, 0 0 0.1em whitesmoke'
                : 'none'
            }}
            type="search"
            autoComplete="off"
            spellCheck="true"
            ref={refInputSearch}
            name={searchEngine.activeSearchEngine.searchKey}
            onFocus={() => setinputSearchActive(true)}
            onBlur={() => setinputSearchActive(false)}
            className="searchTerm"
            onChange={(event) => setvalueSearch(event.target.value)}
          ></input>
        </form>

        <OverlayTrigger
          trigger="click"
          key="OverlayTrigger-browser"
          placement={
            clockState.analogClock
              ? width < 1000 || searchEngine.searchEngineSizeValue > 60
                ? 'left'
                : 'right'
              : 'bottom'
          }
          rootClose={true}
          onHide={(event) => {
            hideIcons();
          }}
          onEnter={(event) => {}}
          overlay={
            <Popover
              id={`popover-browser`}
              className={
                clockState.analogClock
                  ? width < 1000 || searchEngine.searchEngineSizeValue > 60
                    ? 'search-engines-popover popover-meavana-arrow-left'
                    : 'search-engines-popover popover-meavana-arrow-right'
                  : 'search-engines-popover popover-meavana-arrow-bottom'
              }
            >
              <Popover.Body className="search-engines-popover-body">
                <div className="container-search-engines">
                  {SearchEngineList.map((engine, index) => {
                    return (
                      <div
                        className={
                          searchEngine.activeSearchEngine.name === engine.name
                            ? 'search-engine-item-active'
                            : 'search-engine-item'
                        }
                        key={'item-' + index}
                      >
                        <div
                          className="container-search-engine-img"
                          onClick={() => {
                            document.body.click();
                            dispatch(updateActiveSecondarySearchEngineAction(engine, props.index));
                            if (refInputSearch.current) {
                              refInputSearch.current.focus();
                            }
                          }}
                          key={index}
                        >
                          <img
                            src={GetImageUrl(engine.icons.color)}
                            className="search-engine-icon"
                            alt={'search engine icon ' + engine.name + index}
                          ></img>
                          {engine.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div className="search-engine-active-icon-container">
            <img
              src={
                searchEngine.searchEnginColored
                  ? GetImageUrl(searchEngine.activeSearchEngine.icons.color)
                  : GetImageUrl(searchEngine.activeSearchEngine.icons.gray)
              }
              className="search-engine-active-icon"
              alt="active search engine"
            ></img>
            <KeyboardArrowDown
              tyle={{
                color: `${searchEngine.searchEngineLine ? 'white' : '#686464'}`
              }}
              fontSize="small"
            />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          trigger="click"
          key="OverlayTrigger-search-engine-settings"
          placement={width < 1000 || searchEngineSizeValue > 60 ? 'bottom' : 'right'}
          rootClose={true}
          onExit={(event) => {
            setsearchEngineSizeValue(searchEngine.searchEnginSize);
            meaVanaConsole('update setsearchEngineSizeValue');
          }}
          onHide={(event) => {
            hideIcons();
            setsearchEngineSizeValue(searchEngine.searchEnginSize);
            meaVanaConsole('update setsearchEngineSizeValue');
          }}
          overlay={
            <Popover
              id={`popover-search-engine-settings`}
              className={
                width < 1000 || searchEngineSizeValue > 60
                  ? 'popover-search-engine-setting popover-meavana-arrow-bottom'
                  : 'popover-search-engine-setting popover-meavana-arrow-right'
              }
            >
              <Popover.Body className="popover-search-engine-setting-body">
                <div
                  style={{
                    opacity: `${searchEngine.searchEngineLine ? 0.7 : 1}`
                  }}
                  className="popover-search-engine-setting-item"
                >
                  Rectangle
                  <Switch
                    disabled={searchEngine.searchEngineLine}
                    checked={searchEngine.searchEngineRectangle}
                    onChange={(event) => {
                      dispatch(
                        updateSecondarySearchEngineRectangleAction(
                          event.target.checked,
                          props.index
                        )
                      );
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div
                  style={{
                    opacity: `${searchEngine.searchEngineLine ? 0.7 : 1}`
                  }}
                  className="popover-search-engine-setting-item"
                >
                  Border
                  <Switch
                    checked={searchEngine.searchEngineBorder}
                    disabled={searchEngine.searchEngineLine}
                    onChange={(event) => {
                      dispatch(
                        updateSecondarySearchEngineBorderAction(event.target.checked, props.index)
                      );
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Line
                  <Switch
                    checked={searchEngine.searchEngineLine}
                    onChange={(event) => {
                      dispatch(
                        updateSecondarySearchEngineLineAction(event.target.checked, props.index)
                      );
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Color
                  <Switch
                    checked={searchEngine.searchEnginColored}
                    onChange={(event) => {
                      dispatch(
                        updateSecondarySearchEngineCloredAction(event.target.checked, props.index)
                      );
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Open new Tab
                  <Switch
                    checked={searchEngine.searchEngineOpenNewTab}
                    onChange={(event) => {
                      dispatch(
                        updateSecondarySearchEngineOpenNewTabAction(
                          event.target.checked,
                          props.index
                        )
                      );
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Multiple Search
                  <Switch
                    checked={searchEngineState.showMultipleSeachEngine}
                    onChange={(event) => {
                      dispatch(updateShowMultipleSearchEngineAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  <span style={{ marginRight: '20px' }}>Size</span>
                  <Slider
                    size="medium"
                    min={10}
                    max={maxWidth}
                    value={searchEngine.searchEnginSize}
                    onChange={(event) => {
                      dispatch(
                        updateSecondarySearchEngineSizeAction(event.target.value, props.index)
                      );
                      dispatch(resetHomeThemeAction());
                    }}
                    aria-label="Small"
                    valueLabelDisplay="off"
                  />
                </div>

                <Button
                  className="bookmark-btn-delete-all"
                  variant="contained"
                  color="info"
                  size="small"
                  style={{ margin: '0.5rem' }}
                  onClick={() => {
                    dispatch(deleteSecondarySearchEngineAction(props.index));
                    document.body.click();
                  }}
                >
                  Delete
                </Button>
              </Popover.Body>
            </Popover>
          }
        >
          <IconButton
            className="search-engine-setting-icon"
            aria-label="search-engine-Settings"
            style={showIcons ? { color: '#fff', opacity: 1 } : { opacity: 0 }}
            disabled={!showIcons}
          >
            <MoreHoriz className="home-icon-setting-font-size" />
          </IconButton>
        </OverlayTrigger>
      </div>
    </div>
  );
}
