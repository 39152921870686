/*global chrome*/

import { FacebookLoginAuthServer } from "../../server/Auth/auth.server";
import { checkErrorResponseFormat } from "../../server/Middleware/utils.server";
import { loginSSOFacebookFailureAction } from "../../store/reducers/authReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

const FACBOOK_CLIENT_ID = encodeURIComponent(427102462256460);
const FACBOOK_RESPONSE_TYPE = encodeURIComponent("token");

//const FACBOOK_REDIRECT_URI = chrome.identity.getRedirectURL();
// This redirect URI is something like https://<extension-id>.chromeapp.org

const FACBOOK_STATE = encodeURIComponent("jsksf3");
const FACBOOK_SCOPE = encodeURIComponent("public_profile email");

function Facebook_create_auth_endpoint(FACBOOK_REDIRECT_URI) {
  let openId_endpoint_url = `https://www.facebook.com/v13.0/dialog/oauth
?client_id=${FACBOOK_CLIENT_ID}
&response_type=${FACBOOK_RESPONSE_TYPE}
&redirect_uri=${FACBOOK_REDIRECT_URI}
&scope=${FACBOOK_SCOPE}
&state=${FACBOOK_STATE}`;

  meaVanaConsole("openId_endpoint_url", openId_endpoint_url);
  return openId_endpoint_url;
}

export const FacebookChromeAuth = (deviceId) => {
  return async (dispatch) => {
    try {
      meaVanaConsole("start FacebookChromeAuth");
      if (typeof chrome != "undefined") {
        chrome.identity.launchWebAuthFlow(
          {
            url: Facebook_create_auth_endpoint(
              chrome.identity.getRedirectURL()
            ),
            interactive: true
          },
          function (redirect_url) {
            meaVanaConsole("Facebook redirect_url ", redirect_url);
            if (redirect_url && redirect_url?.includes("access_token")) {
              let access_token = redirect_url.substring(
                redirect_url.indexOf("access_token=") + 13
              );
              access_token = access_token.substring(
                0,
                access_token.indexOf("&")
              );
              meaVanaConsole("Facebook id_token", access_token);
              meaVanaConsole("Facebook sso success");
              let user = {
                tokenId: access_token,
                deviceId: deviceId
              };
              dispatch(FacebookLoginAuthServer(user));
            } else {
              meaVanaConsole("Facebook sso error in redirect url");
              let response = {
                result: "error",
                message: "error in redirect url"
              };
              dispatch(loginSSOFacebookFailureAction(response));
            }
          }
        );
      } else {
        meaVanaConsole("chrome undefined");
      }
    } catch (error) {
      meaVanaConsole(
        "FacebookChromeAuth catch err",
        checkErrorResponseFormat(error)
      );
      dispatch(loginSSOFacebookFailureAction(checkErrorResponseFormat(error)));
    }
  };
};
