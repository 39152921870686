import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateAmPmAction,
  updateAnalogClockSizeAction,
  updateShowAnalogClockAction,
  updateTimeAnalogClockAction,
  updateTimeFontSizeAction,
  updateTimeFormatAction,
  updateTimeSecondAction,
  updateTimeThemeModeAction
} from "../../../store/reducers/clockReducer/action";
import { resetHomeThemeAction } from "../../../store/reducers/homeReducer/action";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function UpdateClock(clock) {
  return async (dispatch) => {
    try {
      const message = {
        isDark: "",
        isNumbered: "",
        kind: "",
        showAmPm: "",
        showSeconds: "",
        showTwentyFour: "",
        size: ""
      };
      if (clock) {
        if ("showSeconds" in clock) {
          dispatch(updateTimeSecondAction(clock.showSeconds));
          message.blackAndWhite = "updated";
        }

        if ("kind" in clock) {
          switch (clock.kind) {
            case "AnalogClockStyle":
              if ("size" in clock) {
                if (clock.size > 40) {
                  dispatch(updateAnalogClockSizeAction(400));
                } else if (clock.size < 15) {
                  dispatch(updateAnalogClockSizeAction(150));
                } else {
                  dispatch(updateAnalogClockSizeAction(clock.size * 10));
                }
                message.size = "updated ,value=" + clock.size;
              }
              if ("isNumbered" in clock) {
                if (clock.isNumbered) {
                  dispatch(updateTimeAnalogClockAction("normal"));
                } else {
                  dispatch(updateTimeAnalogClockAction("minimal"));
                }
                message.isNumbered = "updated ,value=" + clock.isNumbered;
              }
              if ("isDark" in clock) {
                if (clock.isDark) {
                  dispatch(updateTimeThemeModeAction("dark"));
                } else {
                  dispatch(updateTimeThemeModeAction("light"));
                }
                message.isDark = "updated ,value=" + clock.isDark;
              }
              dispatch(updateShowAnalogClockAction(true));
              message.kind = "updated ,value=Analog";
              break;
            case "DigitalClockStyle":
              if ("size" in clock) {
                if (clock.size > 100) {
                  dispatch(updateTimeFontSizeAction(150));
                } else {
                  dispatch(updateTimeFontSizeAction(clock.size + 50));
                }

                message.size = "updated ,value=" + clock.size;
              }
              if ("showAmPm" in clock) {
                dispatch(updateAmPmAction(clock.showAmPm));
                message.showAmPm = "updated ,value=" + clock.showAmPm;
              }
              if ("showTwentyFour" in clock) {
                dispatch(updateTimeFormatAction(clock.showTwentyFour));
                dispatch(updateAmPmAction(!clock.showTwentyFour));
                message.showTwentyFour =
                  "updated ,value=" + clock.showTwentyFour;
              }
              dispatch(updateShowAnalogClockAction(false));

              message.kind = "updated ,value=Digital";
              break;

            default:
              break;
          }
        }
        dispatch(resetHomeThemeAction());
        meaVanaConsole("UpdateClock :", message);
      }
    } catch (error) {
      meaVanaConsole("UpdateClock error:", error);
    }
  };
}
