import { newsStateAction } from "./constants";

export const updateNewsWindowStateAction = (index = null) => {
  return (dispatch) => {
    dispatch({
      type: newsStateAction.UPDATE_NEWS_WINDOW_STATE,
      payload: { index }
    });
  };
};

export const updateNewsPinAction = (index = null, pin = false) => {
  return (dispatch) => {
    dispatch({
      type: newsStateAction.UPDATE_NEWS_PIN,
      payload: { index, pin }
    });
  };
};

export const getNewsDataRequestAction = (index) => {
  return (dispatch) => {
    dispatch({
      type: newsStateAction.GET_NEWS_API_REQUEST,
      payload: { index }
    });
  };
};

export const getNewsDataSuccessAction = (index, data) => {
  return (dispatch) => {
    dispatch({
      type: newsStateAction.GET_NEWS_API_SUCCESS,
      payload: { index, data }
    });
  };
};

export const getNewsDataFailureAction = (index, error) => {
  return (dispatch) => {
    dispatch({
      type: newsStateAction.GET_NEWS_API_FAILURE,
      payload: { index, error }
    });
  };
};
