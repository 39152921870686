import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { appStateAction } from '../appReducer/constants';
import { initialState, musicstateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { integrationStateAction } from '../integrationsReducer/constants';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const musicPersistConfig = {
  key: 'music',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING music :', error)
};
export const musicPersisted = persistReducer(musicPersistConfig, musicReducer);

export default function musicReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case integrationStateAction.RESET_INTEGRATION_PIN_STATE:
      return {
        ...state,
        musicapiSource: state.musicapiSource.map((music) => {
          return { ...music, pined: false };
        })
      };

    case musicstateAction.UPDATE_MUSIC_WINDOW_STATE:
      if (action.payload == null) {
        return {
          ...state,
          activeMusicId: null,
          musicapiSource: state.musicapiSource.map((music) => {
            return { ...music, active: false };
          })
        };
      } else {
        return {
          ...state,
          activeMusicId: action.payload,
          musicapiSource: state.musicapiSource.map((music) => {
            if (action.payload === music.id) {
              return { ...music, active: true };
            }
            return { ...music, active: false };
          })
        };
      }

    case musicstateAction.UPDATE_MUSIC_PIN:
      if (action.payload.musicId == null) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          musicapiSource: state.musicapiSource.map((music) => {
            if (action.payload.musicId === music.id) {
              return { ...music, pined: action.payload.pin };
            }
            return { ...music };
          })
        };
      }

    case musicstateAction.SET_MUSIC_ACCESS_TOKEN:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, accessToken: action.payload.accessToken };
          }
          return music;
        })
      };

    case musicstateAction.SET_MUSIC_REFRESH_TOKEN:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, refreshToken: action.payload.refreshToken };
          }
          return music;
        })
      };

    case musicstateAction.SET_MUSIC_EXPIRES_IN:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, expiresIn: action.payload.expiresIn };
          }
          return music;
        })
      };

    case musicstateAction.SET_MUSIC_CODE_TOKEN:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, code: action.payload.code };
          }
          return music;
        })
      };

    case musicstateAction.SET_MUSIC_IS_PLAYING:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, isPlaying: action.payload.isPlaying };
          }
          return music;
        })
      };

    case musicstateAction.SET_MUSIC_CURRENT_TRACK_ID:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, currentTrackId: action.payload.currentTrackId };
          }
          return music;
        })
      };

    case musicstateAction.TOGGLE_MUSIC_TRACK_REPEAT_MODE:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, repeatMode: action.payload.repeatMode };
          }
          return music;
        })
      };

    case musicstateAction.TOGGLE_MUSIC_TRACK_SHUFFLE_MODE:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, shuffleMode: action.payload.shuffleMode };
          }
          return music;
        })
      };

    case musicstateAction.GET_MUSIC_API_REQUEST:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return { ...music, status: 'loading' };
          }
          return music;
        })
      };

    case musicstateAction.GET_MUSIC_API_SUCESS:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return {
              ...music,
              data: action.payload.data,
              loaded: true,
              status: 'success',
              lastUpdate: new Date()
            };
          }
          return music;
        })
      };

    case musicstateAction.GET_MUSIC_API_FAILURE:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          if (action.payload.musicId === music.id) {
            return {
              ...music,
              loaded: false,
              status: 'error',
              error: action.payload.error
            };
          }
          return music;
        })
      };

    case musicstateAction.GET_MUSIC_API_FAILURE_MESSAGE:
      return {
        ...state,

        musicapiSource: state.musicapiSource.map((music) => {
          meaVanaConsole('action.payload.errorMessage >>> ', action.payload.errorMessage);
          if (action.payload.musicId === music.id) {
            return {
              ...music,
              loaded: false,
              status: 'error',
              errorMessage: action.payload.errorMessage
            };
          }
          return music;
        })
      };

    default:
      return state;
  }
}
