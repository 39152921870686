export const WeatherIconsMap = [
  {
    id: 200,
    blackIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-black.png",
      night: "/assets/icons/weather/rain-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-white.png",
      night: "/assets/icons/weather/rain-thunderstorm-white.png"
    }
  },
  {
    id: 201,
    blackIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-black.png",
      night: "/assets/icons/weather/rain-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-white.png",
      night: "/assets/icons/weather/rain-thunderstorm-white.png"
    }
  },
  {
    id: 202,
    blackIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-black.png",
      night: "/assets/icons/weather/rain-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-white.png",
      night: "/assets/icons/weather/rain-thunderstorm-white.png"
    }
  },
  {
    id: 230,
    blackIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-black.png",
      night: "/assets/icons/weather/rain-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-white.png",
      night: "/assets/icons/weather/rain-thunderstorm-white.png"
    }
  },
  {
    id: 231,
    blackIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-black.png",
      night: "/assets/icons/weather/rain-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-white.png",
      night: "/assets/icons/weather/rain-thunderstorm-white.png"
    }
  },
  {
    id: 232,
    blackIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-black.png",
      night: "/assets/icons/weather/rain-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/rain-thunderstorm-white.png",
      night: "/assets/icons/weather/rain-thunderstorm-white.png"
    }
  },
  {
    id: 210,
    blackIcon: {
      day: "/assets/icons/weather/thunderstorm-black.png",
      night: "/assets/icons/weather/thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/thunderstorm-white.png",
      night: "/assets/icons/weather/thunderstorm-white.png"
    }
  },
  {
    id: 211,
    blackIcon: {
      day: "/assets/icons/weather/thunderstorm-black.png",
      night: "/assets/icons/weather/thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/thunderstorm-white.png",
      night: "/assets/icons/weather/thunderstorm-white.png"
    }
  },
  {
    id: 212,
    blackIcon: {
      day: "/assets/icons/weather/heavy-thunderstorm-black.png",
      night: "/assets/icons/weather/heavy-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-thunderstorm-white.png",
      night: "/assets/icons/weather/heavy-thunderstorm-white.png"
    }
  },
  {
    id: 221,
    blackIcon: {
      day: "/assets/icons/weather/heavy-thunderstorm-black.png",
      night: "/assets/icons/weather/heavy-thunderstorm-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-thunderstorm-white.png",
      night: "/assets/icons/weather/heavy-thunderstorm-white.png"
    }
  },
  {
    id: 300,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 301,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 302,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 310,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 311,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 312,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 313,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 314,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 321,
    blackIcon: {
      day: "/assets/icons/weather/drizzle-black.png",
      night: "/assets/icons/weather/drizzle-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/drizzle-white.png",
      night: "/assets/icons/weather/drizzle-white.png"
    }
  },
  {
    id: 500,
    blackIcon: {
      day: "/assets/icons/weather/light-rain-black.png",
      night: "/assets/icons/weather/light-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/light-rain-white.png",
      night: "/assets/icons/weather/light-rain-white.png"
    }
  },
  {
    id: 520,
    blackIcon: {
      day: "/assets/icons/weather/light-rain-black.png",
      night: "/assets/icons/weather/light-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/light-rain-white.png",
      night: "/assets/icons/weather/light-rain-white.png"
    }
  },
  {
    id: 501,
    blackIcon: {
      day: "/assets/icons/weather/moderate-rain-black.png",
      night: "/assets/icons/weather/moderate-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/moderate-rain-white.png",
      night: "/assets/icons/weather/moderate-rain-white.png"
    }
  },
  {
    id: 521,
    blackIcon: {
      day: "/assets/icons/weather/moderate-rain-black.png",
      night: "/assets/icons/weather/moderate-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/moderate-rain-white.png",
      night: "/assets/icons/weather/moderate-rain-white.png"
    }
  },
  {
    id: 511,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/freezing-rain-white.png",
      night: "/assets/icons/weather/freezing-rain-white.png"
    }
  },
  {
    id: 502,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-rain-white.png",
      night: "/assets/icons/weather/heavy-rain-white.png"
    }
  },
  {
    id: 503,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-rain-white.png",
      night: "/assets/icons/weather/heavy-rain-white.png"
    }
  },
  {
    id: 504,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-rain-white.png",
      night: "/assets/icons/weather/heavy-rain-white.png"
    }
  },
  {
    id: 522,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-rain-white.png",
      night: "/assets/icons/weather/heavy-rain-white.png"
    }
  },
  {
    id: 531,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-rain-white.png",
      night: "/assets/icons/weather/heavy-rain-white.png"
    }
  },
  {
    id: 600,
    blackIcon: {
      day: "/assets/icons/weather/light-snow-black.png",
      night: "/assets/icons/weather/light-snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/light-snow-white.png",
      night: "/assets/icons/weather/light-snow-white.png"
    }
  },
  {
    id: 620,
    blackIcon: {
      day: "/assets/icons/weather/light-snow-black.png",
      night: "/assets/icons/weather/light-snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/light-snow-white.png",
      night: "/assets/icons/weather/light-snow-white.png"
    }
  },
  {
    id: 601,
    blackIcon: {
      day: "/assets/icons/weather/snow-black.png",
      night: "/assets/icons/weather/snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/snow-white.png",
      night: "/assets/icons/weather/snow-white.png"
    }
  },
  {
    id: 621,
    blackIcon: {
      day: "/assets/icons/weather/snow-black.png",
      night: "/assets/icons/weather/snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/snow-white.png",
      night: "/assets/icons/weather/snow-white.png"
    }
  },
  {
    id: 613,
    blackIcon: {
      day: "/assets/icons/weather/snow-black.png",
      night: "/assets/icons/weather/snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/snow-white.png",
      night: "/assets/icons/weather/snow-white.png"
    }
  },
  {
    id: 611,
    blackIcon: {
      day: "/assets/icons/weather/snow-black.png",
      night: "/assets/icons/weather/snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/snow-white.png",
      night: "/assets/icons/weather/snow-white.png"
    }
  },
  {
    id: 612,
    blackIcon: {
      day: "/assets/icons/weather/snow-black.png",
      night: "/assets/icons/weather/snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/snow-white.png",
      night: "/assets/icons/weather/snow-white.png"
    }
  },
  {
    id: 602,
    blackIcon: {
      day: "/assets/icons/weather/heavy-snow-black.png",
      night: "/assets/icons/weather/heavy-snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-snow-white.png",
      night: "/assets/icons/weather/heavy-snow-white.png"
    }
  },
  {
    id: 622,
    blackIcon: {
      day: "/assets/icons/weather/heavy-snow-black.png",
      night: "/assets/icons/weather/heavy-snow-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/heavy-snow-white.png",
      night: "/assets/icons/weather/heavy-snow-white.png"
    }
  },
  {
    id: 615,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/freezing-rain-white.png",
      night: "/assets/icons/weather/freezing-rain-white.png"
    }
  },
  {
    id: 616,
    blackIcon: {
      day: "/assets/icons/weather/freezing-rain-black.png",
      night: "/assets/icons/weather/freezing-rain-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/freezing-rain-white.png",
      night: "/assets/icons/weather/freezing-rain-white.png"
    }
  },
  {
    id: 800,
    blackIcon: {
      day: "/assets/icons/weather/clear-sky-black-day.png",
      night: "/assets/icons/weather/clear-sky-black-night.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/clear-sky-white-day.png",
      night: "/assets/icons/weather/clear-sky-white-night.png"
    }
  },
  {
    id: 801,
    blackIcon: {
      day: "/assets/icons/weather/few-clouds-black-day.png",
      night: "/assets/icons/weather/few-clouds-black-night.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/few-clouds-white-day.png",
      night: "/assets/icons/weather/few-clouds-white-night.png"
    }
  },
  {
    id: 802,
    blackIcon: {
      day: "/assets/icons/weather/scattered-clouds-black.png",
      night: "/assets/icons/weather/scattered-clouds-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/scattered-clouds-white.png",
      night: "/assets/icons/weather/scattered-clouds-white.png"
    }
  },
  {
    id: 803,
    blackIcon: {
      day: "/assets/icons/weather/broken-clouds-black.png",
      night: "/assets/icons/weather/broken-clouds-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/broken-clouds-white.png",
      night: "/assets/icons/weather/broken-clouds-white.png"
    }
  },
  {
    id: 804,
    blackIcon: {
      day: "/assets/icons/weather/broken-clouds-black.png",
      night: "/assets/icons/weather/broken-clouds-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/broken-clouds-white.png",
      night: "/assets/icons/weather/broken-clouds-white.png"
    }
  },
  {
    id: 701,
    blackIcon: {
      day: "/assets/icons/weather/mist-black.png",
      night: "/assets/icons/weather/mist-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/mist-white.png",
      night: "/assets/icons/weather/mist-white.png"
    }
  },
  {
    id: 711,
    blackIcon: {
      day: "/assets/icons/weather/smoke-black.png",
      night: "/assets/icons/weather/smoke-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/smoke-white.png",
      night: "/assets/icons/weather/smoke-white.png"
    }
  },
  {
    id: 721,
    blackIcon: {
      day: "/assets/icons/weather/haze-black.png",
      night: "/assets/icons/weather/haze-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/haze-white.png",
      night: "/assets/icons/weather/haze-white.png"
    }
  },
  {
    id: 731,
    blackIcon: {
      day: "/assets/icons/weather/mist-black.png",
      night: "/assets/icons/weather/mist-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/mist-white.png",
      night: "/assets/icons/weather/mist-white.png"
    }
  },
  {
    id: 741,
    blackIcon: {
      day: "/assets/icons/weather/fog-black.png",
      night: "/assets/icons/weather/fog-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/fog-white.png",
      night: "/assets/icons/weather/fog-white.png"
    }
  },
  {
    id: 751,
    blackIcon: {
      day: "/assets/icons/weather/mist-black.png",
      night: "/assets/icons/weather/mist-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/mist-white.png",
      night: "/assets/icons/weather/mist-white.png"
    }
  },
  {
    id: 761,
    blackIcon: {
      day: "/assets/icons/weather/mist-black.png",
      night: "/assets/icons/weather/mist-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/mist-white.png",
      night: "/assets/icons/weather/mist-white.png"
    }
  },
  {
    id: 762,
    blackIcon: {
      day: "/assets/icons/weather/smoke-black.png",
      night: "/assets/icons/weather/smoke-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/smoke-white.png",
      night: "/assets/icons/weather/smoke-white.png"
    }
  },
  {
    id: 781,
    blackIcon: {
      day: "/assets/icons/weather/tornado-black.png",
      night: "/assets/icons/weather/tornado-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/tornado-white.png",
      night: "/assets/icons/weather/tornado-white.png"
    }
  },
  {
    id: 771,
    blackIcon: {
      day: "/assets/icons/weather/wind-black.png",
      night: "/assets/icons/weather/wind-black.png"
    },
    whiteIcon: {
      day: "/assets/icons/weather/wind-white.png",
      night: "/assets/icons/weather/wind-white.png"
    }
  }
];
