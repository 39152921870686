import { appStateAction } from '../appReducer/constants';
import { homeStateAction, initialState } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const homePersistConfig = {
  key: 'home',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING home :', error)
};
export const homePersisted = persistReducer(homePersistConfig, homeReducer);

export default function homeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case homeStateAction.RESET_HOME_THEME:
      return {
        ...state,
        homeTheme: ''
      };
    case homeStateAction.UPDATE_HOME_THEME:
      return {
        ...state,
        ...action.theme.home
      };

    case homeStateAction.UPDATE_HOME_SHOW_TIME:
      return {
        ...state,
        showTime: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_ALL_FEATURE:
      if (action.value > 0) {
        return {
          ...state,
          showAllFeature: action.value,
          showTime: action.value,
          showBackgroundInfo: action.value,
          showBookmark: action.value,
          showDate: action.value,
          showQuote: action.value,
          showWeather: action.value,
          showSearch: action.value,
          showNote: action.value,
          showIntegration: action.value,
          hideAllFeatures: false
        };
      } else {
        return {
          ...state,
          showAllFeature: action.value,
          hideAllFeatures: true
        };
      }

    case homeStateAction.UPDATE_HOME_SHOW_DATE:
      return {
        ...state,
        showDate: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_INTEGRATION:
      return {
        ...state,
        showIntegration: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_SEARCH:
      return {
        ...state,
        showSearch: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_QUOTE:
      return {
        ...state,
        showQuote: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_WEATHER:
      return {
        ...state,
        showWeather: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_BACKGROUND_INFO:
      return {
        ...state,
        showBackgroundInfo: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_BOOKMARK:
      return {
        ...state,
        showBookmark: action.value,
        showAllFeature: 1
      };
    case homeStateAction.UPDATE_HOME_SHOW_NOTE:
      return {
        ...state,
        showNote: action.value,
        showAllFeature: 1
      };

    case homeStateAction.HIDE_ALL_FEATURES:
      return {
        ...state,
        showAllFeature: action.payload ? 0 : 1,
        hideAllFeatures: action.payload
      };

    default:
      return state;
  }
}
