import { videostateAction } from "./constants";

export const updateVideosWindowStateAction = (videoId = null) => {
  return (dispatch) => {
    dispatch({
      type: videostateAction.UPDATE_VIDEO_WINDOW_STATE,
      payload: videoId
    });
  };
};

export const updateVideosPinAction = (videoId = null, pin = false) => {
  return (dispatch) => {
    dispatch({
      type: videostateAction.UPDATE_VIDEO_PIN,
      payload: { videoId: videoId, pin: pin }
    });
  };
};
