import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useRadioGroup } from "@mui/material/RadioGroup";

import FormControlLabel from "@mui/material/FormControlLabel";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiSvgIcon-fontSizeMedium": checked
    ? {
        color: "var(--meavana-green-color) !important"
      }
    : {
        color: "var(--popup-text-color) !important"
      },

  ".MuiFormControlLabel-label": checked
    ? {
        fontWeight: 700,
        fontSize: "1em !important",
        fontFamily: "var(--theme-font-family) !important"
      }
    : {
        fontWeight: 700,
        fontSize: "1em !important",
        fontFamily: "var(--theme-font-family)!important"
      },
  ".css-1hbvpl3-MuiSvgIcon-root": checked
    ? {
        //  color:'#2e7d32'
        color: "var(--popup-text-color) !important"
      }
    : {
        color: "var(--popup-text-color) !important"
      },
  ".css-q8lw68": checked
    ? {
        //  color:'#2e7d32'
        color: "var(--popup-text-color) !important"
      }
    : {
        color: "var(--popup-text-color) !important"
      },

  ".css-11zohuh-MuiSvgIcon-root": checked
    ? {
        color: "var(--meavana-green-color) !important"
      }
    : {}
}));

export default function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any
};
