import React from "react";
import IntegrationWindows from "../../features/IntegrationsFeature/IntegrationWindows";
import WallpaperWindow from "../../features/WallpaperFeature/WallpaperWindow";

export default function WindowContainer() {
  return (
    <>
      <IntegrationWindows />
      <WallpaperWindow />
    </>
  );
}
