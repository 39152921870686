import { CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lottie from "lottie-web/build/player/lottie_light";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";

import BackgroundMeaVana from "../../../containers/Background/BackgroundMeaVana";
import SpaceAnimation from "../../../containers/Background/animations/background-purple-space.json";
import HillsAnimation from "../../../containers/Background/animations/background-green-field.json";
import OceanAnimation from "../../../containers/Background/animations/background-blue-ocean.json";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

import "./AnimationsBackground.css";
import { updateAnimationBackgroundAction } from "../../../store/reducers/animReducer/action";
import { updateBackgroundModeAction } from "../../../store/reducers/appReducer/action";

export default function AnimationsBackground({ windowSize }) {
  const animationState = useSelector((state) => state.animation);
  const dispatch = useDispatch();

  let [searchTerm, setSearchTerm] = useState("");
  const [gridTemplateCulumnsWidth, setGridTemplateCulumnsWidth] = useState(
    "wallpaper-animations-background-grid-template-colums-400"
  );

  const getGridTemplateCulumnsWidth = (width) => {
    if (width >= 795) {
      setGridTemplateCulumnsWidth(
        "wallpaper-animations-background-grid-template-colums-800"
      );
    }
    if (width < 795 && width >= 595) {
      setGridTemplateCulumnsWidth(
        "wallpaper-animations-background-grid-template-colums-600"
      );
    }
    if (width < 595) {
      setGridTemplateCulumnsWidth(
        "wallpaper-animations-background-grid-template-colums-400"
      );
    }
  };

  useEffect(() => {
    getGridTemplateCulumnsWidth(windowSize.width);
  }, [windowSize]);

  const spaceAnimationRef = useRef(null);

  useEffect(() => {
    meaVanaConsole("start loading lotttie");
    lottie.loadAnimation({
      container: spaceAnimationRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: SpaceAnimation,
      rendererSettings: {
        scaleMode: "xMinYMin slice",
        preserveAspectRatio: "xMinYMin slice"
      }
    });

    return () => {
      lottie.destroy();
    };
  }, [animationState?.currentAnimation]);

  const hillsAnimationRef = useRef(null);

  useEffect(() => {
    meaVanaConsole("start loading lotttie");

    lottie.loadAnimation({
      container: hillsAnimationRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: HillsAnimation,
      rendererSettings: {
        scaleMode: "xMinYMin slice",
        preserveAspectRatio: "xMinYMin slice"
      }
    });

    return () => {
      lottie.destroy();
    };
  }, [animationState?.currentAnimation]);
  const oceanAnimationRef = useRef(null);

  useEffect(() => {
    meaVanaConsole("start loading lotttie");

    lottie.loadAnimation({
      container: oceanAnimationRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: OceanAnimation,
      rendererSettings: {
        scaleMode: "xMinYMin slice",
        preserveAspectRatio: "xMinYMin slice"
      }
    });

    return () => {
      lottie.destroy();
    };
  }, [animationState?.currentAnimation]);

  const getAnimationRef = (name) => {
    switch (name) {
      case "background-purple-space":
        return spaceAnimationRef;
      case "background-green-field":
        return hillsAnimationRef;
      case "background-blue-ocean":
        return oceanAnimationRef;
      default:
        return spaceAnimationRef;
    }
  };

  const updateAnimatedBackgroundMode = (event, newValue) => {
    event.preventDefault();
    meaVanaConsole("update animationbackground : ", newValue);
    dispatch(updateAnimationBackgroundAction(newValue));
    dispatch(updateBackgroundModeAction(newValue));
  };

  return (
    <div className="wallpaper-animations-background-container">
      <div className="header-settings">
        <span className="header-settings-title">Animations</span>
        <span className="header-settings-subtitle">Select your animation</span>
      </div>
      <div
        className={
          "wallpaper-animations-background-container-grid " +
          gridTemplateCulumnsWidth
        }
      >
        {animationState.animations.map((animation, index) => (
          <div
            className="wallpaper-animations-background-container-grid-item"
            key={"wallpaper-animations-background-" + animation.name}
            onClick={(event) => {
              // updateAnimatedBackgroundMode(event, animation.name);
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="wallpaper-animations-background-grid-item-inactive-msg-container">
              <span style={{ fontSize: "1em" }}>Coming soon</span>
            </div>

            <div
              className="wallpaper-animations-background-animation-container"
              ref={getAnimationRef(animation.name)}
            ></div>
            <div className="wallpaper-animations-background-action-container">
              <span>{animation.label}</span>
            </div>
            {/*animationState.currentAnimation &&
              animationState.currentAnimation.name === animation.name && (
                <div
                  className="clicked-topic-loading-container"
                  style={{ zIndex: 10 }}
                >
                  <IconButton
                    className="active-topic-icon-container"
                    aria-label="selectedTopic"
                  >
                    <CheckCircle fontSize="medium" />
                    <span
                      style={{
                        color: "var(--popup-text-color)",
                        fontSize: "1rem",
                        marginLeft: "0.3rem",
                      }}
                    >
                      Selected
                    </span>
                  </IconButton>
                </div>
              )*/}
          </div>
        ))}
      </div>
    </div>
  );
}
