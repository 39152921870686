import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import {
  createStateSyncMiddleware,
  initMessageListener,
  initStateWithPrevTab,
  withReduxStateSync
} from 'redux-state-sync';
import { PERSIST, PURGE } from 'redux-persist/es/constants';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { stateMigrations } from './stateMigrations';
import {
  DebugPersistConfig,
  DebugStoreMigrate,
  STORAGE_NAME_VERSION,
  STORE_VERSION
} from '../config/constants';

const initialState = {};

const persistConfig = {
  key: STORAGE_NAME_VERSION,
  storage,
  version: 12,
  serialize: true,
  blacklist: [
    'alarms',
    //'analytics',
    'animation',
    'app',
    'auth',
    'background',
    'bookmarks',
    'clock',
    'coincap',
    'crypto',
    'dashboard',
    'home',
    'integration',
    //'logs',
    'music',
    'news',
    'notes',
    'pictures',
    'searchEngine',
    'timer',
    'todos',
    'unsplash',
    'video',
    'wallpaper',
    'weather',
    'youtube',
    'google'
  ],
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING DATA WHEN UPDATING STORE', error)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
//const persistedReducer = persistReducer(persistConfig, withReduxStateSync(rootReducer));

const reduxStateSyncConfig = {
  blacklist: [PERSIST, PURGE]
};

const middleWare = [thunk, createStateSyncMiddleware(reduxStateSyncConfig)];

const store = createStore(
  // withReduxStateSync(rootReducer),
  persistedReducer,
  // rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
);

//initMessageListener(store);
initStateWithPrevTab(store);
let persistor = persistStore(store);
export { store, persistor };
