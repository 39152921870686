import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { init, send } from "emailjs-com";
import validator from "validator";

import {
  Emailjs_Service_ID,
  Emailjs_Template,
  Emailjs_User_ID
} from "../../../config/constants";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

init(Emailjs_User_ID);

export default function ContactSettingsGuestMode(props) {
  const [inputEmail, setinputEmail] = useState("");
  const [inputEmailTouched, setinputEmailTouched] = useState(false);
  const [emailErrorMsg, setemailErrorMsg] = useState({
    message: "",
    error: false
  });
  const verifEmailFormat = (email = "") => {
    setsubmitMessage({
      message: "",
      error: false,
      submit: false
    });
    if (validator.isEmpty(email, { ignore_whitespace: true })) {
      setemailErrorMsg({
        error: true,
        message: "Please enter your email address"
      });
    } else {
      if (!validator.isEmail(email)) {
        setemailErrorMsg({
          error: true,
          message: "Email must be a valid format"
        });
      } else if (email.indexOf(" ") >= 0) {
        setemailErrorMsg({
          error: true,
          message: "Email must not contain white spaces"
        });
      } else {
        setemailErrorMsg({ error: false, message: "" });
      }
    }
  };

  const [inputUsername, setinputUsername] = useState("");

  const [inputMessage, setinputMessage] = useState("");
  const [inputMessageTouched, setinputMessageTouched] = useState(false);
  const [messageErrorMsg, setMessageErrorMsg] = useState({
    message: "",
    error: false
  });
  const verifMessageFormat = (message = "") => {
    setsubmitMessage({
      message: "",
      error: false,
      submit: false
    });
    if (validator.isEmpty(message, { ignore_whitespace: true })) {
      setMessageErrorMsg({
        error: true,
        message: "Please enter a message"
      });
    } else {
      setMessageErrorMsg({ error: false, message: "" });
    }
  };

  const [inputType, setinputType] = useState("Feedback");

  const [loadingBtn, setloadingBtn] = useState(false);

  const [submitMessage, setsubmitMessage] = useState({
    message: "",
    error: false,
    submit: false
  });

  useEffect(() => {
    resetContactForm();
  }, []);

  const resetContactForm = () => {
    setloadingBtn(false);

    setinputMessage("");
    setinputMessageTouched(false);
    setMessageErrorMsg({ error: false, message: "" });

    setinputEmail("");
    setinputEmailTouched(false);
    setemailErrorMsg({ error: false, message: "" });

    setinputUsername("");

    setinputType(inputType);

    setsubmitMessage({
      message: "",
      error: false,
      submit: false
    });
  };

  const verifyContactForm = () => {
    verifEmailFormat(inputEmail);
    verifMessageFormat(inputMessage);
  };
  const sendEmail = async (e) => {
    e.preventDefault();
    verifyContactForm();
    if (!emailErrorMsg.error && !messageErrorMsg.error) {
      setloadingBtn(true);
      send(Emailjs_Service_ID, Emailjs_Template, {
        title: "Type: " + inputType,
        message: inputMessage,
        user_name: inputUsername,
        user_email: inputEmail
      })
        .then((response) => {
          resetContactForm();
          setsubmitMessage({
            message: "you message have been sent",
            error: false,
            submit: true
          });
          meaVanaConsole("send email SUCCESS! :", response);
        })
        .catch((err) => {
          setloadingBtn(false);
          setsubmitMessage({
            message: "Sorry, you message have not been sent",
            error: true,
            submit: true
          });
          meaVanaConsole("FAILED...", err);
        });
    } else {
      setsubmitMessage({
        message: "You need to fill the contact form to send a message",
        error: true,
        submit: true
      });
    }
  };

  return (
    <div className="settings-view">
      <div className="header-settings">
        <span className="header-settings-title">Contact</span>
        <span className="header-settings-subtitle">Connect with us</span>
      </div>
      <form onSubmit={sendEmail} className="container-contact-form">
        <div className="contact-form-group form-group">
          <label>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            autoComplete="username email"
            className={
              emailErrorMsg.error
                ? " contact-form-control form-control input-error"
                : "contact-form-control form-control"
            }
            placeholder="Enter email"
            value={inputEmail}
            onFocus={(e) => {
              setemailErrorMsg({ error: false, message: "" });
            }}
            onBlur={(event) => {
              verifEmailFormat(inputEmail);
            }}
            onChange={(event) => {
              setinputEmail(event.target.value);
              setinputEmailTouched(true);
            }}
          />
          <p className="error-msg-txt">{emailErrorMsg.message}</p>
        </div>
        <div className="contact-form-group form-group">
          <label>Name</label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control contact-form-control"
            placeholder="Enter your name"
            value={inputUsername}
            onChange={(event) => {
              setinputUsername(event.target.value);
            }}
          />
        </div>

        <div className="contact-form-group form-group">
          <label>Select Topic</label>
          <select
            type="select"
            id="Topic"
            value={inputType}
            onChange={(event) => {
              setinputType(event.target.value);
            }}
            className="contact-form-control select-arrow-contact form-control"
          >
            <option disabled>-- Select Topic --</option>
            <option value="Feedback">Feedback</option>
            <option value="Suggestions">Suggestions</option>
            <option value="Report a Bug">Report a Bug</option>
            <option value="Question">Question</option>
            <option value="Suggest a Topic">Suggest a Topic</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="contact-form-group">
          <label>Message</label>
          <textarea
            className={
              messageErrorMsg.error
                ? "contact-form-control form-control contact-form-control-message input-error"
                : "contact-form-control form-control contact-form-control-message"
            }
            type="textarea"
            placeholder="Enter message"
            id="Message"
            name="Message"
            value={inputMessage}
            onFocus={(e) => {
              setMessageErrorMsg({ error: false, message: "" });
            }}
            onBlur={(event) => {
              verifMessageFormat(inputMessage);
            }}
            onChange={(event) => {
              setinputMessage(event.target.value);
              setinputMessageTouched(true);
            }}
            aria-multiline={true}
          />
          <p className="error-msg-txt">{messageErrorMsg.message}</p>
        </div>
        <div className="contact-form-submit-area">
          <p></p>
          <Button
            className="contact-form-btn-send button-setting-popup"
            variant="contained"
            disabled={loadingBtn}
            onClick={sendEmail}
            color="info"
          >
            {loadingBtn ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#fff",
                  alignSelf: "center"
                }}
              />
            ) : (
              "Send"
            )}
          </Button>
        </div>
        {submitMessage.submit ? (
          submitMessage.error ? (
            <p
              className="error-msg-txt"
              style={{ textAlign: "center", marginBottom: "5px" }}
            >
              {submitMessage.message ? submitMessage.message : ""}
            </p>
          ) : (
            <p
              className="succes-msg-txt"
              style={{ textAlign: "center", marginBottom: "5px" }}
            >
              {submitMessage.message ? submitMessage.message : ""}
            </p>
          )
        ) : null}
      </form>
    </div>
  );
}
