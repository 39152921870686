import React, { useEffect, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { CircularProgress, IconButton, Switch } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getUnsplashSearchedResults } from '../../../server/Integration/pictures/unsplash.server';
import { updateActiveAnimationBackgroundAction } from '../../../store/reducers/animReducer/action';
import { updateBackgroundModeAction } from '../../../store/reducers/appReducer/action';
import {
  setShowSearchedPictures,
  setUnsplashSelectedPinctureAction,
  setUnsplashUseBackgroundAction
} from '../../../store/reducers/unsplashReducer/action';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import './UnsplashSearchContainer.css';
import { updateBackgroundIndexDBStateAction } from '../../../store/reducers/backgroundReducer/action';
export default function UnsplashSearchContainer({ windowSize }) {
  let [searchTerm, setSearchTerm] = useState('');
  const [gridTemplateCulumnsWidth, setGridTemplateCulumnsWidth] = useState(
    'wallpaper-background-unsplash-grid-template-colums-400'
  );

  const getGridTemplateCulumnsWidth = (width) => {
    if (width >= 795) {
      setGridTemplateCulumnsWidth('wallpaper-background-unsplash-grid-template-colums-800');
    }
    if (width < 795 && width >= 595) {
      setGridTemplateCulumnsWidth('wallpaper-background-unsplash-grid-template-colums-600');
    }
    if (width < 595) {
      setGridTemplateCulumnsWidth('wallpaper-background-unsplash-grid-template-colums-400');
    }
  };

  useEffect(() => {
    getGridTemplateCulumnsWidth(windowSize.width);
  }, [windowSize]);

  const unsplashState = useSelector((state) => state.unsplash);
  const dispatch = useDispatch();
  //handler for when a picture is clicked from list(will have other operations download etc)
  const handleOnClickSearchedPicture = (e, picture) => {
    meaVanaConsole('update bakground picture :', picture);
    dispatch(setUnsplashSelectedPinctureAction(picture));
    dispatch(updateBackgroundModeAction('background-home-picture'));
    dispatch(updateActiveAnimationBackgroundAction(false));
    dispatch(updateBackgroundIndexDBStateAction(false));
    // dispatch(setUnsplashUseBackgroundAction(true));
  };

  //handler for search button
  const handleOnClickSearchBtn = (e) => {
    e.preventDefault();
    if (searchTerm !== '') {
      dispatch(getUnsplashSearchedResults(searchTerm, 1, 30));
      //show the searched picture list
      dispatch(setShowSearchedPictures(true));
    }
  };
  return (
    <div className="wallpaper-unsplash-container">
      <div className="unsplash-search-container md-5 py-2">
        <form onSubmit={handleOnClickSearchBtn}>
          <FormControl
            placeholder="Enter keyword"
            aria-label="image"
            aria-describedby="basic-addon1"
            id="searchbox"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <Button
            variant="outline-secondary"
            type="submit"
            id="button-addon2"
            onClick={handleOnClickSearchBtn}
          >
            Search
          </Button>
        </form>

        <div className="unsplash-switch-container">
          Use Selected Picture
          <Switch
            checked={unsplashState.useUnspashForBackground}
            onChange={(event) => {
              dispatch(setUnsplashUseBackgroundAction(event.target.checked));
              dispatch(updateBackgroundIndexDBStateAction(false));
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            color="success"
          />
        </div>
      </div>
      <div className={'wallpaper-background-unsplash-image-grid ' + gridTemplateCulumnsWidth}>
        {unsplashState.pictures.map((picture, index) => (
          <div
            className="wallpaper-background-unsplash-image-grid-item"
            key={'card-unsplash-' + index}
            onClick={(event) => {
              if (!unsplashState.loadingUnspashBackground) {
                handleOnClickSearchedPicture(event, picture);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <img
              className="wallpaper-background-unsplash-image-grid-item-img"
              src={picture.urls.regular}
              alt={picture.alt_description}
              loading="lazy"
            />

            {unsplashState.useUnspashForBackground &&
              unsplashState.selectedPicture &&
              unsplashState.selectedPicture.id === picture.id && (
                <div className="clicked-topic-loading-container" style={{ zIndex: 10 }}>
                  <IconButton className="active-topic-icon-container" aria-label="selectedTopic">
                    <CheckCircle fontSize="medium" />
                    <span
                      style={{
                        color: 'var(--popup-text-color)',
                        fontSize: '1rem',
                        marginLeft: '0.3rem'
                      }}
                    >
                      Selected
                    </span>
                  </IconButton>
                </div>
              )}
            <div className="wallpaper-background-unsplash-action-container">
              <span>Click to Select Picture</span>
            </div>
            {unsplashState.loadingUnspashBackground &&
              unsplashState.selectedPicture.id === picture.id && (
                <div className="active-picture-unsplash-action-container">
                  <CircularProgress
                    size={30}
                    sx={{
                      color: '#fff',
                      alignSelf: 'center'
                    }}
                  />
                </div>
              )}
          </div>
        ))}
      </div>
      <div className="wallpaper-background-unsplash-poweredby-container ">
        <span>Powered by Unsplash</span>
      </div>
    </div>
  );
}
