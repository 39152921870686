export const preloadImageToHead = (url) => {
  const preloadLink = document.createElement("link");
  preloadLink.href = url;
  preloadLink.rel = "preload";
  preloadLink.as = "image";
  document.head.appendChild(preloadLink);
};

export const preloadBackgroundsToCache = (backgrounds = []) => {
  for (let i = 0; i < backgrounds.length; i++) {
    if (backgrounds[i].url) {
      preloadImageToHead(backgrounds[i].url);
    }
  }
};
