import { appStateAction } from '../appReducer/constants';
import { bookmarkStateAction, initialState, params } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const bookmarkPersistConfig = {
  key: 'bookmark',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING bookmark :', error)
};
export const bookmarkPersisted = persistReducer(bookmarkPersistConfig, bookmarkReducer);

export default function bookmarkReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case bookmarkStateAction.DELETE_ALL_BOOKMARKS_REQUEST:
      return {
        ...state,
        loadingDeleteAllBookmarks: true,
        errorDeleteAllBookmarks: null
      };

    case bookmarkStateAction.SET_DIALOG_DELETE_ALL_BOOKMARKS_VALUE:
      return {
        ...state,
        openDialogDeleteAllBookmark: action.value
      };
    case bookmarkStateAction.DELETE_ALL_BOOKMARKS_FAILURE:
      return {
        ...state,
        loadingDeleteAllBookmarks: false,
        errorDeleteAllBookmarks: action.error
      };
    case bookmarkStateAction.DELETE_ALL_BOOKMARKS_SUCCESS:
      return {
        ...state,
        loadingDeleteAllBookmarks: false,
        errorDeleteAllBookmarks: null,
        responseDeleteAllBookmarks: action.payload,
        openDialogDeleteAllBookmark: false,
        bookmarks: []
      };

    case bookmarkStateAction.GET_DASHBOARD_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.payload
      };
    case bookmarkStateAction.SET_BOOKMARK_NEW_WINDOW:
      return {
        ...state,
        openBookmarkInNewWindow: action.payload
      };
    case bookmarkStateAction.SET_BOOKMARK_POSITION_TOP:
      return {
        ...state,
        positionBOokmarksTop: action.payload
      };
    case bookmarkStateAction.GET_CHROME_BOOKMARKS:
      return {
        ...state,
        chromeBoomkmarks: action.payload
      };
    case bookmarkStateAction.GET_TOP_VISITED_SITES:
      return {
        ...state,
        topVisitedSites: action.payload
      };
    case bookmarkStateAction.UPDATE_CHROME_BOOKMARKS_TAB:
      return {
        ...state,
        chromeBoomkmarks: action.payload
      };

    case bookmarkStateAction.ADD_BOOKMARK_SUCCESS:
      return {
        ...state,
        bookmarks: [...state.bookmarks, action.payload],
        addBoomarkLoading: false,
        errorAddBoomark: null
      };
    case bookmarkStateAction.ADD_BOOKMARK_REQUEST:
      return {
        ...state,
        addBoomarkLoading: true,
        errorAddBoomark: null
      };
    case bookmarkStateAction.ADD_BOOKMARK_FAILURE:
      return {
        ...state,
        addBoomarkLoading: false,
        errorAddBoomark: action.error
      };
    case bookmarkStateAction.UPDATE_BOOKMARK_SUCCESS:
      let updatedBookmarks = state.bookmarks.map((bookmark) => {
        //update the bookmark with new values if the id matches during iteration
        if (bookmark._id === action.payload._id) {
          bookmark.name = action.payload.name;
          bookmark.url = action.payload.url;
        }
        return bookmark;
      });
      return {
        ...state,
        bookmarks: updatedBookmarks
      };
    case bookmarkStateAction.UPDATE_BOOKMARK_REQUEST:
      return {
        ...state,
        bookmarkToUpdate: action.payload.bookmark
      };
    case bookmarkStateAction.UPDATE_BOOKMARK_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case bookmarkStateAction.UPDATE_BOOKMARK_BULK_LIST_FAILURE:
      return {
        ...state,
        errorUpdateBulkListBookmarks: action.error,
        loadingUpdateBulkListBookmarks: false
      };
    case bookmarkStateAction.UPDATE_BOOKMARK_BULK_LIST_REQUEST:
      return {
        ...state,
        errorUpdateBulkListBookmarks: null,
        loadingUpdateBulkListBookmarks: true,
        responseUpdateBulkListBookmarks: null,
        listUpdateBulkListBookmarks: action.payload,
        bookmarks: action.payload
      };
    case bookmarkStateAction.UPDATE_BOOKMARK_BULK_LIST_SUCCESS:
      return {
        ...state,
        errorUpdateBulkListBookmarks: null,
        loadingUpdateBulkListBookmarks: false,
        responseUpdateBulkListBookmarks: action.payload
      };
    case bookmarkStateAction.DELETE_BOOKMARK_SUCCESS:
      return {
        ...state,
        /*  bookmarks: state.bookmarks.filter(
          (bookmark) => bookmark._id !== action.payload.bookmarkId
        ),*/
        deleteBoomarkLOading: false
      };
    case bookmarkStateAction.DELETE_BOOKMARK_REQUEST:
      return {
        ...state,
        deleteBoomarkLOading: true,
        errorDeleteBoomark: null,
        bookmarks: state.bookmarks.filter(
          (bookmark) => bookmark._id !== action.payload.bookmark._id
        )
      };
    case bookmarkStateAction.DELETE_BOOKMARK_FAILURE:
      return {
        ...state,
        errorDeleteBoomark: action.error,
        deleteBoomarkLOading: false
      };
    case bookmarkStateAction.TOGGLE_BOOKMARK_UPDATE_DIALOG:
      return {
        ...state,
        showUpdateDialog: action.payload
      };
    case bookmarkStateAction.SET_SELECTED_BOOKMARK:
      return {
        ...state,
        selectedBookmark: action.payload
      };
    case bookmarkStateAction.UPDATE_BOOKMARK_LIST:
      return {
        ...state,
        bookmarks: action.payload.bookmarks
      };
    case bookmarkStateAction.SET_BOOKMARK_SIZE:
      return {
        ...state,
        bookmarkSizeBig: action.payload
      };
    case bookmarkStateAction.SET_BOOKMARK_COLOR:
      return {
        ...state,
        bookmarkColored: action.payload
      };
    case bookmarkStateAction.SET_BOOKMARK_SHAPE:
      return {
        ...state,
        bookmarkShapeRectangle: action.payload
      };

    // guest mode
    case bookmarkStateAction.DELETE_ALL_BOOKMARKS_GUEST_MODE:
      return {
        ...state,
        loadingDeleteAllBookmarks: false,
        errorDeleteAllBookmarks: null,
        openDialogDeleteAllBookmark: false,
        bookmarksGuestMode: []
      };
    case bookmarkStateAction.UPDATE_BOOKMARKS_GUEST_MODE:
      return {
        ...state,
        bookmarksGuestMode: action.bookmarks
      };

    case bookmarkStateAction.UPDATE_BOOKMARK_TOPIC_STYLING_LIST:
      return {
        ...state,
        bookmarksTopicStyling: action.payload
      };

    case bookmarkStateAction.SHOW_BOOKMARKS_TOPIC_STYLING:
      return {
        ...state,
        showTopicStylingBookmark: action.payload,
        showchromeBoomkmarks: false,
        showTopVisitedSites: false
      };

    case bookmarkStateAction.SHOW_CHROME_BOOKMARKS:
      return {
        ...state,
        showchromeBoomkmarks: action.payload,
        showTopVisitedSites: false,
        showTopicStylingBookmark: false
      };
    case bookmarkStateAction.SHOW_TOP_VISITED_SITES:
      return {
        ...state,
        showTopVisitedSites: action.payload,
        showTopicStylingBookmark: false,
        showchromeBoomkmarks: false
      };

    case bookmarkStateAction.ADD_BOOKMARK_GUEST_MODE:
      return {
        ...state,
        bookmarksGuestMode: [...state.bookmarksGuestMode, action.bookmark]
      };
    default:
      return state;
  }
}
