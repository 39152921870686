import { PushPin, PushPinOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePicturePinAction,
  updatePictureWindowStateAction
} from "../../../store/reducers/picturesReducer/action";
import { GetImageUrl } from "../../../Utils/GetImageUrl";

export default function PicturesPopup({ dark }) {
  const picturesState = useSelector((state) => state.pictures);
  const dispatch = useDispatch();

  return (
    <div className="integration-grid">
      {picturesState.picturesApiSource.map((picture, index) => {
        return (
          <div
            key={"pictures-popup-grid-item-" + picture.id}
            className={
              picture.active
                ? "integration-grid-item-active integration-grid-item"
                : "integration-grid-item"
            }
          >
            {picture.pined ? (
              <PushPin
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updatePicturePinAction(picture.id, false));
                }}
              />
            ) : (
              <PushPinOutlined
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updatePicturePinAction(picture.id, true));
                }}
              />
            )}
            <div
              className="integration-grid-item-container"
              onClick={() => {
                if (picture.active) {
                  dispatch(updatePictureWindowStateAction());
                } else {
                  dispatch(updatePictureWindowStateAction(picture.id));
                }
              }}
            >
              <img
                src={
                  dark
                    ? GetImageUrl(picture.icons.white)
                    : GetImageUrl(picture.icons.black)
                }
                alt={picture.id + " icon"}
              />
              <span>{picture.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
