import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./AnalogClock.css";

export default function ModernAnalogClock(props) {
  const clockState = useSelector((state) => state.clock);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const hr = time.getHours();
  const min = time
    .getMinutes()
    .toLocaleString("en-us", { minimumIntegerDigits: 2, useGrouping: false });
  const sec = time.getSeconds();

  return (
    <div
      className={`modernAnalogClock ${
        clockState.themeMode === "light"
          ? "modernAnalogClock--light"
          : "modernAnalogClock--dark"
      }`}
      style={
        props.display || props.opacity === 0
          ? { display: "none" }
          : {
              opacity: props.opacity,
              cursor: "pointer",
              fontSize: clockState.analogClockSize / 10 + "px",
              width: clockState.analogClockSize + "px",
              height: clockState.analogClockSize + "px"
            }
      }
    >
      <div
        className={`modernAnalogClock--innerCirlce ${
          clockState.themeMode === "light"
            ? "modernAnalogClock--innerCirlceLight"
            : "modernAnalogClock--innerCirlceDark"
        }`}
      >
        <div className="modernAnalogClock__container">
          {Array.from({ length: 12 }, (_, i) => (
            <div
              key={i}
              style={{
                height: 1,
                transform: `${
                  i === 0
                    ? "rotate(0deg)"
                    : i === 1
                    ? "rotate(30deg)"
                    : i === 2
                    ? "rotate(60deg)"
                    : i === 3
                    ? "rotate(90deg)"
                    : i === 4
                    ? "rotate(120deg)"
                    : i === 5
                    ? "rotate(150deg)"
                    : i === 6
                    ? "rotate(180deg)"
                    : i === 7
                    ? "rotate(210deg)"
                    : i === 8
                    ? "rotate(240deg)"
                    : i === 9
                    ? "rotate(270deg)"
                    : i === 10
                    ? "rotate(300deg)"
                    : "rotate(330deg)"
                }`
              }}
              className="modernAnalogClock__nums"
            >
              <div
                style={{ height: "3px", borderRadius: "9999px" }}
                className={`${
                  i === 0 || i === 3 || i === 6 || i === 9
                    ? `modernAnalogClock--primaryDots ${
                        clockState.themeMode === "light"
                          ? "modernAnalogClock--primaryDotsLight"
                          : "modernAnalogClock--primaryDotsDark"
                      }`
                    : `modernAnalogClock--secondaryDots ${
                        clockState.themeMode === "light"
                          ? "modernAnalogClock--secondaryDotsLight"
                          : "modernAnalogClock--secondaryDotsDark"
                      }`
                }`}
              />
            </div>
          ))}

          {/* Seconds */}
          {clockState.showSeconds && (
            <div
              style={{ transform: `rotate(${sec * 6 - 90}deg)` }}
              className={`modernAnalogClock__seconds ${
                clockState.themeMode === "light"
                  ? "modernAnalogClock__secondsLight"
                  : "modernAnalogClock__secondsDark"
              }`}
            />
          )}

          {/* minute handler */}
          <div
            style={{ transform: `rotate(${min * 6 - 90}deg)` }}
            className="modernAnalogClock__minutes"
          >
            <div
              className={`modernAnalogClock__minutes--line ${
                clockState.themeMode === "light"
                  ? "modernAnalogClock__minutes--lineLight"
                  : "modernAnalogClock__minutes--lineDark"
              }`}
            />
          </div>

          <div
            style={{
              transform: `rotate(${hr * 30 + min * 0.5 - 90}deg)`
            }}
            className="modernAnalogClock__hours"
          >
            <div
              className={`modernAnalogClock__hours--line ${
                clockState.themeMode === "light"
                  ? "modernAnalogClock__hours--lineLight"
                  : "modernAnalogClock__hours--lineDark"
              }`}
            />
          </div>

          <div className="modernAnalogClock__centerDot">
            <div
              className={`modernAnalogClock__centerDot--inner ${
                clockState.themeMode === "light"
                  ? "modernAnalogClock__centerDot--innerLight"
                  : "modernAnalogClock__centerDot--innerDark"
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
