export const initialState = {
  alarms: [],
  error: "",
  isAudioPlaying: false,
  hasMessage: false,
  message: "",
};

export const alarmStateAction = {
  ADD_ALARM: "alarm/ADD_ALARM",
  REMOVE_ALARM: "alarm/REMOVE_ALARM",
  CLEAR_ALARM: "alarm/CLEAR_ALARM",
  SET_ALARM_ERROR: "alarm/SET_ALARM_ERROR",
  SET_ALARM_AUDIO: "alarm/SET_ALARM_AUDIO",
  SET_ALARM_HAS_MESSAGE: "alarm/SET_ALARM_HAS_MESSAGE",
  SET_ALARM_MESSAGE: "alarm/SET_ALARM_MESSAGE",
};
