import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import {
  Check,
  Close,
  Code,
  ControlPoint,
  Delete,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Fullscreen,
  FullscreenExit,
  MoreHoriz
} from "@mui/icons-material";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { useDispatch, useSelector } from "react-redux";
//for rich text editor(Bold, Italics etc) we have incorporated DraftJS package and its variuos utility function
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

import "./ToDoSection.css";
import { Tooltip } from "@mui/material";
import {
  addToDoServer,
  deleteAllTodosServer,
  deleteToDoServer,
  updateToDoBulkListServer
} from "../../../server/Dashbord/todos.server";
import {
  createEmptyToDo,
  deleteTodoRequestAction,
  setSelectedToDo,
  updateTodoInStateAction,
  updateTodosListAction
} from "../../../store/reducers/todoReducer/action";
import {
  toggleExpandedView,
  updateNoteModeView
} from "../../../store/reducers/notesReducer/action";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function ToDoSection() {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const todosState = useSelector((state) => state.todos);
  const notesState = useSelector((state) => state.notes);

  let todoList = todosState && todosState.todos ? todosState.todos : [];

  let dashboardId = dashboard && dashboard._id ? dashboard._id : null;
  let selectedToDo =
    todosState && todosState.selectedToDo ? todosState.selectedToDo : {};

  let expandedView =
    notesState && notesState.toggleExpandedView
      ? notesState.toggleExpandedView
      : false;

  const [inputToDoTitle, setinputToDoTitle] = useState(selectedToDo.title);
  const [inputTodoStatus, setinputTodoStatus] = useState(
    selectedToDo.done !== undefined ? selectedToDo.done : false
  );

  const [isTitleFilledFromEditor, setIsTitleFilledFromEditor] = useState(false);

  //construct a constant and local state to store DraftJS Rich text content having inline styles(Bold, Italic.etc)
  const [editorState, setEditorState] = useState(() => {
    //if a todo is already selected set it's content or empty otherwise
    if (selectedToDo && selectedToDo.content) {
      let todoContent = selectedToDo.content;
      if (todoContent.includes("blocks")) {
        return EditorState.createWithContent(
          convertFromRaw(JSON.parse(todoContent))
        );
      } else {
        return EditorState.createEmpty();
      }
    }
    return EditorState.createEmpty();
  });

  // Delete todo section

  const [showDeLeteToDoArea, setshowDeLeteToDoArea] = useState(false);
  const handleClickPopoverDelete = (event) => {
    event.preventDefault();
    setshowDeLeteToDoArea(true);
  };

  const handleClosePopoverDelete = (event) => {
    event.preventDefault();
    setshowDeLeteToDoArea(false);
  };

  const deleteToDo = (event) => {
    event.preventDefault();
    //handleClosePopoverDelete(event);
    let todoId = selectedToDo ? selectedToDo._id : null;

    dispatch(deleteToDoServer(todoId, dashboardId));
    //trigger the new todo event click so user see blank inputs which is equivalent to creating a new todo after deletion
    resetSelectedToDo();
  };

  /***************** add empty ToDo******************** */

  const onClickAddNewEmptyToDo = () => {
    //create empty ToDo

    //set the current selected ToDo within store as empty
    if (selectedToDo.title === "") {
      if (selectedToDo.content === "") {
        dispatch(deleteTodoRequestAction(selectedToDo._id));
      } else {
        let newToDoUp = selectedToDo;
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        if (blocks.length) {
          const editorValue = blocks[0].text.substring(0, 12);
          newToDoUp.title = editorValue;
        } else {
          newToDoUp.title = "ToDo Title";
        }

        dispatch(updateTodoInStateAction(newToDoUp));
      }
    }

    //clear the inputs
    setinputToDoTitle("");
    //for Draft JS we would create an Empty state using it's "createEmpty"
    setEditorState(EditorState.createEmpty());
    setIsTitleFilledFromEditor(false);
    setinputTodoStatus(false);
    //change edit mode as content cannot be saved without title
    dispatch(createEmptyToDo(todoList, dashboardId));
    //dispatch(addtodoAction(selectedToDo, dashboardId));
  };
  /***************** reset selected ToDo ******************** */

  const resetSelectedToDo = () => {
    //create empty ToDo

    //set the current selected ToDo within store as empty
    if (selectedToDo.title === "") {
      if (selectedToDo.content === "") {
        dispatch(deleteTodoRequestAction(selectedToDo._id));
      } else {
        let newToDoUp = selectedToDo;
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        if (blocks.length) {
          const editorValue = blocks[0].text.substring(0, 12);
          newToDoUp.title = editorValue;
        } else {
          newToDoUp.title = "ToDo Title";
        }

        dispatch(updateTodoInStateAction(newToDoUp));
      }
    }

    dispatch(setSelectedToDo(todoList[0]));
    setshowDeLeteToDoArea(false);
    //clear the inputs
    setinputToDoTitle(todoList[0].title);
    //for Draft JS we would create an Empty state using it's "createEmpty"

    if (todoList[0] && todoList[0].content) {
      let todoContent = todoList[0].content;
      if (todoContent.includes("blocks")) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(JSON.parse(todoContent)))
        );
      } else {
        setEditorState(EditorState.createEmpty());
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
    setIsTitleFilledFromEditor(false);
  };

  //the click event for expanding/collapsing ToDo
  const onClickToggleExpandedView = () => {
    dispatch(toggleExpandedView(!expandedView));
  };
  //Ends -----the click events for Deleting ToDo ------

  const onClickSetActiveMode = (activeMode) => {
    //enable edit mode for new ToDo to be saved
    dispatch(updateNoteModeView(activeMode == "NOTE" ? true : false));
  };

  //the click event for selecting a ToDo
  const onClickToDo = (todo) => {
    //set the current selected ToDo to redux state

    if (selectedToDo.title === "") {
      if (selectedToDo.content === "") {
        dispatch(deleteTodoRequestAction(selectedToDo._id));
      } else {
        let newToDoUp = selectedToDo;
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        if (blocks.length) {
          const editorValue = blocks[0].text.substring(0, 12);
          newToDoUp.title = editorValue;
        } else {
          newToDoUp.title = "ToDo Title";
        }
        dispatch(updateTodoInStateAction(newToDoUp));
      }
    }
    dispatch(setSelectedToDo(todo));

    //set the current selected todo properties(title,content) to respective inputs
    setinputToDoTitle(todo.title);
    //since todo content is a Draft Editor stringify object we have to parse it
    //and utilize "convertFromRaw" and createWithContent offered by DraftJS to make it DraftJs recognizable state
    setIsTitleFilledFromEditor(false);
    if (todo.content.includes("blocks")) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(todo.content)))
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };

  //delete all todo
  const onClickRemoveAllOption = (event) => {
    // dispatch({ type: DELETE_ALL_TODOS });
    dispatch(deleteAllTodosServer(dashboardId));
    resetSelectedToDo();
  };

  //******** editor section */
  const styleMapForEditor = {
    CUSTOMCODE: {
      fontFamily: "monospace",
      overflowWrap: "break-word",
      background: "grey",
      padding: "0 1px"
    }
  };

  //Note: following function looks identical, check if they could be refactored.
  //BOLD
  const onBoldBtnClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };
  //Italic
  const onItalicBtnClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };
  //Underline
  const onUnderlineBtnClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };
  //CODE(Not used for now as we have to add more style we are using cutomCode styling defined just below it)
  const onCodeBtnClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "CODE"));
  };
  //CUSTOM CODE inline styles(adds the grey color block with small padding)
  const onCustomCodeBtnClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "CUSTOMCODE"));
  };
  //Bulleted List
  const onBulletBtnClick = () => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
  };

  const handleKeyCommand = (command) => {
    //Note: we have to check more over this handleKeyCommand() of RichUtilis for it's overall purpose and usage
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      //set the editor state with new state
      setEditorState(newState);
      let rawEditorContent = convertToRaw(editorState.getCurrentContent());
      //since draft Js editor content is an object we have to stringfy it before dispatching and saving it to backend
      let inputTodoContent = JSON.stringify(rawEditorContent);
      if (selectedToDo.content != inputTodoContent) {
        let updateToDo = selectedToDo;
        updateToDo.content = inputTodoContent;
        updateToDo.saved = false;
        dispatch(setSelectedToDo(updateToDo));
      }
      return "handled";
    }
    return "not-handled";
  };

  //define onchange event handlers for Title and Input Editor
  const onChangeInputTitle = (event) => {
    setIsTitleFilledFromEditor(false);

    if (event.target.value === "") {
      setinputToDoTitle("ToDo Title");
      let updateToDo = selectedToDo;
      updateToDo.title = "ToDo Title";
      updateToDo.saved = false;

      dispatch(setSelectedToDo(updateToDo));
    } else {
      setinputToDoTitle(event.target.value);
      let updateToDo = selectedToDo;
      updateToDo.title = event.target.value;
      updateToDo.saved = false;
      dispatch(setSelectedToDo(updateToDo));
    }
  };

  const onChangeToDoStatusCheckbox = (event, todo) => {
    //set todo status
    event.preventDefault();
    todo.done = event.target.checked;
    setinputTodoStatus(todo.done);
    let updateToDo = todo;
    updateToDo.done = event.target.checked;
    updateToDo.saved = false;
    dispatch(setSelectedToDo(updateToDo));
    onClickToDo(todo);
  };

  const onChangeEditorContent = (editorText) => {
    //set the state of editor
    setEditorState(editorText);

    let rawEditorContent = convertToRaw(editorState.getCurrentContent());
    //since draft Js editor content is an object we have to stringfy it before dispatching and saving it to backend
    let inputTodoContent = JSON.stringify(rawEditorContent);
    if (selectedToDo.content != inputTodoContent) {
      let updateToDo = selectedToDo;
      updateToDo.content = inputTodoContent;
      updateToDo.saved = false;
      dispatch(setSelectedToDo(updateToDo));
    }

    //check if title is empty and fill it with editor content if empty
    let titleLenToBeTaken = 12;
    if (
      (inputToDoTitle === "" || isTitleFilledFromEditor) &&
      inputToDoTitle.length < titleLenToBeTaken
    ) {
      //we will utilize convertoRaw function of DraftJS which gives us the blocks(array of objects having editor text and other meta info)
      //than the blocks array is used to get the plain text to set as title
      const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
      if (blocks.length) {
        //we will only get the values from first line of editor for now
        //so we will pick the first object only from block array(index = 0)
        const editorValue = blocks[0].text.substring(0, titleLenToBeTaken);
        //if required we can uncomment the following line and map through each object and join the "text" from multiple lines as single line
        //const editorValue = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('');
        setinputToDoTitle(editorValue);
        let updateToDo = selectedToDo;
        updateToDo.title = editorValue;
        updateToDo.saved = false;
        dispatch(setSelectedToDo(updateToDo));
        //set the state that we have title filled from Editor content
        //so it helps in building the logic for filling the rest of title with Editor content only til(12 words)
        setIsTitleFilledFromEditor(true);
      }
    }
    //reset the state of ToDo to not take the length if it's greater than needed(12)
    if (inputToDoTitle && inputToDoTitle.length > titleLenToBeTaken) {
      setIsTitleFilledFromEditor(false);
    }
  };
  /************ save ToDos to backend**************************************  */

  const saveToDos = async () => {
    if (selectedToDo.title === "") {
      if (selectedToDo.content === "") {
        dispatch(deleteTodoRequestAction(selectedToDo._id));
      } else {
        let newToDoUp = selectedToDo;
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        if (blocks.length) {
          const editorValue = blocks[0].text.substring(0, 12);
          newToDoUp.title = editorValue;
        } else {
          newToDoUp.title = "ToDo Title";
        }

        dispatch(updateTodoInStateAction(newToDoUp));
      }
    }

    let indexNew = todoList.findIndex((e) => e._id.includes("empty"));
    if (indexNew >= 0) {
      meaVanaConsole("dispatch addToDoAction");
      dispatch(addToDoServer(todoList[indexNew], dashboardId));
    } else {
      meaVanaConsole("dispatch updateToDoAction");
      dispatch(updateToDoBulkListServer(todoList, dashboardId));
    }
  };

  /************** drag and drop ToDos list item ****************************************** */

  const onToDoDragEnd = (result) => {
    //return without processing further if no result
    //or return if result exists but no drag happened i.e ToDo is at same place where it was()
    if (!result || (result && result.oldIndex === result.newIndex)) return;
    let ToDosListUpdated = todoList;
    ToDosListUpdated[result.oldIndex].saved = false;
    //update the index of ToDo dragged/dropped within Array
    let reorderedToDos = arrayMove(
      ToDosListUpdated,
      result.oldIndex,
      result.newIndex
    );
    //update the index property of ToDo objects as well to be saved in Backend/DB
    reorderedToDos = reorderedToDos.map((_ToDo, index) => {
      return {
        ..._ToDo,
        prevIndex: _ToDo.index,
        index: index + 1
      };
    });
    // settodoList(reorderedToDos);
    dispatch(updateTodosListAction(reorderedToDos));
  };

  const ToDoDragDropItem = SortableElement(({ index, todo }) => {
    return (
      <div className="todos-list-item-container">
        <div
          key={todo._id}
          className={
            todo._id === selectedToDo._id
              ? "todos-list-item active-todo"
              : "todos-list-item"
          }
          onClick={() => {
            if (showDeLeteToDoArea) {
              setshowDeLeteToDoArea(false);
            }

            onClickToDo(todo);
          }}
        >
          <Form.Check
            type="checkbox"
            checked={todo.done}
            onChange={(event) => {
              onChangeToDoStatusCheckbox(event, todo);
            }}
            className={
              todo.done
                ? "form-check d-flex"
                : "form-check d-flex todo-btn-icon"
            }
          />
          <span>
            {todo._id === selectedToDo._id && inputToDoTitle !== ""
              ? inputToDoTitle
              : todo.title}
          </span>
          <IconButton
            id="removetodoBtn"
            aria-label="delete todo"
            className="todo-btn-icon"
            onClick={(event) => {
              // trigger on click todo event
              setshowDeLeteToDoArea(false);
              onClickToDo(todo);
              handleClickPopoverDelete(event);
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </div>
        {showDeLeteToDoArea && todo._id === selectedToDo._id ? (
          <div className="todos-list-item-delete-container">
            <span>are you sure ?</span>
            <div className="todos-list-item-delete-container-action">
              <IconButton
                id="removetodoBtn"
                aria-label="delete todo"
                className="todo-btn-icon"
                onClick={(event) => {
                  handleClosePopoverDelete(event);
                }}
              >
                <Close fontSize="small" />
              </IconButton>
              <IconButton
                id="removetodoBtn"
                aria-label="delete todo"
                className="todo-btn-icon"
                onClick={(event) => {
                  deleteToDo(event);
                }}
              >
                <Check fontSize="small" />
              </IconButton>
            </div>
          </div>
        ) : null}
      </div>
    );
  });

  const SortableToDokList = SortableContainer(({ todos = [] }) => {
    return (
      <div className="todos-list">
        {todos.map((todo, index) => {
          return (
            <ToDoDragDropItem key={`item-${index}`} index={index} todo={todo} />
          );
        })}
      </div>
    );
  });

  return (
    <div className="todo-section">
      <div className="todos-list-section">
        <div className="item-list-action">
          <OverlayTrigger
            trigger="click"
            key="OverlayTrigger-todo-options"
            placement="right"
            rootClose={true}
            overlay={
              <Popover
                id={`popover-todo-options`}
                className="popover-todo-options"
              >
                <Popover.Body className="popover-todo-options-body">
                  <IconButton
                    className="todo-btn-icon"
                    aria-label="delete-all-todos"
                    onClick={onClickRemoveAllOption}
                  >
                    <Delete fontSize="small" />
                    <span>Remove All</span>
                  </IconButton>
                </Popover.Body>
              </Popover>
            }
          >
            <IconButton className="todo-btn-icon" aria-label="todo-more-option">
              <MoreHoriz fontSize="meduim" />
            </IconButton>
          </OverlayTrigger>

          <IconButton
            id="addNewtodoBtn"
            aria-label="todo-add-new"
            className="todo-btn-icon"
            onClick={(event) => {
              event.preventDefault();
              onClickAddNewEmptyToDo();
            }}
          >
            <ControlPoint fontSize="medium" />
          </IconButton>
        </div>
        <SortableToDokList
          todos={todoList}
          axis="y"
          distance={1}
          onSortEnd={onToDoDragEnd}
        />
      </div>
      <div className="todos-editor-section">
        <div className="todos-header-container">
          <div className="todos-header-tab-container">
            <button
              className="todo-tab"
              onClick={() => onClickSetActiveMode("NOTE")}
            >
              Notes
            </button>
            <button
              className="todo-tab-active"
              onClick={() => onClickSetActiveMode("TODO")}
            >
              To-Do
            </button>
          </div>
          <div className="todos-header-action-container">
            <IconButton
              id="expandtodoBtn"
              aria-label="expande-todo"
              className="todo-btn-icon"
              onClick={onClickToggleExpandedView}
            >
              {!expandedView ? (
                <Fullscreen fontSize="medium" />
              ) : (
                <FullscreenExit fontSize="medium" />
              )}
            </IconButton>
          </div>
        </div>
        <div className="todos-editor-container">
          <div className="form-group-todo border-bottom">
            <input
              type="text"
              className="form-control-todo todo-title-input"
              placeholder="Enter Title"
              value={inputToDoTitle}
              onChange={onChangeInputTitle}
              onFocus={(event) => {
                if (todoList.length === 0) {
                  event.preventDefault();
                  onClickAddNewEmptyToDo();
                }
              }}
            />
          </div>
          <div className="form-group-todo form-group-todo-editor border-none pb-0">
            <div className="editor-container">
              <Editor
                editorState={editorState}
                onChange={onChangeEditorContent}
                handleKeyCommand={handleKeyCommand}
                className="editor-input"
                onFocus={(event) => {
                  if (todoList.length === 0) {
                    event.preventDefault();
                    onClickAddNewEmptyToDo();
                  }
                }}
                customStyleMap={styleMapForEditor}
              />

              {/* the editor toolbar containing various buttons for inline styling(Bold, Italics..etc) */}
              <div className="editor-toolbar p-1">
                <div className="container-editor-toolbar-btn">
                  <Tooltip
                    title="Bold (CTRL + B)"
                    className="editor-btn-tooltip"
                  >
                    <IconButton
                      className="todo-btn-icon"
                      aria-label="todo-editor-bold"
                      onClick={onBoldBtnClick}
                    >
                      <FormatBold fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Italic (CTRL + I)"
                    className="editor-btn-tooltip"
                  >
                    <IconButton
                      className="todo-btn-icon"
                      aria-label="todo-editor-italic"
                      onClick={onItalicBtnClick}
                    >
                      <FormatItalic fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Underline (CTRL + U)"
                    className="editor-btn-tooltip"
                  >
                    <IconButton
                      className="todo-btn-icon"
                      aria-label="todo-editor-underline"
                      onClick={onUnderlineBtnClick}
                    >
                      <FormatUnderlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Code (CTRL + SHIFT + C)"
                    className="editor-btn-tooltip"
                  >
                    <IconButton
                      className="todo-btn-icon"
                      aria-label="todo-editor-codeblock"
                      onClick={onCustomCodeBtnClick}
                    >
                      <Code fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Bulleted List" className="editor-btn-tooltip">
                    <IconButton
                      className="todo-btn-icon"
                      aria-label="todo-editor-bullet"
                      onClick={onBulletBtnClick}
                    >
                      <FormatListBulleted fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                {/* the primary button for saving the todo 
                <Button
                  color="info"
                  variant="contained"
                  onClick={saveToDos}
                  disabled={!todosState.updatedList}
                >
                  {todosState.editMode === "saving" ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#fff",
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
