import { coincapStateAction } from "./constants";

// action get Coincap Assets:  request initiated
export const getCoincapAssetsRequestAction = () => {
  return (dispatch) => {
    dispatch({
      type: coincapStateAction.GET_COINCAP_ASSETS_REQUEST,
      payload: {}
    });
  };
};

// action get Coincap Assets: success
export const getCoincapAssetsSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: coincapStateAction.GET_COINCAP_ASSETS_SUCESS,
      payload: {
        data: data
      }
    });
  };
};

// action get Coincap Assets: failure
export const getCoincapAssetsFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: coincapStateAction.GET_COINCAP_ASSETS_FAILURE,
      payload: { error: error }
    });
  };
};

//action show Coincap Window
export const setShowCoincapWindow = (isShow) => {
  return (dispatch) => {
    dispatch({
      type: coincapStateAction.SHOW_COINCAP_WINDOW,
      payload: {
        showCoincapWindow: isShow
      }
    });
  };
};
