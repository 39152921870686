import { animBackgroundStateAction } from "./constants";

//action update Animation Background
export const updateAnimationBackgroundAction = (animation) => {
  return (dispatch) => {
    dispatch({
      type: animBackgroundStateAction.UPDATE_ANIMATION_BACKGROUND,
      payload: animation
    });
  };
};

//action update Animation Background
export const updateActiveAnimationBackgroundAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: animBackgroundStateAction.UPDATE_ACTIVE_ANIMATION_BACKGROUND,
      payload: state
    });
  };
};
