import { assetsIcons } from "../../../config/images";

export const initialState = {
  videosApiSource: [
    {
      id: "youtube",
      name: "Youtube",
      icons: assetsIcons.youtube,
      active: false,
      loaded: false,
      status: "emplty",
      error: null,
      pined: false,
      lastUpdate: null
    }
  ],
  activeVideoId: null,
  lastUpdate: null
};

export const videostateAction = {
  UPDATE_VIDEO_WINDOW_STATE: "videos/UPDATE_VIDEO_WINDOW_STATE",
  UPDATE_VIDEO_PIN: "videos/UPDATE_VIDEO_PIN"
};
