import React from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import "./HideAllFeatures.css";
import { hideAllFeatureAction } from "../../store/reducers/homeReducer/action";

export default function HideAllFeatures(props) {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.home);

  return (
    <div
      className="container-btn-hide-all"
      onClick={() => {
        dispatch(hideAllFeatureAction(!homeState.hideAllFeatures));
      }}
    >
      {homeState.hideAllFeatures ? (
        <VisibilityOff className="home-icon-font-size" /*fontSize="large"*/ />
      ) : (
        <Visibility className="home-icon-font-size" /*fontSize="large"*/ />
      )}
    </div>
  );
}
