import moment from 'moment';
import { meaVanaConsole } from './MeaVanaConsole';

export const checkBackgroundLastUpdate = (backgroundLastUpdate) => {
  meaVanaConsole('checkBackgroundLastUpdate :', backgroundLastUpdate);
  if (backgroundLastUpdate) {
    if (!moment.utc().isSame(moment.utc(backgroundLastUpdate), 'day')) {
      meaVanaConsole('same day false so update backgroound');
      meaVanaConsole('********************************************************************');
      return true;
    }
    meaVanaConsole('same day true so no update backgroound');
    meaVanaConsole('********************************************************************');
    return false;
  } else {
    return true;
  }
};

export const checkUpdateTopicFromBackend = (topicLastUpdate) => {
  if (topicLastUpdate && moment.utc().isSame(moment.utc(topicLastUpdate), 'day')) {
    return true;
  }
  return false;
};
