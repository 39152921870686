import React, { useEffect, useState } from "react";
import { Chip, Stack, Avatar } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import "./Settings.css";

import UpdatePasswordComponent from "./ProfileComponent/UpdatePasswordComponent";
import DeleteAccountComponent from "./ProfileComponent/DeleteAccountComponent";
import UpdateProfileInfoComponent from "./ProfileComponent/UpdateProfileInfoComponent";
import { resetProfileStateAction } from "../../store/reducers/authReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

export default function ProfileSettings() {
  const stateAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [showProfileInfo, setshowProfileInfo] = useState(true);
  const [showUpdateProfileInfo, setShowUpdateProfileInfo] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [showDeleteAccount, setshowDeleteAccount] = useState(false);

  const [showDisableAccount, setshowDisableAccount] = useState(false);

  useEffect(() => {
    goBackToProfileInfo();
  }, []);
  const goBackToProfileInfo = () => {
    dispatch(resetProfileStateAction());
    setshowProfileInfo(true);
    setShowUpdateProfileInfo(false);
    setShowUpdatePassword(false);
    setshowDeleteAccount(false);
    setshowDisableAccount(false);
  };

  /*const DisableAccount = (event) => {
    event.preventDefault();
    meaVanaConsole("DisableAccount clicked");
    setshowProfileInfo(false);
    setShowUpdateProfileInfo(false);
    setShowUpdatePassword(false);
    setshowDeleteAccount(false);
    setshowDisableAccount(true);
  };*/
  const deleteAccount = (event) => {
    event.preventDefault();
    meaVanaConsole("deleteAccount clicked");
    setshowProfileInfo(false);
    setShowUpdateProfileInfo(false);
    setShowUpdatePassword(false);
    setshowDeleteAccount(true);
    setshowDisableAccount(false);
  };
  const UpdateInfo = (event) => {
    event.preventDefault();
    meaVanaConsole("UpdateInfo clicked");
    setshowProfileInfo(false);
    setShowUpdateProfileInfo(true);
    setShowUpdatePassword(false);
    setshowDeleteAccount(false);
    setshowDisableAccount(false);
  };
  const UpdatePassword = (event) => {
    event.preventDefault();
    meaVanaConsole("UpdatePassword clicked");
    setshowProfileInfo(false);
    setShowUpdateProfileInfo(false);
    setShowUpdatePassword(true);
    setshowDeleteAccount(false);
    setshowDisableAccount(false);
  };

  const stringAvatar = () => {
    let name = "";
    if (stateAuth && stateAuth.user) {
      if (stateAuth.user.username) {
        name = stateAuth.user.username.toUpperCase();
      } else {
        if (stateAuth.user.email) {
          name = stateAuth.user.email.toUpperCase();
        } else {
          name = "Mea Vana";
        }
      }
    } else {
      name = "Mea Vana";
    }
    let nameRendred = "";
    name.split(" ").forEach((element) => {
      if (element[0] && nameRendred.length < 5) {
        nameRendred += element[0];
      }
    });
    return nameRendred;
  };

  return (
    <div className="settings-view">
      <div className="header-settings">
        <span className="header-settings-title">Profile</span>
        <span className="header-settings-subtitle">
          Your personal information
        </span>
      </div>
      {showUpdateProfileInfo ? (
        <UpdateProfileInfoComponent goBackToProfileInfo={goBackToProfileInfo} />
      ) : null}
      {showUpdatePassword ? (
        <UpdatePasswordComponent goBackToProfileInfo={goBackToProfileInfo} />
      ) : null}
      {showDeleteAccount ? (
        <DeleteAccountComponent goBackToProfileInfo={goBackToProfileInfo} />
      ) : null}

      {showProfileInfo ? (
        <div className="profile-conntainer-info">
          <div className="container-profile-info">
            <Avatar
              // alt={stateAuth.user.username.toUpperCase()}
              src={
                stateAuth.user.profilePicture
                  ? stateAuth.user.profilePicture
                  : null
              }
              sx={{
                width: "120px",
                height: "120px",
                bgcolor: "#380c9c",
                fontSize: "30px",
                fontWeight: "500",
                letterSpacing: "1px"
              }}
            >
              {!stateAuth.user.profilePicture ? stringAvatar() : null}
            </Avatar>

            <span className="profile-info-username-txt">
              {stateAuth.user && stateAuth.user.username
                ? stateAuth.user.username
                : "Meavana User"}
            </span>
            {/*stateAuth.user &&
            stateAuth.user.firstName &&
            stateAuth.user.lastName ? (
              <span className="profile-info-username-txt">
                {stateAuth.user.firstName + " " + stateAuth.user.lastName}
              </span>
            ) : null*/}
            {stateAuth.user && stateAuth.user.email ? (
              <span className="profile-info-email-txt">
                {stateAuth.user.email}
              </span>
            ) : null}
          </div>

          <div className="list-settings-profile">
            <div className="setting-profile-list-groupe-header"></div>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              spacing={1}
              rowGap={1}
              width="100%"
              padding={2}
            >
              <Chip
                label="Update Information"
                onClick={UpdateInfo}
                color="info"
                className="button-setting-popup"
              />
              <Chip
                label="Update Password"
                onClick={UpdatePassword}
                color="info"
                className="button-setting-popup"
              />
              <Chip
                label="Delete Account"
                onClick={deleteAccount}
                color="info"
                className="button-setting-popup"
              />
            </Stack>
          </div>

          <div className="profile-thanks-container">
            <span className="profile-thanks-text">
              Thank you for using MeaVana
            </span>
            <span className="profile-user-since-text">
              {stateAuth.user.signupDate
                ? "User since " +
                  new Date(stateAuth.user.signupDate).toLocaleString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour12: false
                  })
                : null}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
