import { appStateAction } from '../appReducer/constants';
import { initialState, picturestateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { integrationStateAction } from '../integrationsReducer/constants';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const picturesPersistConfig = {
  key: 'pictures',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING pictures :', error)
};
export const picturesPersisted = persistReducer(picturesPersistConfig, picturesReducer);

export default function picturesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case integrationStateAction.RESET_INTEGRATION_PIN_STATE:
      return {
        ...state,
        picturesApiSource: state.picturesApiSource.map((picture) => {
          return { ...picture, pined: false };
        })
      };

    case picturestateAction.UPDATE_PICTURE_WINDOW_STATE:
      if (action.payload == null) {
        return {
          ...state,
          activePictureId: null,
          picturesApiSource: state.picturesApiSource.map((picture) => {
            return { ...picture, active: false };
          })
        };
      } else {
        return {
          ...state,
          activePictureId: action.payload,
          picturesApiSource: state.picturesApiSource.map((picture) => {
            if (action.payload === picture.id) {
              return { ...picture, active: true };
            }
            return { ...picture, active: false };
          })
        };
      }
    case picturestateAction.UPDATE_PICTURE_PIN:
      if (action.payload.pictureId == null) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          picturesApiSource: state.picturesApiSource.map((picture) => {
            if (action.payload.pictureId === picture.id) {
              return { ...picture, pined: action.payload.pin };
            }
            return { ...picture };
          })
        };
      }

    default:
      return state;
  }
}
