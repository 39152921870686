import React from "react";
import ShareSendEmail from "../../../components/ShareSendEmail/ShareSendEmail";
import BackgroundInfoFeature from "../../../features/BackgroundInfoFeature/BackgroundInfoFeature";
import HideAllFeatures from "../../../features/HideAllFeatures/HideAllFeatures";
import NoteFeature from "../../../features/NoteFeature/NoteFeature";
import QuoteFeature from "../../../features/QuoteFeature/QuoteFeature";
import SettingsFeature from "../../../features/SettingsFeature/SettingsFeature";

export default function BottomArea({ homeState }) {
  return (
    <>
      <div className="bottom-right-area">
        <ShareSendEmail
          display={homeState.hideAllFeatures}
          opacity={homeState.showNote}
        />
        <NoteFeature
          display={homeState.hideAllFeatures}
          opacity={homeState.showNote}
        />
      </div>
      <div className="bottom-left-area">
        <SettingsFeature display={homeState.hideAllFeatures} />
        <BackgroundInfoFeature display={homeState.hideAllFeatures} />
        <HideAllFeatures />
      </div>
      <div className="Quote-area">
        <QuoteFeature
          opacity={homeState.showQuote}
          display={homeState.hideAllFeatures}
        />
      </div>
    </>
  );
}
