import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";

import "./MeavanaTopics.css";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import { authBackroundUrl } from "../../../config/images";

const meavanaTopicsArray = [
  {
    _id: "62a3d2664718290b2d8865a0",
    name: "Black & White ",
    backgroundImage: {
      isComplete: true,
      tags: [],
      url_low_res:
        "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/black_%26%20white%20/low_res/blocks-0R1ci4Rb9jU-unsplash.jpg",
      url: "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/black_%26%20white%20/blocks-0R1ci4Rb9jU-unsplash.jpg",
      _id: "62a3d2664718290b2d8865a8",
      quotes: [],
      createdAt: "2022-06-10T23:23:18.666Z",
      updatedAt: "2022-06-10T23:23:45.461Z",
      title: "Elegant Glasses "
    },
    usersCount: 1,
    imagesCount: 6,
    createdAt: "2022-06-10T23:23:18.667Z"
  },

  {
    _id: "62419d96a25f2fd5a32c6ec8",
    name: "Chess",
    backgroundImage: {
      description: "Beautiful colored glass chess pieces of the black side ",
      date: "26 Sep 2021",
      photographer: "Alexander Mils",
      source: "https://unsplash.com/photos/W8C1d0Cydhc",
      tags: [],
      url: "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/chess/1_chess.jpg",
      quotes: [
        {
          metadata: "- Marcel Duchamp",
          content:
            "While all artists are not chess players, all chess players are artists",
          _id: "62419d96a25f2fd5a32c6eca",
          createdAt: "2022-03-28T11:35:50.719Z",
          updatedAt: "2022-03-28T11:35:50.719Z"
        }
      ],
      title: "Colored Glass Chess Pieces ",
      _id: "62419d96a25f2fd5a32c6ec9",
      createdAt: "2022-03-28T11:35:50.720Z",
      updatedAt: "2022-05-09T17:58:51.407Z"
    },
    usersCount: 27,
    imagesCount: 47,
    createdAt: "2022-03-28T11:35:50.724Z"
  },
  {
    _id: "622df39ef4cb44d2b5691868",
    name: "Landscapes",
    backgroundImage: {
      description:
        "A small waterfall flowing into a creek in a beautiful green forest ",
      date: "27 June 2017 10:42am",
      photographer: "Pixabay",
      source: "https://www.pexels.com/photo/waterfalls-in-forest-460621/",
      tags: [],
      url: "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/launch_set/1_Launch.jpg",
      quotes: [
        {
          metadata: "- Conrad Hilton",
          content: "Keep moving; make mistakes but don't quit",
          _id: "622df39ef4cb44d2b569186a",
          createdAt: "2022-03-13T13:37:35.059Z",
          updatedAt: "2022-03-13T13:37:35.059Z"
        }
      ],
      title: "Waterfall in a Lush Green Forest",
      _id: "622df39ef4cb44d2b5691869",
      createdAt: "2022-03-13T13:37:35.060Z",
      updatedAt: "2022-06-13T00:50:34.764Z",
      url_low_res:
        "https://tabuland-statics-dev.s3.us-east-2.amazonaws.com/background-images/launch_set_low_res/1_Launch.jpg"
    },
    usersCount: 574,
    imagesCount: 57,
    createdAt: "2022-03-13T13:37:35.070Z"
  },
  {
    _id: "62ab2d9946364c13d3dad2cd",
    name: "Space",
    backgroundImage: {
      isComplete: true,
      tags: [],
      url_low_res:
        "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/space/low_res/pexels-killian-eon-2868665.jpg",
      url: "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/space/pexels-killian-eon-2868665.jpg",
      _id: "62ab2d9946364c13d3dad2d4",
      quotes: [
        {
          metadata: "Marcus Aurelius, Meditations",
          content:
            "Dwell on the beauty of life. Watch the stars, and see yourself running with them",
          _id: "62b8c5a85aff292f7370f8be",
          updatedAt: "2022-06-26T21:14:11.570Z",
          createdAt: "2022-06-26T21:14:11.570Z"
        }
      ],
      createdAt: "2022-06-16T13:18:17.356Z",
      updatedAt: "2022-06-26T21:14:11.570Z",
      title: "Star Trail Photography ",
      date: "4 September 2019",
      photographer: "Killian Eon"
    },
    usersCount: 23,
    imagesCount: 14,
    createdAt: "2022-06-16T13:18:17.356Z"
  },

  {
    _id: "62a7b75d2410092eeeb5b9d8",
    name: "Wildlife",
    backgroundImage: {
      isComplete: true,
      tags: [],
      url_low_res:
        "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/wildlife/low_res/ansie-potgieter-GV2LxPJArgQ-unsplash.jpg",
      url: "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/wildlife/ansie-potgieter-GV2LxPJArgQ-unsplash.jpg",
      _id: "62a7b75d2410092eeeb5b9e0",
      quotes: [],
      createdAt: "2022-06-13T22:17:01.261Z",
      updatedAt: "2022-06-13T22:17:20.390Z",
      title: "Lion Around"
    },
    usersCount: 13,
    imagesCount: 6,
    createdAt: "2022-06-13T22:17:01.262Z"
  },
  {
    _id: "62a7b9532410092eeeb5ba40",
    name: "Zen ",
    backgroundImage: {
      isComplete: true,
      tags: [],
      url_low_res:
        "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/zen_/low_res/alejandro-pinero-amerio-KBn4-lyqRgQ-unsplash.jpg",
      url: "https://meavana-statics-prod.s3.us-east-2.amazonaws.com/background-images/zen_/alejandro-pinero-amerio-KBn4-lyqRgQ-unsplash.jpg",
      _id: "62a7b9532410092eeeb5ba48",
      quotes: [],
      createdAt: "2022-06-13T22:25:23.428Z",
      updatedAt: "2022-06-13T22:25:44.634Z",
      title: "Zen Stones"
    },
    usersCount: 14,
    imagesCount: 6,
    createdAt: "2022-06-13T22:25:23.429Z"
  }
];
export default function TopicList(props) {
  return (
    <>
      <div className="header-settings">
        <span className="header-settings-title">MeaVana Topics:</span>
        <span className="header-settings-subtitle">
          Some of MeaVana's most popular topics
        </span>
      </div>

      <div
        className={
          "wallpaper-meavana-topics-container-grid " +
          props.gridTemplateCulumnsWidth
        }
      >
        {meavanaTopicsArray.map((topic, index) => (
          <Tooltip
            key={"tooltip-topic-" + index}
            arrow
            followCursor
            title="Click to Select Topic"
            placement="top"
          >
            <div
              className="wallpaper-meavana-topics-container-grid-item"
              key={"wallpaper-meavana-topics-" + topic.name}
              onClick={(event) => {
                if (!props.loadingSelectedTopic) {
                  props.clickedTopicToSelect(topic, "list");
                }
              }}
              style={{
                cursor: "pointer",
                backgroundImage:
                  topic.backgroundImage && topic.backgroundImage.url
                    ? "url(" + topic.backgroundImage.url + ")"
                    : "url(" + GetImageUrl(authBackroundUrl) + ")"
              }}
            >
              {props.loadingSelectedTopic &&
                props.clickedTopicId === topic._id && (
                  <div className="clicked-topic-loading-container">
                    <CircularProgress
                      size={30}
                      sx={{
                        color: "#fff",
                        alignSelf: "center"
                      }}
                    />
                  </div>
                )}

              {props.selectedTopic._id === topic._id && (
                <div className="clicked-topic-loading-container">
                  <IconButton
                    className="active-topic-icon-container"
                    aria-label="selectedTopic"
                  >
                    <CheckCircle fontSize="medium" />
                    <span
                      style={{
                        color: "var(--popup-text-color)",
                        fontSize: "1rem",
                        marginLeft: "0.3rem"
                      }}
                    >
                      Selected
                    </span>
                  </IconButton>
                </div>
              )}

              <div className="wallpaper-meavana-topics-action-container">
                <span>{topic.name}</span>
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
    </>
  );
}
