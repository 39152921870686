import { clockStateAction } from "./constants";

export const updateTimeFormatAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.UPDATE_TIME_FORMAT,
      payload: state
    });
  };
};

export const updateAmPmAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.UPDATE_AM_PM,
      payload: state
    });
  };
};

export const updateTimeSecondAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.UPDATE_TIME_SECONDS,
      payload: state
    });
  };
};
export const updateTimeAnalogClockAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.UPDATE_ANALOG_CLOCK,
      payload: state
    });
  };
};
export const toggleAnalogClockAction = () => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.SHOW_ANALOG
    });
  };
};
export const updateShowAnalogClockAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.UPDATE_SHOW_ANALOG,
      payload: state
    });
  };
};
export const updateTimeThemeModeAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.UPDATE_THEME_MODE,
      payload: state
    });
  };
};
export const updateAnalogClockSizeAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.SET_ANALOG_CLOCK_SIZE,
      payload: value
    });
  };
};
export const updateTimeFontSizeAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.SET_TIME_DATE_FONT_SIZE,
      payload: value
    });
  };
};
export const updateTimeFontWeightAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: clockStateAction.SET_TIME_DATE_FONT_WEIGHT,
      payload: value
    });
  };
};
