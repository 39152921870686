/* global chrome */
import React from "react";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import { loginSSOFacebookRequestAction } from "../../../store/reducers/authReducer/action";
//import { signInWithFacebook } from "../../../config/firebase";
import { APP_ENV } from "../../../config/constants";

import Button from "@mui/material/Button";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FacebookChromeAuth } from "../../../components/AuthFunctions/FacebookChromeAuth";
import { assetsIcons } from "../../../config/images";

export default function Facebook() {
  const stateAuth = useSelector((state) => state.auth);
  const appState = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const chromeFacebookLogin = () => {
    if (typeof chrome != "undefined") {
      meaVanaConsole("send message to login function in background");
        dispatch(FacebookChromeAuth(appState.deviceId));
    } else {
      meaVanaConsole("chrome undefined");
    }
  };
  /*
   const firebaseFacebookLogin = () => {
    dispatch(loginSSOFacebookRequestAction());

    dispatch(signInWithFacebook(appState.deviceId));
  };
  if (APP_ENV === "development") {
    return (
      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-facebook-login"
        placement="top"
        rootClose={true}
        overlay={
          <Popover
            id={`popover-facebook-login`}
            className="popover-share-meavana"
          >
            <Popover.Body className="popover-share-meavana-body">
              <div className="share-meavana-grid">
                <Button
                  className="contact-form-btn-send button-setting-popup"
                  variant="contained"
                  onClick={chromeFacebookLogin}
                  color="info"
                >
                  Chrome
                </Button>
                <Button
                  className="contact-form-btn-send button-setting-popup"
                  variant="contained"
                  onClick={firebaseFacebookLogin}
                  color="warning"
                >
                  Firebase
                </Button>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <IconButton
          className="google-sso-button"
          disabled={stateAuth.loadingFacebookSSO}
        >
          <img
            alt="Facebook-sso"
            src={GetImageUrl(assetsIcons.facebook.color)}
            style={stateAuth.loadingFacebookSSO ? { opacity: 0.6 } : {}}
          />
          {stateAuth.loadingFacebookSSO && (
            <CircularProgress
              style={{ position: "absolute" }}
              size={35}
              sx={{
                color: "#ffffff",
                alignSelf: "center",
              }}
            />
          )}
        </IconButton>
      </OverlayTrigger>
    );
  } else {
    */
  return (
    <IconButton
      className="google-sso-button"
      onClick={chromeFacebookLogin}
      disabled={stateAuth.loadingFacebookSSO}
    >
      <img
        alt="Facebook-sso"
        src={GetImageUrl(assetsIcons.facebook.color)}
        style={stateAuth.loadingFacebookSSO ? { opacity: 0.6 } : {}}
      />
      {stateAuth.loadingFacebookSSO && (
        <CircularProgress
          style={{ position: "absolute" }}
          size={35}
          sx={{
            color: "#ffffff",
            alignSelf: "center"
          }}
        />
      )}
    </IconButton>
  );
}
