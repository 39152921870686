import { assetsIcons } from "../../../config/images";

const data = {
  videos: [],
  selectedVideo: {
    kind: "youtube#searchResult",
    etag: "ytVLx9FYxAkPxo1E4FrwszvXv8Y",
    id: {
      kind: "youtube#video",
      videoId: "X8cILIln5vk"
    },
    snippet: {
      publishedAt: "2022-05-23T06:47:52Z",
      channelId: "UC9X4mdWkuXAVIjeR4ieJyPA",
      title: "Meavana Teaser Video",
      description:
        "A teaser video of what the MeaVana platform is about. Video credit: https://www.instagram.com/theartsensei/ Produced by one of ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/X8cILIln5vk/default.jpg",
          width: 120,
          height: 90
        },
        medium: {
          url: "https://i.ytimg.com/vi/X8cILIln5vk/mqdefault.jpg",
          width: 320,
          height: 180
        },
        high: {
          url: "https://i.ytimg.com/vi/X8cILIln5vk/hqdefault.jpg",
          width: 480,
          height: 360
        }
      },
      channelTitle: "MeaVana",
      liveBroadcastContent: "none",
      publishTime: "2022-05-23T06:47:52Z"
    }
  }
};

export const params = {
  showSearchedVideos: false
};

export const initialState = {
  id: "youtube",
  name: "Youtube",
  icons: assetsIcons.youtube,
  active: false,
  loaded: false,
  status: "emplty",
  error: null,
  pined: false,
  lastUpdate: null,
  ...data,
  ...params
};

export const youtubeStateAction = {
  GET_SEARCH_API_REQUEST: "youtube/GET_SEARCH_API_REQUEST",
  GET_SEARCH_API_SUCESS: "youtube/GET_SEARCH_API_SUCESS",
  GET_SEARCH_API_FAILURE: "youtube/GET_SEARCH_API_FAILURE",
  SET_SELECTED_VIDEO: "youtube/SET_SELECTED_VIDEO",
  SHOW_SEARCHED_VIDEOS: "youtube/SHOW_SEARCHED_VIDEOS",
  UPDATE_SHOW_YOUTUBE_WINDOW: "youtube/UPDATE_SHOW_YOUTUBE_WINDOW",
  UPDATE_PIN_YOUTUBE_HOME: "youtube/UPDATE_PIN_YOUTUBE_HOME"
};
