import { arrayMove } from 'react-sortable-hoc';
import { appStateAction } from '../appReducer/constants';
import { initialState, noteStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const notePersistConfig = {
  key: 'note',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING note :', error)
};
export const notePersisted = persistReducer(notePersistConfig, noteReducer);

export default function noteReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;
    case noteStateAction.GET_DASHBOARD_NOTES:
      return {
        ...state,
        notes: action.payload,
        updatedList: false
      };
    case noteStateAction.ADD_EMPTY_NOTE:
      return {
        ...state,

        selectedNote: action.payload[0],
        notes: action.payload,
        updatedList: true
      };
    case noteStateAction.DELETE_ALL_NOTE:
      return {
        ...state,
        selectedNote: {},
        notes: [],

        updatedList: true
      };
    case noteStateAction.ADD_NOTE_REQUEST:
      let updatedNewNotes1 = state.notes.map((note) => {
        //update the notes with new values if the id matches during iteration

        if (note._id.includes('empty') || !note._id) {
          note = action.payload.note;
        }
        return note;
      });
      return {
        ...state,
        selectedNote: state.selectedNote._id.includes('empty')
          ? action.payload.note
          : state.selectedNote,

        notes: updatedNewNotes1
      };
    case noteStateAction.ADD_NOTE_SUCCESS:
      let updatedNewNotes = state.notes.map((note) => {
        //update the notes with new values if the id matches during iteration

        if (note._id.includes('empty') || !note._id) {
          note = action.payload.note;
        }
        return note;
      });
      return {
        ...state,
        selectedNote: state.selectedNote._id.includes('empty')
          ? action.payload.note
          : state.selectedNote,

        notes: updatedNewNotes
      };
    case noteStateAction.ADD_NOTE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case noteStateAction.UPDATE_NOTE_REQUEST:
      return {
        ...state,
        note: action.payload.notes,
        updatedList: false
      };
    case noteStateAction.UPDATE_NOTE_SUCCESS:
      let updatedBuldNotes = state.notes.map((note) => {
        return { ...note, saved: true };
      });
      return {
        ...state,
        note: updatedBuldNotes,
        updatedList: false
      };
    case noteStateAction.UPDATE_NOTE_IN_STATE:
      let updatedNotes = state.notes.map((note) => {
        //update the notes with new values if the id matches during iteration
        let updatednote = action.payload.note;
        if (note._id === updatednote._id) {
          note = updatednote;
          note.saved = false;
        }
        return note;
      });
      return {
        ...state,
        notes: updatedNotes,

        updatedList: true
      };
    case noteStateAction.UPDATE_NOTE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case noteStateAction.DELETE_NOTE_REQUEST:
      let reorderedNotesAfterDelete1 = state.notes;
      let index1 = state.notes.findIndex((note) => note._id === action.payload.noteId);
      if (index1 >= 0) {
        reorderedNotesAfterDelete1 = arrayMove(state.notes, index1, state.notes.length - 1);
        reorderedNotesAfterDelete1.pop();
      } else {
        reorderedNotesAfterDelete1 = reorderedNotesAfterDelete1.filter(
          (note) => note._id !== action.payload.noteId
        );
      }

      return {
        ...state,
        updatedList: false,
        notes: reorderedNotesAfterDelete1
      };
    case noteStateAction.DELETE_NOTE_SUCCESS:
      let reorderedNotesAfterDelete = state.notes;
      let index = state.notes.findIndex((note) => note._id === action.payload.noteId);
      if (index >= 0) {
        reorderedNotesAfterDelete = arrayMove(state.notes, index, state.notes.length - 1);
        reorderedNotesAfterDelete.pop();
      } else {
        reorderedNotesAfterDelete = reorderedNotesAfterDelete.filter(
          (note) => note._id !== action.payload.noteId
        );
      }

      return {
        ...state,
        updatedList: true,
        notes: reorderedNotesAfterDelete
      };

    case noteStateAction.DELETE_NOTE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case noteStateAction.SET_SELECTED_NOTE:
      let updatedSelectedNotes = state.notes.map((note) => {
        if (note._id === action.payload._id) {
          note = action.payload;
        }
        return note;
      });
      return {
        ...state,

        selectedNote: action.payload,
        notes: updatedSelectedNotes,
        updatedList: true
      };
    case noteStateAction.TOGGLE_EXPANDED_VIEW:
      return {
        ...state,
        toggleExpandedView: action.payload
      };

    case noteStateAction.SET_ACTIVE_MODE:
      return {
        ...state,
        noteActiveMode: action.payload
      };
    case noteStateAction.UPDATE_NOTE_LIST:
      return {
        ...state,
        notes: action.payload.notes,
        updatedList: true
      };
    case noteStateAction.DELETE_ALL_NOTE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case noteStateAction.DELETE_ALL_NOTE_SUCCESS:
      return {
        ...state,
        notes: [],
        selectedNote: {},
        updatedList: true
      };
    case noteStateAction.DELETE_ALL_NOTE_REQUEST:
      return {
        ...state,
        notes: [],
        selectedNote: {},
        updatedList: false
      };
    case noteStateAction.SHOW_NOTE_DELETE_CONFIRMATION:
      return {
        ...state,
        showNoteDeleteConfirmation: action.payload.showNoteDeleteConfirmation
      };

    default:
      return state;
  }
}
