import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import { authBackroundUrl } from "../../../config/images";

export default function UserSelectedTopics(props) {
  return (
    <>
      <div className="header-settings">
        <span className="header-settings-title">Your Topics:</span>
        <span className="header-settings-subtitle">
          Topics that you have subscribed to
        </span>
      </div>

      <div
        className={
          "wallpaper-meavana-topics-container-grid " +
          props.gridTemplateCulumnsWidth
        }
      >
        {props.selectedTopics &&
          props.selectedTopics.map((topic, index) => (
            <Tooltip
              key={"tooltip-topic-" + index}
              arrow
              followCursor
              title="Click to Select Topic"
              placement="top"
            >
              <div
                className="wallpaper-meavana-topics-container-grid-item"
                key={"wallpaper-meavana-topics-" + topic.name}
                onClick={(event) => {
                  if (!props.loadingSelectedTopic) {
                    props.clickedTopicToSelect(topic, "selected");
                  }
                }}
                style={{
                  cursor: "pointer",
                  backgroundImage:
                    topic.dailyImage && topic.dailyImage.url
                      ? "url(" + topic.dailyImage.url + ")"
                      : "url(" + GetImageUrl(authBackroundUrl) + ")"
                }}
              >
                {props.loadingSelectedTopic &&
                  props.clickedTopicId === topic._id && (
                    <div className="clicked-topic-loading-container">
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "#fff",
                          alignSelf: "center"
                        }}
                      />
                    </div>
                  )}

                {props.selectedTopic._id === topic._id &&
                  !props.useUnspashForBackground && (
                    <div className="clicked-topic-loading-container">
                      <IconButton
                        className="active-topic-icon-container"
                        aria-label="selectedTopic"
                      >
                        <CheckCircle fontSize="medium" />
                        <span
                          style={{
                            color: "var(--popup-text-color)",
                            fontSize: "1rem",
                            marginLeft: "0.3rem"
                          }}
                        >
                          Selected
                        </span>
                      </IconButton>
                    </div>
                  )}

                <div className="wallpaper-meavana-topics-action-container">
                  <span>{topic.name}</span>
                </div>
              </div>
            </Tooltip>
          ))}
      </div>
    </>
  );
}
