import { useSelector, useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";
import { GetImageUrl } from "../../../Utils/GetImageUrl";
import {
  updateNewsPinAction,
  updateNewsWindowStateAction
} from "../../../store/reducers/newsReducer/action";

export default function NewsPin({ whiteIcon }) {
  const newsState = useSelector((state) => state.news);
  const dispatch = useDispatch();

  return (
    <>
      {newsState.newsSources.map((source, index) => {
        const isSourceActive = newsState.newsSources.activeNewsIndex === index;
        return source.pinned ? (
          <div
            key={"news-pin-grid-item-" + index.toString()}
            className={
              isSourceActive
                ? "news-pin-grid-item-active news-pin-grid-item"
                : "news-pin-grid-item"
            }
          >
            <Close
              className="news-pin-button-pin"
              onClick={() => {
                dispatch(updateNewsPinAction(index, false));
              }}
            />

            <div
              className="news-pin-grid-item-container"
              onClick={() => {
                dispatch(
                  updateNewsWindowStateAction(isSourceActive ? null : index)
                );
              }}
            >
              <img
                src={
                  whiteIcon
                    ? GetImageUrl(source.icons.white)
                    : GetImageUrl(source.icons.black)
                }
                style={!whiteIcon ? { opacity: 0.6 } : {}}
                alt={`${source.name} icon`}
              />
              <span>{source.name}</span>
            </div>
          </div>
        ) : null;
      })}
    </>
  );
}
