import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUnsplashUseBackgroundAction } from '../../../store/reducers/unsplashReducer/action';
import {
  updateBackgroundIndexDBStateAction,
  updateSelectedTopicAction,
  updateSelectedTopicSuccessAction
} from '../../../store/reducers/backgroundReducer/action';
import { selectTopicService } from '../../../server/Dashbord/backgound.server';

import './MeavanaTopics.css';

import Button from '@mui/material/Button';
import { CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { updateBackgroundModeAction } from '../../../store/reducers/appReducer/action';
import { updateActiveAnimationBackgroundAction } from '../../../store/reducers/animReducer/action';
import TopicList from './TopicList';
import UserSelectedTopics from './UserSelectedTopics';
import UpdatingTopicStyling from '../../../hooks/TopicStyling/UpdatingTopicStyling';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { defaultTopicStyling } from '../../../Utils/DefaultTopicStyling';

export default function MeavanaTopics({ windowSize }) {
  const backgroundState = useSelector((state) => state.background);
  const dispatch = useDispatch();
  const unsplashState = useSelector((state) => state.unsplash);
  const dashboardState = useSelector((state) => state.dashboard);

  const [openDialog, setopenDialog] = useState(false);
  const [clickedTopic, setclickedTopic] = useState(null);
  const [clickedTopicFrom, setclickedTopicFrom] = useState('');
  const [gridTemplateCulumnsWidth, setGridTemplateCulumnsWidth] = useState(
    'wallpaper-meavana-topics-grid-template-colums-400'
  );

  const getGridTemplateCulumnsWidth = (width) => {
    if (width >= 795) {
      setGridTemplateCulumnsWidth('wallpaper-meavana-topics-grid-template-colums-800');
    }
    if (width < 795 && width >= 595) {
      setGridTemplateCulumnsWidth('wallpaper-meavana-topics-grid-template-colums-600');
    }
    if (width < 595) {
      setGridTemplateCulumnsWidth('wallpaper-meavana-topics-grid-template-colums-400');
    }
  };

  useEffect(() => {
    getGridTemplateCulumnsWidth(windowSize.width);
  }, [windowSize]);

  const clickedTopicToSelect = (itemTopic, from) => {
    setopenDialog(true);
    setclickedTopic(itemTopic);
    setclickedTopicFrom(from);
  };

  useEffect(() => {
    let time;
    if (backgroundState.loadingSelectedTopic) {
      time = setInterval(() => {
        meaVanaConsole('force topic loading to stop after 5s');
        dispatch(updateSelectedTopicSuccessAction());
      }, 5000);
    }
    return () => {
      clearInterval(time);
    };
  }, [backgroundState.loadingSelectedTopic]);

  const updateSelectedTopics = (itemTopic, from, topicStyling) => {
    setopenDialog(false);
    setclickedTopic(null);
    setclickedTopicFrom('');
    dispatch(setUnsplashUseBackgroundAction(false));
    dispatch(updateBackgroundIndexDBStateAction(false));

    dispatch(updateBackgroundModeAction('background-home-picture'));
    dispatch(updateActiveAnimationBackgroundAction(false));
    if (from === 'selected') {
      dispatch(updateSelectedTopicAction(itemTopic));
      if (topicStyling) {
        if ('style' in itemTopic) {
          dispatch(UpdatingTopicStyling(itemTopic.style));
        } else {
          dispatch(UpdatingTopicStyling(defaultTopicStyling));
        }
      }
    } else {
      let indexTopic = dashboardState.dashboard.selectedTopics.findIndex(
        (item) => item._id === itemTopic._id
      );
      if (indexTopic > -1) {
        dispatch(updateSelectedTopicAction(dashboardState.dashboard.selectedTopics[indexTopic]));
        if (topicStyling) {
          if ('style' in dashboardState.dashboard.selectedTopics[indexTopic]) {
            dispatch(
              UpdatingTopicStyling(dashboardState.dashboard.selectedTopics[indexTopic].style)
            );
          } else {
            dispatch(UpdatingTopicStyling(defaultTopicStyling));
          }
        }
      } else {
        dispatch(selectTopicService(itemTopic._id, dashboardState.dashboard._id, topicStyling));
      }
    }
  };
  return (
    <div className="wallpaper-meavana-topic-container">
      <TopicList
        gridTemplateCulumnsWidth={gridTemplateCulumnsWidth}
        loadingSelectedTopic={backgroundState.loadingSelectedTopic}
        clickedTopicId={backgroundState.clickedTopicId}
        selectedTopic={backgroundState.selectedTopic}
        clickedTopicToSelect={clickedTopicToSelect}
      />
      <div className="about-container-button-external">
        <span className="about-link-chrome-store">
          Explore more topics at{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://meavana.com/">
            meavana.com
          </a>
        </span>
      </div>
      {dashboardState.dashboard && dashboardState.dashboard.selectedTopics && (
        <UserSelectedTopics
          gridTemplateCulumnsWidth={gridTemplateCulumnsWidth}
          loadingSelectedTopic={backgroundState.loadingSelectedTopic}
          clickedTopicId={backgroundState.clickedTopicId}
          selectedTopic={backgroundState.selectedTopic}
          useUnspashForBackground={unsplashState.useUnspashForBackground}
          clickedTopicToSelect={clickedTopicToSelect}
          selectedTopics={dashboardState.dashboard.selectedTopics}
        />
      )}
      <div className="about-container-button-external">
        <span className="about-link-chrome-store">
          Explore more topics at{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://meavana.com/">
            meavana.com
          </a>
        </span>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => {
          setopenDialog(false);
        }}
      >
        <DialogContent>
          <label>Which feature layout do you want for your new topic?</label>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              updateSelectedTopics(clickedTopic, clickedTopicFrom, false);
            }}
          >
            Use Your Existing Feature Layout
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              updateSelectedTopics(clickedTopic, clickedTopicFrom, true);
            }}
          >
            Use Default Topic Feature Layout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
