import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetImageUrl } from '../../Utils/GetImageUrl';
import './Background.css';
import { meaVanaConsole } from '../../Utils/MeaVanaConsole';
import { setLoadingUnsplashUseBackgroundAction } from '../../store/reducers/unsplashReducer/action';
import { authBackroundUrl } from '../../config/images';
import {
  updateBackgroundIndexDBArrayAction,
  updateSelectedTopicFailureAction,
  updateSelectedTopicSuccessAction
} from '../../store/reducers/backgroundReducer/action';
import { useIndexedDB } from 'react-indexed-db';

export default function BackgroundMeaVana(props) {
  const background = useSelector((state) => state.background);
  const unsplashState = useSelector((state) => state.unsplash);
  const { getByIndex } = useIndexedDB('background');

  const dispatch = useDispatch();

  const animationState = useSelector((state) => state.animation);
  const authState = useSelector((state) => state.auth);
  const [backgroundImageUrl, setbackgroundImageUrl] = useState(
    props.isLoggedIn && background.background && background.background.url
      ? unsplashState.useUnspashForBackground &&
        unsplashState.selectedPicture &&
        unsplashState.selectedPicture.urls &&
        unsplashState.selectedPicture.urls.full
        ? unsplashState.selectedPicture.urls.full
        : background.background.url
      : GetImageUrl(authBackroundUrl)
  );

  const loadTopicBasedBg = (bgUrl) => {
    //debugger;
    //starts temp code to set background based on topic
    const currpath = window.location.pathname;
    const baseImgBgPath = '/assets/backgrounds/';
    switch (currpath) {
      case '/basketball':
        bgUrl = baseImgBgPath + 'bg_basketball.jpg';
        break;
      case '/black-and-white':
        bgUrl = baseImgBgPath + 'bg_bnw.jpg';
        break;
      case '/cat':
        bgUrl = baseImgBgPath + 'bg_cat.jpg';
        break;
      case '/landscape':
        bgUrl = baseImgBgPath + 'bg_landscape.jpg';
        break;
      case '/lofi':
        bgUrl = baseImgBgPath + 'bg_lofi.jpg';
        break;
      case '/messi':
        bgUrl = baseImgBgPath + 'bg_messi.jpeg';
        break;
      case '/new-york':
        bgUrl = baseImgBgPath + 'bg_new_york.jpg';
        break;
      case '/wildlife':
        bgUrl = baseImgBgPath + 'bg_wildlife.jpg';
        break;
      case '/zen':
        bgUrl = baseImgBgPath + 'bg_zen.jpg';
        break;
      case '/space':
        bgUrl = baseImgBgPath + 'bg_space.jpg';
        break;
      case '/tom-cruise':
        bgUrl = baseImgBgPath + 'bg_tom_cruise.jpg';
        break;
      case '/shah-rukh-khan':
        bgUrl = baseImgBgPath + 'bg_srk.jpg';
        break;
      case '/elon-musk':
        bgUrl = baseImgBgPath + 'bg_elon_musk.jpg';
        break;
      case '/magnus-carlsen':
        bgUrl = baseImgBgPath + 'bg_carlsen.jpg';
        break;
      case '/michael-phelps':
        bgUrl = baseImgBgPath + 'bg_phelps.jpg';
        break;
      case '/neymar':
        bgUrl = baseImgBgPath + 'bg_neymar.jpg';
        break;
      case '/virat-kohli':
        bgUrl = baseImgBgPath + 'bg_virat_kohli.jpg';
        break;
      case '/ariana-grande':
        bgUrl = baseImgBgPath + 'bg_ariana2.jpg';
        break;
      case '/aurora':
        bgUrl = baseImgBgPath + 'bg_aurora.jpg';
        break;
      case '/chess':
        bgUrl = baseImgBgPath + 'bg_chess.jpeg';
        break;
      case '/beyonce':
        bgUrl = baseImgBgPath + 'bg_beyonce.jpg';
        break;
      case '/taylor-swift':
        bgUrl = baseImgBgPath + 'bg_taylor.jpg';
        break;
      case '/lord-of-the-rings':
        bgUrl = baseImgBgPath + 'bg_lord_rings.jpg';
        break;
      case '/peaceful-mind':
        bgUrl = baseImgBgPath + 'bg_peaceful_mind.jpg';
        break;
      case '/drink-your-tea':
        bgUrl = baseImgBgPath + 'bg_tea.jpg';
        break;
      case '/stephan-curry':
        bgUrl = baseImgBgPath + 'bg_stephan_curry.jpeg';
        break;
      case '/iron-man':
        bgUrl = baseImgBgPath + 'bg_iron_man.jpg';
        break;
      case '/zen-martial-arts':
        bgUrl = baseImgBgPath + 'bg_zen_martial_arts.jpg';
        break;
      case '/coffee':
        bgUrl = baseImgBgPath + 'bg_coffee.jpg';
        break;
      case '/dogs':
        bgUrl = baseImgBgPath + 'bg_dogs.jpg';
        break;
      case '/women-empowerment':
        bgUrl = baseImgBgPath + 'bg_women_empowerment.jpg';
        break;
      case '/sports-cars':
        bgUrl = baseImgBgPath + 'bg_sports_cars.jpg';
        break;
      case '/billionaire-lifestyle':
        bgUrl = baseImgBgPath + 'bg_billionaire_lifestyle.jpg';
        break;
      case '/forests':
        bgUrl = baseImgBgPath + 'bg_forests.jpg';
        break;
      case '/yoga-with-sapna':
        bgUrl = baseImgBgPath + 'bg_yoga_with_sapna.jpg';
        break;
      case '/cuba':
        bgUrl = baseImgBgPath + 'bg_cuba.jpg';
        break;
      case '/nicaragua':
        bgUrl = baseImgBgPath + 'bg_nicaragua.jpg';
        break;
      case '/coffee-and-beyond':
        bgUrl = baseImgBgPath + 'bg_coffee_and_beyond.png';
        break;
      default:
        break;
    }
    return bgUrl;
    //ends temp code to set background based on topic
  };

  const loadBackgroundImage = (url) => {
    meaVanaConsole('start loadBackgroundImage url: ', url);
    url = loadTopicBasedBg(url);
    var image = new Image();
    image.src = url;
    image.onload = function () {
      setbackgroundImageUrl(url);
      dispatch(setLoadingUnsplashUseBackgroundAction(false));
      dispatch(updateSelectedTopicSuccessAction());
    };
    image.onerror = function (error) {
      meaVanaConsole('error while uploading background :', error);
      dispatch(updateSelectedTopicFailureAction(error));
      dispatch(setLoadingUnsplashUseBackgroundAction(false));

      if (unsplashState.useUnspashForBackground) {
        if (background.background.url) {
          setbackgroundImageUrl(background.background.url);
        } else {
          setbackgroundImageUrl(GetImageUrl(authBackroundUrl));
        }
      } else {
        setbackgroundImageUrl(GetImageUrl(authBackroundUrl));
      }
    };
  };

  const getBackgroundFromIndexDB = () => {
    getByIndex(
      'name',
      background.backgroundIndexFromDBArray[background.backgroundIndexFromDBIndex].name
    )
      .then((backgroundFromDB) => {
        if (backgroundFromDB) {
          meaVanaConsole('background from indexDB : ', backgroundFromDB);

          // setbackgroundImageUrl(backgroundFromDB.data);
          loadBackgroundImage(backgroundFromDB.data);
          dispatch(
            updateBackgroundIndexDBArrayAction(background.backgroundIndexFromDBIndex, {
              ...background.backgroundIndexFromDBArray[background.backgroundIndexFromDBIndex],
              used: new Date()
            })
          );
        } else {
          meaVanaConsole('error background from indexDB : ', backgroundFromDB);
        }
      })
      .catch((error) => {
        meaVanaConsole('error background from indexDB : ', error);
        loadBackgroundImage(GetImageUrl(authBackroundUrl));
        meaVanaConsole('set background from assets');
      });
  };

  useEffect(() => {
    let abortController = new AbortController();
    // your async action is here

    meaVanaConsole('start useEffect update background');
    if (!background.backgroundIndexFromDB) {
      if (props.isLoggedIn && background.background && background.background.url) {
        if (
          unsplashState.useUnspashForBackground &&
          unsplashState.selectedPicture &&
          unsplashState.selectedPicture.urls &&
          unsplashState.selectedPicture.urls.full
        ) {
          // loadUnsplashBackgroundImage(unsplashState.selectedPicture);
          // loadBackgroundImage(unsplashState.selectedPicture.urls.raw);
          // loadBackgroundImage(unsplashState.selectedPicture.urls.raw);
          loadBackgroundImage(unsplashState.selectedPicture.urls.full);
          // loadBackgroundImage(unsplashState.selectedPicture.urls.regular);
          meaVanaConsole('set background from unsplashState');
        } else {
          loadBackgroundImage(background.background.url);
          meaVanaConsole('set background from background');
        }
      } else {
        loadBackgroundImage(GetImageUrl(authBackroundUrl));
        meaVanaConsole('set background from assets');
      }
    } else {
      getBackgroundFromIndexDB();
    }
    return () => {
      abortController.abort();
    };
  }, [
    background.background,
    background.backgroundIndexFromDB,
    background.backgroundIndexFromDBIndex,
    props.isLoggedIn,
    unsplashState.useUnspashForBackground,
    unsplashState.selectedPicture
  ]);

  /*
  useEffect(() => {
    if (props.isLoggedIn && animationState.currentAnimation) {
      // const animationData=getAnimationData(GetImageUrl(animationState.currentAnimation.file));
      lottie.loadAnimation({
        container: document.querySelector("#anim"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("./animations/" +
          animationState.currentAnimation.name +
          ".json"),
        rendererSettings: {
          scaleMode: "xMinYMin slice",
          preserveAspectRatio: "xMinYMin slice",
        },
      });
    }
    return () => {
      lottie.destroy();
    };
  }, [animationState?.currentAnimation, props.isLoggedIn]);
*/
  return (
    <div
      className="background-home "
      style={
        !props.isLoggedIn && !props.guestMode
          ? {
              backgroundImage: 'url(' + GetImageUrl(authBackroundUrl) + ')',
              backgroundColor: 'transparent'
            }
          : animationState?.currentAnimation !== null && authState.user
          ? { background: 'transparent' }
          : { backgroundImage: 'url(' + backgroundImageUrl + ')' }
      }>
      {/*animationState?.currentAnimation !== null && props.isLoggedIn && (
        <div
          id="anim"
          className={
            animationState?.currentAnimation !== null && props.isLoggedIn
              ? "background-animation-container background-animation-fadeIn"
              : "background-animation-container background-animation-fadeOut"
          }
        />
      )*/}
    </div>
  );
}
