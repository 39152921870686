import { SERVER_URL } from "../../config/constants";
import {
  addTodoFailureAction,
  addTodoRequestAction,
  addTodoSuccessAction,
  deleteAllTodosFailureAction,
  deleteAllTodosRequestAction,
  deleteAllTodosSuccessAction,
  deleteTodoFailureAction,
  deleteTodoRequestAction,
  deleteTodoSuccessAction,
  setSelectedToDo,
  updateTodoBulkListFailureAction,
  updateTodoBulkListRequestAction,
  updateTodoBulkListSuccessAction
} from "../../store/reducers/todoReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import axiosInstance from "../Middleware/axiosInstance";
import { checkErrorResponseFormat } from "../Middleware/utils.server";

/**
 * the action for adding a new ToDo.
 *
 * @param {string} todo - The todo object.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const addToDoServer = (todo, dashboardId) => {
  return async (dispatch) => {
    try {
      dispatch(addTodoRequestAction(todo));
      axiosInstance()
        .post(SERVER_URL + "dashboard/" + dashboardId + "/todo/add", todo)
        .then((response) => {
          todo._id = response.data._id;
          todo.saved = true;

          dispatch(addTodoSuccessAction(todo));

          //select the newly added todo
          dispatch(setSelectedToDo(todo));

          meaVanaConsole("addToDo response  ", response.data);
        })
        .catch((error1) => {
          meaVanaConsole("addToDo err1 ", checkErrorResponseFormat(error1));
          dispatch(addTodoFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("addToDo err2", checkErrorResponseFormat(error));
      dispatch(addTodoFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

/**
 * the action for updating an existing ToDo.
 *
 * @param {string} todo - The todo object.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const updateToDoBulkListServer = (bulkTodos, dashboardId) => {
  return async (dispatch) => {
    try {
      dispatch(updateTodoBulkListRequestAction(bulkTodos));

      axiosInstance()
        .put(SERVER_URL + "dashboard/" + dashboardId + "/todo/bulk-update", {
          todoList: bulkTodos
        })
        .then((response) => {
          dispatch(updateTodoBulkListSuccessAction(response.data));
          meaVanaConsole("bulk todo :", response.data);
        })
        .catch((error1) => {
          meaVanaConsole("updateToDo err1 ", checkErrorResponseFormat(error1));
          //show message to user for better UI experience
          dispatch(
            updateTodoBulkListFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole("addToDo err2", checkErrorResponseFormat(error));
      dispatch(
        updateTodoBulkListFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

/**
 * the action for updating an existing ToDo.
 *
 * @param {string} todoId - The todo Id of todo to be deleted.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const deleteToDoServer = (todoId, dashboardId) => {
  return async (dispatch) => {
    dispatch(deleteTodoRequestAction(todoId));
    try {
      axiosInstance()
        .delete(
          SERVER_URL + "dashboard/" + dashboardId + "/todo/item/" + todoId
        )
        .then((response) => {
          meaVanaConsole("deleteToDoAction 200 ", response.data);
          dispatch(deleteTodoSuccessAction(response.data));
        })
        .catch((error1) => {
          meaVanaConsole(
            "deleteToDoAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(deleteTodoFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("deleteToDoAction err2", checkErrorResponseFormat(error));

      dispatch(deleteTodoFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

//remove all todos of a user(bulk delete)
export const deleteAllTodosServer = (dashboardId) => {
  return async (dispatch) => {
    dispatch(deleteAllTodosRequestAction());
    try {
      axiosInstance()
        .delete(SERVER_URL + "dashboard/" + dashboardId + "/todo/all")
        .then((response) => {
          dispatch(deleteAllTodosSuccessAction(response.data));

          dispatch(setSelectedToDo({}));
          meaVanaConsole(
            "deleteAlltodosAction response not 200 ",
            response.data
          );
        })
        .catch((error1) => {
          meaVanaConsole(
            "deleteAlltodosAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(
            deleteAllTodosFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "deleteAlltodosAction err2",
        checkErrorResponseFormat(error)
      );
      dispatch(deleteAllTodosFailureAction(checkErrorResponseFormat(error)));
    }
  };
};
