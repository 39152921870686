import { PushPin, PushPinOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNewsPinAction,
  updateNewsWindowStateAction
} from "../../../store/reducers/newsReducer/action";
import { GetImageUrl } from "../../../Utils/GetImageUrl";

export default function NewsPopup({ dark }) {
  const newsState = useSelector((state) => state.news);
  const dispatch = useDispatch();

  return (
    <div className="integration-grid"
   /* style={{
      display: 'flex',
      flexDirection:'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignItems: 'center'
    }}*/
    >
      {newsState.newsSources.map((source, index) => {
        const isSourceActive = newsState.newsSources.activeNewsIndex === index;
        return (
          <div
            key={"news-grid-item-" + index.toString()}
            className={
              isSourceActive
                ? "integration-grid-item-active integration-grid-item"
                : "integration-grid-item"
            }
           /* style={{width:'auto '}}*/
          >
            {source.pinned ? (
              <PushPin
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateNewsPinAction(index, false));
                }}
              />
            ) : (
              <PushPinOutlined
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateNewsPinAction(index, true));
                }}
              />
            )}

            <div
              className="integration-grid-item-container"
              onClick={() => {
                dispatch(
                  updateNewsWindowStateAction(isSourceActive ? null : index)
                );
              }}
            >
              <img
                src={
                  dark
                    ? GetImageUrl(source.icons.white)
                    : GetImageUrl(source.icons.black)
                }
                alt={`${source.name} icon`}
              />
              <span>{source.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
