import React, { useState } from "react";
import "./TrackSearchResult.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getMusicDataFailureAction,
  getMusicDataFailureMessageAction,
  setCurrentTrackIdAction,
  setIsPlayingAction
} from "../../../../store/reducers/musicReducer/action";
import { spotifyApi } from "../../../../hooks/useSongInfo";

function TrackSearchResult({ accessToken, track }) {
  const musicState = useSelector((state) => state.music.musicapiSource[0]);
  const dispatch = useDispatch();

  const playSong = () => {
    if (!accessToken) return;
    dispatch(setCurrentTrackIdAction(musicState.id, track.id));
    dispatch(setIsPlayingAction(musicState.id, true));

    spotifyApi
      .play({
        uris: track?.uri ? [track.uri] : []
      })
      .then(() => dispatch(getMusicDataFailureAction("spotify", null)))
      .catch((e) => {
        dispatch(
          getMusicDataFailureMessageAction(musicState.id, e.body.error.reason)
        );
        dispatch(getMusicDataFailureAction(musicState.id, e.statusCode));
        dispatch(setIsPlayingAction(musicState.id, false));
      });
  };

  return (
    <div onClick={playSong} className="trackSearchResult">
      <img className="" src={track.albumUrl} alt="" />
      <div className="trackSearchResult__right">
        <p className="trackSearchResult__rightTitle">{track.title}</p>
        <p className="trackSearchResult__rightArtist">{track.artist}</p>
      </div>
    </div>
  );
}

export default TrackSearchResult;
