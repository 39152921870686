import React, { useEffect, useRef, useState } from "react";
import "./WeatherContainer.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
import countries from "i18n-iso-countries";
import Tooltip from "@mui/material/Tooltip";
import {
  ArrowBackIos,
  ArrowForwardIos,
  PushPin,
  PushPinOutlined
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { Grid, IconButton, TextField } from "@mui/material";

import {
  clearTodayWeatherErrorAction,
  updateEditingCity,
  updatePinWeatherToHome,
  updateSearchCity,
  updateTodayWeatherAction,
  updateWeatherUSTempUnitToCelsius,
  updateWeatherUSTempUnitToFar
} from "../../store/reducers/weatherReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import { GetImageUrl } from "../../Utils/GetImageUrl";
import { WeatherIconsMap } from "../../Utils/WeatherIconsMap";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { getByCityTodayWeatherApiOpenWeatherMap } from "../../server/Weather/weather.server";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const WeatherCityForm = () => {
  const dispatch = useDispatch();
  const refInputSearchWeatherCity = useRef();
  const weatherState = useSelector((state) => state.weather);
  useEffect(() => {
    if (refInputSearchWeatherCity.current) {
      refInputSearchWeatherCity.current.focus();
    }
  });

  const getWeatherReport = async (e) => {
    e.preventDefault();

    dispatch(
      getByCityTodayWeatherApiOpenWeatherMap(
        weatherState.searchCity,
        weatherState.firstTimeUsingWeather
      )
    );
  };
  return (
    <form
      className="container-weather-today-main-form"
      onSubmit={getWeatherReport}
      style={{ marginBottom: "1rem" }}
      action=""
    >
      <TextField
        error={weatherState.errorTodayWeather ? true : false}
        value={weatherState.searchCity}
        inputRef={refInputSearchWeatherCity}
        className="container-weather-today-main-form-input"
        onChange={(e) => {
          dispatch(clearTodayWeatherErrorAction());
          dispatch(updateSearchCity(e.target.value));
        }}
        type="text"
        id="standard-error-helper-text"
        label="Enter City"
        defaultValue="Enter City"
        helperText={
          weatherState.errorTodayWeather
            ? weatherState.errorTodayWeather.message
            : ""
        }
        variant="standard"
      />
      <IconButton
        className="weather-btn-edit-city"
        onClick={() => {
          dispatch(clearTodayWeatherErrorAction());

          dispatch(updateEditingCity(false));
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <IconButton
        className="weather-btn-edit-city"
        type="submit"
        onClick={getWeatherReport}
      >
        <SearchIcon />
      </IconButton>
    </form>
  );
};

export default function WeatherFeature(props) {
  const appState = useSelector((state) => state.app);
  const clockState = useSelector((state) => state.clock);
  const weatherState = useSelector((state) => state.weather);

  const dispatch = useDispatch();

  let todayWeatherInfo =
    weatherState && weatherState.todayWeatherInfo
      ? weatherState.todayWeatherInfo
      : {};
  let todayWeather =
    weatherState && weatherState.todayWeather ? weatherState.todayWeather : {};

  let pinWeatherToHome =
    weatherState && weatherState.hourForcastWeather
      ? weatherState.hourForcastWeather
      : [];

  let weekForcastWeather =
    weatherState && weatherState.weekForcastWeather
      ? weatherState.weekForcastWeather
      : [];

  let hourForcastWeather =
    weatherState && weatherState.hourForcastWeather
      ? weatherState.hourForcastWeather
      : [];

  let geoAccess =
    weatherState && weatherState.geoAccess ? weatherState.geoAccess : false;
  let tempUnitCel =
    weatherState && weatherState.tempUnitCel ? weatherState.tempUnitCel : false;
  let firstTimeUsingWeather =
    weatherState && weatherState.firstTimeUsingWeather
      ? weatherState.firstTimeUsingWeather
      : true;
  let pinWeatherToHomeState =
    weatherState && weatherState.pinWeatherToHomeState
      ? weatherState.pinWeatherToHomeState
      : false;

  const resetScrollingWeatherItem = () => {
    if (refHourlyWeather) {
      refHourlyWeather.current.scrollLeft = 0;
    }
    setscrollHourlyWeatherValue(0);
    setscrollHourlyWeatherMAx(false);

    if (refWeeklyWeather) {
      refWeeklyWeather.current.scrollLeft = 0;
    }
    setscrollWeeklyWeatherValue(0);
    setscrollWeeklyWeatherMAx(false);
  };

  const refHourlyWeather = useRef({ scrollLeft: 0 });
  const [scrollHourlyWeatherMAx, setscrollHourlyWeatherMAx] = useState(false);
  const [scrollHourlyWeatherValue, setscrollHourlyWeatherValue] = useState(0);

  const scrollCheckHourlyWeather = () => {
    setscrollHourlyWeatherValue(refHourlyWeather.current.scrollLeft);
    if (
      Math.floor(
        refHourlyWeather.current.scrollWidth -
          refHourlyWeather.current.scrollLeft
      ) <= refHourlyWeather.current.offsetWidth
    ) {
      setscrollHourlyWeatherMAx(true);
    } else {
      setscrollHourlyWeatherMAx(false);
    }
  };

  const handleHourlyWeatherScroll = (shift) => {
    if (refHourlyWeather) {
      refHourlyWeather.current.scrollLeft += shift;
      setscrollHourlyWeatherValue(scrollHourlyWeatherValue + shift);

      if (
        Math.floor(
          refHourlyWeather.current.scrollWidth -
            refHourlyWeather.current.scrollLeft
        ) <= refHourlyWeather.current.offsetWidth
      ) {
        setscrollHourlyWeatherMAx(true);
      } else {
        setscrollHourlyWeatherMAx(false);
      }
    }
  };

  const refWeeklyWeather = useRef(null);
  const [scrollWeeklyWeatherMAx, setscrollWeeklyWeatherMAx] = useState(false);
  const [scrollWeeklyWeatherValue, setscrollWeeklyWeatherValue] = useState(0);

  const scrollCheckWeeklyWeather = () => {
    setscrollWeeklyWeatherValue(refWeeklyWeather.current.scrollLeft);
    if (
      Math.floor(
        refWeeklyWeather.current.scrollWidth -
          refWeeklyWeather.current.scrollLeft
      ) <= refWeeklyWeather.current.offsetWidth
    ) {
      setscrollWeeklyWeatherMAx(true);
    } else {
      setscrollWeeklyWeatherMAx(false);
    }
  };

  const handleWeeklyWeatherScroll = (shift) => {
    if (refWeeklyWeather) {
      refWeeklyWeather.current.scrollLeft += shift;
      setscrollWeeklyWeatherValue(scrollWeeklyWeatherValue + shift);

      if (
        Math.floor(
          refWeeklyWeather.current.scrollWidth -
            refWeeklyWeather.current.scrollLeft
        ) <= refWeeklyWeather.current.offsetWidth
      ) {
        setscrollWeeklyWeatherMAx(true);
      } else {
        setscrollWeeklyWeatherMAx(false);
      }
    }
  };

  const getWetherIconFromMap = (id, icon) => {
    // exemple {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'}
    let iconObj = WeatherIconsMap.find((ic) => ic.id === id);
    if (appState.themeMode.includes("light")) {
      if (icon.includes("d")) {
        return GetImageUrl(iconObj.blackIcon.day);
      } else {
        return GetImageUrl(iconObj.blackIcon.night);
      }
    } else {
      if (icon.includes("d")) {
        return GetImageUrl(iconObj.whiteIcon.day);
      } else {
        return GetImageUrl(iconObj.whiteIcon.night);
      }
    }
  };
  const getHomeWetherIconFromMap = (id, icon) => {
    // exemple {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'}
    let iconObj = WeatherIconsMap.find((ic) => ic.id === id);

    if (icon.includes("d")) {
      if (
        appState.backgroundMode &&
        appState.backgroundMode.includes("light")
      ) {
        return GetImageUrl(iconObj.blackIcon.day);
      } else {
        return GetImageUrl(iconObj.whiteIcon.day);
      }
    } else {
      if (
        appState.backgroundMode &&
        appState.backgroundMode.includes("light")
      ) {
        return GetImageUrl(iconObj.blackIcon.night);
      } else {
        return GetImageUrl(iconObj.whiteIcon.night);
      }
    }
  };

  const checkScrollabelLeft = () => {};

  const weatherTempUnit = (temp) => {
    if (tempUnitCel) {
      return parseInt(temp);
    } else {
      return parseInt((temp * 9) / 5 + 32);
    }
  };

  const wetherTimeFormatForPin = (date) => {
    let dateFormat = new Date(date * 1000).toLocaleString("en-US", {
      hour: "numeric",
      hour12: !clockState.hour24
    });
    let dateArray = dateFormat.split(" ");
    if (!clockState.hour24) {
      return (
        <div className="weather-hourly-time-container">
          <span className="weather-pin-txt-2">{dateArray[0]}</span>
          <span className="weather-pin-hourly-time-container-am-txt">
            {dateArray[1]}
          </span>
        </div>
      );
    } else {
      // return <span className="weather-popover-txt-2">{dateFormat + " "}h</span>;
      return (
        <div className="weather-hourly-time-container">
          <span className="weather-pin-txt-2">{dateFormat}</span>
          <span className="weather-pin-hourly-time-container-am-txt">H</span>
        </div>
      );
    }
  };
  const wetherTimeFormat = (date) => {
    let dateFormat = new Date(date * 1000).toLocaleString("en-US", {
      hour: "numeric",
      hour12: !clockState.hour24
    });
    let dateArray = dateFormat.split(" ");
    if (!clockState.hour24) {
      return (
        <div className="weather-hourly-time-container">
          <span className="weather-popover-txt-2">{dateArray[0]}</span>
          <span className="weather-hourly-time-container-am-txt">
            {dateArray[1]}
          </span>
        </div>
      );
    } else {
      return <span className="weather-popover-txt-2">{dateFormat + " "}h</span>;
    }
  };
  const gridItem = 2;
  const gridItemInfo = 2;

  if (geoAccess) {
    if (props.display || props.opacity === 0) {
      return (
        <div
          className="weather-component"
          key="weather-container-component"
          style={{ opacity: 0, cursor: "default" }}
        >
          {pinWeatherToHome && todayWeather && todayWeather.weather ? (
            <div className="weather-container" style={{ cursor: "default" }}>
              <div
                className="weather-container-main-info"
                style={{ cursor: "default" }}
              >
                <div className="container-img-temperature">
                  <img
                    src={getHomeWetherIconFromMap(
                      todayWeather.weather.id,
                      todayWeather.weather.icon
                    )}
                    alt="weather status icon"
                    className="home-icon-wether-main-size"
                  />
                  <span
                    className="weather-temperature-txt"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    {weatherTempUnit(todayWeather.weather.temp)}&deg;
                    {tempUnitCel ? "C" : "F"}
                  </span>
                </div>

                <div className="container-weather-place">
                  <span className="weather-country">
                    {todayWeather.location && todayWeather.location.name
                      ? todayWeather.location.name
                      : ""}
                  </span>
                </div>
              </div>
              {pinWeatherToHome &&
                pinWeatherToHome[0] &&
                pinWeatherToHome[0].weather && (
                  <div
                    className="container-weather-pin-home"
                    style={{ opacity: 0 }}
                  >
                    <div
                      className="container-weather-pin-home-item-active"
                      style={{ cursor: "default" }}
                      key={"hourlyWeather-0"}
                    >
                      <div className="container-weather-hourly-item-main">
                        <img
                          src={getHomeWetherIconFromMap(
                            pinWeatherToHome[0].weather[0].id,
                            pinWeatherToHome[0].weather[0].icon
                          )}
                          alt="weather status icon"
                          className="weather-popover-icon-secondairy home-icon-wether-secondary-size"
                        />

                        <span className="weather-pin-txt-3">
                          {weatherTempUnit(pinWeatherToHome[0].temp)}&deg;
                        </span>
                      </div>
                      <span
                        className="weather-pin-txt-2"
                        style={{ fontWeight: "600" }}
                      >
                        now
                      </span>
                    </div>
                  </div>
                )}
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div
          className="weather-component"
          key="weather-container-component"
          style={
            props.display
              ? { display: "none" }
              : props.opacity === 0
              ? { display: "none" }
              : { opacity: props.opacity }
          }
        >
          <div className="weather-container">
            <OverlayTrigger
              trigger="click"
              key="OverlayTrigger-weather"
              placement="bottom-start"
              rootClose={true}
              onEnter={() => {
                resetScrollingWeatherItem();
              }}
              overlay={
                <Popover id={`popover-weather`} className="weather-popover">
                  <Popover.Body className="weather-popover-body">
                    {todayWeather ? (
                      <div className="container-weather-today-main">
                        <div className="container-weather-today-main-location">
                          {weatherState.editingCity ? (
                            <WeatherCityForm />
                          ) : (
                            <span
                              className="weather-popover-txt-place"
                              style={{ marginBottom: "1rem" }}
                            >
                              {todayWeather.location &&
                              todayWeather.location.name
                                ? todayWeather.location.name
                                : ""}
                              <IconButton
                                className="weather-btn-edit-city"
                                onClick={() =>
                                  dispatch(updateEditingCity(true))
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </span>
                          )}
                          {todayWeather.location &&
                            todayWeather.location.sunrise && (
                              <Grid container rowSpacing={1} columnSpacing={1}>
                                <Grid
                                  key="weather-info-grid-sunrise"
                                  item
                                  xs={6}
                                >
                                  <span className="weather-popover-txt-2">
                                    sunrise:
                                  </span>
                                </Grid>
                                <Grid
                                  key="weather-info-grid-sunrise-value"
                                  item
                                  xs={6}
                                >
                                  <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                    {new Date(
                                      todayWeather.location.sunrise * 1000
                                    ).toLocaleString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: !clockState.hour24
                                    })}
                                  </span>
                                </Grid>
                              </Grid>
                            )}
                          {todayWeather.location &&
                            todayWeather.location.sunset && (
                              <Grid container rowSpacing={1} columnSpacing={1}>
                                <Grid
                                  key="weather-info-grid-sunset"
                                  item
                                  xs={6}
                                >
                                  <span className="weather-popover-txt-2">
                                    sunset:
                                  </span>
                                </Grid>
                                <Grid
                                  key="weather-info-grid-sunset-value"
                                  item
                                  xs={6}
                                >
                                  <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                    {new Date(
                                      todayWeather.location.sunset * 1000
                                    ).toLocaleString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: !clockState.hour24
                                    })}
                                  </span>
                                </Grid>
                              </Grid>
                            )}
                        </div>
                        <div className="container-weather-today-main-info">
                          {todayWeather && todayWeather.weather ? (
                            <div className="container-weather-today-main-cloud-info">
                              <img
                                src={getWetherIconFromMap(
                                  todayWeather.weather.id,
                                  todayWeather.weather.icon
                                )}
                                alt="weather status icon"
                                className="weather-popover-icon-main"
                              />
                              <span
                                className="weather-popover-txt-2"
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                {todayWeather.weather.description
                                  ? todayWeather.weather.description
                                  : ""}
                              </span>
                            </div>
                          ) : null}

                          <div className="container-weather-today-main-temp-info">
                            <div className="container-weather-today-main-temp-info-temp">
                              <span className="weather-popover-txt-main-temp">
                                {todayWeather.weather &&
                                todayWeather.weather.temp
                                  ? weatherTempUnit(todayWeather.weather.temp)
                                  : ""}
                                &deg;
                              </span>
                              <div className="container-weather-today-main-temp-unit">
                                <span
                                  onClick={(event) => {
                                    event.preventDefault();
                                    meaVanaConsole(
                                      "onClick F tempUnitCel:",
                                      tempUnitCel
                                    );

                                    dispatch(
                                      updateWeatherUSTempUnitToCelsius()
                                    );
                                  }}
                                  className={
                                    tempUnitCel
                                      ? "weather-popover-txt-temp-unit-active"
                                      : "weather-popover-txt-temp-unit"
                                  }
                                >
                                  C
                                </span>
                                <span
                                  onClick={(event) => {
                                    event.preventDefault();
                                    meaVanaConsole(
                                      "onClick F tempUnitCel:",
                                      tempUnitCel
                                    );

                                    dispatch(updateWeatherUSTempUnitToFar());
                                  }}
                                  className={
                                    !tempUnitCel
                                      ? "weather-popover-txt-temp-unit-active"
                                      : "weather-popover-txt-temp-unit"
                                  }
                                >
                                  F
                                </span>
                              </div>
                            </div>
                            {todayWeather &&
                            todayWeather.weather &&
                            todayWeather.weather.feelsLike ? (
                              <span
                                className="weather-popover-txt-2"
                                style={{ width: "100%", textAlign: "start" }}
                              >
                                Feels like{" "}
                                {" " +
                                  weatherTempUnit(
                                    todayWeather.weather.feelsLike
                                  )}
                                &deg;
                              </span>
                            ) : (
                              <span
                                className="weather-popover-txt-2"
                                style={{ width: "100%", textAlign: "start" }}
                              >
                                Feels like &deg;
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {hourForcastWeather && todayWeather ? (
                      <div className="weather-hourly ">
                        <span className="weather-popover-txt-hourly">
                          Today
                        </span>
                        {pinWeatherToHomeState ? (
                          <IconButton
                            className="weather-btn-pin"
                            onClick={() => {
                              dispatch(updatePinWeatherToHome(false));
                            }}
                          >
                            <PushPin />
                          </IconButton>
                        ) : (
                          <IconButton
                            className="weather-btn-pin"
                            onClick={() => {
                              dispatch(updatePinWeatherToHome(true));
                            }}
                          >
                            <PushPinOutlined />
                          </IconButton>
                        )}
                        <div className="container-weather-hourly">
                          {scrollHourlyWeatherValue !== 0 ? (
                            <div
                              className="weather-hourly-btn-scroll-left"
                              onClick={() => handleHourlyWeatherScroll(-400)}
                            >
                              <ArrowBackIos fontSize="small" />
                            </div>
                          ) : (
                            <div className="weather-hourly-btn-scroll-left">
                              <ArrowBackIos
                                style={{ opacity: 0.5 }}
                                fontSize="small"
                              />
                            </div>
                          )}

                          <div
                            className="subcontainer-weather-hourly"
                            ref={refHourlyWeather}
                            onScroll={scrollCheckHourlyWeather}
                          >
                            {hourForcastWeather.map((hourWeather, index) => {
                              return index === 0 ? (
                                <Tooltip
                                  arrow
                                  //followCursor
                                  key={"weather-hourly-item-" + index}
                                  title={
                                    todayWeather.weather &&
                                    todayWeather.weather.description
                                      ? todayWeather.weather.description
                                      : ""
                                  }
                                  placement="top"
                                >
                                  <div
                                    className="container-weather-hourly-item-active"
                                    key={"hourlyWeather" + index}
                                  >
                                    {todayWeather.weather && (
                                      <div className="container-weather-hourly-item-main">
                                        <img
                                          src={getWetherIconFromMap(
                                            todayWeather.weather.id,
                                            todayWeather.weather.icon
                                          )}
                                          alt="weather status icon"
                                          className="weather-popover-icon-secondairy"
                                        />

                                        <span className="weather-popover-txt-3">
                                          {weatherTempUnit(
                                            todayWeather.weather.temp
                                          )}
                                          &deg;
                                        </span>
                                      </div>
                                    )}

                                    <span
                                      className="weather-popover-txt-2"
                                      style={{ fontWeight: "600" }}
                                    >
                                      now
                                    </span>
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  arrow
                                  //followCursor
                                  key={"weather-hourly-item-" + index}
                                  title={hourWeather.weather[0].description}
                                  placement="top"
                                >
                                  <div
                                    className="container-weather-hourly-item"
                                    key={"hourlyWeather" + index}
                                  >
                                    <div className="container-weather-hourly-item-main">
                                      <img
                                        src={getWetherIconFromMap(
                                          hourWeather.weather[0].id,
                                          hourWeather.weather[0].icon
                                        )}
                                        alt="weather status icon"
                                        className="weather-popover-icon-secondairy"
                                      />

                                      <span className="weather-popover-txt-3">
                                        {weatherTempUnit(hourWeather.temp)}&deg;
                                      </span>
                                    </div>

                                    {wetherTimeFormat(hourWeather.dt)}
                                  </div>
                                </Tooltip>
                              );
                            })}
                          </div>
                          {!scrollHourlyWeatherMAx ? (
                            <div
                              className="weather-hourly-btn-scroll-right"
                              onClick={() => handleHourlyWeatherScroll(+400)}
                            >
                              <ArrowForwardIos fontSize="small" />
                            </div>
                          ) : (
                            <div className="weather-hourly-btn-scroll-right">
                              <ArrowForwardIos
                                style={{ opacity: 0.5 }}
                                fontSize="small"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {todayWeatherInfo ? (
                      <div className="container-weather-today-other-info">
                        <Grid container rowSpacing={1} columnSpacing={0.5}>
                          {todayWeatherInfo.clouds !== undefined ? (
                            <>
                              <Grid
                                key="weather-info-grid-cloudiness"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  Cloudiness:
                                </span>
                              </Grid>
                              <Grid
                                key="weather-info-grid-cloudiness-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {todayWeatherInfo.clouds}%
                                </span>
                              </Grid>
                            </>
                          ) : null}
                          {todayWeatherInfo.visibility ? (
                            <>
                              <Grid
                                key="weather-info-grid-visibility"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  visibility:
                                </span>
                              </Grid>
                              <Grid
                                key="weather-info-grid-visibility-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {parseInt(
                                    todayWeatherInfo.visibility / 1000
                                  ) + "   km"}
                                </span>
                              </Grid>
                            </>
                          ) : null}
                          {todayWeatherInfo.wind_speed ? (
                            <>
                              <Grid
                                key="weather-info-grid-Wind"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  Wind:
                                </span>
                              </Grid>
                              <Grid
                                key="weather-info-grid-Wind-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {parseInt(todayWeatherInfo.wind_speed * 3.6) +
                                    " km/h"}
                                </span>
                              </Grid>
                            </>
                          ) : null}
                          {todayWeatherInfo.humidity ? (
                            <>
                              <Grid
                                key="weather-info-grid-Humidity"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  Humidity:
                                </span>
                              </Grid>

                              <Grid
                                key="weather-info-grid-Humidity-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {todayWeatherInfo.humidity}%
                                </span>
                              </Grid>
                            </>
                          ) : null}
                          {todayWeatherInfo.uvi ? (
                            <>
                              <Grid
                                key="weather-info-grid-UVI"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  UVI:
                                </span>
                              </Grid>
                              <Grid
                                key="weather-info-grid-UVI-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {todayWeatherInfo.uvi}
                                </span>
                              </Grid>
                            </>
                          ) : null}
                          {todayWeatherInfo.pressure ? (
                            <>
                              <Grid
                                key="weather-info-grid-Pressure"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  Pressure:
                                </span>
                              </Grid>
                              <Grid
                                key="weather-info-grid-Pressure-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {parseFloat(todayWeatherInfo.pressure / 100) +
                                    "  hPa"}
                                </span>
                              </Grid>
                            </>
                          ) : null}
                          {todayWeatherInfo.rain &&
                          todayWeatherInfo.rain["1h"] ? (
                            <>
                              <Grid
                                key="weather-info-grid-rain"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  Rain volume:
                                </span>
                              </Grid>
                              <Grid
                                key="weather-info-grid-rain-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {parseInt(todayWeatherInfo.rain["1h"])} mm
                                </span>
                              </Grid>
                            </>
                          ) : null}
                          {todayWeatherInfo.snow &&
                          todayWeatherInfo.snow["1h"] ? (
                            <>
                              <Grid
                                key="weather-info-grid-snow"
                                item
                                xs={gridItem}
                              >
                                <span className="weather-popover-txt-2">
                                  Snow volume:
                                </span>
                              </Grid>
                              <Grid
                                key="weather-info-grid-snow-value"
                                item
                                xs={gridItemInfo}
                              >
                                <span className="weather-popover-txt-2 weather-popover-txt-dim">
                                  {parseInt(todayWeatherInfo.snow["1h"])} mm
                                </span>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </div>
                    ) : null}
                    {weekForcastWeather ? (
                      <div className="weather-weekly">
                        <span className="weather-popover-txt-weekly">Week</span>
                        <div className="container-weather-weekly">
                          {scrollWeeklyWeatherValue !== 0 ? (
                            <div
                              className="weather-hourly-btn-scroll-left"
                              onClick={() => handleWeeklyWeatherScroll(-400)}
                            >
                              <ArrowBackIos fontSize="small" />
                            </div>
                          ) : (
                            <div className="weather-hourly-btn-scroll-left">
                              <ArrowBackIos
                                style={{ opacity: 0.5 }}
                                fontSize="small"
                              />
                            </div>
                          )}
                          <div
                            className="subcontainer-weather-weekly"
                            ref={refWeeklyWeather}
                            onScroll={scrollCheckWeeklyWeather}
                          >
                            {weekForcastWeather.map((dayWeath, index) => {
                              return (
                                <Tooltip
                                  arrow
                                  //followCursor
                                  key={"weather-daily-item-" + index}
                                  title={dayWeath.weather[0].description}
                                  placement="top"
                                >
                                  <div
                                    className="container-weather-weekly-item-active"
                                    key={"weeklyWeather" + index}
                                  >
                                    <div className="container-weather-weekly-item-main">
                                      <img
                                        src={getWetherIconFromMap(
                                          dayWeath.weather[0].id,
                                          dayWeath.weather[0].icon
                                        )}
                                        alt="weather status icon"
                                        className="weather-popover-icon-secondairy"
                                      />
                                      <div className="weather-weekly-container-temp-max-min">
                                        <span className="weather-popover-txt-3">
                                          {weatherTempUnit(dayWeath.temp.max)}
                                          &deg;
                                        </span>
                                        <span className="weather-popover-txt-3">
                                          {weatherTempUnit(dayWeath.temp.min)}
                                          &deg;
                                        </span>
                                      </div>
                                    </div>
                                    <span
                                      className="weather-popover-txt-2"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {index === 0
                                        ? "today"
                                        : new Date(
                                            dayWeath.dt * 1000
                                          ).toLocaleString("en-US", {
                                            weekday: "short"
                                          })}
                                    </span>
                                  </div>
                                </Tooltip>
                              );
                            })}
                          </div>
                          {!scrollWeeklyWeatherMAx ? (
                            <div
                              className="weather-hourly-btn-scroll-right"
                              onClick={() => handleWeeklyWeatherScroll(+400)}
                            >
                              <ArrowForwardIos fontSize="small" />
                            </div>
                          ) : (
                            <div className="weather-hourly-btn-scroll-right">
                              <ArrowForwardIos
                                style={{ opacity: 0.5 }}
                                fontSize="small"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </Popover.Body>
                </Popover>
              }
            >
              {todayWeather ? (
                <div className="weather-container-main-info">
                  <Tooltip
                    key="tooltip-weather-today-img-temp"
                    arrow
                    //followCursor
                    title={
                      todayWeather.weather && todayWeather.weather.description
                        ? todayWeather.weather.description
                        : ""
                    }
                    placement="top"
                  >
                    <div className="container-img-temperature">
                      <img
                        src={
                          todayWeather.weather
                            ? getHomeWetherIconFromMap(
                                todayWeather.weather.id,
                                todayWeather.weather.icon
                              )
                            : ""
                        }
                        alt="weather status icon"
                        className="home-icon-wether-main-size"
                      />

                      <span
                        className="weather-temperature-txt"
                        style={{ marginLeft: "0.5rem" }}
                      >
                        {todayWeather.weather && todayWeather.weather.temp
                          ? weatherTempUnit(todayWeather.weather.temp)
                          : ""}
                        &deg;
                        {tempUnitCel ? "C" : "F"}
                      </span>
                    </div>
                  </Tooltip>
                  <Tooltip
                    arrow
                    key="tooltip-weather-today-country"
                    //followCursor
                    title={
                      todayWeather.location &&
                      todayWeather.location.name &&
                      todayWeather.location.country
                        ? todayWeather.location.name +
                          ", " +
                          countries.getName(
                            todayWeather.location.country,
                            "en",
                            {
                              select: "official"
                            }
                          )
                        : ""
                    }
                    placement="top"
                  >
                    <div className="container-weather-place">
                      <span className="weather-country">
                        {todayWeather.location && todayWeather.location.name
                          ? todayWeather.location.name
                          : ""}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              ) : null}
            </OverlayTrigger>
            {pinWeatherToHome ? (
              <div
                className="container-weather-pin-home"
                style={!pinWeatherToHomeState ? { opacity: 0 } : {}}
              >
                {pinWeatherToHome.slice(0, 4).map((pinWeatherItem, index) => {
                  return (
                    <Tooltip
                      arrow
                      title={
                        index === 0
                          ? todayWeather &&
                            todayWeather.weather &&
                            todayWeather.weather.description
                            ? todayWeather.weather.description
                            : "---"
                          : pinWeatherItem.weather[0].description
                      }
                      placement="top"
                      hidden={!pinWeatherToHomeState}
                      key={"pin-wether-tooltip-" + index}
                    >
                      <div
                        className="container-weather-pin-home-item"
                        key={"hourlyWeather" + index}
                      >
                        {todayWeather && todayWeather.weather && (
                          <div className="container-weather-hourly-item-main">
                            <img
                              src={
                                index === 0
                                  ? getHomeWetherIconFromMap(
                                      todayWeather.weather.id,
                                      todayWeather.weather.icon
                                    )
                                  : getHomeWetherIconFromMap(
                                      pinWeatherItem.weather[0].id,
                                      pinWeatherItem.weather[0].icon
                                    )
                              }
                              alt="weather status icon"
                              className="weather-popover-icon-secondairy home-icon-wether-secondary-size"
                            />

                            <span className="weather-pin-txt-3">
                              {index === 0
                                ? weatherTempUnit(todayWeather.weather.temp)
                                : weatherTempUnit(pinWeatherItem.temp)}
                              &deg;
                            </span>
                          </div>
                        )}

                        {index === 0 ? (
                          <span
                            className="weather-pin-txt-2"
                            style={{ fontWeight: "600" }}
                          >
                            now
                          </span>
                        ) : (
                          wetherTimeFormatForPin(pinWeatherItem.dt)
                        )}
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  } else {
    return (
      <div
        className="weather-component"
        style={
          props.display
            ? { display: "none" }
            : props.opacity === 0
            ? { display: "none" }
            : { opacity: props.opacity }
        }
      >
        <div className="weather-container">
          {weatherState.editingCity ? (
            <WeatherCityForm />
          ) : (
            <div className="container-weather-place">
              <span className="weather-country">
                Allow Weather Access / Enter a city
              </span>
              <IconButton
                className="weather-btn-edit-city"
                onClick={() => dispatch(updateEditingCity(true))}
              >
                <EditIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}
