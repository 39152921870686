import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { bookmarkStateAction } from './constants';

export const getBookmarksAction = (bookmarkList) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.GET_DASHBOARD_BOOKMARKS,
      payload: bookmarkList
    });
  };
};
export const getChromeBookmarksAction = (bookmarkList = []) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.GET_CHROME_BOOKMARKS,
      payload: bookmarkList
    });
  };
};
export const getTopVisitedSitesAction = (topVisitedSitesList = []) => {
  meaVanaConsole('topVisitedSitesList >>> ', topVisitedSitesList);
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.GET_TOP_VISITED_SITES,
      payload: topVisitedSitesList
    });
  };
};

export const updateDialogDeleteAllBookmarkAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SET_DIALOG_DELETE_ALL_BOOKMARKS_VALUE,
      value: value
    });
  };
};

//set the bookmark size based on size submitted as param
export const setBookmarkSizeBig = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SET_BOOKMARK_SIZE,
      payload: value
    });
  };
};

//set the bookmark size based on size submitted as param
export const setShowChromeBookmark = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SHOW_CHROME_BOOKMARKS,
      payload: value
    });
  };
};

export const setShowTopVisitedSites = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SHOW_TOP_VISITED_SITES,
      payload: value
    });
  };
};

export const setBookmarkNewWindow = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SET_BOOKMARK_NEW_WINDOW,
      payload: value
    });
  };
};

export const setBookmarkPositionTop = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SET_BOOKMARK_POSITION_TOP,
      payload: value
    });
  };
};

//set the bookmark color based on color submitted as param
export const setBookmarkToBlackWhite = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SET_BOOKMARK_COLOR,
      payload: value
    });
  };
};

//set the bookmark shape based on shape submitted as param
export const setBookmarkShapeRectangle = (value) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SET_BOOKMARK_SHAPE,
      payload: value
    });
  };
};

// action deleteAllBookmarks request
export const deleteAllBookmarksRequestAction = () => {
  return (dispatch) => {
    dispatch({ type: bookmarkStateAction.DELETE_ALL_BOOKMARKS_REQUEST });
  };
};

// action deleteAllBookmarks success
export const deleteAllBookmarksSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.DELETE_ALL_BOOKMARKS_SUCCESS,
      payload: data
    });
  };
};

// action deleteAllBookmarks failure
export const deleteAllBookmarksFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.DELETE_ALL_BOOKMARKS_FAILURE,
      error: error
    });
  };
};

// action update chrome bookmark failure
export const updateChromeBookmarkAction = (reorderedBookmarks) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_CHROME_BOOKMARKS_TAB,
      payload: reorderedBookmarks
    });
  };
};

export const updateBookmarksListAction = (updatedBookmarks) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_LIST,
      payload: {
        bookmarks: updatedBookmarks
      }
    });
  };
};

// action updateBookmarkBulkList request
export const updateBookmarkBulkListRequestAction = (bookmarks) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_BULK_LIST_REQUEST,
      payload: bookmarks
    });
  };
};

// action updateBookmarkBulkList success
export const updateBookmarkBulkListSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_BULK_LIST_SUCCESS,
      payload: data
    });
  };
};

// action updateBookmarkBulkList failure
export const updateBookmarkBulkListFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_BULK_LIST_FAILURE,
      error: error
    });
  };
};

export const setSelectedBookmark = (bookmark) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SET_SELECTED_BOOKMARK,
      payload: bookmark
    });
  };
};

// action addBookmark request
export const addBookmarkRequestAction = (bookmark) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.ADD_BOOKMARK_REQUEST,
      payload: { bookmark: bookmark }
    });
  };
};

// action addBookmark success
export const addBookmarkSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.ADD_BOOKMARK_SUCCESS,
      payload: data
    });
  };
};

// action addBookmark failure
export const addBookmarkFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.ADD_BOOKMARK_FAILURE,
      error: error
    });
  };
};

// action updateBookmark request
export const updateBookmarkRequestAction = (bookmark) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_REQUEST,
      payload: { bookmark: bookmark }
    });
  };
};

// action updateBookmark success
export const updateBookmarkSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_SUCCESS,
      payload: data
    });
  };
};

// action updateBookmark failure
export const updateBookmarkFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_FAILURE,
      error: error
    });
  };
};

// action deleteBookmark request
export const deleteBookmarkRequestAction = (bookmark) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.DELETE_BOOKMARK_REQUEST,
      payload: { bookmark: bookmark }
    });
  };
};

// action deleteBookmark success
export const deleteBookmarkSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.DELETE_BOOKMARK_SUCCESS,
      payload: data
    });
  };
};

// action deleteBookmark failure
export const deleteBookmarkFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.DELETE_BOOKMARK_FAILURE,
      error: error
    });
  };
};

// action deleteBookmark failure
export const updateBookmarkTopicStylingAction = (bookmarkList) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.UPDATE_BOOKMARK_TOPIC_STYLING_LIST,
      payload: bookmarkList
    });
  };
};
// action deleteBookmark failure
export const updateShowBookmarkTopicStylingAction = (state) => {
  return (dispatch) => {
    dispatch({
      type: bookmarkStateAction.SHOW_BOOKMARKS_TOPIC_STYLING,
      payload: state
    });
  };
};

/*
export const deleteAllBookmarksGuestModeAction = () => {
  return {
    type: DELETE_ALL_BOOKMARKS_GUEST_MODE,
  };
};

export const addBookmarkGuestModeAction = (bookmark) => {
  return {
    type: ADD_BOOKMARK_GUEST_MODE,
    bookmark: bookmark,
  };
};
export const deleteBookmarkGuestModeAction = (index) => {
  return {
    type: DELETE_BOOKMARK_GUEST_MODE,
    index: index,
  };
};
export const updateBookmarkGuestModeAction = (bookmarks) => {
  return {
    type: UPDATE_BOOKMARKS_GUEST_MODE,
    bookmarks: bookmarks,
  };
};
*/
