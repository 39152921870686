import { SERVER_URL } from "../../config/constants";
import {
  addBookmarkFailureAction,
  addBookmarkRequestAction,
  addBookmarkSuccessAction,
  deleteAllBookmarksFailureAction,
  deleteAllBookmarksRequestAction,
  deleteAllBookmarksSuccessAction,
  deleteBookmarkFailureAction,
  deleteBookmarkRequestAction,
  deleteBookmarkSuccessAction,
  updateBookmarkBulkListFailureAction,
  updateBookmarkBulkListRequestAction,
  updateBookmarkBulkListsListAction,
  updateBookmarkBulkListSuccessAction,
  updateBookmarkFailureAction,
  updateBookmarkRequestAction,
  updateBookmarkSuccessAction
} from "../../store/reducers/bookmarksReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import axiosInstance from "../Middleware/axiosInstance";
import { checkErrorResponseFormat } from "../Middleware/utils.server";

/**
 * the action for adding for adding a new Bookmark.
 *
 * @param {string} bookmark - The bookmark object.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const addBookmarkServer = (bookmark, dashboardId) => {
  return async (dispatch) => {
    try {
      dispatch(addBookmarkRequestAction(bookmark));
      axiosInstance()
        .post(
          SERVER_URL + "dashboard/" + dashboardId + "/bookmarks/add",
          bookmark
        )
        .then((response) => {
          dispatch(addBookmarkSuccessAction(response.data));
        })
        .catch((error1) => {
          meaVanaConsole("addBookmark err1 ", checkErrorResponseFormat(error1));

          dispatch(addBookmarkFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("addBookmark err2", checkErrorResponseFormat(error));

      dispatch(addBookmarkFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

/**
 * the action for updating an existing Bookmark.
 *
 * @param {string} bookmark - The bookmark object.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const updateBookmarkServer = (bookmark, dashboardId) => {
  return async (dispatch) => {
    try {
      dispatch(updateBookmarkRequestAction(bookmark));

      axiosInstance()
        .put(
          SERVER_URL +
            "dashboard/" +
            dashboardId +
            "/bookmarks/update/" +
            bookmark._id,
          bookmark
        )
        .then((response) => {
          dispatch(updateBookmarkSuccessAction(bookmark));
        })
        .catch((error1) => {
          meaVanaConsole(
            "updateBookmark err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            updateBookmarkFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole("addBookmark err2", checkErrorResponseFormat(error));

      dispatch(updateBookmarkFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

/**
 * the action for updating an existing Bookmark.
 *
 * @param {string} bookmarkId - The bookmark Id of Bookmark to be deleted.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const deleteBookmarkServer = (bookmark, dashboardId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteBookmarkRequestAction(bookmark));
      let _deleteBookmarkDto = {
        index: bookmark.index
      };
      axiosInstance()
        .delete(
          SERVER_URL +
            "dashboard/" +
            dashboardId +
            "/bookmarks/item/" +
            bookmark._id,
          { data: _deleteBookmarkDto }
        )
        .then((response) => {
          dispatch(deleteBookmarkSuccessAction(bookmark));
        })
        .catch((error1) => {
          meaVanaConsole(
            "deleteBookmarkAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            deleteBookmarkFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "deleteBookmarkAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(deleteBookmarkFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

/**
 * the action for bulk updating existing Bookmarks.
 *
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 * @param {array} bookmarks - The bookmarks Array of bookmark objects.
 *
 */
export const bulkUpdateBookmarksServer = (dashboardId, bookmarks) => {
  return async (dispatch) => {
    try {
      let bookmarksDto = {
        bookmarks: bookmarks
      };
      dispatch(updateBookmarkBulkListRequestAction(bookmarks));
      axiosInstance()
        .put(
          SERVER_URL + "dashboard/" + dashboardId + "/bookmarks/bulk-update",
          bookmarksDto
        )
        .then((response) => {
          meaVanaConsole("response : ", response);

          dispatch(updateBookmarkBulkListSuccessAction(bookmarks));
        })
        .catch((error1) => {
          meaVanaConsole(
            "bulkUpdateBookmarkAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            updateBookmarkBulkListFailureAction(
              checkErrorResponseFormat(error1)
            )
          );
        });
    } catch (error) {
      meaVanaConsole(
        "bulkUpdateBookmarkAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(
        updateBookmarkBulkListFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

//remove all bookmarks of a user(bulk delete)
export const deleteAllBookmarksServer = (dashboardId) => {
  return async (dispatch) => {
    dispatch(deleteAllBookmarksRequestAction());

    try {
      axiosInstance()
        .delete(SERVER_URL + "dashboard/" + dashboardId + "/bookmarks/all")
        .then((response) => {
          dispatch(deleteAllBookmarksSuccessAction(response));
        })
        .catch((error1) => {
          meaVanaConsole(
            "deleteAllBookmarksAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            deleteAllBookmarksFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "deleteAllBookmarksAction err2",
        checkErrorResponseFormat(error)
      );
      dispatch(
        deleteAllBookmarksFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};
