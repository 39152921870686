import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import './Marketing.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import { InstallDesktop, KeyboardBackspace, WidgetsRounded } from '@mui/icons-material';
import useWindowDimensions from '../../../Utils/useWindowDimensions';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';

import { assetsIcons } from '../../../config/images';

export default function InstallExtBtn2(props) {
  const appState = useSelector((state) => state.app);
  const integrationState = useSelector((state) => state.integration);
  const dispatch = useDispatch();

  const [integrationAppLevel, setIntegrationAppLevel] = useState(0);
  const [integrationAppIndex, setIntegrationAppIndex] = useState(0);

  const [iconSrc, setIconSrc] = useState(assetsIcons.integration.white);
  const [iconOpacity, setIconOpacity] = useState(1);

  const { height, width } = useWindowDimensions();
  //Starts: temp code for topic based guest mode flow
  const bitlyArray = [
    {
      id: 0,
      slug: '',
      bitly: 'https://bit.ly/3IUYGIW'
    },
    {
      id: 1,
      slug: 'basketball',
      bitly: 'https://bit.ly/3TWqGBC'
    },
    {
      id: 2,
      slug: 'black-and-white',
      bitly: 'https://bit.ly/3Qke6JQ'
    },
    {
      id: 3,
      slug: 'landscape',
      bitly: 'https://bit.ly/3IUYGIW'
    },
    {
      id: 4,
      slug: 'lofi',
      bitly: 'https://bit.ly/3WPss9D'
    },
    {
      id: 5,
      slug: 'messi',
      bitly: 'https://bit.ly/3C3ETWf'
    },
    {
      id: 6,
      slug: 'space',
      bitly: 'http://bit.ly/3vtwuYA'
    },
    {
      id: 7,
      slug: 'wildlife',
      bitly: 'http://bit.ly/3WCTIHH'
    },
    {
      id: 8,
      slug: 'zen',
      bitly: 'https://bit.ly/3JkroEa'
    },
    {
      id: 9,
      slug: 'tom-cruise',
      bitly: 'https://bit.ly/3I2ccNA'
    },
    {
      id: 10,
      slug: 'shah-rukh-khan',
      bitly: 'http://bit.ly/3GugJH3'
    },
    {
      id: 11,
      slug: 'elon-musk',
      bitly: 'https://bit.ly/3jvhKWv'
    },
    {
      id: 12,
      slug: 'magnus-carlsen',
      bitly: 'http://bit.ly/3jHQq7j'
    },
    {
      id: 13,
      slug: 'michael-phelps',
      bitly: 'http://bit.ly/3Q8WZwa'
    },
    {
      id: 14,
      slug: 'neymar',
      bitly: 'https://bit.ly/3Cfa0hL'
    },
    {
      id: 15,
      slug: 'virat-kohli',
      bitly: 'http://bit.ly/3Q3MY30'
    },
    {
      id: 16,
      slug: 'cat',
      bitly: 'http://bit.ly/3CfeuVD'
    },
    {
      id: 17,
      slug: 'new-york',
      bitly: 'http://bit.ly/3vqCktV'
    }
  ];

  const [currBitlyLink, setCurrBitlyLink] = useState(bitlyArray[0]);

  useEffect(function () {
    loadTopicBasedBitly();
  }, []);

  const loadTopicBasedBitly = () => {
    const currpath = window.location.pathname;
    var _bitlyObj = bitlyArray.find((bitlyObj) => bitlyObj.slug === currpath.replace('/', ''));
    if (_bitlyObj) setCurrBitlyLink(_bitlyObj);
  };

  const onClickChromeExtBtn = () => {
    //window.location.href = currBitlyLink.bitly;
    //for opening in a complete new tab
    window.open(currBitlyLink.bitly, '_blank');
  };
  //Ends: temp code for topic based guest mode flow

  useEffect(() => {
    switch (appState.backgroundMode) {
      case 'background-home-picture':
        setIconSrc(assetsIcons.Chrome.white);

        setIconOpacity(1);
        break;
      case 'background-home-dark':
        setIconSrc(assetsIcons.Chrome.white);
        setIconOpacity(1);
        break;
      case 'background-home-light':
        setIconSrc(assetsIcons.Chrome.black);
        setIconOpacity(0.6);
        break;
      default:
        setIconSrc(assetsIcons.Chrome.white);
        setIconOpacity(1);
        break;
    }
  }, [appState.backgroundMode]);
  return (
    <div
      onClick={onClickChromeExtBtn}
      className="container-btn-integration marketing-btn"
      style={props.display ? { display: 'none' } : { opacity: props.opacity }}>
      {/* Icons commented for now */}
      {/* <img
            className="home-icon-font-size icon-integration"
            style={{ opacity: iconOpacity }}
            src={iconSrc}
            alt="integration icon"
          /> */}

      <div className="marketing-btn-text ">
        <i className="text-light">
          <small>Transform your Chrome!</small>
        </i>
        <div className="d-flex justify-content-center">
          <span>Add to Chrome |</span>
          <span className="text-light px-1 font">
            <small>It's Free</small>
          </span>
        </div>
      </div>
    </div>
  );
}
