export const initialState = {
  active: false,
  currentAnimation: null,
  animations: [
    {
      name: "background-purple-space",
      label: "Space"
    },
    {
      name: "background-green-field",
      label: "Hills"
    },
    {
      name: "background-blue-ocean",
      label: "Ocean"
    }
  ],
  error: null,
  loading: false
};

export const animBackgroundStateAction = {
  UPDATE_ANIMATION_BACKGROUND: "animation/UPDATE_ANIMATION_BACKGROUND",
  UPDATE_ACTIVE_ANIMATION_BACKGROUND:
    "animation/UPDATE_ACTIVE_ANIMATION_BACKGROUND"
};
