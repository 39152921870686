import { Photo } from "@mui/icons-material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateWallpaperWindowStateAction } from "../../store/reducers/wallpaperReducer/action";

import "./WallpaperFeature.css";

export default function WallpaperFeature(props) {
  const dispatch = useDispatch();

  if (props.display || props.opacity === 0) {
    return (
      <div
        className="container-btn-Wallpaper"
        style={{ opacity: 0, cursor: "inherit" }}
      >
        <Photo wallpaper-icon-font-size />
      </div>
    );
  } else {
    return (
      <div
        className="container-btn-Wallpaper"
        style={props.display ? { display: "none" } : { opacity: props.opacity }}
        onClick={() => {
          dispatch(updateWallpaperWindowStateAction());
        }}
      >
        <Photo className="wallpaper-icon-font-size" />
      </div>
    );
  }
}
