import React, { useEffect, useState } from "react";
import { IconButton, CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import validator from "validator";
import { UpdateUserPasswordUserServer } from "../../../server/Auth/user.server";
export default function UpdatePasswordComponent(props) {
  const stateAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [showOldPassword, setshowOldPassword] = useState(false);
  const [inputOldPassword, setinputOldPassword] = useState("");
  const [inputOldPasswordTouched, setinputOldPasswordTouched] = useState(false);
  const [OldPasswordErrorMsg, setOldPasswordErrorMsg] = useState({
    message: "",
    error: false
  });

  const [showNewPassword, setshowNewPassword] = useState(false);

  const [inputNewPassword, setinputNewPassword] = useState("");
  const [inputNewPasswordTouched, setinputNewPasswordTouched] = useState(false);
  const [NewPasswordErrorMsg, setNewPasswordErrorMsg] = useState({
    message: "",
    error: false
  });

  const [showConfirmNewPassword, setshowConfirmNewPassword] = useState(false);

  const [inputConfirmNewPassword, setinputConfirmNewPassword] = useState("");
  const [inputConfirmNewPasswordTouched, setinputConfirmNewPasswordTouched] =
    useState(false);
  const [ConfirmNewPasswordErrorMsg, setConfirmNewPasswordErrorMsg] = useState({
    message: "",
    error: false
  });

  useEffect(() => {
    setinputOldPassword("");
    setinputOldPasswordTouched(false);
    setinputNewPassword("");
    setinputNewPasswordTouched(false);
    setinputConfirmNewPassword("");
    setinputConfirmNewPasswordTouched(false);
    setshowNewPassword(false);
    setshowConfirmNewPassword(false);
    setshowOldPassword(false);
    setOldPasswordErrorMsg({
      message: "",
      error: false
    });

    setNewPasswordErrorMsg({
      message: "",
      error: false
    });

    setConfirmNewPasswordErrorMsg({
      message: "",
      error: false
    });
  }, []);
  const validateFormUpdateUserPassword = () => {
    verifOldPasswordFormat(inputOldPassword);
    verifNewPasswordFormat(inputNewPassword);
    verifConfirmNewPasswordFormat(inputConfirmNewPassword);
  };

  const verifOldPasswordFormat = (password = "") => {
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setOldPasswordErrorMsg({
        error: true,
        message: "Please enter a password"
      });
    } else {
      if (password.length < 5) {
        setOldPasswordErrorMsg({
          error: true,
          message: "Please enter a password longer than 5 characters"
        });
      } else if (password.length > 32) {
        setOldPasswordErrorMsg({
          error: true,
          message: "Please enter a password shorter than 32 characters"
        });
      } else {
        setOldPasswordErrorMsg({ error: false, message: "" });
      }
    }
  };
  const verifNewPasswordFormat = (password = "") => {
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setNewPasswordErrorMsg({
        error: true,
        message: "Please enter a password"
      });
    } else {
      if (password.length < 5) {
        setNewPasswordErrorMsg({
          error: true,
          message: "Please enter a password longer than 5 characters"
        });
      } else if (password.length > 32) {
        setNewPasswordErrorMsg({
          error: true,
          message: "Please enter a password shorter than 32 characters"
        });
      } else {
        setNewPasswordErrorMsg({ error: false, message: "" });
      }
    }
  };
  const verifConfirmNewPasswordFormat = (password = "") => {
    if (validator.isEmpty(password, { ignore_whitespace: true })) {
      setConfirmNewPasswordErrorMsg({
        error: true,
        message: "Please enter a password"
      });
    } else {
      if (password.length < 5) {
        setConfirmNewPasswordErrorMsg({
          error: true,
          message: "Please enter a password longer than 5 characters"
        });
      } else if (password.length > 32) {
        setConfirmNewPasswordErrorMsg({
          error: true,
          message: "Please enter a password shorter than 32 characters"
        });
      } else {
        setConfirmNewPasswordErrorMsg({ error: false, message: "" });
      }
    }
  };
  const updateUserPasswordButtonAction = (event) => {
    event.preventDefault();
    validateFormUpdateUserPassword();

    if (
      ((inputOldPasswordTouched && !OldPasswordErrorMsg.error) ||
        stateAuth.user.isSocial) &&
      inputNewPasswordTouched &&
      !NewPasswordErrorMsg.error &&
      inputConfirmNewPasswordTouched &&
      !ConfirmNewPasswordErrorMsg.error
    ) {
      let newUserPassword = {
        oldPassword: inputOldPassword,
        newPassword: inputNewPassword,
        passwordConfirm: inputConfirmNewPassword
      };
      dispatch(UpdateUserPasswordUserServer(newUserPassword));
    } else {
    }
  };

  return (
    <div className="list-settings-profile">
      <div className="setting-profile-list-groupe-header">
        <p className="setting-list-groupe-header-title">Update Password</p>
      </div>
      {stateAuth.successUpdateUserPassword ? (
        <div className="container-profile-form">
          <div className="profile-form-group">
            <label>{stateAuth.responseUpdateUserPassword.message}</label>
          </div>

          <div className="profile-form-submit-area">
            <Button
              className="contact-form-btn-send"
              variant="contained"
              color="info"
              onClick={(event) => {
                event.preventDefault();
                props.goBackToProfileInfo();
              }}
            >
              go back to profile
            </Button>
          </div>
        </div>
      ) : (
        <form
          onSubmit={updateUserPasswordButtonAction}
          className="container-profile-form"
        >
          {stateAuth.user.isSocial ? null : (
            <div className="profile-form-group">
              <label>Old Password</label>
              <div className="container-input-icon-auth">
                <input
                  type={showOldPassword ? "text" : "password"}
                  className={
                    OldPasswordErrorMsg.error
                      ? "form-control input-error"
                      : "form-control"
                  }
                  id="password"
                  placeholder="Enter password"
                  value={inputOldPassword}
                  onFocus={(e) => {
                    setOldPasswordErrorMsg({ error: false, message: "" });
                  }}
                  onBlur={(e) => {
                    verifOldPasswordFormat(inputOldPassword);
                  }}
                  onChange={(event) => {
                    setinputOldPassword(event.target.value);
                    setinputOldPasswordTouched(true);
                    verifOldPasswordFormat(event.target.value);
                  }}
                />
                {!showOldPassword ? (
                  <IconButton
                    className="button-icon-auth"
                    onClick={() => setshowOldPassword(true)}
                  >
                    <VisibilityOff size="small" />
                  </IconButton>
                ) : (
                  <IconButton
                    className="button-icon-auth"
                    onClick={() => setshowOldPassword(false)}
                  >
                    <Visibility size="small" />
                  </IconButton>
                )}
              </div>

              <p className="error-msg-txt">{OldPasswordErrorMsg.message}</p>
            </div>
          )}

          <div className="profile-form-group">
            <label>New Password</label>
            <div className="container-input-icon-auth">
              <input
                type={showNewPassword ? "text" : "password"}
                className={
                  NewPasswordErrorMsg.error
                    ? "form-control input-error"
                    : "form-control"
                }
                id="password"
                placeholder="Enter password"
                value={inputNewPassword}
                onFocus={(e) => {
                  setNewPasswordErrorMsg({ error: false, message: "" });
                }}
                onBlur={(e) => {
                  verifNewPasswordFormat(inputNewPassword);
                }}
                onChange={(event) => {
                  setinputNewPassword(event.target.value);
                  setinputNewPasswordTouched(true);
                  verifNewPasswordFormat(event.target.value);
                }}
              />
              {!showNewPassword ? (
                <IconButton
                  className="button-icon-auth"
                  onClick={() => setshowNewPassword(true)}
                >
                  <VisibilityOff size="small" />
                </IconButton>
              ) : (
                <IconButton
                  className="button-icon-auth"
                  onClick={() => setshowNewPassword(false)}
                >
                  <Visibility size="small" />
                </IconButton>
              )}
            </div>

            <p className="error-msg-txt">{NewPasswordErrorMsg.message}</p>
          </div>
          <div className="profile-form-group">
            <label>Confirm New Password</label>
            <div className="container-input-icon-auth">
              <input
                type={showConfirmNewPassword ? "text" : "password"}
                className={
                  ConfirmNewPasswordErrorMsg.error
                    ? "form-control input-error"
                    : "form-control"
                }
                id="password"
                placeholder="Enter password"
                value={inputConfirmNewPassword}
                onFocus={(e) => {
                  setConfirmNewPasswordErrorMsg({
                    error: false,
                    message: ""
                  });
                }}
                onBlur={(e) => {
                  verifConfirmNewPasswordFormat(inputConfirmNewPassword);
                }}
                onChange={(event) => {
                  setinputConfirmNewPassword(event.target.value);
                  setinputConfirmNewPasswordTouched(true);
                  verifConfirmNewPasswordFormat(event.target.value);
                }}
              />
              {!showConfirmNewPassword ? (
                <IconButton
                  className="button-icon-auth"
                  onClick={() => setshowConfirmNewPassword(true)}
                >
                  <VisibilityOff size="small" />
                </IconButton>
              ) : (
                <IconButton
                  className="button-icon-auth"
                  onClick={() => setshowConfirmNewPassword(false)}
                >
                  <Visibility size="small" />
                </IconButton>
              )}
            </div>

            <p className="error-msg-txt">
              {ConfirmNewPasswordErrorMsg.message}
            </p>
          </div>
          <p className="error-msg-txt">
            {stateAuth.errorUpdateUserPassword
              ? stateAuth.errorUpdateUserPassword.message
              : ""}
          </p>
          <div className="profile-form-submit-area">
            <Button
              className="contact-form-btn-send button-setting-popup"
              variant="contained"
              color="info"
              onClick={(event) => {
                event.preventDefault();
                props.goBackToProfileInfo();
              }}
            >
              Cancel
            </Button>
            <Button
              className="contact-form-btn-send button-setting-popup"
              variant="contained"
              color="info"
              style={{ marginLeft: "1rem" }}
              disabled={stateAuth.loadingUpdateUserPassword}
              onClick={updateUserPasswordButtonAction}
              type="submit"
            >
              {stateAuth.loadingUpdateUserPassword ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#fff",
                    alignSelf: "center"
                  }}
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
