import { dashboardStateAction } from "./constants";

export const getDashboardAction = (dashboard) => {
  return (dispatch) => {
    dispatch({
      type: dashboardStateAction.GET_CURRENT_DASHBORD_SUCCESS,
      payload: dashboard
    });
  };
};
export const getDashboardRequestAction = () => {
  return (dispatch) => {
    dispatch({
      type: dashboardStateAction.GET_CURRENT_DASHBORD_REQUEST
    });
  };
};
export const getDashboardFailueAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: dashboardStateAction.GET_CURRENT_DASHBORD_FAILURE,
      error: error
    });
  };
};
