import FullCalendar, { computeEdges } from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a FullCalendar plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a FullCalendar plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

import { useEffect, useState } from 'react';
import axios from 'axios';

import { GoogleLogin, GoogleLogout } from 'react-google-login';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, Chip, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';

import { getAuthToken } from '../../../../server/Auth/googleAuth.server';

import './GCalendar.css';

function GCalendar(props) {
  const [accessToken, setAccessToken] = useState('');
  const [calendarId, setCalendarId] = useState(
    'tm8iajksvacgiqbu9r9hihergo@group.calendar.google.com'
  );
  const apiKey = 'AIzaSyAlcy6yirNzRilq1NGETPPH2-YVLj_A2pI';

  const onClickGetGAuthToken = async () => {
    //get Google AuthToken from server
    // const tokenData = await getAuthToken('');
  };

  const [currdate, setCurrDate] = useState(dayjs);
  const [calEvents, setCalEvents] = useState([]);
  const [userCalendars, setUserCalendars] = useState([]);
  const [googleEvents, setGoogleEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedGoogleEvent, setSelectedGoogleEvent] = useState();
  const [showEditEventContainer, setShowEditEventContainer] = useState(false);

  const config = {
    clientId: calendarId,
    apiKey: apiKey,
    scope: 'https://www.googleapis.com/auth/calendar',
    discoveryDocs: ['https://www.googleapis.com/calendar/v3/users/me/calendarList']
  };

  // default headerToolbar properties
  const headerToolbar = {
    start: 'today prev,next',
    center: 'title',
    end: 'dayGridMonth,timeGridWeek,timeGridDay'
  };

  //const apiCalendar = new ApiCalendar(config);

  useEffect(() => {
    //react google api package
    // const d = new Date();
    // // The user need to signIn with Handle AuthClick before
    // apiCalendar.listEvents({
    //   timeMin: d.toISOString(),
    //   // timeMax: new Date().addDays(10).toISOString(),
    //   timeMax: d.setdate(d.getdate() + 10).toISOString(),
    //   showDeleted: true,
    //   maxResults: 10,
    //   orderBy: 'updated'
    // }).then(({ result }) => {
    //   debugger;
    //   console.log(result.items);
    // });

    getUserCalendarList(accessToken);
    getEvents(accessToken);
  }, [accessToken]);

  //STARTS all code for Google API

  //--Get All the events of User---
  const getEvents = async (accessToken) => {
    let _urlGetAllEvents = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`;

    try {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`
      };
      return await axios({
        method: 'get',
        url: _urlGetAllEvents
      }).then(function (response) {
        console.log(response);
        //returned events
        let _events = response.data.items;

        //set event returned as google calendar events
        setGoogleEvents(_events);

        //set events returned for Calendar
        var _calEvents = _events.map(function (event) {
          return {
            id: event.id,
            title: event.summary,
            start: event.start.dateTime || event.start.date,
            end: event.end.dateTime || event.end.date
          };
        });
        setCalEvents(_calEvents);
      });
    } catch (error) {
      console.log('Error getting events');
      console.error(error);
    }
  };

  const getUserCalendarList = async (accessToken) => {
    let _urlGetAllEvents = `https://www.googleapis.com/calendar/v3/users/me/calendarList`;

    try {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`
      };
      return await axios({
        method: 'get',
        url: _urlGetAllEvents
      }).then(function (response) {
        console.log(response);

        var _userCalendars = response.data.items;
        setUserCalendars(_userCalendars);
      });
    } catch (error) {
      console.log('Error getting users calendars');
      console.error(error);
    }
  };
  const createEventForCalendar = async (accessToken, calendarId, eventData) => {
    let _calendarId = calendarId;
    let _urlCreateEventForCalendar = `https://www.googleapis.com/calendar/v3/calendars/${_calendarId}/events/`;
    let _selectedStartDate = dayjs(eventStartDate).format('YYYY-MM-DD');
    let _selectedEndDate = dayjs(eventEndDate).format('YYYY-MM-DD');
    let _eventToCreate = {
      summary: createEventTitle,
      start: {
        date: _selectedStartDate
      },
      end: {
        date: _selectedEndDate
      },
      location: createEventLocation,
      description: createEventDescription,
      attendees: [{ email: 'manoj.salvi.pro@gmail.com' }]
    };

    //check if user has set a google meet with event
    //and append the releavant conference data before sending API request
    if (
      meetingCreationObj && //null and undefined check
      Object.keys(meetingCreationObj).length != 0
    ) {
      //Version number of conference data supported by the API client.
      //Version 0 assumes no conference data support and ignores conference data in the event's body
      _eventToCreate.conferenceDataVersion = 1;
      _eventToCreate.conferenceData = meetingCreationObj;
    }
    try {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`
      };
      return await axios.post(_urlCreateEventForCalendar, _eventToCreate).then(function (response) {
        console.log(response);

        var _userCalendars = response.data;
        console.log('in create event', _userCalendars);
        alert('event created successfully');
        //hide the modal
        setOpenCreateEventModal(false);
        // refresh the list
        getEvents(accessToken);
      });
    } catch (error) {
      console.log('Error Creating Event');
      console.error(error);
    }
  };

  const updateEventForCalendar = async (accessToken, calendarId, eventData) => {
    let _calendarId = calendarId;
    let _urlCreateEventForCalendar = `https://www.googleapis.com/calendar/v3/calendars/${_calendarId}/events/${eventData.id}`;
    let _selectedStartDate = dayjs(updateEventStartDate).format('YYYY-MM-DD');
    let _selectedEndDate = dayjs(updateEventEndDate).format('YYYY-MM-DD');
    let _eventToUpdate = {
      summary: updateEventTitle,
      start: {
        date: _selectedStartDate
      },
      end: {
        date: _selectedEndDate
      },
      location: updateEventLocation,
      description: updateEventDescription
    };
    try {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`
      };
      return await axios.put(_urlCreateEventForCalendar, _eventToUpdate).then(function (response) {
        console.log(response);

        var _userCalendars = response.data;
        console.log('in create event', _userCalendars);
        alert('event updated successfully.');
        //hide the modal
        setOpenDiplayEventModal(false);
        // refresh the list
        getEvents(accessToken);
      });
    } catch (error) {
      console.log('Error Creating Event');
      console.error(error);
    }
  };

  const deleteEventForCalendar = async (accessToken, calendarId, googleEvent) => {
    let _calendarId = calendarId;
    let _urlDeleteEventForCalendar = `https://www.googleapis.com/calendar/v3/calendars/${_calendarId}/events/${googleEvent.id}`;

    try {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`
      };
      return await axios.delete(_urlDeleteEventForCalendar).then(function (response) {
        console.log(response);
        alert('event deleted successfully');
      });
    } catch (error) {
      console.log('Error Deleting selected Event');
      console.error(error);
    }
  };

  //ENDS all code for Google API

  const handleCalEventClick = (clickInfo) => {
    // alert(clickInfo.event._def.title);

    let _selectedGoogleEvent = googleEvents.find(
      (gEvent) => gEvent.id === clickInfo.event._def.publicId
    );
    setSelectedGoogleEvent(_selectedGoogleEvent);
    //set other fields and values
    setSelectedEvent({
      title: _selectedGoogleEvent.summary
    });
    setUpdateEventTitle(_selectedGoogleEvent.summary);
    setUpdateEventStartDate(
      _selectedGoogleEvent && _selectedGoogleEvent.start && _selectedGoogleEvent.start.date
        ? _selectedGoogleEvent.start.date
        : new Date()
    );
    setUpdateEventEndDate(
      _selectedGoogleEvent && _selectedGoogleEvent.end && _selectedGoogleEvent.end.date
        ? _selectedGoogleEvent.end.date
        : new Date()
    );

    setOpenDiplayEventModal(true);
    console.log(clickInfo);
  };

  const onChangeAccessTokenInput = (e) => setAccessToken(e.target.value);
  const onChangeCalendarIdInput = (e) => setCalendarId(e.target.value);

  const onClickGetEvents = () => {
    getEvents(accessToken);
  };

  //full calendar google integrations only works for public calendar
  // var calendarEl = document.getElementById('calendar');
  // let calendar = new Calendar(calendarEl, {
  //   plugins: [googleCalendarPlugin],
  //   googleCalendarApiKey: 'AIzaSyBoJeeI5Yd57A8Vzi0s2ual1cNkgiy6Ydg',
  //   events: {
  //     googleCalendarId: 'tm8iajksvacgiqbu9r9hihergo@group.calendar.google.com'
  //   }
  // });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #eee',
    boxShadow: 24,
    p: 4
  };
  // Create Event Modal code
  const [openCreateEventModal, setOpenCreateEventModal] = useState(false);
  const handleOpenCreateModal = () => setOpenCreateEventModal(true);
  const handleCloseCreateEventModal = () => setOpenCreateEventModal(false);

  //create event form
  const [createEventTitle, setCreateEventTitle] = useState();
  const [eventStartDate, setEventStartDate] = useState(dayjs(new Date()));
  const [eventEndDate, setEventEndDate] = useState(dayjs(new Date()));
  const [createEventLocation, setCreateEventLocation] = useState();
  const [createEventDescription, setCreateEventDescription] = useState();
  const [meetingCreationObj, setMeetingCreationObj] = useState({});

  const onChangeCreateEventTitle = (event) => setCreateEventTitle(event.target.value);
  const onChangeStartDatePicker = (newStartDate) => setEventStartDate(newStartDate);
  const onChangeEndDatePicker = (newEndDate) => setEventEndDate(newEndDate);
  const onChangeCreateEventLocation = (event) => setCreateEventLocation(event.target.value);
  const onChangeCreateEventDescription = (event) => setCreateEventDescription(event.target.value);

  const onClickSaveEvent = () => {
    createEventForCalendar(accessToken, calendarId, {});
  };

  const onClickAddGoogleMeetBtn = () => {
    //get a random meeting link string
    let _meetingLinkString = [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
    //create conference data obj to be send to API for creation of Meeting
    let _conferenceData = {
      createRequest: {
        conferenceSolutionKey: {
          type: 'hangoutsMeet'
        },
        requestId: _meetingLinkString
      }
    };
    //set obj to state
    setMeetingCreationObj(_conferenceData);
  };
  //------ Display/Edit Event Modal code ------
  const [openDisplayEventModal, setOpenDiplayEventModal] = useState(false);
  const handleOpenDisplayEventModal = () => setOpenDiplayEventModal(true);
  const handleCloseDisplayEventModal = () => setOpenDiplayEventModal(false);

  //---- update Form ----
  const [updateEventTitle, setUpdateEventTitle] = useState();
  const [updateEventStartDate, setUpdateEventStartDate] = useState(
    dayjs(
      selectedGoogleEvent && selectedGoogleEvent.start ? selectedGoogleEvent.start.date : new Date()
    )
  );
  const [updateEventEndDate, setUpdateEventEndDate] = useState(
    dayjs(
      selectedGoogleEvent && selectedGoogleEvent.end ? selectedGoogleEvent.end.date : new Date()
    )
  );
  const [updateEventLocation, setUpdateEventLocation] = useState();
  const [updateEventDescription, setUpdateEventDescription] = useState();

  const onChangeUpdateEventTitle = (event) => setUpdateEventTitle(event.target.value);
  const onChangeUpdateStartDatePicker = (newStartDate) => setUpdateEventStartDate(newStartDate);
  const onChangeUpdateEndDatePicker = (newEndDate) => setUpdateEventEndDate(newEndDate);

  //--Edit event button to show edit form
  const onClickEditEventBtn = () => {
    setShowEditEventContainer(true);
  };

  //update event button to process the updation of event
  const onClickUpdateEventBtn = () => {
    updateEventForCalendar(accessToken, calendarId, selectedGoogleEvent);
  };

  const onClickDeleteEventBtn = () => {
    deleteEventForCalendar(accessToken, calendarId, selectedGoogleEvent);
    alert('Event Deleted');
  };

  const responseErrorGoogle = (response) => {
    console.log(response);
  };

  const responseSuccessGoogle = (response) => {
    console.log(response);
    setAccessToken(response.accessToken);
  };
  const onGoogleLogoutSuccessBtn = (response) => {
    console.log(response);
  };

  const onGoogleLogoutFailureBtn = (resposne) => {
    console.log(resposne);
  };

  let googleScopes = [
    'openid',
    'email',
    'profile',
    'https://www.googleapis.com/auth/drive',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/calendar',
    'https://www.googleapis.com/auth/calendar.events',
    'https://www.googleapis.com/auth/calendar.settings.readonly'
  ].join(String.fromCharCode(32));
  return (
    <>
      {/* <h4>Access Token</h4>
      <input type="text" onChange={onChangeAccessTokenInput} placeholder="Enter Access Token" />
      <h4>Calendar Id</h4>
      <input type="text" onChange={onChangeCalendarIdInput} placeholder="Enter Calendar" />
      <button onClick={onClickGetEvents}>Get Events</button> */}
      {/* <button onClick={onClickGetGAuthToken}>Get Token</button> */}
      <GoogleLogin
        clientId="1045255083194-uifgeg654sgiqsgmj6l60vr7t20knse5.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={responseSuccessGoogle}
        onFailure={responseErrorGoogle}
        cookiePolicy="single_host_origin"
        scope={googleScopes}
      />
      {/* <GoogleLogout
        clientId="1045255083194-uifgeg654sgiqsgmj6l60vr7t20knse5.apps.googleusercontent.com"
        buttonText="Logout"
        onLogoutSuccess={onGoogleLogoutSuccessBtn}
        onFailure={onGoogleLogoutFailureBtn}
      /> */}
      <div className="calendar-grid-container">
        <Grid container spacing={2}>
          <Grid item md={3}>
            <div className="calendar-menu">
              <div className="calendar-picker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CalendarPicker date={currdate} onChange={(newDate) => setCurrDate(newDate)} />
                </LocalizationProvider>
              </div>

              <div className="user-calendars">
                <h4>
                  <strong>My Calendars</strong>
                </h4>
                {userCalendars.map((calendar) => {
                  return (
                    <div className="user-calendar-selector">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={calendar.selected} />}
                          label={calendar.summary}
                        />
                      </FormGroup>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item md={8}>
            {/* Right section Full Calendar */}
            <div className="calendar-container">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={calEvents}
                eventClick={handleCalEventClick}
                dateClick={handleOpenCreateModal}
                headerToolbar={headerToolbar}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div>
        {/* Create event Modal */}

        {/* Button for direclty opening the modal commented for now */}
        {/* <Button onClick={handleOpenCreateModal}>Open modal</Button> */}
        <Modal
          open={openCreateEventModal}
          onClose={handleCloseCreateEventModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          hideBackdrop={true}>
          <Box sx={style}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button onClick={handleCloseCreateEventModal}>x</Button>
            </div>
            <div className="create-event-container">
              <FormGroup>
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    margin="dense"
                    id="title"
                    label="Add Title and Time"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChangeCreateEventTitle}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div>
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="DD-MM-YYYY"
                        value={eventStartDate}
                        onChange={onChangeStartDatePicker}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                    <div>
                      <DesktopDatePicker
                        label="End Date"
                        inputFormat="DD-MM-YYYY"
                        value={eventEndDate}
                        onChange={onChangeEndDatePicker}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </LocalizationProvider>
                </div>
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="All Day" />
              </FormGroup>
              <FormGroup>
                <Button variant="contained" color="primary" onClick={onClickAddGoogleMeetBtn}>
                  Add Google Meet video conferencing
                </Button>
              </FormGroup>
              <FormGroup>
                <TextField
                  autoFocus
                  margin="dense"
                  id="location"
                  label="Location"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={onChangeCreateEventLocation}
                />
              </FormGroup>

              <FormGroup>
                <TextField
                  autoFocus
                  margin="dense"
                  id="description"
                  label="Description"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={onChangeCreateEventDescription}
                />
              </FormGroup>
              <FormGroup>
                <Button variant="contained" color="primary" onClick={onClickSaveEvent}>
                  Save
                </Button>
              </FormGroup>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openDisplayEventModal}
          onClose={handleCloseDisplayEventModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          hideBackdrop={true}>
          <Box sx={style}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button onClick={onClickEditEventBtn} variant="outlined" color="secondary">
                Edit
              </Button>
              <Button onClick={onClickDeleteEventBtn} variant="outlined" color="error">
                Delete
              </Button>
              <Button onClick={handleCloseDisplayEventModal}>x</Button>
            </div>
            <div className={`display-event-container ${showEditEventContainer && 'd-none'}`}>
              <FormGroup>
                <h3>
                  <Chip label="" />
                  {selectedGoogleEvent && selectedGoogleEvent.summary
                    ? selectedGoogleEvent.summary
                    : '(No Title)'}
                </h3>
              </FormGroup>
            </div>

            <div className={`edit-event-container ${!showEditEventContainer && 'd-none'}`}>
              <FormGroup>
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label={
                      selectedGoogleEvent && selectedGoogleEvent.summary
                        ? selectedGoogleEvent.summary
                        : ''
                    }
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChangeUpdateEventTitle}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div>
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="DD-MM-YYYY"
                        value={updateEventStartDate}
                        onChange={onChangeUpdateStartDatePicker}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                    <div>
                      <DesktopDatePicker
                        label="End Date"
                        inputFormat="DD-MM-YYYY"
                        value={updateEventEndDate}
                        onChange={onChangeUpdateEndDatePicker}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </LocalizationProvider>
                </div>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        selectedGoogleEvent &&
                        selectedGoogleEvent.start.date === selectedGoogleEvent.end.data
                      }
                    />
                  }
                  label="All Day"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  autoFocus
                  margin="dense"
                  id="location"
                  label="Location"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </FormGroup>
              <FormGroup>
                <Button variant="contained" color="primary" onClick={onClickUpdateEventBtn}>
                  Update
                </Button>
              </FormGroup>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default GCalendar;
