export const initialState = {
  consoleLogs: [
    {
      log: { message: "fisrt console log in setting reducer" },
      date: new Date()
    }
  ],
  errorLogs: [
    {
      log: { message: "fisrt error log in setting reducer" },
      date: new Date()
    }
  ]
};

export const logsStateAction = {
  ADD_CONSOLE_LOGS: "logs/ADD_CONSOLE_LOGS",
  ADD_ERROR_LOGS: "logs/ADD_ERROR_LOGS"
};
