import React, { useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { useIndexedDB } from 'react-indexed-db';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import { authBackroundUrl } from '../../../config/images';
import { CheckCircle, CheckCircleOutline, Close } from '@mui/icons-material';
import { CircularProgress, IconButton, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  updateBackgroundIndexDBArrayAction,
  updateBackgroundIndexDBSelectedIndexAction,
  updateBackgroundIndexDBStateAction
} from '../../../store/reducers/backgroundReducer/action';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import UploadBackgroundDropZone from './UploadBackgroundDropZone';

export default function UploadBackgroundItem({
  backgroundItem,
  index,
  selectedIndex,
  useBAckgroundIndexDB
}) {
  const dispatch = useDispatch();

  const [uploadedImageStatus, setuploadedImageStatus] = useState(false);
  const [imageData, setimageData] = useState(null);
  const { add, getByID, getByIndex, deleteRecord } = useIndexedDB('background');
  // specify upload params and url for your files

  useEffect(() => {
    if (getByIndex) {
      getBackgroundFromIndexDB();
    }
  }, []);

  const getBackgroundFromIndexDB = () => {
    getByIndex('name', backgroundItem.name)
      .then((backgroundFromDB) => {
        if (backgroundFromDB) {
          meaVanaConsole('background from indexDB : ', backgroundFromDB);
          setuploadedImageStatus(true);
          setimageData(backgroundFromDB);
          dispatch(
            updateBackgroundIndexDBArrayAction(index, {
              ...backgroundItem,
              uploaded: true,
              updated: new Date()
            })
          );
        } else {
          meaVanaConsole('error background from indexDB : ', backgroundFromDB);
        }
      })
      .catch((error) => {
        meaVanaConsole('error background from indexDB : ', error);
      });
  };

  const handleClickedBackground = () => {
    if (imageData && imageData.data) {
      if (useBAckgroundIndexDB !== true || selectedIndex !== index) {
        meaVanaConsole('update selected background index :', imageData);

        dispatch(updateBackgroundIndexDBStateAction(true));
        dispatch(updateBackgroundIndexDBSelectedIndexAction(index));
      }
    }
  };

  const removeBackgroundFromIndexDb = () => {
    if (imageData) {
      deleteRecord(imageData.id).then(
        (event) => {
          meaVanaConsole('removeBackgroundFromIndexDb success : ', event);
          setuploadedImageStatus(false);
          setimageData(null);
          dispatch(
            updateBackgroundIndexDBArrayAction(index, {
              ...backgroundItem,
              uploaded: false,
              updated: new Date()
            })
          );
        },
        (error) => {
          meaVanaConsole('removeBackgroundFromIndexDb error :', error);
        }
      );
    }
  };

  return (
    <div
      className="wallpaper-upload-background-container-grid-item"
      key={'wallpaper-upload-background-' + backgroundItem.name}
    >
      {imageData && imageData.data ? (
        <>
          <img
            alt={backgroundItem.name}
            style={{ width: '100%', height: '100%', borderRadius: '0.5em' }}
            src={imageData.data}
          />
          <div className="wallpaper-upload-background-action-main-container">
            <div
              className="wallpaper-upload-background-action-container"
              onClick={handleClickedBackground}
            >
              <span>{imageData && imageData.data && imageData.name}</span>
            </div>
            <IconButton
              className="upload-background-delete-icon-container"
              aria-label="selectedTopic"
              onClick={removeBackgroundFromIndexDb}
            >
              <Close fontSize="medium" />
            </IconButton>
          </div>
          {useBAckgroundIndexDB && selectedIndex === index && (
            <IconButton
              className="upload-background-selected-icon-container"
              aria-label="selectedTopic"
            >
              <CheckCircle fontSize="medium" />
              <span
                style={{
                  color: 'var(--popup-text-color)',
                  fontSize: '1rem',
                  marginLeft: '0.3rem'
                }}
              >
                Selected
              </span>
            </IconButton>
          )}
        </>
      ) : (
        <UploadBackgroundDropZone
          backgroundItem={backgroundItem}
          index={index}
          getBackgroundFromIndexDB={getBackgroundFromIndexDB}
        />
      )}
    </div>
  );
}
