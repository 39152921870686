import {
  getNewsDataFailureAction,
  getNewsDataRequestAction,
  getNewsDataSuccessAction
} from "../../store/reducers/newsReducer/action";

import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import isURL from "validator/lib/isURL";

function getTextFromElement(element) {
  let text = null;

  switch (element.firstChild?.nodeType) {
    case Node.CDATA_SECTION_NODE:
      text = element.firstChild.data;
      break;
    case Node.TEXT_NODE:
      text = element.firstChild.textContent;
      break;
    default:
      break;
  }

  const trimmed = text?.trim();
  return trimmed ? trimmed : null;
}

function extractFromElement(from, ...items) {
  const output = {};

  for (const item of items) {
    const child = from.getElementsByTagName(item)[0];

    if (child) {
      const childContent = getTextFromElement(child);
      if (childContent) {
        output[item] = childContent;
      }
    }
  }

  return output;
}

function getOGP(name, htmlDoc) {
  return htmlDoc
    .querySelector(`meta[property="og:${name}"]`)
    ?.getAttribute("content");
}

async function getItemsFromRss(url) {
  const response = await fetch(url);
  const xml = await response.text();
  const xmlDocument = new DOMParser().parseFromString(xml, "text/xml");

  if (xmlDocument.querySelector("parsererror")) {
    throw new Error("Unable to parse XML.");
  }

  const data = [];
  const itemElements = xmlDocument.querySelectorAll("item");

  for (const itemElement of itemElements) {
    const extracted = extractFromElement(
      itemElement,
      "title",
      "description",
      "link"
    );

    if (extracted.description) {
      const fragment = new DOMParser().parseFromString(
        extracted.description,
        "text/html"
      );

      const image = fragment.body.querySelector("img");
      const firstPElement = fragment.body.querySelector("p");

      if (image) {
        const imgSrc = image.getAttribute("src");
        if (imgSrc) {
          extracted.image = imgSrc;
        }
      }

      if (firstPElement) {
        const description = firstPElement.textContent?.trim();
        if (description) {
          extracted.description = description;
        }
      }

      /**
       * Sometimes, it's better to fallback to OpenGraph for a description with
       * more words.
       */
      const words = extracted.description.split(String.fromCharCode(32));
      if (words.length < 3) {
        delete extracted.description;
      }
    }

    if (extracted.link == null) {
      const guidElement = itemElement.querySelector("guid");
      if (guidElement) {
        const guidContent = getTextFromElement(guidElement);
        if (
          guidContent &&
          isURL(guidContent, { protocols: ["http", "https"] })
        ) {
          extracted.link = guidContent;
        }
      }
    }

    /**
     * No use in having a news item without a link back to the full article. So,
     * we don't include it in output.
     */
    if (extracted.link == null) {
      continue;
    }

    data.push({
      partialItem: extracted,
      isMissing: {
        title: extracted.title == null,
        description: extracted.description == null,
        image: extracted.image == null,
        link: extracted.link == null
      }
    });
  }

  const promises = data.reduce(
    (obj, dataItem, index) => {
      const shouldFetch = Object.entries(dataItem.isMissing).some(
        ([, missing]) => missing
      );

      if (shouldFetch) {
        const promise = fetch(dataItem.partialItem.link)
          .then((response) => response.text())
          .then((html) => new DOMParser().parseFromString(html, "text/html"));

        obj.indices.push(index);
        obj.promises.push(promise);
      }

      return obj;
    },
    { indices: [], promises: [] }
  );

  (await Promise.all(promises.promises)).map((doc, index) => {
    /**
     * Fortunately OpenGraph field names happens to have the same names as
     * fields in output object. So, iterating to reduce the number of LOC.
     */
    for (const [ogField, missing] of Object.entries(
      data[promises.indices[index]].isMissing
    )) {
      if (missing) {
        const fieldData = getOGP(ogField, doc);
        if (fieldData) {
          data[promises.indices[index]].partialItem[ogField] = fieldData;
          data[promises.indices[index]].isMissing[ogField] = false;
        }
      }
    }
  });

  return data
    .filter(
      (dataItem) =>
        !Object.entries(dataItem.isMissing).some(([, missing]) => missing)
    )
    .map((dataItem) => dataItem.partialItem);
}

export function getNewsItems(url, index) {
  return (dispatch) => {
    try {
      dispatch(getNewsDataRequestAction(index));
      getItemsFromRss(url)
        .then((newsItems) => {
          meaVanaConsole("getNewsItems data: ", newsItems);
          dispatch(getNewsDataSuccessAction(index, newsItems));
        })
        .catch((err) => {
          meaVanaConsole("getNewsItem error1: ", err);
          dispatch(getNewsDataFailureAction(index, err));
        });
    } catch (err) {
      meaVanaConsole("getNewsItem error: ", err);
      dispatch(getNewsDataFailureAction(index, err));
    }
  };
}
