import axios from "axios";
import { SERVER_URL } from "../../config/constants";
import {
  updateAppStateLoadingAction,
  updateLoggedInSateAction
} from "../../store/reducers/appReducer/action";
import {
  checkOldUser,
  desactiveAuthenticationLoading,
  forgotPasswordFailureAction,
  forgotPasswordRequestAction,
  forgotPasswordSuccessAction,
  loginFailureAction,
  loginRequestAction,
  loginSSOFacebookFailureAction,
  loginSSOFacebookRequestAction,
  loginSSOFacebookSuccessAction,
  loginSSOGoogleFailureAction,
  loginSSOGoogleRequestAction,
  loginSSOGoogleSuccessAction,
  loginSuccessAction,
  registerFailureAction,
  registerRequestAction,
  registerSuccessAction,
  resetPasswordFailureAction,
  resetPasswordRequestAction,
  resetPasswordSuccessAction,
  VerifyEmailforgotPasswordFailureAction,
  VerifyEmailforgotPasswordRequestAction,
  VerifyEmailforgotPasswordSuccessAction
} from "../../store/reducers/authReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import { dashbordDataSetUpMultipleDashbords } from "../Dashbord/dashboard.server";
import { checkErrorResponseFormat } from "../Middleware/utils.server";

export const forgotPasswordAuthServer = (email) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("email", email);
      dispatch(forgotPasswordRequestAction(email));
      axios
        .post(SERVER_URL + "user/forgot-password", params)
        .then((response) => {
          meaVanaConsole("forgotPasswordAuthAction response: ", response.data);

          dispatch(forgotPasswordSuccessAction(response.data));
        })
        .catch((error1) => {
          meaVanaConsole(
            "forgotPasswordAuthAction error1: ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            forgotPasswordFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "forgotPasswordAuthAction error: ",
        checkErrorResponseFormat(error)
      );
      dispatch(forgotPasswordFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

export const VerifyEmailforgotPasswordAuthServer = (code) => {
  return async (dispatch) => {
    try {
      dispatch(VerifyEmailforgotPasswordRequestAction(code));
      const params = new URLSearchParams();
      params.append("verification", code);
      axios
        .post(SERVER_URL + "user/forgot-password-verify", params)
        .then((response) => {
          meaVanaConsole(
            "forgotPasswordVerifyEmailAuthAction response: ",
            response.data
          );

          dispatch(VerifyEmailforgotPasswordSuccessAction(response.data));
        })
        .catch((error1) => {
          meaVanaConsole(
            "forgotPasswordVerifyEmailAuthAction error1: ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            VerifyEmailforgotPasswordFailureAction(
              checkErrorResponseFormat(error1)
            )
          );
        });
    } catch (error) {
      meaVanaConsole(
        "forgotPasswordVerifyEmailAuthAction error: ",
        checkErrorResponseFormat(error)
      );

      dispatch(
        VerifyEmailforgotPasswordFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

export const ResetPasswordAuthServer = (userState) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("email", userState.email);
      params.append("password", userState.password);
      params.append("passwordConfirm", userState.passwordConfirm);
      dispatch(resetPasswordRequestAction(userState));
      axios
        .post(SERVER_URL + "user/reset-password", params)
        .then((response) => {
          meaVanaConsole("ResetPasswordAction response: ", response.data);

          dispatch(resetPasswordSuccessAction(response.data));
        })
        .catch((error1) => {
          meaVanaConsole(
            "ResetPasswordAction error1: ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            resetPasswordFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "ResetPasswordAction error: ",
        checkErrorResponseFormat(error)
      );

      dispatch(resetPasswordFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

const authSuccessDataSetUp = (data) => {
  return async (dispatch) => {
    meaVanaConsole("dispatch authSuccessDataSetUp: ", data);

    dispatch(updateAppStateLoadingAction(true));
    dispatch(updateLoggedInSateAction(true));

    checkOldUser(data.email);

    dispatch(dashbordDataSetUpMultipleDashbords(data.dashboards[0]));

    dispatch(desactiveAuthenticationLoading());
  };
};

export const RegiterAuthServer = (userState) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("username", userState.username);
      params.append("email", userState.email);
      params.append("password", userState.password);
      params.append("deviceId", userState.deviceId);
      dispatch(registerRequestAction(userState));
      axios
        .post(SERVER_URL + "user/create", params)
        .then((response) => {
          meaVanaConsole("response register :", response.data);

          dispatch(authSuccessDataSetUp(response.data));
          const responseData = response.data;
          delete responseData.dashboards;
          dispatch(registerSuccessAction(responseData));
        })
        .catch((error1) => {
          meaVanaConsole(
            "LoginAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(desactiveAuthenticationLoading());
          dispatch(registerFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("LoginAuthAction err2", checkErrorResponseFormat(error));
      dispatch(desactiveAuthenticationLoading());
      dispatch(registerFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

export const LoginAuthServer = (userState) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("email", userState.email);
      params.append("password", userState.password);
      //params.append("deviceId", DEVICE_ID);
      params.append("deviceId", userState.deviceId);
      dispatch(loginRequestAction(userState));
      axios
        .post(SERVER_URL + "user/login/", params)
        .then((response) => {
          meaVanaConsole("response login :", response.data);

          dispatch(authSuccessDataSetUp(response.data));
          const responseData = response.data;
          delete responseData.dashboards;
          dispatch(loginSuccessAction(responseData));
        })
        .catch((error1) => {
          meaVanaConsole(
            "LoginAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(desactiveAuthenticationLoading());
          dispatch(loginFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("LoginAuthAction err2", checkErrorResponseFormat(error));
      dispatch(desactiveAuthenticationLoading());
      dispatch(loginFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

export const GoogleLoginAuthServer = (userState) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("tokenId", userState.tokenId);
      params.append("deviceId", userState.deviceId);
      dispatch(loginSSOGoogleRequestAction(userState));
      axios
        .post(SERVER_URL + "user/google-auth/", params)
        .then((response) => {
          meaVanaConsole("response login :", response.data);

          dispatch(authSuccessDataSetUp(response.data));
          const responseData = response.data;
          delete responseData.dashboards;
          dispatch(loginSSOGoogleSuccessAction(responseData));
        })
        .catch((error1) => {
          meaVanaConsole(
            "LoginAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(desactiveAuthenticationLoading());
          dispatch(
            loginSSOGoogleFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole("LoginAuthAction err2", checkErrorResponseFormat(error));
      dispatch(desactiveAuthenticationLoading());
      dispatch(loginSSOGoogleFailureAction(checkErrorResponseFormat(error)));
    }
  };
};
export const FacebookLoginAuthServer = (userState) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("tokenId", userState.tokenId);
      params.append("deviceId", userState.deviceId);
      dispatch(loginSSOFacebookRequestAction(userState));
      axios
        .post(SERVER_URL + "user/facebook-auth/", params)
        .then((response) => {
          meaVanaConsole("response login :", response.data);

          dispatch(authSuccessDataSetUp(response.data));
          const responseData = response.data;
          delete responseData.dashboards;
          dispatch(loginSSOFacebookSuccessAction(responseData));
        })
        .catch((error1) => {
          meaVanaConsole(
            "LoginAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(desactiveAuthenticationLoading());
          dispatch(
            loginSSOFacebookFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole("LoginAuthAction err2", checkErrorResponseFormat(error));
      dispatch(desactiveAuthenticationLoading());
      dispatch(loginSSOFacebookFailureAction(checkErrorResponseFormat(error)));
    }
  };
};
