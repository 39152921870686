import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetHomeThemeAction,
  updateShowHideBookmarkFeatureAction,
  updateShowHideDateFeatureAction,
  updateShowHideIntegrationFeatureAction,
  updateShowHideNoteFeatureAction,
  updateShowHideQuoteFeatureAction,
  updateShowHideSearchEngineFeatureAction,
  updateShowHideTimeFeatureAction,
  updateShowHideWeatherFeatureAction
} from "../../../store/reducers/homeReducer/action";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export default function UpdateFeatures(features) {
  return async (dispatch) => {
    try {
      const message = {
        integrations: "",
        bookmarks: "",
        weather: "",
        notes: "",
        quote: "",
        searchBar: "",
        date: "",
        time: ""
      };
      if (features) {
        if (features.integrations) {
          switch (features.integrations) {
            case "hide":
              dispatch(updateShowHideIntegrationFeatureAction(0));
              message.integrations = "updated ,value=hide";
              break;
            case "dim":
              dispatch(updateShowHideIntegrationFeatureAction(0.5));
              message.integrations = "updated ,value=dim";

              break;
            case "show":
              dispatch(updateShowHideIntegrationFeatureAction(1));
              message.integrations = "updated ,value=show";

              break;

            default:
              break;
          }
        }
        if (features.bookmarks) {
          switch (features.bookmarks) {
            case "hide":
              dispatch(updateShowHideBookmarkFeatureAction(0));
              message.bookmarks = "updated ,value=hide";

              break;
            case "dim":
              dispatch(updateShowHideBookmarkFeatureAction(0.5));
              message.bookmarks = "updated ,value=dim";

              break;
            case "show":
              dispatch(updateShowHideBookmarkFeatureAction(1));
              message.bookmarks = "updated ,value=show";

              break;

            default:
              break;
          }
        }
        if (features.weather) {
          switch (features.weather) {
            case "hide":
              dispatch(updateShowHideWeatherFeatureAction(0));
              message.weather = "updated ,value=hide";

              break;
            case "dim":
              dispatch(updateShowHideWeatherFeatureAction(0.5));
              message.weather = "updated ,value=dim";

              break;
            case "show":
              dispatch(updateShowHideWeatherFeatureAction(1));
              message.weather = "updated ,value=show";

              break;

            default:
              break;
          }
        }
        if (features.notes) {
          switch (features.notes) {
            case "hide":
              dispatch(updateShowHideNoteFeatureAction(0));
              message.notes = "updated ,value=hide";

              break;
            case "dim":
              dispatch(updateShowHideNoteFeatureAction(0.5));
              message.notes = "updated ,value=dim";

              break;
            case "show":
              dispatch(updateShowHideNoteFeatureAction(1));
              message.notes = "updated ,value=show";

              break;

            default:
              break;
          }
        }
        if (features.quote) {
          switch (features.quote) {
            case "hide":
              dispatch(updateShowHideQuoteFeatureAction(0));
              message.quote = "updated ,value=hide";

              break;
            case "dim":
              dispatch(updateShowHideQuoteFeatureAction(0.5));
              message.quote = "updated ,value=dim";

              break;
            case "show":
              dispatch(updateShowHideQuoteFeatureAction(1));
              message.quote = "updated ,value=show";

              break;

            default:
              break;
          }
        }
        if (features.searchBar) {
          switch (features.searchBar) {
            case "hide":
              dispatch(updateShowHideSearchEngineFeatureAction(0));
              message.searchBar = "updated ,value=hide";

              break;
            case "dim":
              dispatch(updateShowHideSearchEngineFeatureAction(0.5));
              message.searchBar = "updated ,value=dim";
              break;
            case "show":
              dispatch(updateShowHideSearchEngineFeatureAction(1));
              message.searchBar = "updated ,value=show";
              break;

            default:
              break;
          }
        }
        if (features.date) {
          switch (features.date) {
            case "hide":
              dispatch(updateShowHideDateFeatureAction(0));
              message.date = "updated ,value=hide";
              break;
            case "dim":
              dispatch(updateShowHideDateFeatureAction(0.5));
              message.date = "updated  ,value=dim";

              break;
            case "show":
              dispatch(updateShowHideDateFeatureAction(1));
              message.date = "updated ,value=show";

              break;

            default:
              break;
          }
        }
        if (features.time) {
          switch (features.time) {
            case "hide":
              dispatch(updateShowHideTimeFeatureAction(0));
              message.time = "updated ,value=hide";

              break;
            case "dim":
              dispatch(updateShowHideTimeFeatureAction(0.5));
              message.time = "updated ,value=dim";

              break;
            case "show":
              dispatch(updateShowHideTimeFeatureAction(1));
              message.time = "updated ,value=show";

              break;

            default:
              break;
          }
        }
        dispatch(resetHomeThemeAction());
      }
      meaVanaConsole("UpdateFeatures :", message);
    } catch (error) {
      meaVanaConsole("UpdateFeatures Error:", error);
    }
  };
}
