export const IndexDBConfig = {
  name: 'meavanaDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'background',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: true } },
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'type', keypath: 'type', options: { unique: false } },
        { name: 'created', keypath: 'created', options: { unique: false } },
        { name: 'updated', keypath: 'updated', options: { unique: false } }
      ]
    }
  ]
};
