import { APP_ENV, PROJECT_VERSION, STORE_VERSION } from '../config/constants';
import { initialState } from './reducers/searchEngineReducer/constants';

export const stateMigrations = {
  0: (state) => {
    console.log('update redux store version 0 :');
    localStorage.clear();
    return {
      ...state,
      app: {
        ...state.app,
        guestMode: false,
        isLoggedIn: false,

        deviceId: null,
        showLoading: false,

        homeTheme: 'standard',
        themeMode: 'dark-translucent-theme',
        backgroundMode: 'background-home-picture',
        themeColor: 'dark',
        themeStyle: 'translucent',
        themeFontFamily: 'Classic-Font',
        themeTextSize: 'Medium-Size'
      },
      auth: {
        ...state.auth,
        firstTimeUser: true,
        datefirstTimeUserLogin: null,
        oldUser: null,
        historyUsers: [],

        user: null,
        ACCESS_TOKEN: null,
        accessTokenExpirationDate: null,
        REFRESH_TOKEN: null,

        error: null,
        loading: false,

        loadingGoogleSSO: false,
        errorGoogleSSO: null,

        loadingUpdateUserInfo: false,
        successUpdateUserInfo: false,
        responseUpdateUserInfo: null,
        errorUpdateUserInfo: null,

        loadingUpdateUserPassword: false,
        successUpdateUserPassword: false,
        responseUpdateUserPassword: null,
        errorUpdateUserPassword: null,

        successDeleteAccount: false,
        loadingDeleteAccount: false,
        responseDeleteAccount: null,
        errorDeleteAccount: null,

        loadingDisableAccount: false,
        successDisableAccount: false,
        responseDisableAccount: null,
        errorDisableAccount: null,

        loadingForgotPassword: false,
        successForgotPassword: false,
        emailForgotPassword: null,
        responseForgotPassword: null,
        errorForgotPassword: null,

        loadingForgotPasswordVerifyEmail: false,
        successForgotPasswordVerifyEmail: false,
        codeForgotPasswordVerifyEmail: null,
        responseForgotPasswordVerifyEmail: null,
        errorForgotPasswordVerifyEmail: null,

        loadingResetPassword: false,
        successResetPassword: false,
        userResetPassword: null,
        responseResetPassword: null,
        errorResetPassword: null
      },
      background: {
        ...state.background,
        background: null,
        backgroundBatch: [],
        quote: null,
        backgroundLastUpdate: null,
        error: null,
        loading: false
      },
      bookmarks: {
        ...state.bookmarks,
        showUpdateDialog: false,

        bookmarkSizeBig: false,
        bookmarkColored: true,
        bookmarkShapeRectangle: false,
        openBookmarkInNewWindow: true,
        positionBOokmarksTop: false,
        showchromeBoomkmarks: false,

        deleteBoomarkLOading: false,

        errorDeleteBoomark: null,
        addBoomarkLoading: false,
        errorAddBoomark: null,

        loadingDeleteAllBookmarks: false,
        errorDeleteAllBookmarks: null,

        openDialogDeleteAllBookmark: false,

        bookmarks: [],
        selectedBookmark: {},
        chromeBoomkmarks: []
      },
      clock: {
        ...state.clock,
        hour24: false,
        showAmPm: false,
        showSeconds: false,
        analogClock: false,

        showStopWatch: false,
        showAlarm: false,
        showTimer: false,
        showTimeFeature: false,
        analogClockSize: 150,
        timeDateFontSize: 100
      },
      dashboard: {
        ...state.dashboard,
        error: null,

        dashboard: null,
        loading: false
      },
      home: {
        ...state.home,
        homeTheme: 'standard',

        hideAllFeatures: false,

        showTime: 1,
        showAllFeature: 1,
        showDate: 1,
        showNote: 1,
        showSearch: 0.5,
        showBookmark: 1,
        showQuote: 1,
        showBackgroundInfo: 1,
        showWeather: 1
      },
      integration: {
        ...state.integration
      },
      logs: {},
      notes: {
        ...state.notes,
        selectedNote: {},
        notes: [],
        toggleExpandedView: false,
        updatedList: false,
        error: '',
        noteActiveMode: true,
        showNoteDeleteConfirmation: false
      },
      searchEngine: {
        ...state.searchEngine,
        searchEngineRectangle: false,
        searchEngineBorder: false,
        searchEnginSize: 35,
        searchEngineOpenNewTab: true,
        listSearchEngine: [
          {
            name: 'Google',
            url: 'https://google.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Google/black.svg',
              color: '/assets/icons/Google/color.svg',
              white: '/assets/icons/Google/white.svg'
            }
          },
          {
            name: 'Bing',
            url: 'https://bing.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Bing/black.svg',
              color: '/assets/icons/Bing/color.svg',
              white: '/assets/icons/Bing/white.svg'
            }
          },
          {
            name: 'Amazon',
            url: 'https://www.amazon.com/s',
            searchKey: 'k',
            icons: {
              black: '/assets/icons/Amazon/black.svg',
              color: '/assets/icons/Amazon/color.svg',
              white: '/assets/icons/Amazon/white.svg'
            }
          },
          {
            name: 'Youtube',
            url: 'https://youtube.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Youtube/black.svg',
              color: '/assets/icons/Youtube/color.svg',
              white: '/assets/icons/Youtube/white.svg'
            }
          },
          {
            name: 'Twitter',
            url: 'https://Twitter.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Twitter/black.svg',
              color: '/assets/icons/Twitter/color.svg',
              white: '/assets/icons/Twitter/white.svg'
            }
          },
          {
            name: 'Wikipedia',
            url: 'https://en.wikipedia.org/w/index.php',
            searchKey: 'search',
            icons: {
              black: '/assets/icons/Wikipedia/black.svg',
              color: '/assets/icons/Wikipedia/color.svg',
              white: '/assets/icons/Wikipedia/white.svg'
            }
          },
          {
            name: 'Facebook',
            url: 'https://Facebook.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Facebook/black.svg',
              color: '/assets/icons/Facebook/color.svg',
              white: '/assets/icons/Facebook/white.svg'
            }
          },
          {
            name: 'Yahoo',
            url: 'https://search.yahoo.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Yahoo/black.svg',
              color: '/assets/icons/Yahoo/color.svg',
              white: '/assets/icons/Yahoo/white.svg'
            }
          },
          {
            name: 'DuckDuckGo',
            url: 'https://DuckDuckGo.com/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/DuckDuckGo/black.svg',
              color: '/assets/icons/DuckDuckGo/color.svg',
              white: '/assets/icons/DuckDuckGo/white.svg'
            }
          },
          {
            name: 'LinkedIn',
            url: 'https://www.linkedin.com/search/results/all',
            searchKey: 'keywords',
            icons: {
              black: '/assets/icons/LinkedIN/black.svg',
              color: '/assets/icons/LinkedIN/color.svg',
              white: '/assets/icons/LinkedIN/white.svg'
            }
          },

          {
            name: 'GDrive',
            url: 'https://drive.google.com/drive/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/GoogleDrive/black.svg',
              color: '/assets/icons/GoogleDrive/color.svg',
              white: '/assets/icons/GoogleDrive/white.svg'
            }
          },
          {
            name: 'Baidu',
            url: 'https://www.baidu.com/s',
            searchKey: 'wd',
            icons: {
              black: '/assets/icons/Baidu/black.svg',
              color: '/assets/icons/Baidu/color.svg',
              white: '/assets/icons/Baidu/white.svg'
            }
          },
          {
            name: 'Reddit',
            url: 'https://www.reddit.com/search/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Reddit/black.svg',
              color: '/assets/icons/Reddit/color.svg',
              white: '/assets/icons/Reddit/white.svg'
            }
          },
          {
            name: 'Pinterest',
            url: 'https://www.pinterest.com/search/pins/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Pinterest/black.svg',
              color: '/assets/icons/Pinterest/color.svg',
              white: '/assets/icons/Pinterest/white.svg'
            }
          }
        ],
        activeSearchEngine: {
          name: 'Google',
          url: 'https://google.com/search',
          searchKey: 'q',
          icons: {
            black: '/assets/icons/Google/black.svg',
            color: '/assets/icons/Google/color.svg',
            white: '/assets/icons/Google/white.svg'
          }
        },
        searchEngines: [
          {
            name: 'Google',
            url: 'https://google.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Google/black.svg',
              color: '/assets/icons/Google/color.svg',
              white: '/assets/icons/Google/white.svg'
            }
          },
          {
            name: 'Bing',
            url: 'https://bing.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Bing/black.svg',
              color: '/assets/icons/Bing/color.svg',
              white: '/assets/icons/Bing/white.svg'
            }
          },
          {
            name: 'Amazon',
            url: 'https://www.amazon.com/s',
            searchKey: 'k',
            icons: {
              black: '/assets/icons/Amazon/black.svg',
              color: '/assets/icons/Amazon/color.svg',
              white: '/assets/icons/Amazon/white.svg'
            }
          },
          {
            name: 'Youtube',
            url: 'https://youtube.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Youtube/black.svg',
              color: '/assets/icons/Youtube/color.svg',
              white: '/assets/icons/Youtube/white.svg'
            }
          },
          {
            name: 'Twitter',
            url: 'https://Twitter.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Twitter/black.svg',
              color: '/assets/icons/Twitter/color.svg',
              white: '/assets/icons/Twitter/white.svg'
            }
          }
        ]
      },
      todos: {
        ...state.todos,
        selectedToDo: {},
        todos: [],
        updatedList: false,
        error: '',
        alert: {}
      },
      weather: {
        ...state.weather,
        geoAccess: false,
        tempUnitCel: true,
        firstTimeUsingWeather: true,
        pinWeatherToHomeState: false,

        todayWeatherInfo: null,
        todayWeather: null,
        pinWeatherToHome: null,
        dailyWeather: null,
        hourlyWeather: null,
        lastUpdate: null
      }
    };
  },
  1: (state) => {
    console.log('update redux store version 1 :');
    return {
      ...state
    };
  },
  2: (state) => {
    console.log('update redux store version 2 :');
    return {
      ...state,
      integration: {
        integrationApp: {
          level: 0,
          id: 'app',
          name: 'applications',
          pages: [
            {
              level: 1,
              id: 'news',
              name: 'news',
              pined: false,
              active: true,
              icons: {
                black: '/assets/icons/News/NewYorkTimes/black.svg',
                color: '/assets/icons/News/NewYorkTimes/black.svg',
                white: '/assets/icons/News/NewYorkTimes/white.svg'
              }
            }
          ]
        }
      },
      news: {
        newsSources: [
          {
            name: 'NYTimes',
            rssUrl: 'https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml',
            icons: {
              black: '/assets/icons/Integration/News/NewYorkTimes/black.svg',
              color: '/assets/icons/Integration/News/NewYorkTimes/black.svg',
              white: '/assets/icons/Integration/News/NewYorkTimes/white.svg'
            },
            error: null,
            pinned: false,

            // "empty" | "loading" | "success" | "error"
            status: 'empty',
            lastUpdatedAt: null,
            data: []
          }
        ],
        activeNewsIndex: null,
        lastUpdatedAt: null
      }
    };
  },
  3: (state) => {
    console.log('update redux store version 3 :');
    return {
      ...state,
      app: {
        ...state.app,
        appVersion: PROJECT_VERSION,
        appEnvironment: APP_ENV
      },
      searchEngine: {
        ...state.searchEngine,
        searchEngineRectangle: false,
        searchEngineBorder: false,
        searchEnginSize: 35,
        searchEngineOpenNewTab: true,
        listSearchEngine: [
          {
            name: 'Google',
            url: 'https://google.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Google/black.svg',
              color: '/assets/icons/Google/color.svg',
              white: '/assets/icons/Google/white.svg',
              gray: '/assets/icons/Google/gray.svg'
            }
          },
          {
            name: 'Bing',
            url: 'https://bing.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Bing/black.svg',
              color: '/assets/icons/Bing/color.svg',
              white: '/assets/icons/Bing/white.svg',
              gray: '/assets/icons/Bing/gray.svg'
            }
          },
          {
            name: 'Amazon',
            url: 'https://www.amazon.com/s',
            searchKey: 'k',
            icons: {
              black: '/assets/icons/Amazon/black.svg',
              color: '/assets/icons/Amazon/color.svg',
              white: '/assets/icons/Amazon/white.svg',
              gray: '/assets/icons/Amazon/gray.svg'
            }
          },
          {
            name: 'Youtube',
            url: 'https://youtube.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Youtube/black.svg',
              color: '/assets/icons/Youtube/color.svg',
              white: '/assets/icons/Youtube/white.svg',
              gray: '/assets/icons/Youtube/gray.svg'
            }
          },
          {
            name: 'Twitter',
            url: 'https://Twitter.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Twitter/black.svg',
              color: '/assets/icons/Twitter/color.svg',
              white: '/assets/icons/Twitter/white.svg',
              gray: '/assets/icons/Twitter/gray.svg'
            }
          },
          {
            name: 'Wikipedia',
            url: 'https://en.wikipedia.org/w/index.php',
            searchKey: 'search',
            icons: {
              black: '/assets/icons/Wikipedia/black.svg',
              color: '/assets/icons/Wikipedia/color.svg',
              white: '/assets/icons/Wikipedia/white.svg',
              gray: '/assets/icons/Wikipedia/gray.svg'
            }
          },
          {
            name: 'Facebook',
            url: 'https://Facebook.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Facebook/black.svg',
              color: '/assets/icons/Facebook/color.svg',
              white: '/assets/icons/Facebook/white.svg',
              gray: '/assets/icons/Facebook/gray.svg'
            }
          },
          {
            name: 'Yahoo',
            url: 'https://search.yahoo.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Yahoo/black.svg',
              color: '/assets/icons/Yahoo/color.svg',
              white: '/assets/icons/Yahoo/white.svg',
              gray: '/assets/icons/Yahoo/gray.svg'
            }
          },
          {
            name: 'DuckDuckGo',
            url: 'https://DuckDuckGo.com/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/DuckDuckGo/black.svg',
              color: '/assets/icons/DuckDuckGo/color.svg',
              white: '/assets/icons/DuckDuckGo/white.svg',
              gray: '/assets/icons/DuckDuckGo/gray.svg'
            }
          },
          {
            name: 'LinkedIn',
            url: 'https://www.linkedin.com/search/results/all',
            searchKey: 'keywords',
            icons: {
              black: '/assets/icons/LinkedIN/black.svg',
              color: '/assets/icons/LinkedIN/color.svg',
              white: '/assets/icons/LinkedIN/white.svg',
              gray: '/assets/icons/LinkedIN/gray.svg'
            }
          },

          {
            name: 'GDrive',
            url: 'https://drive.google.com/drive/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/GoogleDrive/black.svg',
              color: '/assets/icons/GoogleDrive/color.svg',
              white: '/assets/icons/GoogleDrive/white.svg',
              gray: '/assets/icons/GoogleDrive/gray.svg'
            }
          },
          {
            name: 'Baidu',
            url: 'https://www.baidu.com/s',
            searchKey: 'wd',
            icons: {
              black: '/assets/icons/Baidu/black.svg',
              color: '/assets/icons/Baidu/color.svg',
              white: '/assets/icons/Baidu/white.svg',
              gray: '/assets/icons/Baidu/gray.svg'
            }
          },
          {
            name: 'Reddit',
            url: 'https://www.reddit.com/search/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Reddit/black.svg',
              color: '/assets/icons/Reddit/color.svg',
              white: '/assets/icons/Reddit/white.svg',
              gray: '/assets/icons/Reddit/gray.svg'
            }
          },
          {
            name: 'Pinterest',
            url: 'https://www.pinterest.com/search/pins/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Pinterest/black.svg',
              color: '/assets/icons/Pinterest/color.svg',
              white: '/assets/icons/Pinterest/white.svg',
              gray: '/assets/icons/Pinterest/gray.svg'
            }
          }
        ],
        activeSearchEngine: {
          name: 'Google',
          url: 'https://google.com/search',
          searchKey: 'q',
          icons: {
            black: '/assets/icons/Google/black.svg',
            color: '/assets/icons/Google/color.svg',
            white: '/assets/icons/Google/white.svg',
            gray: '/assets/icons/Google/gray.svg'
          }
        },
        searchEngines: [
          {
            name: 'Google',
            url: 'https://google.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Google/black.svg',
              color: '/assets/icons/Google/color.svg',
              white: '/assets/icons/Google/white.svg',
              gray: '/assets/icons/Google/gray.svg'
            }
          },
          {
            name: 'Bing',
            url: 'https://bing.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Bing/black.svg',
              color: '/assets/icons/Bing/color.svg',
              white: '/assets/icons/Bing/white.svg',
              gray: '/assets/icons/Bing/gray.svg'
            }
          },
          {
            name: 'Amazon',
            url: 'https://www.amazon.com/s',
            searchKey: 'k',
            icons: {
              black: '/assets/icons/Amazon/black.svg',
              color: '/assets/icons/Amazon/color.svg',
              white: '/assets/icons/Amazon/white.svg',
              gray: '/assets/icons/Amazon/gray.svg'
            }
          },
          {
            name: 'Youtube',
            url: 'https://youtube.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Youtube/black.svg',
              color: '/assets/icons/Youtube/color.svg',
              white: '/assets/icons/Youtube/white.svg',
              gray: '/assets/icons/Youtube/gray.svg'
            }
          },
          {
            name: 'Twitter',
            url: 'https://Twitter.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Twitter/black.svg',
              color: '/assets/icons/Twitter/color.svg',
              white: '/assets/icons/Twitter/white.svg',
              gray: '/assets/icons/Twitter/gray.svg'
            }
          }
        ]
      }
    };
  },
  4: (state) => {
    console.log('update store version 4 :');
    return {
      ...state,
      animation: {
        active: false,
        currentAnimation: null,
        animations: [
          {
            name: 'background-purple-space',
            label: 'Space'
          },
          {
            name: 'background-green-field',
            label: 'Hills'
          },
          {
            name: 'background-blue-ocean',
            label: 'Ocean'
          }
        ],
        error: null,
        loading: false
      },
      integration: {
        integrationApp: {
          level: 0,
          id: 'app',
          name: 'applications',
          pages: [
            {
              level: 1,
              id: 'news',
              name: 'news',
              pined: false,
              active: true,
              icons: {
                black: '/assets/icons/News/NewYorkTimes/black.svg',
                color: '/assets/icons/News/NewYorkTimes/black.svg',
                white: '/assets/icons/News/NewYorkTimes/white.svg'
              }
            },
            {
              level: 2,
              id: 'music',
              name: 'music',
              pined: false,
              active: false,
              icons: {
                black: '/assets/icons/News/NewYorkTimes/black.svg',
                color: '/assets/icons/News/NewYorkTimes/black.svg',
                white: '/assets/icons/News/NewYorkTimes/white.svg'
              }
            }
          ]
        }
      },
      music: {
        musicapiSource: [
          {
            id: 'spotify',
            name: 'Spotify',
            accessToken: '',
            refreshToken: '',
            expiresIn: '',
            code: '',
            isPlaying: false,
            currentTrackId: '',
            icons: {
              black: '/assets/icons/News/NewYorkTimes/black.svg',
              color: '/assets/icons/News/NewYorkTimes/black.svg',
              white: '/assets/icons/News/NewYorkTimes/white.svg'
            },
            active: false,
            loaded: false,
            status: 'empty',
            error: null,
            pined: false
          }
        ],
        activeMusicId: null
      },
      news: {
        newsSources: [
          {
            name: 'NYTimes',
            rssUrl: 'https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml',
            icons: {
              black: '/assets/icons/Integration/News/NewYorkTimes/black.svg',
              color: '/assets/icons/Integration/News/NewYorkTimes/black.svg',
              white: '/assets/icons/Integration/News/NewYorkTimes/white.svg'
            },
            error: null,
            pinned: false,

            // "empty" | "loading" | "success" | "error"
            status: 'empty',
            lastUpdatedAt: null,
            data: []
          }
        ],
        activeNewsIndex: null,
        lastUpdatedAt: null
      },
      searchEngine: {
        ...state.searchEngine,
        searchEnginColored: false,
        showMultipleSeachEngine: false,
        secondarySearchEngine: [
          {
            searchEngineRectangle: false,
            searchEngineBorder: false,
            searchEnginSize: 35,
            searchEnginColored: false,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Google',
              url: 'https://google.com/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/Google/black.svg',
                color: '/assets/icons/Google/color.svg',
                white: '/assets/icons/Google/white.svg',
                gray: '/assets/icons/Google/gray.svg'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: false,
            searchEnginSize: 35,
            searchEnginColored: false,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Bing',
              url: 'https://bing.com/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/Bing/black.svg',
                color: '/assets/icons/Bing/color.svg',
                white: '/assets/icons/Bing/white.svg',
                gray: '/assets/icons/Bing/gray.svg'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: false,
            searchEnginSize: 35,
            searchEnginColored: false,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Amazon',
              url: 'https://www.amazon.com/s',
              searchKey: 'k',
              icons: {
                black: '/assets/icons/Amazon/black.svg',
                color: '/assets/icons/Amazon/color.svg',
                white: '/assets/icons/Amazon/white.svg',
                gray: '/assets/icons/Amazon/gray.svg'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: false,
            searchEnginSize: 35,
            searchEnginColored: false,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Youtube',
              url: 'https://youtube.com/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/Youtube/black.svg',
                color: '/assets/icons/Youtube/color.svg',
                white: '/assets/icons/Youtube/white.svg',
                gray: '/assets/icons/Youtube/gray.svg'
              }
            }
          }
        ]
      }
    };
  },
  5: (state) => {
    console.log('update store version 5 :');
    return {
      ...state,
      animation: { ...state.animation },
      app: { ...state.app },
      auth: {
        ...state.auth,
        loadingFacebbokSSO: false,
        errorFacebookSSO: null
      },
      background: {
        ...state.background,
        topics: [],
        topicLastUpdate: null,
        loadingTopic: false,
        errorTopic: null,

        selectedTopic: {},
        clickedTopicId: null,
        loadingSelectedTopic: false,
        errorSelectedTopic: null
      },
      bookmarks: { ...state.bookmarks },
      clock: {
        ...state.clock,
        analogClock: 'normal',
        showAnalog: false,
        themeMode: 'light'
      },
      coincap: { ...state.coincap, assets: [], showCoincapWindow: false },
      crypto: {
        ...state.crypto,
        cryptoApiSource: [
          {
            id: 'coincap',
            name: 'CoinCap',
            icons: {
              black: '/assets/icons/Integration/Crypto/CoinCap/color.svg',
              color: '/assets/icons/Integration/Crypto/CoinCap/color.svg',
              white: '/assets/icons/Integration/Crypto/CoinCap/color.svg'
            },
            active: false,
            loaded: false,
            status: 'emplty',
            error: null,
            pined: false,
            lastUpdate: null
          }
        ],
        activeCryptoId: null,
        lastUpdate: null
      },
      dashboard: { ...state.dashboard },
      home: { ...state.home, showIntegration: 1 },
      integration: {
        ...state.integration,
        integrationApp: {
          level: 0,
          id: 'app',
          name: 'applications',
          pages: [
            {
              level: 1,
              id: 'news',
              name: 'news',
              pined: false,
              active: true,
              icons: {
                black: '/assets/icons/Integration/News/black.svg',
                color: '/assets/icons/Integration/News/black.svg',
                white: '/assets/icons/Integration/News/white.svg'
              }
            },
            {
              level: 1,
              id: 'music',
              name: 'Music',
              pined: false,
              active: false,
              icons: {
                black: '/assets/icons/Integration/Music/black.svg',
                color: '/assets/icons/Integration/Music/color.svg',
                white: '/assets/icons/Integration/Music/white.svg'
              }
            },
            {
              level: 1,
              id: 'pictures',
              name: 'Pictures',
              pined: false,
              active: false,
              icons: {
                black: '/assets/icons/Integration/Pictures/black.svg',
                color: '/assets/icons/Integration/Pictures/black.svg',
                white: '/assets/icons/Integration/Pictures/white.svg'
              }
            },
            {
              level: 1,
              id: 'video',
              name: 'Video',
              pined: false,
              active: false,
              icons: {
                black: '/assets/icons/Integration/Video/black.svg',
                color: '/assets/icons/Integration/Video/black.svg',
                white: '/assets/icons/Integration/Video/white.svg'
              }
            },
            {
              level: 1,
              id: 'crypto',
              name: 'crypto',
              pined: false,
              active: true,
              icons: {
                black: '/assets/icons/Integration/Crypto/black.svg',
                color: '/assets/icons/Integration/Crypto/color.svg',
                white: '/assets/icons/Integration/Crypto/white.svg'
              }
            },
            {
              level: 1,
              id: 'google',
              name: 'google',
              pined: false,
              active: true,
              icons: {
                black: '/assets/icons/Integration/Crypto/black.svg',
                color: '/assets/icons/Integration/Crypto/color.svg',
                white: '/assets/icons/Integration/Crypto/white.svg'
              }
            }
          ]
        }
      },
      logs: { ...state.logs },
      music: {
        musicapiSource: [
          {
            id: 'spotify',
            name: 'Spotify',
            accessToken: '',
            refreshToken: '',
            expiresIn: '',
            code: '',
            isPlaying: false,
            repeatMode: false,
            shuffleMode: false,
            currentTrackId: '',
            icons: {
              black: '/assets/icons/Integration/Music/Spotify/black.svg',
              color: '/assets/icons/Integration/Music/Spotify/color.svg',
              white: '/assets/icons/Integration/Music/Spotify/white.svg'
            },
            active: false,
            loaded: false,
            status: 'empty',
            error: null,
            errorMessage: '',
            pined: false
          }
        ],
        activeMusicId: null
      },
      google: {
        googleApps: [
          {
            id: 'gcalendar',
            name: 'Calendar',
            accessToken: '',
            refreshToken: '',
            icons: {
              black: '/assets/icons/Integration/Music/Spotify/black.svg',
              color: '/assets/icons/Integration/Music/Spotify/color.svg',
              white: '/assets/icons/Integration/Music/Spotify/white.svg'
            },
            active: false,
            loaded: false,
            status: 'empty',
            error: null,
            errorMessage: '',
            pined: false
          }
        ],
        activeGoogleId: null
      },
      news: {
        newsSources: [
          {
            name: 'NYTimes',
            rssUrl: 'https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml',
            icons: {
              black: '/assets/icons/Integration/News/NewYorkTimes/black.svg',
              color: '/assets/icons/Integration/News/NewYorkTimes/black.svg',
              white: '/assets/icons/Integration/News/NewYorkTimes/white.svg'
            },
            error: null,
            pinned: false,

            // "empty" | "loading" | "success" | "error"
            status: 'empty',
            lastUpdatedAt: null,
            data: []
          }
        ],
        activeNewsIndex: null,
        lastUpdatedAt: null
      },
      notes: { ...state.notes },
      pictures: {
        picturesApiSource: [
          {
            id: 'unsplash',
            name: 'Unsplash',
            icons: {
              black: '/assets/icons/Integration/Pictures/Unsplash/black.svg',
              color: '/assets/icons/Integration/Pictures/Unsplash/black.svg',
              white: '/assets/icons/Integration/Pictures/Unsplash/white.svg'
            },
            active: false,
            loaded: false,
            status: 'emplty',
            error: null,
            pined: false,
            lastUpdate: null
          }
        ],
        activePictureId: null,
        lastUpdate: null
      },
      searchEngine: {
        ...state.searchEngine,
        searchEngineRectangle: false,
        searchEngineBorder: false,
        searchEngineLine: false,
        searchEnginSize: 35,
        searchEnginColored: false,
        searchEngineOpenNewTab: true,
        showMultipleSeachEngine: false,
        secondarySearchEngine: [
          {
            searchEngineRectangle: false,
            searchEngineBorder: false,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Google',
              url: 'https://google.com/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/Google/black.svg',
                color: '/assets/icons/Google/color.svg',
                white: '/assets/icons/Google/white.svg',
                gray: '/assets/icons/Google/gray.svg'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: false,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Youtube',
              url: 'https://youtube.com/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/Youtube/black.svg',
                color: '/assets/icons/Youtube/color.svg',
                white: '/assets/icons/Youtube/white.svg',
                gray: '/assets/icons/Youtube/gray.svg'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: true,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'GDrive',
              url: 'https://drive.google.com/drive/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/GoogleDrive/black.svg',
                color: '/assets/icons/GoogleDrive/color.svg',
                white: '/assets/icons/GoogleDrive/white.svg',
                gray: '/assets/icons/GoogleDrive/gray.svg'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: true,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Amazon',
              url: 'https://www.amazon.com/s',
              searchKey: 'k',
              icons: {
                black: '/assets/icons/Amazon/black.svg',
                color: '/assets/icons/Amazon/color.svg',
                white: '/assets/icons/Amazon/white.svg',
                gray: '/assets/icons/Amazon/gray.svg'
              }
            }
          }
        ]
      },
      todos: { ...state.todos },
      unsplash: {
        pictures: [],
        selectedPicture: {},
        showSearchedPictures: false,
        useUnspashForBackground: false,
        loadingUnspashBackground: false,

        loadingPicture: false,
        errorPicture: null
      },
      video: {
        videosApiSource: [
          {
            id: 'youtube',
            name: 'Youtube',
            icons: {
              black: '/assets/icons/Youtube/black.svg',
              color: '/assets/icons/Youtube/color.svg',
              white: '/assets/icons/Youtube/white.svg'
            },
            active: false,
            loaded: false,
            status: 'emplty',
            error: null,
            pined: false,
            lastUpdate: null
          }
        ],
        activeVideoId: null,
        lastUpdate: null
      },
      weather: { ...state.weather },
      youtube: {
        id: 'youtube',
        name: 'Youtube',
        icons: {
          black: '/assets/icons/Youtube/black.svg',
          color: '/assets/icons/Youtube/color.svg',
          white: '/assets/icons/Youtube/white.svg'
        },
        active: false,
        loaded: false,
        status: 'emplty',
        error: null,
        pined: false,
        lastUpdate: null,
        showSearchedVideos: false,
        videos: [],
        selectedVideo: {
          kind: 'youtube#searchResult',
          etag: 'ytVLx9FYxAkPxo1E4FrwszvXv8Y',
          id: {
            kind: 'youtube#video',
            videoId: 'X8cILIln5vk'
          },
          snippet: {
            publishedAt: '2022-05-23T06:47:52Z',
            channelId: 'UC9X4mdWkuXAVIjeR4ieJyPA',
            title: 'Meavana Teaser Video',
            description:
              'A teaser video of what the MeaVana platform is about. Video credit: https://www.instagram.com/theartsensei/ Produced by one of ...',
            thumbnails: {
              default: {
                url: 'https://i.ytimg.com/vi/X8cILIln5vk/default.jpg',
                width: 120,
                height: 90
              },
              medium: {
                url: 'https://i.ytimg.com/vi/X8cILIln5vk/mqdefault.jpg',
                width: 320,
                height: 180
              },
              high: {
                url: 'https://i.ytimg.com/vi/X8cILIln5vk/hqdefault.jpg',
                width: 480,
                height: 360
              }
            },
            channelTitle: 'MeaVana',
            liveBroadcastContent: 'none',
            publishTime: '2022-05-23T06:47:52Z'
          }
        }
      }
    };
  },
  6: (state) => {
    console.log('update store version 6 :');
    return {
      ...state,
      analytics: { dailyUsageHistory: [] }
    };
  },
  7: (state) => {
    console.log('update store version 7 :');
    return {
      ...state,

      app: {
        ...state.app,
        appStoreVersion: STORE_VERSION
      },
      background: {
        ...state.background,
        quoteSize: 100
      },
      wallpaper: {
        userCollection: [],

        meavanaTopic: [],
        meavanaTopicLastUpdate: null,
        loadingMeavanaTopic: false,
        errorMeavanaTopic: null,

        backgroundHistory: [],

        currentBackground: [],
        currentBackgroundError: null,
        currentBackgroundLoading: false,

        selectedMeavanaTopic: {},
        selectedMeavanaTopicLastUpdate: null,
        loadingSelectedTopic: false,
        errorSelectedTopic: null,

        clickedTopicId: null,

        activeMode: 'meavana-topic'
      },
      weather: {
        ...state.weather,
        searchCity: '',
        editingCity: false,

        lastUpdate: null,
        todayWeather: {},
        errorTodayWeather: null,
        loadingTodayWeather: false,

        todayWeatherInfo: {},
        errorTodayWeatherInfo: null,
        loadingTodayWeatherInfo: false,

        hourForcastWeather: [],
        errorhourForcastWeather: null,
        loadinghourForcastWeather: false,

        weekForcastWeather: [],
        errorweekForcastWeather: null,
        loadingweekForcastWeather: false
      }
    };
  },
  8: (state) => {
    console.log('update store version 8 :');
    return {
      ...state,
      clock: {
        ...state.clock,
        timeDateFontWeight: 400
      },
      weather: {
        ...state.weather,
        coordsSearchCity: null
      }
    };
  },
  9: (state) => {
    console.log('update store version 9 :');
    return {
      ...state,
      app: {
        ...state.app,
        appVersion: '0.1.21',
        appStoreVersion: '9'
      },
      searchEngine: {
        ...state.searchEngine,
        listSearchEngine: [
          {
            name: 'Google',
            url: 'https://google.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Google/black.svg',
              color: '/assets/icons/Google/color.png',
              white: '/assets/icons/Google/white.svg',
              gray: '/assets/icons/Google/gray.png'
            }
          },
          {
            name: 'Youtube',
            url: 'https://youtube.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Youtube/black.svg',
              color: '/assets/icons/Youtube/color.png',
              white: '/assets/icons/Youtube/white.svg',
              gray: '/assets/icons/Youtube/gray.png'
            }
          },
          {
            name: 'GDrive',
            url: 'https://drive.google.com/drive/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/GoogleDrive/black.svg',
              color: '/assets/icons/GoogleDrive/color.png',
              white: '/assets/icons/GoogleDrive/white.svg',
              gray: '/assets/icons/GoogleDrive/gray.png'
            }
          },
          {
            name: 'Amazon',
            url: 'https://www.amazon.com/s',
            searchKey: 'k',
            icons: {
              black: '/assets/icons/Amazon/black.svg',
              color: '/assets/icons/Amazon/color.png',
              white: '/assets/icons/Amazon/white.svg',
              gray: '/assets/icons/Amazon/gray.png'
            }
          },
          {
            name: 'Twitter',
            url: 'https://Twitter.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Twitter/black.svg',
              color: '/assets/icons/Twitter/color.png',
              white: '/assets/icons/Twitter/white.svg',
              gray: '/assets/icons/Twitter/gray.png'
            }
          },
          {
            name: 'GScholar',
            url: 'https://scholar.google.com/scholar',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/GoogleScholar/black.svg',
              color: '/assets/icons/GoogleScholar/color.png',
              white: '/assets/icons/GoogleScholar/white.svg',
              gray: '/assets/icons/GoogleScholar/gray.png'
            }
          },
          {
            name: 'Bing',
            url: 'https://bing.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Bing/black.svg',
              color: '/assets/icons/Bing/color.png',
              white: '/assets/icons/Bing/white.svg',
              gray: '/assets/icons/Bing/gray.png'
            }
          },
          {
            name: 'Wikipedia',
            url: 'https://en.wikipedia.org/w/index.php',
            searchKey: 'search',
            icons: {
              black: '/assets/icons/Wikipedia/black.svg',
              color: '/assets/icons/Wikipedia/color.png',
              white: '/assets/icons/Wikipedia/white.svg',
              gray: '/assets/icons/Wikipedia/gray.png'
            }
          },
          {
            name: 'Facebook',
            url: 'https://Facebook.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Facebook/black.svg',
              color: '/assets/icons/Facebook/color.png',
              white: '/assets/icons/Facebook/white.svg',
              gray: '/assets/icons/Facebook/gray.png'
            }
          },
          {
            name: 'Yahoo',
            url: 'https://search.yahoo.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Yahoo/black.svg',
              color: '/assets/icons/Yahoo/color.png',
              white: '/assets/icons/Yahoo/white.svg',
              gray: '/assets/icons/Yahoo/gray.png'
            }
          },
          {
            name: 'DuckDuckGo',
            url: 'https://DuckDuckGo.com/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/DuckDuckGo/black.svg',
              color: '/assets/icons/DuckDuckGo/color.png',
              white: '/assets/icons/DuckDuckGo/white.svg',
              gray: '/assets/icons/DuckDuckGo/gray.png'
            }
          },
          {
            name: 'LinkedIn',
            url: 'https://www.linkedin.com/search/results/all',
            searchKey: 'keywords',
            icons: {
              black: '/assets/icons/LinkedIN/black.svg',
              color: '/assets/icons/LinkedIN/color.png',
              white: '/assets/icons/LinkedIN/white.svg',
              gray: '/assets/icons/LinkedIN/gray.png'
            }
          },
          {
            name: 'Baidu',
            url: 'https://www.baidu.com/s',
            searchKey: 'wd',
            icons: {
              black: '/assets/icons/Baidu/black.svg',
              color: '/assets/icons/Baidu/color.png',
              white: '/assets/icons/Baidu/white.svg',
              gray: '/assets/icons/Baidu/gray.png'
            }
          },
          {
            name: 'Reddit',
            url: 'https://www.reddit.com/search/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Reddit/black.svg',
              color: '/assets/icons/Reddit/color.png',
              white: '/assets/icons/Reddit/white.svg',
              gray: '/assets/icons/Reddit/gray.png'
            }
          },
          {
            name: 'Pinterest',
            url: 'https://www.pinterest.com/search/pins/',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Pinterest/black.svg',
              color: '/assets/icons/Pinterest/color.png',
              white: '/assets/icons/Pinterest/white.svg',
              gray: '/assets/icons/Pinterest/gray.png'
            }
          }
        ],
        activeSearchEngine: {
          name: 'Google',
          url: 'https://google.com/search',
          searchKey: 'q',
          icons: {
            black: '/assets/icons/Google/black.svg',
            color: '/assets/icons/Google/color.png',
            white: '/assets/icons/Google/white.svg',
            gray: '/assets/icons/Google/gray.png'
          }
        },
        searchEngines: [
          {
            name: 'Google',
            url: 'https://google.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Google/black.svg',
              color: '/assets/icons/Google/color.png',
              white: '/assets/icons/Google/white.svg',
              gray: '/assets/icons/Google/gray.png'
            }
          },
          {
            name: 'Bing',
            url: 'https://bing.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Bing/black.svg',
              color: '/assets/icons/Bing/color.png',
              white: '/assets/icons/Bing/white.svg',
              gray: '/assets/icons/Bing/gray.png'
            }
          },
          {
            name: 'Amazon',
            url: 'https://www.amazon.com/s',
            searchKey: 'k',
            icons: {
              black: '/assets/icons/Amazon/black.svg',
              color: '/assets/icons/Amazon/color.png',
              white: '/assets/icons/Amazon/white.svg',
              gray: '/assets/icons/Amazon/gray.png'
            }
          },
          {
            name: 'Youtube',
            url: 'https://youtube.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Youtube/black.svg',
              color: '/assets/icons/Youtube/color.png',
              white: '/assets/icons/Youtube/white.svg',
              gray: '/assets/icons/Youtube/gray.png'
            }
          },
          {
            name: 'Twitter',
            url: 'https://Twitter.com/search',
            searchKey: 'q',
            icons: {
              black: '/assets/icons/Twitter/black.svg',
              color: '/assets/icons/Twitter/color.png',
              white: '/assets/icons/Twitter/white.svg',
              gray: '/assets/icons/Twitter/gray.png'
            }
          }
        ],
        secondarySearchEngine: [
          {
            searchEngineRectangle: false,
            searchEngineBorder: true,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Google',
              url: 'https://google.com/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/Google/black.svg',
                color: '/assets/icons/Google/color.png',
                white: '/assets/icons/Google/white.svg',
                gray: '/assets/icons/Google/gray.png'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: true,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Youtube',
              url: 'https://youtube.com/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/Youtube/black.svg',
                color: '/assets/icons/Youtube/color.png',
                white: '/assets/icons/Youtube/white.svg',
                gray: '/assets/icons/Youtube/gray.png'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: true,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'GDrive',
              url: 'https://drive.google.com/drive/search',
              searchKey: 'q',
              icons: {
                black: '/assets/icons/GoogleDrive/black.svg',
                color: '/assets/icons/GoogleDrive/color.png',
                white: '/assets/icons/GoogleDrive/white.svg',
                gray: '/assets/icons/GoogleDrive/gray.png'
              }
            }
          },
          {
            searchEngineRectangle: false,
            searchEngineBorder: true,
            searchEngineLine: false,
            searchEnginSize: 35,
            searchEnginColored: true,
            searchEngineOpenNewTab: true,
            activeSearchEngine: {
              name: 'Amazon',
              url: 'https://www.amazon.com/s',
              searchKey: 'k',
              icons: {
                black: '/assets/icons/Amazon/black.svg',
                color: '/assets/icons/Amazon/color.png',
                white: '/assets/icons/Amazon/white.svg',
                gray: '/assets/icons/Amazon/gray.png'
              }
            }
          }
        ]
      }
    };
  },
  10: (state) => {
    console.log('update store version 10 :');
    return {
      ...state,
      app: {
        ...state.app,
        appVersion: '0.1.22',
        appStoreVersion: '10'
      }
    };
  },
  11: (state) => {
    console.log('update store version 11 :');
    return {
      ...state,
      app: {
        ...state.app,
        appVersion: '0.1.22'
      }
    };
  },
  12: (state) => {
    console.log('update store version 12 :');
    return {
      ...state,
      app: {
        ...state.app,
        appVersion: '0.1.23'
      }
    };
  }
};
