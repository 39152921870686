import { assetsIcons } from '../../../config/images';

export const initialState = {
  newsSources: [
    {
      name: 'NYTimes',
      rssUrl: 'https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml',
      icons: assetsIcons.integration.news.NewYorkTimes,
      error: null,
      pinned: false,

      // "empty" | "loading" | "success" | "error"
      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'ESPN',
      rssUrl: 'https://www.espn.com/espn/rss/news',
      icons: assetsIcons.integration.news.ESPN,
      error: null,
      pinned: false,

      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'TechCrunch',
      rssUrl: 'https://techcrunch.com/feed/',
      icons: assetsIcons.integration.news.TechCrunch,
      error: null,
      pinned: false,

      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'Chess.com',
      rssUrl: 'https://www.chess.com/rss/news',
      icons: assetsIcons.integration.news.ChessCom,
      error: null,
      pinned: false,

      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'Yahoo Finance',
      rssUrl: 'https://finance.yahoo.com/news/rssindex',
      icons: assetsIcons.integration.news.YahooFinance,
      error: null,
      pinned: false,

      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'BBC',
      rssUrl: 'https://feeds.bbci.co.uk/news/world/rss.xml',
      icons: assetsIcons.integration.news.BBC,
      error: null,
      pinned: false,
      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'Technology Review',
      rssUrl: 'https://www.technologyreview.com/feed',
      icons: assetsIcons.integration.news.TechReview,
      error: null,
      pinned: false,
      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'LifeHacker',
      rssUrl: 'https://lifehacker.com/rss',
      icons: assetsIcons.integration.news.LifeHacker,
      error: null,
      pinned: false,
      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'Mashable',
      rssUrl: 'https://mashable.com/feeds/rss/digital-culture',
      icons: assetsIcons.integration.news.Mashable,
      error: null,
      pinned: false,
      status: 'empty',
      lastUpdatedAt: null,
      data: []
    },
    {
      name: 'Forbes Billionaires',
      rssUrl: 'https://www.forbes.com/worlds-billionaires/feed',
      icons: assetsIcons.integration.news.Forbes,
      error: null,
      pinned: false,
      status: 'empty',
      lastUpdatedAt: null,
      data: []
    }
  ],
  activeNewsIndex: null,
  lastUpdatedAt: null
};

export const newsStateAction = {
  UPDATE_NEWS_WINDOW_STATE: 'news/UPDATE_NEWS_WINDOW_STATE',
  UPDATE_NEWS_PIN: 'news/UPDATE_NEWS_PIN',
  GET_NEWS_API_REQUEST: 'news/GET_NEWS_API_REQUEST',
  GET_NEWS_API_SUCCESS: 'news/GET_NEWS_API_SUCCESS',
  GET_NEWS_API_FAILURE: 'news/GET_NEWS_API_FAILURE'
};
