import { preloadBackgroundsToCache } from '../../../Utils/loadImageToCache';
import { backgroundStateAction } from './constants';

export const updateBackgroundRequest = () => {
  return { type: backgroundStateAction.UPDATE_BACKGROUND_REQUEST };
};
export const updateBackgroundSuccess = (background = {}, quote = {}, backgroundBatch = []) => {
  return {
    type: backgroundStateAction.UPDATE_BACKGROUND_SUCCESS,
    payload: {
      background: background,
      quote: quote,
      backgroundBatch: backgroundBatch
    }
  };
};

export const updateQuoteSizeAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: backgroundStateAction.SET_QUOTE_SIZE,
      payload: value
    });
  };
};

export const updateBackgroundSuccessFromLogin = (background = {}, quote = {}) => {
  return {
    type: backgroundStateAction.UPDATE_BACKGROUND_SUCCESS_FROM_LOGIN,
    payload: {
      background: background,
      quote: quote
    }
  };
};

export const updateBackgroundFailure = (error) => {
  return {
    type: backgroundStateAction.UPDATE_BACKGROUND_FAILURE,
    error: error
  };
};

export const getAllTopicRequest = () => {
  return {
    type: backgroundStateAction.GET_ALL_TOPIC_REQUEST
  };
};
export const grtAllTopicSuccess = (topics) => {
  return {
    type: backgroundStateAction.GET_ALL_TOPIC_SUCCESS,
    payload: topics
  };
};
export const getAllTopicFailure = (error) => {
  return {
    type: backgroundStateAction.GET_ALL_TOPIC_FAILURE,
    error: error
  };
};

export const updateSelectedTopicRequestAction = (clickedTopicId) => {
  return {
    type: backgroundStateAction.UPDATE_SELECTED_TOPIC_REQUEST,
    payload: clickedTopicId
  };
};
export const updateSelectedTopicSuccessAction = () => {
  return {
    type: backgroundStateAction.UPDATE_SELECTED_TOPIC_SUCCESS
  };
};
export const updateSelectedTopicFailureAction = (error) => {
  return {
    type: backgroundStateAction.UPDATE_SELECTED_TOPIC_FAILURE,
    error: error
  };
};

export const updateSelectedTopicAction = (topic) => {
  preloadBackgroundsToCache(topic.imagesBatch.slice(0, 2));
  return {
    type: backgroundStateAction.UPDATE_SELECTED_TOPIC,
    payload: topic
  };
};
export const updateSelectedTopicFromServerAction = (topics) => {
  // preloadBackgroundsToCache(topic.imagesBatch.slice(0, 2));
  return {
    type: backgroundStateAction.UPDATE_SELECTED_TOPIC_FROM_SERVER,
    payload: topics
  };
};

export const updateBackgroundIndexDBStateAction = (state) => {
  return {
    type: backgroundStateAction.UPDATE_BACKGROUND_INDEXDB_STATE,
    payload: state
  };
};
export const updateBackgroundIndexDBSelectedIndexAction = (index) => {
  return {
    type: backgroundStateAction.UPDATE_BACKGROUND_INDEXDB_INDEX,
    payload: index
  };
};
export const updateBackgroundIndexDBArrayAction = (index, item) => {
  return {
    type: backgroundStateAction.UPDATE_BACKGROUND_INDEXDB_ARRAY,
    payload: { index: index, item: item }
  };
};
