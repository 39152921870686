import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Quote.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { MoreHoriz, AccessTime, AlarmOn } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import useWindowDimensions from '../../Utils/useWindowDimensions';
import { Button, Checkbox, Slider } from '@mui/material';
import '../SearchEngineFeature/SearchEngine.css';
import { resetHomeThemeAction } from '../../store/reducers/homeReducer/action';
import { updateQuoteSizeAction } from '../../store/reducers/backgroundReducer/action';

export default function QuoteFeature(props) {
  const backgroundState = useSelector((state) => state.background);
  const [showIcons, setshowIcons] = useState(false);
  const [statePopoverQuoteSetting, setStatePopoverQuoteSetting] = useState(false);
  const appState = useSelector((state) => state.app);

  const hideIcons = async () => {
    if (!statePopoverQuoteSetting) {
    }
    setshowIcons(false);
  };

  //Starts: temp code for topic based guest mode flow
  const quoteArray = [
    {
      id: 0,
      slug: 'nelson-mandela',
      text: 'The greatest glory in living lies not in never falling, but in rising every time we fall',
      author: 'Nelson Mandela'
    },
    {
      id: 1,
      slug: 'messi',
      text: 'You have to fight to reach your dream. You have to sacrifice and work hard for it.',
      author: 'Lionel Messi'
    },
    {
      id: 2,
      slug: 'tom-cruise',
      text: 'When I work, I work very hard. So I look to work with people who have that level of dedication. And I depend on that from everyone. From the director to my crews that I work with.',
      author: 'Tom Cruise'
    },
    {
      id: 3,
      slug: 'shah-rukh-khan',
      text: 'Success is not a good teacher, failure makes you humble.',
      author: 'Shah Rukh Khan'
    },
    {
      id: 4,
      slug: 'elon-musk',
      text: 'When something is important enough, you do it even if the odds are not in your favor.',
      author: 'Elon Musk'
    },
    {
      id: 5,
      slug: 'magnus-carlsen',
      text: "If you want to get to the top, there's always the risk that it will isolate you from other people.",
      author: 'Magnus Carlsen'
    },
    {
      id: 6,
      slug: 'michael-phelps',
      text: 'I want to be able to look back and say, ‘I’ve done everything I can, and I was successful.’ I don’t want to look back and say I should have done this or that',
      author: 'Michael Phelps'
    },
    {
      id: 7,
      slug: 'neymar',
      text: 'I just want to be better every day, to get better every day, not to be better than anyone else.',
      author: 'Neymar'
    },
    {
      id: 8,
      slug: 'virat-kohli',
      text: 'Self-belief and hard work will always earn you success.',
      author: 'Virat Kohli'
    },
    {
      id: 9,
      slug: 'albert-einstein',
      text: 'Strive not to be a success, but rather to be of value.',
      author: 'Albert Einstein'
    },
    {
      id: 10,
      slug: 'ariana-grande',
      text: `Don't ever doubt yourselves or waste a second of your life. It's too short, and you're too special.`,
      author: 'Araiana Grande'
    },
    {
      id: 11,
      slug: 'beyonce',
      text: `The most alluring thing a woman can have is confidence.`,
      author: 'Beyonce'
    },
    {
      id: 12,
      slug: 'taylor-swift',
      text: `No matter what happens in life, be good to people.`,
      author: 'Taylor Swift'
    },
    {
      id: 13,
      slug: 'lord-of-the-rings',
      text: `I have been awake since before the breaking of the first silence. In that time, I have had many names.`,
      author: 'Lord of the Rings'
    },
    {
      id: 14,
      slug: 'peaceful-mind',
      text: `The universe is an open field of wonders for everyone who understands that there is no "I" but only One consciousness.`,
      author: 'Peaceful Mind'
    },
    {
      id: 15,
      slug: 'drink-your-tea',
      text: `What is the sound of silence ?  If you attain the question, you can have your first cup of tea.`,
      author: 'Drink Your Tea'
    },
    {
      id: 16,
      slug: 'stephan-curry',
      text: `Whatever that means, however you got on that mountain, why not try to climb it? And do it in your own way.`,
      author: 'Stephan Curry'
    },
    {
      id: 17,
      slug: 'iron-man',
      text: `Heroes are made by the paths they choose, not the powers they are graced with.`,
      author: 'Iron Man'
    },
    {
      id: 18,
      slug: 'zen-martial-arts',
      text: `Duality is not good or bad, it is the reality of a human mind--forces of nature. The problem arises when you attach values to duality (nice/ugly, good/bad, like/dislike, etc.).  Piercing duality means to see Oneness in nature.`,
      author: 'Zen Martial Arts'
    },
    {
      id: 19,
      slug: 'coffee',
      text: `May your coffee be strong and your Monday be short.`,
      author: 'Coffee'
    },
    {
      id: 20,
      slug: 'dogs',
      text: `Every dog has its day.`,
      author: 'Dogs'
    },
    {
      id: 21,
      slug: 'women-empowerment',
      text: `I tell my story not because it is unique, but because it is the story of many girl.`,
      author: 'Women Empowerment'
    },
    {
      id: 22,
      slug: 'sports-cars',
      text: `Take care of your car in the garage, and the car will take care of you on the road.`,
      author: 'Sports Cars'
    },
    {
      id: 23,
      slug: 'billionaire-lifestyle',
      text: `I was born poor, but I will die rich.`,
      author: 'Billionaire Lifestyle'
    },
    {
      id: 24,
      slug: 'forests',
      text: `And into the forest I go to lose my mind and find my soul.`,
      author: 'Forests'
    },
    {
      id: 25,
      slug: 'yoga-with-sapna',
      text: `Inhale the future, exhale the past.`,
      author: 'Yoga with Sapna'
    },
    {
      id: 26,
      slug: 'cuba',
      text: `Cuba is like going to a whole other planet.`,
      author: 'Cuba'
    },
    {
      id: 27,
      slug: 'nicaragua',
      text: `In Nicaragua, liberty, equality, and the rule of law were the stuff of dreams.`,
      author: 'Nicaragua'
    },
    {
      id: 27,
      slug: 'coffee-and-beyond',
      text: `Rise and grind, it's coffee time!`,
      author: 'Coffee'
    }
  ];

  const [currQuote, setCurrQuote] = useState(quoteArray[0]);

  useEffect(function () {
    loadTopicBasedQuote();
  }, []);

  const loadTopicBasedQuote = () => {
    const currpath = window.location.pathname;
    var _quote = quoteArray.find((quote) => quote.slug === currpath.replace('/', ''));
    if (_quote) setCurrQuote(_quote);
  };
  //Ends: temp code for topic based guest mode flow

  if (appState.guestMode) {
    return (
      <div
        className="container-quote"
        style={
          props.display
            ? { display: 'none' }
            : {
                opacity: props.opacity,
                overflow: 'hidden',
                fontSize: backgroundState.quoteSize + '%'
              }
        }
        onMouseOver={() => setshowIcons(true)}
        onMouseLeave={() => {
          hideIcons();
        }}>
        <div className="container-quote-action">
          <OverlayTrigger
            trigger="click"
            key="OverlayTrigger-Quote"
            placement="top"
            rootClose={true}
            overlay={
              <Popover id={`popover-Quote`} className="quote-metgadata-popover">
                <Popover.Body className="quote-metgadata-popover-body">
                  <span className="quote-metgadata-popover-txt ">{currQuote.author}</span>
                </Popover.Body>
              </Popover>
            }>
            <span>{currQuote.text}</span>
          </OverlayTrigger>
          <Popup
            hideIcons={hideIcons}
            showIcons={showIcons}
            setStatePopoverQuoteSetting={setStatePopoverQuoteSetting}
          />
        </div>
      </div>
    );
  } else {
    return backgroundState.quote && backgroundState.quote.metadata ? (
      <div
        className="container-quote"
        style={
          props.display
            ? { display: 'none' }
            : {
                opacity: props.opacity,
                overflow: 'hidden',
                fontSize: backgroundState.quoteSize + '%'
              }
        }
        onMouseOver={() => setshowIcons(true)}
        onMouseLeave={() => {
          hideIcons();
        }}>
        <div className="container-quote-action">
          <OverlayTrigger
            trigger="click"
            key="OverlayTrigger-Quote"
            placement="top"
            rootClose={true}
            overlay={
              <Popover id={`popover-Quote`} className="quote-metgadata-popover">
                <Popover.Body className="quote-metgadata-popover-body">
                  <span className="quote-metgadata-popover-txt ">
                    {backgroundState.quote ? backgroundState.quote.metadata : null}
                  </span>
                </Popover.Body>
              </Popover>
            }>
            <span>{backgroundState.quote ? backgroundState.quote.content : null}</span>
          </OverlayTrigger>
          <Popup
            hideIcons={hideIcons}
            showIcons={showIcons}
            setStatePopoverQuoteSetting={setStatePopoverQuoteSetting}
          />
        </div>
      </div>
    ) : (
      <div
        className="container-quote"
        style={
          props.display
            ? { display: 'none' }
            : {
                opacity: props.opacity,
                overflow: 'hidden',
                fontSize: backgroundState.quoteSize + '%'
              }
        }
        onMouseOver={() => setshowIcons(true)}
        onMouseLeave={() => {
          hideIcons();
        }}>
        {backgroundState.quote && backgroundState.quote.content ? (
          <div className="container-quote-action">
            <span>{backgroundState.quote.content}</span>

            <Popup
              hideIcons={hideIcons}
              showIcons={showIcons}
              setStatePopoverQuoteSetting={setStatePopoverQuoteSetting}
            />
          </div>
        ) : (
          <div className="container-quote-action"></div>
        )}
      </div>
    );
  }
}

const Popup = ({ hideIcons, showIcons, setStatePopoverQuoteSetting }) => {
  const { height, width } = useWindowDimensions();
  const backgroundState = useSelector((state) => state.background);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();

  return (
    <OverlayTrigger
      trigger="click"
      key="OverlayTrigger-quote-settings"
      placement="top"
      rootClose={true}
      onHide={(event) => {
        setStatePopoverQuoteSetting(false);
        hideIcons();
      }}
      onEnter={(event) => {}}
      overlay={
        <Popover
          id={`popover-quote-settings`}
          className="popover-quote-setting popover-meavana-arrow-top">
          <Popover.Body className="popover-quote-setting-body">
            <div className="quote-popover-item">
              <span
                onClick={() => {
                  setCopied((prev) => !prev);
                  navigator.clipboard.writeText(backgroundState.quote.content);
                }}
                style={{ cursor: 'pointer', padding: '10px 0' }}
                className="quote-popover-item-txt">
                {copied ? 'Copied to clipboard' : 'Copy'}
              </span>
            </div>

            <div className="quote-popover-item">
              <span className="quote-popover-item-txt">Size</span>
              <Slider
                size="medium"
                min={50}
                max={150}
                value={backgroundState.quoteSize}
                onChange={(event) => {
                  dispatch(updateQuoteSizeAction(event.target.value));
                  dispatch(resetHomeThemeAction());
                }}
                aria-label="Small"
                valueLabelDisplay="off"
              />
            </div>
          </Popover.Body>
        </Popover>
      }>
      <IconButton
        style={
          !showIcons
            ? {
                opacity: 0,
                cursor: 'inherit'
              }
            : {}
        }
        className="btn-quote-setting"
        aria-label="quote-Settings">
        <MoreHoriz className="home-icon-setting-font-size" />
      </IconButton>
    </OverlayTrigger>
  );
};
