import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GCalendar from './GCalendar';
import WidgetWindow from '../../../../components/WidgetWindow/WidgetWindow';
import { updateCryptoWindowStateAction } from '../../../../store/reducers/cryptoReducer/action';
import { GetImageUrl } from '../../../../Utils/GetImageUrl';

export default function GCalendarWindow() {
  const googleState = useSelector((state) => state.google);
  const googleCalendarState = googleState.googleApps.find((item) => item.id == 'gcalendar');

  const dispatch = useDispatch();

  const onClickWindowCloseButton = () => {
    dispatch(updateCryptoWindowStateAction());
  };
  return (
    <WidgetWindow
      title={googleCalendarState.name}
      isActive={true}
      onClickWindowCloseButton={onClickWindowCloseButton}
      //titleImg={titleImg}
    >
      <GCalendar />
    </WidgetWindow>
  );
}
