import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Description, InstallDesktop } from '@mui/icons-material';
import './NoteGuestMode.css';
import { Logout } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { updateGuestAction } from '../../../store/reducers/appReducer/action';

export default function NoteGuestMode(props) {
  const dispatch = useDispatch();

  if (props.display || props.opacity === 0) {
    return (
      <div className="notes-container-btn" style={{ opacity: 0, cursor: 'inherit' }}>
        <Description className="home-icon-font-size" />
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        placement="top"
        overlay={
          <Popover id="popover-notes" className="popover-notes-guest-mode">
            <Popover.Body className="popover-notes-guest-mode-body">
              <span className="txt-note-guest-mode">
                {/* To use the Notes and Todos, please Sign In */}
                Use a range of features, including Notes, Todos, Stickies, etc
                <br />
                Get the Chrome Extension
              </span>
              <div
                className="container-btn-icons-setting"
                style={{ width: 'auto', marginTop: '0.2rem' }}
                onClick={() => {
                  window.location =
                    'https://chrome.google.com/webstore/detail/meavana-transform-your-ch/kgbcoelgfffkmkpfnldemdinmcbpjlaa';
                }}>
                <InstallDesktop fontSize="medium" />

                <span className="container-btn-icons-setting-txt">Click To Install</span>
              </div>
            </Popover.Body>
          </Popover>
        }>
        <div className="notes-container-btn" style={{ opacity: props.opacity }}>
          <Description className="home-icon-font-size" /*fontSize="large"*/ />
        </div>
      </OverlayTrigger>
    );
  }
}
