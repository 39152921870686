import { integrationStateAction } from "./constants";

export const resetIntegrationStateAction = () => {
  return (dispatch) => {
    dispatch({
      type: integrationStateAction.RESET_INTEGRATION_STATE
    });
  };
};
export const resetIntegrationPinStateAction = () => {
  return (dispatch) => {
    dispatch({
      type: integrationStateAction.RESET_INTEGRATION_PIN_STATE
    });
  };
};
