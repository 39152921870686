import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateContainer from "./DateContainer";
import "./Style.css";
import TimeContainer from "./TimeContainer";

import Clock from "react-live-clock";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import TimerOutlined from "@mui/icons-material/TimerOutlined";
import { MoreHoriz, AccessTime, AlarmOn } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import AnalogClock from "./AnalogClock";
import { FormControl, Radio, RadioGroup, Slider } from "@mui/material";
import {
  toggleAnalogClockAction,
  updateAmPmAction,
  updateAnalogClockSizeAction,
  updateTimeAnalogClockAction,
  updateTimeFontSizeAction,
  updateTimeFontWeightAction,
  updateTimeFormatAction,
  updateTimeSecondAction,
  updateTimeThemeModeAction
} from "../../store/reducers/clockReducer/action";
import { resetHomeThemeAction } from "../../store/reducers/homeReducer/action";
import useWindowDimensions from "../../Utils/useWindowDimensions";
import ModernAnalogClock from "./ModernAnalogClock";
import MyFormControlLabel from "../../Utils/MyFormControlLabel";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

export default function DateAndTime(props) {
  const [showIcons, setshowIcons] = useState(false);

  const clockState = useSelector((state) => state.clock);

  const dispatch = useDispatch();

  const { height, width } = useWindowDimensions();

  const hideIcons = async () => {
    if (!props.showTimeFeature) {
      // &&!statePopoverTimeFeature &&!statePopoverTimeSetting
      setshowIcons(false);
    }
  };

  const [time, settime] = useState(new Date());

  useEffect(() => {
    const time = setInterval(() => {
      settime(new Date());
    }, 1000);
    return () => {
      clearInterval(time);
    };
  }, []);

  const updateClockMode = (event, newValue) => {
    event.preventDefault();

    meaVanaConsole("newValue >>>>> ", newValue);

    dispatch(updateTimeAnalogClockAction(newValue));
    dispatch(resetHomeThemeAction());
  };

  return (
    <div
      className="container-date-time"
      style={
        props.display
          ? { display: "none" }
          : { fontSize: clockState.timeDateFontSize + "%" }
      }
      onMouseOver={() => setshowIcons(true)}
      onMouseLeave={() => {
        hideIcons();
      }}
    >
      <div className="subcontainer-date-time-btn-time-feature">
        <OverlayTrigger
          trigger="click"
          key="OverlayTrigger-time-feature"
          placement={width < 1000 ? "bottom" : "left"}
          rootClose={true}
          onHide={(event) => {
            hideIcons();
          }}
          overlay={
            <Popover
              id={`popover-time-feature`}
              className={
                width < 1000
                  ? "popover-time-feature popover-meavana-arrow-bottom"
                  : "popover-time-feature popover-meavana-arrow-left"
              }
            >
              <Popover.Body className="popover-time-feature-body">
                <div
                  /*className={
                    props.showAlarm
                      ? "btn-time-feature btn-time-feature-active"
                      : "btn-time-feature"
                  }
                  onClick={() => {
                    props.setshowAlarm(!props.showAlarm);
                    props.setshowAlarmFeature(!props.showAlarmFeature);
                    props.setshowTimer(false);
                    props.setshowStopWatch(false);
                  }}*/
                  className="btn-time-feature btn-time-feature-active"
                  style={{ opacity: 0.7 }}
                >
                  <AccessAlarmsIcon fontSize="medium" />
                  Alarms
                </div>

                <div
                  className={
                    props.showTimer
                      ? "btn-time-feature btn-time-feature-active"
                      : "btn-time-feature"
                  }
                  onClick={() => {
                    props.setshowTimer(!props.showTimer);
                    props.setshowTimeFeature(!props.showTimeFeature);
                    props.setshowAlarm(false);
                    props.setshowStopWatch(false);
                  }}
                >
                  <TimerOutlined fontSize="medium" />
                  Timer
                </div>

                <div
                  className={
                    props.showStopWatch
                      ? "btn-time-feature btn-time-feature-active"
                      : "btn-time-feature"
                  }
                  onClick={() => {
                    props.setshowStopWatch(!props.showStopWatch);
                    props.setshowTimeFeature(!props.showTimeFeature);
                    props.setshowAlarm(false);
                    props.setshowTimer(false);
                  }}
                >
                  <AlarmOn fontSize="medium" />
                  Stopwatch
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <IconButton
            className="time-feature-icon"
            disabled={
              showIcons && (props.opacityTime > 0 || props.opacityDate > 0)
                ? false
                : true
            }
            style={
              showIcons && (props.opacityTime > 0 || props.opacityDate > 0)
                ? {}
                : { opacity: 0 }
            }
            aria-label="time-feature"
          >
            <AccessTime
              /*fontSize="medium"*/ className={`home-icon-setting-font-size`}
            />
          </IconButton>
        </OverlayTrigger>
      </div>

      <div className="subcontainer-date-time">
        <div style={{ opacity: props.opacityTime }}>
          {clockState.showAnalog ? (
            clockState.analogClock === "normal" ? (
              <AnalogClock
                display={props.display}
                opacity={props.opacityTime}
                time={time}
              />
            ) : (
              <ModernAnalogClock />
            )
          ) : (
            <TimeContainer
              display={props.display}
              opacity={props.opacityTime}
              fontWeight={clockState.timeDateFontWeight}
            />
          )}
        </div>
        <DateContainer
          display={props.display}
          opacity={props.opacityDate}
          fontWeight={clockState.timeDateFontWeight}
          date={time}
        />
      </div>

      <div className="subcontainer-date-time-btn-setting">
        {!clockState.hour24 &&
          !clockState.showAnalog &&
          !props.display &&
          props.opacityTime > 0 &&
          clockState.showAmPm && (
            <Clock
              format="A"
              interval={60000}
              ticking={true}
              className={`time-24h-am`}
              style={{
                opacity: props.opacityTime,
                fontWeight: clockState.timeDateFontWeight
              }}
            />
          )}
        <OverlayTrigger
          trigger="click"
          key="OverlayTrigger-time-hour24"
          placement={width < 1000 ? "bottom" : "right"}
          rootClose={true}
          onHide={(event) => {
            hideIcons();
          }}
          overlay={
            <Popover
              id={`popover-time-hour24`}
              className={
                width < 1000
                  ? "popover-time-hour24 popover-meavana-arrow-bottom"
                  : "popover-time-hour24 popover-meavana-arrow-right"
              }
            >
              <Popover.Body className="popover-time-hour24-body">
                <div className="popover-time-hour24-item">
                  Analog Clock
                  <Switch
                    checked={clockState.showAnalog}
                    onChange={(event) => {
                      dispatch(toggleAnalogClockAction());
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    color="success"
                  />
                </div>
                <div className="popover-time-hour24-item">
                  Show Seconds
                  <Switch
                    checked={clockState.showSeconds}
                    onChange={(event) => {
                      dispatch(updateTimeSecondAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    color="success"
                  />
                </div>
                {!clockState.showAnalog && (
                  <div
                    className="popover-time-hour24-item"
                    style={clockState.showAnalog ? { opacity: 0.7 } : {}}
                  >
                    24-hour Clock
                    <Switch
                      checked={clockState.hour24}
                      disabled={clockState.showAnalog}
                      onChange={(event) => {
                        dispatch(updateTimeFormatAction(event.target.checked));
                        dispatch(updateAmPmAction(!event.target.checked));
                        dispatch(resetHomeThemeAction());
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      color="success"
                    />
                  </div>
                )}
                {!clockState.showAnalog && (
                  <div
                    className="popover-time-hour24-item"
                    style={
                      clockState.showAnalog || clockState.hour24
                        ? { opacity: 0.7 }
                        : {}
                    }
                  >
                    Show AM/PM
                    <Switch
                      checked={clockState.showAmPm}
                      disabled={clockState.showAnalog || clockState.hour24}
                      onChange={(event) => {
                        dispatch(updateAmPmAction(event.target.checked));
                        dispatch(resetHomeThemeAction());
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      color="success"
                    />
                  </div>
                )}

                {clockState.showAnalog && (
                  <div className="popover-time-hour24-item">
                    Numbered
                    <Switch
                      checked={clockState.analogClock === "normal"}
                      onChange={(event) => {
                        event.preventDefault();

                        if (event.target.checked) {
                          dispatch(updateTimeAnalogClockAction("normal"));
                        } else {
                          dispatch(updateTimeAnalogClockAction("minimal"));
                        }
                        dispatch(resetHomeThemeAction());
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      color="success"
                    />
                  </div>
                )}
                {/*clockState.showAnalog && (
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="Note"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                      name="row-radio-buttons-group"
                      value={clockState.analogClock}
                      onChange={updateClockMode}
                    >
                      <MyFormControlLabel
                        value="normal"
                        control={<Radio color="success" />}
                        label="Numbered"
                      />
                      <MyFormControlLabel
                        value="minimal"
                        control={<Radio color="success" />}
                        label="Non-Numbered"
                      />
                    </RadioGroup>
                  </FormControl>
                )*/}
                {clockState.showAnalog && (
                  <div className="popover-time-hour24-item">
                    Dark Analog
                    <Switch
                      checked={clockState.themeMode === "dark"}
                      onChange={(event) => {
                        dispatch(
                          updateTimeThemeModeAction(
                            clockState.themeMode === "light" ? "dark" : "light"
                          )
                        );
                        dispatch(resetHomeThemeAction());
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      color="success"
                    />
                  </div>
                )}

                <div className="popover-analog-clock-size-item">
                  <span className="popover-analog-clock-size-item-txt">
                    Size
                  </span>
                  {clockState.showAnalog ? (
                    <Slider
                      size="medium"
                      min={15}
                      max={40}
                      value={clockState.analogClockSize / 10}
                      onChange={(event) => {
                        dispatch(
                          updateAnalogClockSizeAction(event.target.value * 10)
                        );

                        dispatch(resetHomeThemeAction());
                      }}
                      aria-label="Small"
                      valueLabelDisplay="off"
                    />
                  ) : (
                    <Slider
                      size="medium"
                      min={0}
                      max={100}
                      value={clockState.timeDateFontSize - 50}
                      onChange={(event) => {
                        dispatch(
                          updateTimeFontSizeAction(event.target.value + 50)
                        );

                        dispatch(resetHomeThemeAction());
                      }}
                      aria-label="Small"
                      valueLabelDisplay="off"
                    />
                  )}
                </div>
                <div className="popover-analog-clock-size-item">
                  <span className="popover-analog-clock-size-item-txt">
                    Bold
                  </span>

                  <Slider
                    size="medium"
                    min={100}
                    max={900}
                    step={100}
                    marks={true}
                    value={clockState.timeDateFontWeight}
                    onChange={(event) => {
                      dispatch(updateTimeFontWeightAction(event.target.value));

                      dispatch(resetHomeThemeAction());
                    }}
                    aria-label="Small"
                    valueLabelDisplay="off"
                  />
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <IconButton
            className="date-time-setting-icon"
            aria-label="date-time-Settings"
            disabled={
              showIcons && (props.opacityTime > 0 || props.opacityDate > 0)
                ? false
                : true
            }
            style={
              showIcons && (props.opacityTime > 0 || props.opacityDate > 0)
                ? {}
                : { opacity: 0 }
            }
          >
            <MoreHoriz
              /*fontSize="large"*/ className="home-icon-setting-font-size"
            />
          </IconButton>
        </OverlayTrigger>
      </div>
    </div>
  );
}
