import { SERVER_URL } from "../../config/constants";
import {
  addNoteFailureAction,
  addNoteRequestAction,
  addNoteSuccessAction,
  deleteAllNoteFailureAction,
  deleteAllNoteRequestAction,
  deleteAllNoteSuccessAction,
  deleteNoteFailureAction,
  deleteNoteRequestAction,
  deleteNoteSuccessAction,
  setSelectedNote,
  updateNoteFailureAction,
  updateNoteRequestAction,
  updateNoteSuccessAction
} from "../../store/reducers/notesReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import axiosInstance from "../Middleware/axiosInstance";
import { checkErrorResponseFormat } from "../Middleware/utils.server";

/**
 * the action for adding a new Note.
 *
 * @param {string} note - The note object.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const addNoteServer = (note, dashboardId) => {
  return async (dispatch) => {
    try {
      dispatch(addNoteRequestAction(note));

      axiosInstance()
        .post(SERVER_URL + "dashboard/" + dashboardId + "/notes/add", note)
        .then((response) => {
          note._id = response.data._id;
          // note.prevIndex = response.data.index + 1;
          note.saved = true;
          if (note.title === " ") {
            note.title = "";
          }
          if (note.content === " ") {
            note.content = "";
          }
          dispatch(addNoteSuccessAction(note));

          //set the newly selected note
          dispatch(setSelectedNote(note));
        })
        .catch((error1) => {
          meaVanaConsole("addNote err1 ", checkErrorResponseFormat(error1));

          dispatch(addNoteFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("addNote err2", checkErrorResponseFormat(error));

      dispatch(addNoteFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

/**
 * the action for updating an existing Note.
 *
 * @param {string} note - The note object.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const updateNoteServer = (bulkNotes, dashboardId) => {
  return async (dispatch) => {
    try {
      dispatch(updateNoteRequestAction(bulkNotes));

      axiosInstance()
        .put(SERVER_URL + "dashboard/" + dashboardId + "/notes/bulk-update", {
          notes: bulkNotes
        })
        .then((response) => {
          dispatch(updateNoteSuccessAction(response.data));
          meaVanaConsole("bulk notes :", response.data);
        })
        .catch((error1) => {
          meaVanaConsole("updateNote err1 ", checkErrorResponseFormat(error1));

          dispatch(updateNoteFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("addNote err2", checkErrorResponseFormat(error));

      dispatch(updateNoteFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

/**
 * the action for updating an existing Note.
 *
 * @param {string} noteId - The note Id of note to be deleted.
 * @param {string} dashboardId - The current Dashboard Id of logged user.
 */
export const deleteNoteServer = (noteId, dashboardId) => {
  return async (dispatch) => {
    dispatch(deleteNoteRequestAction(noteId));
    try {
      axiosInstance()
        .delete(
          SERVER_URL + "dashboard/" + dashboardId + "/notes/item/" + noteId
        )
        .then((response) => {
          dispatch(deleteNoteSuccessAction(noteId));
          meaVanaConsole("deleteNoteAction ", response.data);
        })
        .catch((error1) => {
          meaVanaConsole(
            "deleteNoteAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(deleteNoteFailureAction(checkErrorResponseFormat(error1)));
        });
    } catch (error) {
      meaVanaConsole("deleteNoteAction err2", checkErrorResponseFormat(error));

      dispatch(deleteNoteFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

//remove all todos of a user(bulk delete)
export const deleteAllNotesServer = (dashboardId) => {
  return async (dispatch) => {
    dispatch(deleteAllNoteRequestAction());
    try {
      axiosInstance()
        .delete(SERVER_URL + "dashboard/" + dashboardId + "/notes/all")
        .then((response) => {
          dispatch(deleteAllNoteSuccessAction(response.data));
          //set selected note as an empty note
          dispatch(setSelectedNote({}));

          meaVanaConsole("deleteAllNotesAction response  ", response.data);
        })
        .catch((error1) => {
          meaVanaConsole(
            "deleteAllNotesAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(
            deleteAllNoteFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "deleteAllNotesAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(deleteAllNoteFailureAction(checkErrorResponseFormat(error)));
    }
  };
};
