import { appStateAction } from "./constants";

//action update loading state
export const updateAppStateLoadingAction = (loading) => {
  return (dispatch) => {
    dispatch({ type: appStateAction.UPDATE_SHOW_LOADING, payload: loading });
  };
};
//action update loading state
export const resetAppStateDefaultAction = () => {
  return (dispatch) => {
    dispatch({ type: appStateAction.RESET_APP_STATE_DEFAULT });
  };
};

//action update gest mode state
export const updateGuestAction = (state) => {
  return (dispatch) => {
    dispatch({ type: appStateAction.UPDATE_GUEST_MODE, payload: state });
  };
};
//action update device ID state
export const updateDeviceIDAction = (value) => {
  return (dispatch) => {
    dispatch({ type: appStateAction.UPDATE_DEVICE_ID, payload: value });
  };
};

// action update logged in state
export const updateLoggedInSateAction = (state) => {
  return (dispatch) => {
    dispatch({ type: appStateAction.UPDATE_LOGGED_IN_STATE, payload: state });
  };
};

// action update theme color in state
export const updateThemeColorAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: appStateAction.UPDATE_SETTING_THEME_COLOR,
      color: value
    });
  };
};
// action update theme mode in state
export const updateThemeModeAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: appStateAction.UPDATE_SETTING_THEME_MODE,
      value: value
    });
  };
};

// action update theme style in state
export const updateThemeStyleAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: appStateAction.UPDATE_SETTING_THEME_STYLE,
      style: value
    });
  };
};

// action update background mode in state
export const updateBackgroundModeAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: appStateAction.UPDATE_HOME_BACKGROUND_MODE,
      value: value
    });
  };
};

// action update theme font family  in state
export const updateThemeFontFamilyAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: appStateAction.UPDATE_SETTING_THEME_FONT_FAMILY,
      value: value
    });
  };
};

// action update theme text size in state
export const updateThemeTextSizeAction = (value) => {
  return (dispatch) => {
    dispatch({
      type: appStateAction.UPDATE_SETTING_THEME_TEXT_SIZE,
      value: value
    });
  };
};
