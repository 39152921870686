import { PushPin, PushPinOutlined } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateGooglePinAction,
  updateGoogleWindowStateAction
} from '../../../store/reducers/googleReducer/action';
import { GetImageUrl } from '../../../Utils/GetImageUrl';

export default function GooglePopup({ dark }) {
  const googleState = useSelector((state) => state.google);
  const dispatch = useDispatch();

  return (
    <div className="integration-grid">
      {googleState.googleApps.map((google, index) => {
        return (
          <div
            key={'google-popup-grid-item-' + google.id}
            className={
              google.active
                ? 'integration-grid-item-active integration-grid-item'
                : 'integration-grid-item'
            }>
            {google.pined ? (
              <PushPin
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateGooglePinAction(google.id, false));
                }}
              />
            ) : (
              <PushPinOutlined
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateGooglePinAction(google.id, true));
                }}
              />
            )}
            <div
              className="integration-grid-item-container"
              onClick={() => {
                if (google.active) {
                  dispatch(updateGoogleWindowStateAction());
                } else {
                  dispatch(updateGoogleWindowStateAction(google.id));
                }
              }}>
              <img
                src={dark ? GetImageUrl(google.icons.white) : GetImageUrl(google.icons.black)}
                alt={google.id + ' icon'}
              />
              <span>{google.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
