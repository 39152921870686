export const initialState = {
  selectedToDo: {},
  todos: [],
  updatedList: false,
  error: "",
  alert: {}
};

export const todoStateAction = {
  ADD_TODO_REQUEST: "todo/ADD_TODO_REQUEST",
  ADD_TODO_SUCCESS: "todo/ADD_TODO_SUCCESS",
  ADD_TODO_FAILURE: "todo/ADD_TODO_FAILURE",
  UPDATE_TODO_REQUEST: "todo/UPDATE_TODO_REQUEST",
  UPDATE_TODO_SUCCESS: "todo/UPDATE_TODO_SUCCESS",
  UPDATE_TODO_FAILURE: "todo/UPDATE_TODO_FAILURE",
  UPDATE_TODO_BULK_LIST_REQUEST: "todo/UPDATE_TODO_BULK_LIST_REQUEST",
  UPDATE_TODO_BULK_LIST_SUCCESS: "todo/UPDATE_TODO_BULK_LIST_SUCCESS",
  UPDATE_TODO_BULK_LIST_FAILURE: "todo/UPDATE_TODO_BULK_LIST_FAILURE",
  DELETE_TODO_FAILURE: "todo/DELETE_TODO_FAILURE",
  DELETE_TODO_SUCCESS: "todo/DELETE_TODO_SUCCESS",
  DELETE_TODO_REQUEST: "todo/DELETE_TODO_REQUEST",
  DELETE_ALL_TODOS_SUCCESS: "todo/DELETE_ALL_TODOS_SUCCESS",
  DELETE_ALL_TODOS_REQUEST: "todo/DELETE_ALL_TODOS_REQUEST",
  DELETE_ALL_TODOS_FAILURE: "todo/DELETE_ALL_TODOS_FAILURE",
  SET_SELECTED_TODO: "todo/SET_SELECTED_TODO",
  GET_DASHBOARD_TODOS: "todo/GET_DASHBOARD_TODOS",
  ADD_EMPTY_TODO: "todo/ADD_EMPTY_TODO",
  UPDATE_TODO_LIST: "todo/UPDATE_TODO_LIST",
  UPDATE_TODO_IN_STATE: "todo/UPDATE_TODO_IN_STATE"
};
