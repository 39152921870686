import { PushPin, PushPinOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCryptoPinAction,
  updateCryptoWindowStateAction
} from "../../../store/reducers/cryptoReducer/action";
import { GetImageUrl } from "../../../Utils/GetImageUrl";

export default function CryptoPopup({ dark }) {
  const cryptoState = useSelector((state) => state.crypto);
  const dispatch = useDispatch();

  return (
    <div className="integration-grid">
      {cryptoState.cryptoApiSource.map((crypto, index) => {
        return (
          <div
            key={"crypto-popup-grid-item-" + crypto.id}
            className={
              crypto.active
                ? "integration-grid-item-active integration-grid-item"
                : "integration-grid-item"
            }
          >
            {crypto.pined ? (
              <PushPin
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateCryptoPinAction(crypto.id, false));
                }}
              />
            ) : (
              <PushPinOutlined
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateCryptoPinAction(crypto.id, true));
                }}
              />
            )}
            <div
              className="integration-grid-item-container"
              onClick={() => {
                if (crypto.active) {
                  dispatch(updateCryptoWindowStateAction());
                } else {
                  dispatch(updateCryptoWindowStateAction(crypto.id));
                }
              }}
            >
              <img
                src={
                  dark
                    ? GetImageUrl(crypto.icons.white)
                    : GetImageUrl(crypto.icons.black)
                }
                alt={crypto.id + " icon"}
              />
              <span>{crypto.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
