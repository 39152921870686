import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SpotifyWebApi from "spotify-web-api-node";
import { meaVanaConsole } from "../Utils/MeaVanaConsole";

export const spotifyApi = new SpotifyWebApi({
  clientId: process.env.REACT_APP_SPOTIFY_CLIENT_ID
});

export function useSongInfo() {
  const musicState = useSelector((state) => state.music.musicapiSource[0]);
  const [songInfo, setSongInfo] = useState(null);

  useEffect(() => {
    const fetchSongInfo = async () => {
      if (musicState.currentTrackId) {
        try {
          const trackInfo = await fetch(
            `https://api.spotify.com/v1/tracks/${musicState.currentTrackId}`,
            {
              headers: {
                Authorization: `Bearer ${spotifyApi.getAccessToken()}`
              }
            }
          )
            .then((res) => res.json())
            .catch((err) => {
              meaVanaConsole("err.status >>> ", err.status);
              meaVanaConsole("err.statusCode >>> ", err.statusCode);
              meaVanaConsole("err >>> ", err);
            });
          setSongInfo(trackInfo);
        } catch (error) {
          meaVanaConsole("error.status >>> ", error.status);
          meaVanaConsole("error >>> ", error);
        }
      }
    };

    fetchSongInfo();
  }, [musicState.currentTrackId, spotifyApi]);

  return songInfo;
}
