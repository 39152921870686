import { initialState, secondarySearchEngineitem, searchEngineStateAction } from './constants';

import { appStateAction } from '../appReducer/constants';
import { homeStateAction } from '../homeReducer/constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { SearchEngineList } from '../../../Utils/SearchEngineList';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const searchEnginePersistConfig = {
  key: 'searchEngine',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING searchEngine :', error)
};
export const searchEnginePersisted = persistReducer(searchEnginePersistConfig, searchEngineReducer);

export default function searchEngineReducer(state = initialState, action = {}) {
  switch (action.type) {
    case homeStateAction.UPDATE_HOME_THEME:
      return {
        ...state,
        ...action.theme.searchEngine
      };

    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;

    case searchEngineStateAction.RESET_SEARCH_ENGINE_STATE:
      return {
        ...initialState,
        resetState: true
      };

    case searchEngineStateAction.UPDATE_SEARCH_SIZE:
      return {
        ...state,
        searchEnginSize: action.payload
      };

    case searchEngineStateAction.UPDATE_SEARCH_ENGINE:
      return {
        ...state,
        activeSearchEngine: action.payload
      };
    case searchEngineStateAction.UPDATE_SEARCH_ENGINE_ORDER:
      return {
        ...state,
        searchEngines: action.payload
      };
    case searchEngineStateAction.DELETE_SEARCH_ENGINE:
      let updateSearchEngineDelete = state.searchEngines;
      if (action.payload.index) {
        updateSearchEngineDelete.splice(action.payload.index, 1);
      } else {
        let indDelete = updateSearchEngineDelete.findIndex(
          (e) => e.name === action.payload.engine.name
        );
        if (indDelete >= 0) {
          updateSearchEngineDelete.splice(indDelete, 1);
        }
      }

      return {
        ...state,
        searchEngines: updateSearchEngineDelete
      };
    case searchEngineStateAction.ADD_SEARCH_ENGINE:
      return {
        ...state,
        searchEngines: [...state.searchEngines, SearchEngineList[action.payload]]
      };
    case searchEngineStateAction.UPDATE_SEARCH_ENGINE_REACTANGLE:
      return {
        ...state,
        searchEngineRectangle: action.payload
      };
    case searchEngineStateAction.UPDATE_SEARCH_ENGINE_BORDER:
      return {
        ...state,
        searchEngineBorder: action.payload
      };
    case searchEngineStateAction.UPDATE_SEARCH_ENGINE_LINE:
      return {
        ...state,
        searchEngineLine: action.payload
      };
    case searchEngineStateAction.UPDATE_SEARCH_COLORED:
      return {
        ...state,
        searchEnginColored: action.payload
      };
    case searchEngineStateAction.UPDATE_SEARCH_ENGINE_OPEN_NEW_TAB:
      return {
        ...state,
        searchEngineOpenNewTab: action.payload
      };
    case searchEngineStateAction.UPDATE_SHOW_MULTIPLE_SEARCH:
      return {
        ...state,
        showMultipleSeachEngine: action.payload
      };

    case searchEngineStateAction.UPDATE_SECONDARY_SEARCH_ENGINE_REACTANGLE:
      let SecondarySERectangle = state.secondarySearchEngine;
      SecondarySERectangle[action.index].searchEngineRectangle = action.payload;
      return {
        ...state,
        secondarySearchEngine: SecondarySERectangle
      };
    case searchEngineStateAction.UPDATE_SECONDARY_SEARCH_ENGINE_BORDER:
      let SecondarySEBorder = state.secondarySearchEngine;
      SecondarySEBorder[action.index].searchEngineBorder = action.payload;
      return {
        ...state,
        secondarySearchEngine: SecondarySEBorder
      };
    case searchEngineStateAction.UPDATE_SECONDARY_SEARCH_COLORED:
      let SecondarySEColored = state.secondarySearchEngine;
      SecondarySEColored[action.index].searchEnginColored = action.payload;
      return {
        ...state,
        secondarySearchEngine: SecondarySEColored
      };
    case searchEngineStateAction.UPDATE_SECONDARY_SEARCH_LINE:
      let SecondarySELine = state.secondarySearchEngine;
      SecondarySELine[action.index].searchEngineLine = action.payload;
      return {
        ...state,
        secondarySearchEngine: SecondarySELine
      };
    case searchEngineStateAction.UPDATE_SECONDARY_SEARCH_ENGINE_OPEN_NEW_TAB:
      let SecondarySENewTab = state.secondarySearchEngine;
      SecondarySENewTab[action.index].searchEngineOpenNewTab = action.payload;
      return {
        ...state,
        secondarySearchEngine: SecondarySENewTab
      };

    case searchEngineStateAction.UPDATE_SECONDARY_SEARCH_SIZE:
      let SecondarySESize = state.secondarySearchEngine;
      SecondarySESize[action.index].searchEnginSize = action.payload;
      return {
        ...state,
        secondarySearchEngine: SecondarySESize
      };
    case searchEngineStateAction.UPDATE_ACTIVE_SECONDARY_SEARCH_ENGINE:
      let SecondarySEActive = state.secondarySearchEngine;
      SecondarySEActive[action.index].activeSearchEngine = action.payload;
      return {
        ...state,
        secondarySearchEngine: SecondarySEActive
      };
    case searchEngineStateAction.ADD_SECONDARY_SEARCH_ENGINE:
      let SecondarySENew = state.secondarySearchEngine;
      SecondarySENew.push(secondarySearchEngineitem(SearchEngineList[0]));
      return {
        ...state,
        secondarySearchEngine: SecondarySENew
      };
    case searchEngineStateAction.DELETE_SECONDARY_SEARCH_ENGINE:
      let SecondarySEDelete = state.secondarySearchEngine;
      SecondarySEDelete.splice(action.index, 1);
      return {
        ...state,
        secondarySearchEngine: SecondarySEDelete
      };

    case searchEngineStateAction.DELETE_ALL_SECONDARY_SEARCH_ENGINE:
      return {
        ...state,
        secondarySearchEngine: []
      };
    case searchEngineStateAction.UPDATE_ALL_SECONDARY_SEARCH_ENGINE:
      return {
        ...state,
        secondarySearchEngine: action.payload
      };

    default:
      return state;
  }
}
