import { PushPin, PushPinOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateVideosPinAction,
  updateVideosWindowStateAction
} from "../../../store/reducers/VideoReducer/action";
import { GetImageUrl } from "../../../Utils/GetImageUrl";

export default function VideosPopup({ dark }) {
  const videoState = useSelector((state) => state.video);

  const dispatch = useDispatch();

  return (
    <div className="integration-grid">
      {videoState.videosApiSource.map((video, index) => {
        return (
          <div
            key={"news-grid-item-" + video.id}
            className={
              video.active
                ? "integration-grid-item-active integration-grid-item integration-grid-item-inactive"
                : "integration-grid-item integration-grid-item-inactive"
            }
          >
            {/*video.pined ? (
              <PushPin
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateVideosPinAction(video.id, false));
                }}
              />
            ) : (
              <PushPinOutlined
                className="popover-integration-button-pin"
                onClick={() => {
                  dispatch(updateVideosPinAction(video.id, true));
                }}
              />
              )*/}
            <div className="integration-grid-item-inactive-msg-container">
              <span style={{ fontSize: "0.8em" }}>Coming soon</span>
            </div>

            <div
              className="integration-grid-item-container"
              onClick={() => {
                if (video.active) {
                  dispatch(updateVideosWindowStateAction());
                } else {
                  dispatch(updateVideosWindowStateAction(video.id));
                }
              }}
            >
              <img
                src={
                  dark
                    ? GetImageUrl(video.icons.white)
                    : GetImageUrl(video.icons.black)
                }
                alt={video.id + " icon"}
              />
              <span>{video.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
