export const initialState = {
  background: null,
  backgroundBatch: [],
  quote: null,
  quoteSize: 100,
  backgroundLastUpdate: null,
  error: null,
  loading: false,

  topics: [],
  topicLastUpdate: null,
  loadingTopic: false,
  errorTopic: null,

  selectedTopic: {},
  selectedTopicIdHistory: [],
  clickedTopicId: null,
  loadingSelectedTopic: false,
  errorSelectedTopic: null,

  backgroundIndexFromDB: false,
  backgroundIndexFromDBIndex: 1,
  backgroundIndexFromDBArray: [
    {
      id: 1,
      name: 'background_index_db_1',
      uploaded: false,
      created: null,
      updated: null,
      used: null
    },
    {
      id: 2,
      name: 'background_index_db_2',
      uploaded: false,
      created: null,
      updated: null,
      used: null
    },
    {
      id: 3,
      name: 'background_index_db_3',
      uploaded: false,
      created: null,
      updated: null,
      used: null
    },
    {
      id: 4,
      name: 'background_index_db_4',
      uploaded: false,
      created: null,
      updated: null,
      used: null
    }
  ]
};

export const backgroundStateAction = {
  UPDATE_BACKGROUND_FROM_UNSPASH: 'background/UPDATE_BACKGROUND_FROM_UNSPASH',
  UPDATE_BACKGROUND_REQUEST: 'background/UPDATE_BACKGROUND_REQUEST',
  UPDATE_BACKGROUND_SUCCESS: 'background/UPDATE_BACKGROUND_SUCCESS',
  UPDATE_BACKGROUND_SUCCESS_FROM_LOGIN: 'background/UPDATE_BACKGROUND_SUCCESS_FROM_LOGIN',
  UPDATE_BACKGROUND_FAILURE: 'background/UPDATE_BACKGROUND_FAILURE',
  SET_QUOTE_SIZE: 'background/SET_QUOTE_SIZE',
  GET_DAILY_BACKGROUND: 'background/GET_DAILY_BACKGROUND',
  GET_ALL_TOPIC_REQUEST: 'background/GET_ALL_TOPIC_REQUEST',
  GET_ALL_TOPIC_SUCCESS: 'background/GET_ALL_TOPIC_SUCCESS',
  GET_ALL_TOPIC_FAILURE: 'background/GET_ALL_TOPIC_FAILURE',
  UPDATE_SELECTED_TOPIC: 'background/UPDATE_SELECTED_TOPIC',

  UPDATE_SELECTED_TOPIC_REQUEST: 'background/UPDATE_SELECTED_TOPIC_REQUEST',
  UPDATE_SELECTED_TOPIC_SUCCESS: 'background/UPDATE_SELECTED_TOPIC_SUCCESS',
  UPDATE_SELECTED_TOPIC_FAILURE: 'background/UPDATE_SELECTED_TOPIC_FAILURE',

  UPDATE_SELECTED_TOPIC_FROM_SERVER: 'background/UPDATE_SELECTED_TOPIC_FROM_SERVER',

  UPDATE_BACKGROUND_INDEXDB_STATE: 'background/UPDATE_BACKGROUND_INDEXDB_STATE',
  UPDATE_BACKGROUND_INDEXDB_ARRAY: 'background/UPDATE_BACKGROUND_INDEXDB_ARRAY',
  UPDATE_BACKGROUND_INDEXDB_INDEX: 'background/UPDATE_BACKGROUND_INDEXDB_INDEX'
};
