/* global chrome */
export const GetImageUrl = (url) => {
  try {
    chrome.runtime.getURL(url);
  } catch (e) {
    console.log('chrome.runtime.getURL(url) undefined');
    return url;
  }
  // previous code commented(7Dec2022)
  //if (
  //   chrome !== undefined &&
  //   typeof chrome.runtime != undefined &&
  //   typeof chrome.runtime.getURL(url) != undefined
  // ) {
  //   return chrome.runtime.getURL(url);
  // } else {
  //   return url;
  // }
};
