import React from "react";
import IntegrationsFeature from "../../../features/IntegrationsFeature/IntegrationsFeature";
import WallpaperFeature from "../../../features/WallpaperFeature/WallpaperFeature";
import WeatherFeature from "../../../features/WeatherFeature/WeatherFeature";

export default function TopArea({
  hideAllFeatures,
  showWeather,
  showIntegration
}) {
  return (
    <>
      <div className="weather-area">
        <WeatherFeature display={hideAllFeatures} opacity={showWeather} />
      </div>
      <div className="integration-area">
        <WallpaperFeature display={hideAllFeatures} opacity={showIntegration} />
        <IntegrationsFeature
          display={hideAllFeatures}
          opacity={showIntegration}
        />
      </div>
    </>
  );
}
