import CoincapAssetsList from "./CoincapAssetsList";

import "./Coincap.css";

function CoincapContainer(props) {
  return (
    <div className="coincap-container">
      <div className="coincap-header">
        <h6 className="py-2 m-0">Assets</h6>
      </div>

      <CoincapAssetsList />
    </div>
  );
}

export default CoincapContainer;
