import { initialState, logsStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const logsPersistConfig = {
  key: 'logs',
  storage,
  version: 1,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING logs :', error)
};
export const logsPersisted = persistReducer(logsPersistConfig, logsReducer);

export default function logsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case logsStateAction.ADD_CONSOLE_LOGS:
      return {
        ...state,
        consoleLogs: [...state.consoleLogs, { log: action.log, date: new Date() }]
      };
    case logsStateAction.ADD_ERROR_LOGS:
      return {
        ...state,
        errorLogs: [...state.errorLogs, { log: action.log, date: new Date() }]
      };

    default:
      return state;
  }
}
