import { init, send } from "emailjs-com";
import {
  APP_ENV,
  Emailjs_Service_ID,
  Emailjs_Template,
  Emailjs_User_ID
} from "../config/constants";
import { meaVanaConsole } from "./MeaVanaConsole";

init(Emailjs_User_ID);

export const sendEmailLogout = (title, msg, userName, userEmail) => {
  if (APP_ENV === "production") {
    send(Emailjs_Service_ID, Emailjs_Template, {
      title: title,
      message: msg,
      user_name: userName,
      user_email: userEmail
    })
      .then((response) => {
        meaVanaConsole("send email " + title + " SUCCESS! :", response);
      })
      .catch((err) => {
        meaVanaConsole("send email " + title + "FAILED : ", err);
      });
  } else {
    meaVanaConsole("sendEmailLogout title:", title);
    meaVanaConsole("sendEmailLogout msg:", msg);
    meaVanaConsole("sendEmailLogout userName:", userName);
    meaVanaConsole("sendEmailLogout userEmail:", userEmail);
  }
};
