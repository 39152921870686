import { arrayMove } from "react-sortable-hoc";
import { addToDoServer } from "../../../server/Dashbord/todos.server";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";
import { todoStateAction } from "./constants";

export const getTodosAction = (todoList) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.GET_DASHBOARD_TODOS,
      payload: todoList
    });
  };
};
export const updateTodoInStateAction = (todo) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.UPDATE_TODO_IN_STATE,
      payload: { todo: todo }
    });
  };
};

export const setSelectedToDo = (todo = {}) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.SET_SELECTED_TODO,
      payload: todo
    });
  };
};
export const updateTodosListAction = (todos = []) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.UPDATE_TODO_LIST,
      payload: { todos: todos }
    });
  };
};

export const createEmptyToDo = (todosList, dashboardId) => {
  return async (dispatch) => {
    try {
      let todos = todosList.filter((todo) => todo._id != "empty");

      let newTodo = {
        _id: "empty",
        title: "Todo Title",
        content: "Todo Content",
        done: false,
        saved: false,
        index: todos.length
      };

      todos.push(newTodo);
      let reorderedTodos = arrayMove(todos, todos.length - 1, 0);
      reorderedTodos = reorderedTodos.map((_Todo, index) => {
        let prevIndex = _Todo.index;
        if (_Todo._id.includes("empty")) {
          prevIndex = reorderedTodos.length;
        }
        return {
          ..._Todo,
          prevIndex: prevIndex,
          index: index + 1
        };
      });
      dispatch({
        type: todoStateAction.ADD_EMPTY_TODO,
        payload: reorderedTodos
      });
      if (reorderedTodos.find((todo) => todo._id === "empty"))
        dispatch(
          addToDoServer(
            reorderedTodos.find((todo) => todo._id === "empty"),
            dashboardId
          )
        );
    } catch (error) {
      meaVanaConsole("createEmptyTodo err", error);
    }
  };
};

// action updateTodoBulkList request
export const updateTodoBulkListRequestAction = (todos) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.UPDATE_TODO_BULK_LIST_REQUEST,
      payload: { todos: todos }
    });
  };
};

// action updateTodoBulkList success
export const updateTodoBulkListSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.UPDATE_TODO_BULK_LIST_SUCCESS,
      payload: data
    });
  };
};

// action updateTodoBulkList failure
export const updateTodoBulkListFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.UPDATE_TODO_BULK_LIST_FAILURE,
      error: error
    });
  };
};

// action deleteTodo request
export const deleteTodoRequestAction = (todoId) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.DELETE_TODO_REQUEST,
      payload: { todoId: todoId }
    });
  };
};

// action deleteTodo success
export const deleteTodoSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.DELETE_TODO_SUCCESS,
      payload: data
    });
  };
};

// action deleteTodo failure
export const deleteTodoFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.DELETE_TODO_FAILURE,
      error: error
    });
  };
};

// action addTodo request
export const addTodoRequestAction = (todo) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.ADD_TODO_REQUEST,
      payload: { todo: todo }
    });
  };
};

// action addTodo success
export const addTodoSuccessAction = (todo) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.ADD_TODO_SUCCESS,
      payload: { todo: todo }
    });
  };
};

// action addTodo failure
export const addTodoFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.ADD_TODO_FAILURE,
      error: error
    });
  };
};

// action deleteAllTodos request
export const deleteAllTodosRequestAction = () => {
  return (dispatch) => {
    dispatch({ type: todoStateAction.DELETE_ALL_TODOS_REQUEST });
  };
};

// action deleteAllTodos success
export const deleteAllTodosSuccessAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.DELETE_ALL_TODOS_SUCCESS,
      payload: data
    });
  };
};

// action deleteAllTodos failure
export const deleteAllTodosFailureAction = (error) => {
  return (dispatch) => {
    dispatch({
      type: todoStateAction.DELETE_ALL_TODOS_FAILURE,
      error: error
    });
  };
};
