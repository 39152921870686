import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Calendar from "react-widgets/Calendar";
import "./Style.css";
import { useSelector } from "react-redux";

export default function DateContainer(props) {
  const clockState = useSelector((state) => state.clock);

  return (
    <OverlayTrigger
      trigger="click"
      key="OverlayTrigger-calendar"
      placement="bottom"
      rootClose={true}
      overlay={
        <Popover className="calendar-popover">
          <Popover.Body className="calendar-popover-body">
            <Calendar defaultValue={new Date()} />
          </Popover.Body>
        </Popover>
      }
    >
      <div
        className="date-day"
        style={
          props.display
            ? { display: "none" }
            : props.opacity === 0
            ? { display: "none" }
            : { opacity: props.opacity, fontWeight: props.fontWeight }
        }
      >
        {props.date.toLocaleString("en-US", {
          weekday: "long",
          day: "numeric",
          month: "long",
          hour12: false
        })}
      </div>
    </OverlayTrigger>
  );
}
