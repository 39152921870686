import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "react-bootstrap";

import { getCoincapAssets } from "../../../../server/Integration/crypto/coincap.server";
import { formatNumber } from "../../../../Utils/FormatNumber";
import { GetImageUrl } from "../../../../Utils/GetImageUrl";

function CoincapAssetsList(props) {
  const appState = useSelector((state) => state.app);
  const coincapState = useSelector((state) => state.coincap);
  const dispatch = useDispatch();

  const [filteredAssetsList, setFilteredAssetsList] = useState([]);

  useEffect(() => {
    //get inital coincap data
    dispatch(getCoincapAssets());
  }, []);
  useEffect(() => {
    let _assets =
      coincapState && coincapState.assets ? coincapState.assets : [];
    setFilteredAssetsList(getFilteredAssetsList(_assets));
  }, [coincapState.assets]);

  //a function to get only the limited assets
  const getFilteredAssetsList = (assets) => {
    const _staticfilteredAssets = [
      "BTC",
      "ETH",
      "LTC",
      "STORJ",
      "ADA",
      "SOL",
      "XRP",
      "DOGE",
      "DOT",
      "XTZ",
      "BCH",
      "XLM",
      "ICP",
      "POLY",
      "ATOM",
      "ETC"
    ];
    let _filteredAssetsList = assets.filter((asset) => {
      return _staticfilteredAssets.includes(asset.symbol.trim());
    });

    //return the filtered list
    return _filteredAssetsList;
  };

  return (
    <>
      <Table
        bordered
        hover
        responsive
        className={`assets-table  table-striped ${
          !appState.themeMode.includes("light") ? "table-dark" : ""
        }`}
      >
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Price</th>
            <th>Market cap</th>
            <th>Volume</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssetsList &&
            filteredAssetsList.map((asset, index) => {
              return (
                <tr key={index}>
                  <td>{asset.rank}</td>
                  <td style={{ textAlign: "left" }}>
                    <span>
                      <img
                        src={`${GetImageUrl(
                          "/assets/icons/Integration/Crypto/Currency/" +
                            asset.id +
                            ".png"
                        )}`}
                        alt={asset.id}
                        width="25"
                      />
                    </span>
                    <span className="mx-1">{asset.name} </span>
                    <span>
                      <strong>({asset.symbol})</strong>
                    </span>
                  </td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(asset.priceUsd)}
                  </td>
                  <td>${formatNumber(asset.marketCapUsd.slice(0, 15))}</td>
                  <td>{asset.volumeUsd24Hr.slice(0, 10)}</td>
                  <td>{asset.changePercent24Hr.slice(0, 4)}%</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}

export default CoincapAssetsList;
