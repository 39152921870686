import { initialState, appStateAction } from './constants';

import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { stateMigrations } from './stateMigrations';
import { DebugPersistConfig, DebugStoreMigrate } from '../../../config/constants';

const appPersistConfig = {
  key: 'app',
  storage,
  version: 2,
  serialize: true,
  debug: DebugPersistConfig,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(stateMigrations, { debug: DebugStoreMigrate }),
  writeFailHandler: (error) => console.log('ERROR PERSISTING app :', error)
};
export const appPersisted = persistReducer(appPersistConfig, appReducer);

export default function appReducer(state = initialState, action = {}) {
  switch (action.type) {
    case appStateAction.RESET_APP_STATE_DEFAULT:
      return initialState;
    case appStateAction.UPDATE_SHOW_LOADING:
      return {
        ...state,
        showLoading: action.payload
      };
    case appStateAction.UPDATE_GUEST_MODE:
      return {
        ...state,
        guestMode: action.payload
      };
    case appStateAction.UPDATE_DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload
      };
    case appStateAction.UPDATE_LOGGED_IN_STATE:
      return {
        ...state,
        isLoggedIn: action.payload
      };

    case appStateAction.UPDATE_SETTING_THEME_MODE:
      return {
        ...state,
        themeMode: action.value
      };
    case appStateAction.UPDATE_HOME_BACKGROUND_MODE:
      return {
        ...state,
        backgroundMode: action.value
      };
    case appStateAction.UPDATE_SETTING_THEME_COLOR:
      return {
        ...state,
        themeColor: action.color,
        themeMode: action.color + '-' + state.themeStyle + '-theme'
      };
    case appStateAction.UPDATE_SETTING_THEME_STYLE:
      return {
        ...state,
        themeStyle: action.style,
        themeMode: state.themeColor + '-' + action.style + '-theme'
      };
    case appStateAction.UPDATE_SETTING_THEME_FONT_FAMILY:
      return {
        ...state,
        themeFontFamily: action.value
      };
    case appStateAction.UPDATE_SETTING_THEME_TEXT_SIZE:
      return {
        ...state,
        themeTextSize: action.value
      };

    default:
      return state;
  }
}
