import { APP_ENV, PROJECT_VERSION, STORE_VERSION } from '../../../config/constants';

export const initialState = {
  appVersion: PROJECT_VERSION,
  appEnvironment: APP_ENV,
  appStoreVersion: STORE_VERSION,
  guestMode: false,
  isLoggedIn: false,

  deviceId: null,
  showLoading: false,

  homeTheme: 'standard',
  themeMode: 'dark-translucent-theme',
  backgroundMode: 'background-home-picture',
  themeColor: 'dark',
  themeStyle: 'translucent',
  themeFontFamily: 'Classic-Font',
  themeTextSize: 'Medium-Size'
};

export const standardHomeTheme = {
  home: {
    homeTheme: 'standard',
    hideAllFeatures: false,
    showTime: 1,
    showAllFeature: 1,
    showDate: 1,
    showNote: 1,
    showSearch: 0.5,
    showBookmark: 1,
    showQuote: 1,
    showBackgroundInfo: 1,
    showWeather: 1
  },
  clock: {
    analogClockSize: 150,
    timeDateFontSize: 100,

    hour24: false,
    showAmPm: true,
    showSeconds: false,
    analogClock: 'normal',
    showAnalog: false,
    themeMode: 'light'
  },
  searchEngine: {
    searchEngineRectangle: false,
    searchEngineBorder: false,
    searchEnginSize: 35,
    searchEngineOpenNewTab: true
  }
};

export const minimalistHomeTheme = {
  home: {
    homeTheme: 'minimalist',
    hideAllFeatures: false,
    showTime: 1,
    showAllFeature: 1,
    showDate: 1,
    showNote: 1,
    showSearch: 0,
    showBookmark: 0,
    showQuote: 1,
    showBackgroundInfo: 1,
    showWeather: 1
  },
  clock: {
    analogClockSize: 150,
    timeDateFontSize: 150,

    hour24: false,
    showAmPm: true,
    showSeconds: false,
    analogClock: 'normal',
    showAnalog: false,
    themeMode: 'light'
  },
  searchEngine: {
    searchEngineRectangle: false,
    searchEngineBorder: false,
    searchEnginSize: 35,
    searchEngineOpenNewTab: true
  }
};

export const oldFashionedHomeTheme = {
  home: {
    homeTheme: 'old-fashioned',
    hideAllFeatures: false,
    showTime: 1,
    showAllFeature: 1,
    showDate: 1,
    showNote: 1,
    showSearch: 0.5,
    showBookmark: 1,
    showQuote: 1,
    showBackgroundInfo: 1,
    showWeather: 1
  },
  clock: {
    analogClockSize: 200,
    timeDateFontSize: 100,

    hour24: false,
    showAmPm: true,
    showSeconds: true,
    analogClock: 'normal',
    showAnalog: true,
    themeMode: 'light'
  },
  searchEngine: {
    searchEngineRectangle: false,
    searchEngineBorder: false,
    searchEnginSize: 35,
    searchEngineOpenNewTab: true
  }
};

export const appStateAction = {
  UPDATE_SHOW_LOADING: 'app/UPDATE_SHOW_LOADING',
  UPDATE_LOGGED_IN_STATE: 'app/UPDATE_LOGGED_IN_STATE',
  UPDATE_GUEST_MODE: 'app/UPDATE_GUEST_MODE',
  RESET_APP_STATE_DEFAULT: 'app/RESET_APP_STATE_DEFAULT',
  UPDATE_DEVICE_ID: 'app/UPDATE_DEVICE_ID',
  UPDATE_SETTING_THEME_COLOR: 'app/UPDATE_SETTING_THEME_COLOR',
  UPDATE_SETTING_THEME_MODE: 'app/UPDATE_SETTING_THEME_MODE',
  UPDATE_SETTING_THEME_STYLE: 'app/UPDATE_SETTING_THEME_STYLE',
  UPDATE_HOME_BACKGROUND_MODE: 'app/UPDATE_HOME_BACKGROUND_MODE',
  UPDATE_SETTING_THEME_FONT_FAMILY: 'app/UPDATE_SETTING_THEME_FONT_FAMILY',
  UPDATE_SETTING_THEME_TEXT_SIZE: 'app/UPDATE_SETTING_THEME_TEXT_SIZE'
};
