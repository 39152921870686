import { assetsIcons } from '../../../config/images';

const apps = {
  level: 0,
  id: 'app',
  name: 'applications',
  pages: [
    {
      level: 1,
      id: 'news',
      name: 'news',
      pined: false,
      active: true,
      icons: {
        black: assetsIcons.integration.news.black,
        color: assetsIcons.integration.news.black,
        white: assetsIcons.integration.news.white
      }
    },
    {
      level: 1,
      id: 'music',
      name: 'Music',
      pined: false,
      active: false,
      icons: {
        black: assetsIcons.integration.music.black,
        color: assetsIcons.integration.music.color,
        white: assetsIcons.integration.music.white
      }
    },
    {
      level: 1,
      id: 'pictures',
      name: 'Pictures',
      pined: false,
      active: false,
      icons: {
        black: assetsIcons.integration.pictures.black,
        color: assetsIcons.integration.pictures.black,
        white: assetsIcons.integration.pictures.white
      }
    },
    {
      level: 1,
      id: 'video',
      name: 'Video',
      pined: false,
      active: false,
      icons: {
        black: assetsIcons.integration.video.black,
        color: assetsIcons.integration.video.black,
        white: assetsIcons.integration.video.white
      }
    },
    {
      level: 1,
      id: 'crypto',
      name: 'crypto',
      pined: false,
      active: true,
      icons: {
        black: assetsIcons.integration.crypto.black,
        color: assetsIcons.integration.crypto.color,
        white: assetsIcons.integration.crypto.white
      }
    },
    {
      level: 1,
      id: 'google',
      name: 'google',
      pined: false,
      active: true,
      icons: {
        black: assetsIcons.integration.crypto.black,
        color: assetsIcons.integration.crypto.color,
        white: assetsIcons.integration.crypto.white
      }
    }
  ]
};

export const initialState = {
  integrationApp: apps
};

export const integrationStateAction = {
  RESET_INTEGRATION_STATE: 'integration/RESET_INTEGRATION_STATE',
  RESET_INTEGRATION_PIN_STATE: 'integration/RESET_INTEGRATION_PIN_STATE'
};
