import axios from "axios";
import { COINCAP_API_BASE_URL } from "../../../config/constants";
import {
  getCoincapAssetsRequestAction,
  getCoincapAssetsSuccessAction,
  getCoincapAssetsFailureAction
} from "../../../store/reducers/coincapReducer/action";
import { meaVanaConsole } from "../../../Utils/MeaVanaConsole";

export const getCoincapAssets = () => {
  return async (dispatch) => {
    let params = {};
    try {
      dispatch(getCoincapAssetsRequestAction());
      axios
        .get(COINCAP_API_BASE_URL + "/assets", {
          params
        })
        .then((response) => {
          meaVanaConsole(
            "getCoincapAssetsSuccessAction response: ",
            response.data
          );
          dispatch(getCoincapAssetsSuccessAction(response.data.data));
        })
        .catch((error1) => {
          meaVanaConsole("getCoincapAssetsFailureAction error1: ", error1);
          dispatch(getCoincapAssetsFailureAction(error1));
        });
    } catch (error) {
      meaVanaConsole("getCoincapAssetsFailureAction error: ", error);
      //
      dispatch(getCoincapAssetsFailureAction(error));
    }
  };
};
