import { cryptoStateAction } from "./constants";

export const updateCryptoWindowStateAction = (cryptoId = null) => {
  return (dispatch) => {
    dispatch({
      type: cryptoStateAction.UPDATE_CRYPTO_WINDOW_STATE,
      payload: cryptoId
    });
  };
};

export const updateCryptoPinAction = (cryptoId = null, pin = false) => {
  return (dispatch) => {
    dispatch({
      type: cryptoStateAction.UPDATE_CRYPTO_PIN,
      payload: { cryptoId: cryptoId, pin: pin }
    });
  };
};
