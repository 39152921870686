/*global chrome*/

import { GoogleLoginAuthServer } from "../../server/Auth/auth.server";
import { checkErrorResponseFormat } from "../../server/Middleware/utils.server";
import { loginSSOGoogleFailureAction } from "../../store/reducers/authReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";

const GOOGLE_CLIENT_ID = encodeURIComponent(
  "1045255083194-uifgeg654sgiqsgmj6l60vr7t20knse5.apps.googleusercontent.com"
);
const GOOGLE_RESPONSE_TYPE = encodeURIComponent("id_token");

//const GOOGLE_REDIRECT_URI = chrome.identity.getRedirectURL();
// This redirect URI is something like https://<extension-id>.chromeapp.org

const GOOGLE_STATE = encodeURIComponent("jsksf3");
const GOOGLE_SCOPE = encodeURIComponent("profile email");
const GOOGLE_PROMPT = encodeURIComponent("consent");

function Google_create_auth_endpoint(GOOGLE_REDIRECT_URI) {
  let nonce = encodeURIComponent(
    Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
  );

  let openId_endpoint_url = `https://accounts.google.com/o/oauth2/v2/auth
  ?client_id=${GOOGLE_CLIENT_ID}
  &response_type=${GOOGLE_RESPONSE_TYPE}
  &redirect_uri=${GOOGLE_REDIRECT_URI}
  &scope=${GOOGLE_SCOPE}
  &state=${GOOGLE_STATE}
  &nonce=${nonce}
  &prompt=${GOOGLE_PROMPT}`;

  meaVanaConsole("openId_endpoint_url", openId_endpoint_url);
  return openId_endpoint_url;
}

export const GoogleChromeAuth = (deviceId) => {
  return async (dispatch) => {
    try {
      meaVanaConsole("start GoogleChromeAuth");
      if (typeof chrome != "undefined") {
        meaVanaConsole("start GoogleChromeAuth");
        chrome.identity.launchWebAuthFlow(
          {
            url: Google_create_auth_endpoint(chrome.identity.getRedirectURL()),
            interactive: true
          },
          function (redirect_url) {
            meaVanaConsole("google redirect_url ", redirect_url);
            if (redirect_url && redirect_url?.includes("id_token")) {
              let id_token = redirect_url.substring(
                redirect_url.indexOf("id_token=") + 9
              );
              id_token = id_token.substring(0, id_token.indexOf("&"));
              meaVanaConsole("id_token", id_token);
              meaVanaConsole("google sso success");

              let user = {
                tokenId: id_token,
                deviceId: deviceId
              };
              dispatch(GoogleLoginAuthServer(user));
            } else {
              meaVanaConsole("google sso error in redirect url");
              let response = {
                result: "error",
                message: "error in redirect url"
              };
              dispatch(loginSSOGoogleFailureAction(response));
            }
          }
        );
      } else {
        meaVanaConsole("chrome undefined");
      }
    } catch (error) {
      meaVanaConsole(
        "GoogleChromeAuth catch err",
        checkErrorResponseFormat(error)
      );
      dispatch(loginSSOGoogleFailureAction(checkErrorResponseFormat(error)));
    }
  };
};
