import { googleStateAction } from './constants';

export const updateGoogleWindowStateAction = (index = null) => {
  return (dispatch) => {
    dispatch({
      type: googleStateAction.UPDATE_GOOGLE_WINDOW_STATE,
      payload: { index }
    });
  };
};

export const updateGooglePinAction = (index = null, pin = false) => {
  return (dispatch) => {
    dispatch({
      type: googleStateAction.UPDATE_GOOGLE_PIN,
      payload: { index, pin }
    });
  };
};

export const getGoogleDataRequestAction = (index) => {
  return (dispatch) => {
    dispatch({
      type: googleStateAction.GET_GOOGLE_API_REQUEST,
      payload: { index }
    });
  };
};

export const getGoogleDataSuccessAction = (index, data) => {
  return (dispatch) => {
    dispatch({
      type: googleStateAction.GET_GOOGLE_API_SUCCESS,
      payload: { index, data }
    });
  };
};

export const getGoogleDataFailureAction = (index, error) => {
  return (dispatch) => {
    dispatch({
      type: googleStateAction.GET_GOOGLE_API_FAILURE,
      payload: { index, error }
    });
  };
};
