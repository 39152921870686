import { Add, MoreHoriz, Search } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Close, KeyboardArrowDown } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { init, send } from 'emailjs-com';

import { Chip, Slider, Switch } from '@mui/material';
import useWindowDimensions from '../../../Utils/useWindowDimensions';
import { meaVanaConsole } from '../../../Utils/MeaVanaConsole';
import { GetImageUrl } from '../../../Utils/GetImageUrl';
import {
  addSearchEngineAction,
  deleteSearchEngineAction,
  updateActiveSearchEngineAction,
  updateSearchEngineBorderAction,
  updateSearchEngineLineAction,
  updateSearchEngineColoredAction,
  updateSearchEngineOpenNewTabAction,
  updateSearchEngineOrderAction,
  updateSearchEngineRectangleAction,
  updateSearchEngineSizeAction,
  updateShowMultipleSearchEngineAction
} from '../../../store/reducers/searchEngineReducer/action';
import { resetHomeThemeAction } from '../../../store/reducers/homeReducer/action';
import { Emailjs_Service_ID, Emailjs_Template, Emailjs_User_ID } from '../../../config/constants';
import { SearchEngineList } from '../../../Utils/SearchEngineList';

init(Emailjs_User_ID);

export default function PrimarySearchEngine(props) {
  const { height, width } = useWindowDimensions();

  const [valueSearch, setvalueSearch] = useState('');
  const [statePopoverSearchEngineSetting, setstatePopoverSearchEngineSetting] = useState(false);
  const [statePopoverSeachEngineList, setstatePopoverSeachEngineList] = useState(false);
  const [maxWidth, setmaxWidth] = useState(((width * 8) / 10 - 100) / 100);
  const dispatch = useDispatch();
  const clockState = useSelector((state) => state.clock);
  const appState = useSelector((state) => state.app);
  const stateAuth = useSelector((state) => state.auth);

  const searchEngine = useSelector((state) => state.searchEngine);

  const [open, setOpen] = useState(false);
  const [inputSuggestUrl, setinputSuggestUrl] = useState('');
  const [inputSuggestName, setinputSuggestName] = useState('');
  const [openDialogSuggest, setopenDialogSuggest] = useState(false);
  const [inputSearchActive, setinputSearchActive] = useState(false);
  const [loadingBtn, setloadingBtn] = useState(false);
  const [searchEngineSizeValue, setsearchEngineSizeValue] = useState(searchEngine.searchEnginSize);
  const [responseSuggestMessage, setresponseSuggestMessage] = useState({
    message: '',
    error: false
  });
  const [showIcons, setshowIcons] = useState(false);
  const refInputSearch = useRef();
  const searchEngineContainerRef = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (appState.showLoading) {
      setOpen(false);
      setopenDialogSuggest(false);
    }
  }, [appState.showLoading]);

  useEffect(() => {
    setvalueSearch('');
    if (searchEngineContainerRef.current && searchEngineContainerRef.current.clientWidth) {
      meaVanaConsole('searchEngineContainerRef.current :', searchEngineContainerRef.current);
      meaVanaConsole(
        'searchEngineContainerRef.current.clientWidth:',
        searchEngineContainerRef.current.clientWidth
      );
      setmaxWidth(((searchEngineContainerRef.current.clientWidth - 100) / width) * 100);

      meaVanaConsole(
        'maxWidth :',
        ((searchEngineContainerRef.current.clientWidth - 100) / width) * 100
      );
    } else {
      setmaxWidth(((width * 8) / 10 - 100) / 100);
      meaVanaConsole('not ref maxWidth :', ((width * 8) / 10 - 100) / 100);
    }
  }, [width]);

  useEffect(() => {
    setvalueSearch('');
  }, []);

  const sendSuggestEmail = (e) => {
    if (inputSuggestName === '') {
      setresponseSuggestMessage({
        message: 'You need to type a name of the suggest',
        error: true
      });
    } else {
      e.preventDefault();
      setloadingBtn(true);
      send(Emailjs_Service_ID, Emailjs_Template, {
        title: 'Subject:Suggesting new search engine',
        message: 'name: ' + inputSuggestName + ' ,url: ' + inputSuggestUrl,
        user_name: stateAuth.user.username,
        user_email: stateAuth.user.email
      })
        .then((response) => {
          setloadingBtn(false);
          setinputSuggestName('');
          setinputSuggestUrl('');
          setresponseSuggestMessage({
            message: 'Your Message Has Been Sent',
            error: false
          });
          meaVanaConsole('send email SUCCESS! :', response);
        })
        .catch((err) => {
          setloadingBtn(false);
          setresponseSuggestMessage({
            message: 'Sorry, Your Message Has Not Been Sent',
            error: true
          });
          meaVanaConsole('FAILED...', err);
        });
    }
  };

  const deleteSearchEngin = (engine, index, active) => {
    meaVanaConsole('deleteSearchEngin index :', index);
    meaVanaConsole('searchEngines delete : ', searchEngine.searchEngines[index]);
    meaVanaConsole(' is active :', active);
    if (active) {
      dispatch(deleteSearchEngineAction(index, engine));

      dispatch(updateActiveSearchEngineAction(searchEngine.searchEngines[0]));
    } else {
      dispatch(deleteSearchEngineAction(index, engine));
    }
  };

  const addSearchEngin = (index) => {
    dispatch(addSearchEngineAction(index));
  };

  function checkSearchEngineActive(engine, index) {
    let ind = searchEngine.searchEngines.findIndex((item) => item.name === engine.name);

    if (ind === -1) {
      return (
        <div className="item-dialog-list-engine" key={index}>
          <div className="container-search-engine-img">
            <img
              src={GetImageUrl(engine.icons.color)}
              className="search-engine-icon"
              alt={'search engine icon ' + engine.name + index}
            ></img>
            {engine.name}
          </div>
          <IconButton
            onClick={() => {
              meaVanaConsole('delete search engine');
              addSearchEngin(index);
            }}
            className="icon-add-search-engine"
          >
            <Add fontSize="medium" />
          </IconButton>
        </div>
      );
    } else {
      return null;
    }
  }

  const SortableItem = SortableElement(({ index, engine }) => (
    <div
      className={
        searchEngine.activeSearchEngine.name === engine.name
          ? 'search-engine-item-active'
          : 'search-engine-item'
      }
      key={index}
    >
      <div
        className="container-search-engine-img"
        onClick={() => {
          document.body.click();

          dispatch(updateActiveSearchEngineAction(engine));
          if (refInputSearch.current) {
            refInputSearch.current.focus();
          }
        }}
        key={index}
      >
        <img
          src={GetImageUrl(engine.icons.color)}
          className="search-engine-icon"
          alt={'search engine icon ' + engine.name + index}
        ></img>
        {engine.name}
      </div>
      {searchEngine.searchEngines.length > 1 ? (
        <IconButton
          key={index}
          onClick={() => {
            meaVanaConsole('delete search engine');
            deleteSearchEngin(engine, index, searchEngine.activeSearchEngine.name === engine.name);
          }}
          className="icon-delete-search-engine"
        >
          <Close fontSize="medium" />
        </IconButton>
      ) : (
        <div className="icon-delete-search-engine-hide"></div>
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="container-search-engines">
        {items.map((value, index) => {
          return <SortableItem key={`item-${index}`} index={index} engine={value} />;
        })}
        {searchEngine.searchEngines &&
        searchEngine.searchEngines.length < SearchEngineList.length ? (
          <Button
            variant="contained"
            className="search-engine-item-add"
            //   style={{color:"#008b8b9a"}}
            color="info"
            onClick={() => {
              document.body.click();
              handleClickOpen();
            }}
          >
            <Add fontSize="medium" />
          </Button>
        ) : null}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = arrayMove(searchEngine.searchEngines, oldIndex, newIndex);
    dispatch(updateSearchEngineOrderAction(items));
  };

  const hideIcons = async () => {
    // await Sleep(5000);
    if (!statePopoverSearchEngineSetting && !statePopoverSeachEngineList) {
    }
    setshowIcons(false);
  };

  return (
    <div
      className="search-engine-container"
      ref={searchEngineContainerRef}
      onMouseOver={() => setshowIcons(true)}
      onMouseLeave={() => {
        hideIcons();
      }}
    >
      <div
        className={`container-search 
      ${
        !searchEngine.searchEngineLine &&
        searchEngine.searchEngineRectangle &&
        'rectangle-search-engine'
      }
    ${!searchEngine.searchEngineLine && searchEngine.searchEngineBorder && 'border-search-engine'}
    ${searchEngine.searchEngineLine && 'border-bottom-search-engine'}
    `}
        style={
          props.display || props.opacity === 0
            ? { display: 'none' }
            : inputSearchActive
            ? {
                opacity: 1,
                background: `${!searchEngine.searchEngineLine && '#fff'}`,
                width: searchEngine.searchEnginSize + 'vw',
                maxWidth: maxWidth + 'vw'
              }
            : {
                background: `${
                  searchEngine.searchEngineLine
                    ? 'transparent'
                    : 'rgb(255, 255, 255,' + props.opacity + ')'
                }`,
                width: searchEngine.searchEnginSize + 'vw',
                maxWidth: maxWidth + 'vw'
              }
        }
      >
        <div className="icon-search-container">
          <Search
            // style={valueSearch == "" ? { color: "#fff" } : { color: "#888787" }}
            onClick={() =>
              window.open(
                `${searchEngine.activeSearchEngine.url}?${searchEngine.activeSearchEngine.searchKey}=${refInputSearch.current.value}`,
                `${searchEngine.searchEngineOpenNewTab ? '_blank' : '_self'}`
              )
            }
            style={{
              color: `${searchEngine.searchEngineLine ? 'white' : '#686464'}`,
              cursor: 'pointer'
            }}
            fontSize="medium"
          />
        </div>
        <form
          className="form-search-google"
          action={searchEngine.activeSearchEngine.url}
          target={searchEngine.searchEngineOpenNewTab ? '_blank' : '_self'}
          type="GET"
          id="form"
        >
          <input
            // type="search"
            style={{
              color: searchEngine.searchEngineLine ? 'white' : 'black',
              textShadow: searchEngine.searchEngineLine
                ? '1.5px 1.5px 1px gray, 0 0 0.05em whitesmoke, 0 0 0.1em whitesmoke'
                : 'none'
            }}
            id="input"
            type="search"
            autoComplete="off"
            spellCheck="true"
            ref={refInputSearch}
            name={searchEngine.activeSearchEngine.searchKey}
            onFocus={() => setinputSearchActive(true)}
            onBlur={() => setinputSearchActive(false)}
            className="searchTerm"
            onChange={(event) => setvalueSearch(event.target.value)}
          ></input>
        </form>

        <OverlayTrigger
          trigger="click"
          key="OverlayTrigger-browser"
          placement={
            clockState.analogClock
              ? width < 1000 || searchEngineSizeValue > 60
                ? 'left'
                : 'right'
              : 'bottom'
          }
          rootClose={true}
          onHide={(event) => {
            setstatePopoverSearchEngineSetting(false);
            setstatePopoverSeachEngineList(false);
            hideIcons();
          }}
          onEnter={(event) => {
            setstatePopoverSeachEngineList(true);
          }}
          overlay={
            <Popover
              id={`popover-browser`}
              className={
                clockState.analogClock
                  ? width < 1000 || searchEngineSizeValue > 60
                    ? 'search-engines-popover popover-meavana-arrow-left'
                    : 'search-engines-popover popover-meavana-arrow-right'
                  : 'search-engines-popover popover-meavana-arrow-bottom'
              }
            >
              <Popover.Body className="search-engines-popover-body">
                <SortableList
                  items={searchEngine.searchEngines}
                  axis="y"
                  distance={1}
                  onSortEnd={onSortEnd}
                />
              </Popover.Body>
            </Popover>
          }
        >
          <div className="search-engine-active-icon-container">
            <img
              src={
                searchEngine.searchEnginColored
                  ? GetImageUrl(searchEngine.activeSearchEngine.icons.color)
                  : GetImageUrl(searchEngine.activeSearchEngine.icons.gray)
              }
              className="search-engine-active-icon"
              alt="active search engine"
            ></img>
            <KeyboardArrowDown
              style={{
                color: `${searchEngine.searchEngineLine ? 'white' : '#686464'}`
              }}
              fontSize="small"
            />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          trigger="click"
          key="OverlayTrigger-search-engine-settings"
          placement={width < 1000 || searchEngineSizeValue > 60 ? 'bottom' : 'right'}
          rootClose={true}
          onExit={(event) => {
            setsearchEngineSizeValue(searchEngine.searchEnginSize);
            meaVanaConsole('update setsearchEngineSizeValue');
          }}
          onHide={(event) => {
            setstatePopoverSearchEngineSetting(false);
            setstatePopoverSeachEngineList(false);
            hideIcons();
            setsearchEngineSizeValue(searchEngine.searchEnginSize);
            meaVanaConsole('update setsearchEngineSizeValue');
          }}
          onEnter={(event) => {
            setstatePopoverSearchEngineSetting(true);
          }}
          overlay={
            <Popover
              id={`popover-search-engine-settings`}
              className={
                width < 1000 || searchEngineSizeValue > 60
                  ? 'popover-search-engine-setting popover-meavana-arrow-bottom'
                  : 'popover-search-engine-setting popover-meavana-arrow-right'
              }
            >
              <Popover.Body className="popover-search-engine-setting-body">
                <div
                  style={{
                    opacity: `${searchEngine.searchEngineLine ? 0.7 : 1}`
                  }}
                  className="popover-search-engine-setting-item"
                >
                  Rectangle
                  <Switch
                    disabled={searchEngine.searchEngineLine}
                    checked={searchEngine.searchEngineRectangle}
                    onChange={(event) => {
                      dispatch(updateSearchEngineRectangleAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div
                  style={{
                    opacity: `${searchEngine.searchEngineLine ? 0.7 : 1}`
                  }}
                  className="popover-search-engine-setting-item"
                >
                  Border
                  <Switch
                    disabled={searchEngine.searchEngineLine}
                    checked={searchEngine.searchEngineBorder}
                    onChange={(event) => {
                      dispatch(updateSearchEngineBorderAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Line
                  <Switch
                    checked={searchEngine.searchEngineLine}
                    onChange={(event) => {
                      dispatch(updateSearchEngineLineAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Colored
                  <Switch
                    checked={searchEngine.searchEnginColored}
                    onChange={(event) => {
                      dispatch(updateSearchEngineColoredAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Open new Tab
                  <Switch
                    checked={searchEngine.searchEngineOpenNewTab}
                    onChange={(event) => {
                      dispatch(updateSearchEngineOpenNewTabAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  Multiple Search
                  <Switch
                    checked={searchEngine.showMultipleSeachEngine}
                    onChange={(event) => {
                      dispatch(updateShowMultipleSearchEngineAction(event.target.checked));
                      dispatch(resetHomeThemeAction());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                  />
                </div>
                <div className="popover-search-engine-setting-item">
                  <span style={{ marginRight: '20px' }}>Size</span>
                  <Slider
                    size="medium"
                    min={10}
                    max={maxWidth}
                    value={searchEngine.searchEnginSize}
                    onChange={(event) => {
                      dispatch(updateSearchEngineSizeAction(event.target.value));
                      dispatch(resetHomeThemeAction());
                    }}
                    aria-label="Small"
                    valueLabelDisplay="off"
                  />
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <IconButton
            className="search-engine-setting-icon"
            aria-label="search-engine-Settings"
            style={showIcons ? { color: '#fff', opacity: 1 } : { opacity: 0 }}
            disabled={!showIcons}
          >
            <MoreHoriz className="home-icon-setting-font-size" />
          </IconButton>
        </OverlayTrigger>

        {open ? (
          <Dialog open={open} onClose={handleClose} className="dialog-add-engine">
            <DialogContent className="dialog-add-engine-content">
              <DialogTitle className="dialog-add-engine-title">
                Your active search engines
              </DialogTitle>
              <div className="container-dialog-active-engine">
                {searchEngine.searchEngines.map((engine, index) => {
                  return (
                    <div className="item-dialog-active-engine" key={index}>
                      <div className="container-search-engine-img">
                        <img
                          src={GetImageUrl(engine.icons.color)}
                          className="search-engine-icon"
                          alt={'search engine' + engine.name + ' icon' + index}
                        ></img>
                        {engine.name}
                      </div>
                      {searchEngine.searchEngines.length > 1 ? (
                        <IconButton
                          onClick={() => {
                            meaVanaConsole('delete search engine');
                            deleteSearchEngin(
                              index,
                              engine,
                              searchEngine.activeSearchEngine.name === engine.name
                            );
                          }}
                          className="icon-delete-search-engine"
                        >
                          <Close fontSize="medium" />
                        </IconButton>
                      ) : (
                        <div className="icon-delete-search-engine-hide"></div>
                      )}
                    </div>
                  );
                })}
              </div>
              <DialogTitle className="dialog-add-engine-title">
                Add other search engines
              </DialogTitle>

              {searchEngine.searchEngines &&
              searchEngine.searchEngines.length < SearchEngineList.length ? (
                <div className="container-dialog-list-engine">
                  {SearchEngineList.map((engine, index) => {
                    return checkSearchEngineActive(engine, index);
                  })}
                </div>
              ) : (
                <div className="txt-no-search-engine">All search engines are active</div>
              )}
            </DialogContent>

            <div className="suggest-new-search-engine-container-open-dialog">
              <Chip
                color="info"
                className="dialog-add-engine-btn"
                onClick={() => {
                  // handleClose();
                  setinputSuggestName('');
                  setinputSuggestUrl('');
                  setresponseSuggestMessage({ message: '', error: false });
                  setopenDialogSuggest(true);
                }}
                label="Suggest a New Search Engine"
              />
            </div>
          </Dialog>
        ) : null}
        <Dialog
          open={openDialogSuggest}
          onClose={() => {
            setopenDialogSuggest(false);
          }}
        >
          <DialogTitle>Suggest a New Search Engine</DialogTitle>
          <DialogContent>
            <div className="form-group form-group-bookmark">
              <label>Name</label>
              <input
                type="text"
                className="form-control form-control-bookmark"
                placeholder=""
                onFocus={() => setresponseSuggestMessage({ message: '', error: false })}
                value={inputSuggestName}
                onChange={(event) => setinputSuggestName(event.target.value)}
              />
            </div>
            <div className="form-group form-group-bookmark">
              <label>Url (Optional)</label>
              <input
                type="text"
                className="form-control form-control-bookmark"
                placeholder=""
                onFocus={() => setresponseSuggestMessage({ message: '', error: false })}
                value={inputSuggestUrl}
                onChange={(event) => setinputSuggestUrl(event.target.value)}
              />
            </div>
          </DialogContent>
          <div
            className="form-group form-group-bookmark"
            style={{ alignSelf: 'center', textAlign: 'center', width: '100%' }}
          >
            <span
              style={
                responseSuggestMessage.error
                  ? { color: 'red' }
                  : { color: 'var(--meavana-green-color)' }
              }
            >
              {responseSuggestMessage.message}
            </span>
          </div>
          <DialogActions>
            <Button
              color="info"
              variant="contained"
              onClick={sendSuggestEmail}
              disabled={loadingBtn}
            >
              {loadingBtn ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#fff',
                    alignSelf: 'center'
                  }}
                />
              ) : (
                'Send'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
