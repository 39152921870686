import React from 'react';
import { useSelector } from 'react-redux';
import CoincapWindow from './Crypto/Coincap/CoincapWindow';
import SpotifyWindow from './Music/Spotify/SpotifyWindow';
import NewsWindow from './News/NewsWindow/NewsWindow';
import UnsplashWindow from './Pictures/Unsplash/UnsplashWindow';
import YoutubeWindow from './Videos/Youtube/YoutubeWindow';
import GCalendarWindow from './Google/GCalendar/GCalendarWindow';

export default function IntegrationWindows() {
  const cryptoState = useSelector((state) => state.crypto);
  const coincapCryptoState = cryptoState.cryptoApiSource.find((item) => item.id === 'coincap');

  const musicState = useSelector((state) => state.music);
  const spotifyState = musicState.musicapiSource.find((item) => item.id === 'spotify');

  const newsState = useSelector((state) => state.news);
  const isNewsSourceActive = newsState.activeNewsIndex != null;

  const picturesState = useSelector((state) => state.pictures);
  const unsplashPictureState = picturesState.picturesApiSource.find(
    (item) => item.id === 'unsplash'
  );

  const videoState = useSelector((state) => state.video);
  const YoutubeVideoState = videoState.videosApiSource.find((item) => item.id === 'youtube');

  const googleState = useSelector((state) => state.google);
  const googleCalendarState = googleState.googleApps.find((item) => item.id === 'gcalendar');

  return (
    <>
      {/* Crypto integration windows */}
      {coincapCryptoState.active && <CoincapWindow />}
      {/* Music integration windows */}
      {/*  {spotifyState.active && <SpotifyWindow />}
       */}
      {/* news integration windows */}
      {isNewsSourceActive && <NewsWindow />}
      {/* Pictures integration windows */}
      {unsplashPictureState.active && <UnsplashWindow />}
      {/* Videos integration windows*/}
      {/*YoutubeVideoState.active && <YoutubeWindow />*/}
      {/* Google integration windows */}
      {true && <GCalendarWindow />}
    </>
  );
}
