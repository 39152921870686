export const authBackroundUrl = '/assets/backgrounds/authBackground.jpg';
export const assetsIcons = {
  Adidas: {
    color: '/assets/icons/Adidas/color.svg',
    gray: '/assets/icons/Adidas/gray.svg'
  },
  Alibaba: {
    color: '/assets/icons/Alibaba/color.svg',
    gray: '/assets/icons/Alibaba/gray.svg'
  },
  amazon: {
    color: '/assets/icons/Amazon/color.svg',
    gray: '/assets/icons/Amazon/gray.svg'
  },
  baidu: {
    color: '/assets/icons/Baidu/color.svg',
    gray: '/assets/icons/Baidu/gray.svg'
  },
  Behance: {
    color: '/assets/icons/Behance/color.svg',
    gray: '/assets/icons/Behance/gray.svg'
  },
  bing: {
    color: '/assets/icons/Bing/color.svg',
    gray: '/assets/icons/Bing/gray.svg'
  },
  Craiglist: {
    color: '/assets/icons/Craiglist/color.svg',
    gray: '/assets/icons/Craiglist/gray.svg'
  },

  discord: {
    color: '/assets/icons/Discord/color.svg'
  },
  Dribble: {
    color: '/assets/icons/Dribble/color.svg',
    gray: '/assets/icons/Dribble/gray.svg'
  },
  duckduckgo: {
    color: '/assets/icons/DuckDuckGo/color.svg',
    gray: '/assets/icons/DuckDuckGo/gray.svg'
  },
  Ebay: {
    color: '/assets/icons/Ebay/color.svg',
    gray: '/assets/icons/Ebay/gray.svg'
  },
  facebook: {
    color: '/assets/icons/Facebook/color.svg',
    gray: '/assets/icons/Facebook/gray.svg'
  },
  Flipkart: {
    color: '/assets/icons/Flipkart/color.svg',
    gray: '/assets/icons/Flipkart/gray.svg'
  },
  Github: {
    color: '/assets/icons/Github/color.svg',
    gray: '/assets/icons/Github/gray.svg'
  },
  Gitlab: {
    color: '/assets/icons/Gitlab/color.svg',
    gray: '/assets/icons/Gitlab/gray.svg'
  },
  folder: '/assets/icons/folder.png',
  gmail: {
    color: '/assets/icons/Gmail/color.svg',
    gray: '/assets/icons/Gmail/gray.svg'
  },
  google: {
    color: '/assets/icons/Google/color.svg',
    gray: '/assets/icons/Google/gray.svg'
  },
  googledrive: {
    color: '/assets/icons/GoogleDrive/color.svg',
    gray: '/assets/icons/GoogleDrive/gray.svg'
  },
  googlescholar: {
    color: '/assets/icons/GoogleScholar/color.svg',
    gray: '/assets/icons/GoogleScholar/gray.svg'
  },
  googleservices: {
    google: '/assets/icons/GoogleServices/google.png',
    google_books: '/assets/icons/GoogleServices/google_books.png',
    google_business: '/assets/icons/GoogleServices/google_business.png',
    google_calendar: '/assets/icons/GoogleServices/google_calendar.png',
    google_chat: '/assets/icons/GoogleServices/google_chat.png',
    google_classroom: '/assets/icons/GoogleServices/google_classroom.png',
    google_contacts: '/assets/icons/GoogleServices/google_contacts.png',
    google_docs: '/assets/icons/GoogleServices/google_docs.png',
    google_earth: '/assets/icons/GoogleServices/google_earth.png',
    google_finance: '/assets/icons/GoogleServices/google_finance.png',
    google_hangouts: '/assets/icons/GoogleServices/google_hangouts.png',
    google_jamboard: '/assets/icons/GoogleServices/google_jamboard.png',
    google_maps: '/assets/icons/GoogleServices/google_maps.png',
    google_meet: '/assets/icons/GoogleServices/google_meet.png'
  },
  Grubhub: {
    color: '/assets/icons/Grubhub/color.svg',
    gray: '/assets/icons/Grubhub/gray.svg'
  },
  IMDB: {
    color: '/assets/icons/IMDB/color.svg',
    gray: '/assets/icons/IMDB/gray.svg'
  },
  instagram: {
    black: '/assets/icons/Instagram/black.svg',
    color: '/assets/icons/Instagram/color.svg',
    white: '/assets/icons/Instagram/white.svg',
    gray: '/assets/icons/Instagram/gray.svg'
  },
  integration: {
    black: '/assets/icons/Integration/black.svg',
    white: '/assets/icons/Integration/white.svg',
    crypto: {
      black: '/assets/icons/Integration/Crypto/black.svg',
      color: '/assets/icons/Integration/Crypto/color.svg',
      white: '/assets/icons/Integration/Crypto/white.svg',
      coinCap: {
        color: '/assets/icons/Integration/Crypto/CoinCap/color.svg'
      },
      currency: {}
    },
    music: {
      black: '/assets/icons/Integration/Music/black.svg',
      color: '/assets/icons/Integration/Music/color.svg',
      white: '/assets/icons/Integration/Music/white.svg',
      spotify: {
        black: '/assets/icons/Integration/Music/Spotify/black.svg',
        color: '/assets/icons/Integration/Music/Spotify/color.svg',
        white: '/assets/icons/Integration/Music/Spotify/white.svg'
      }
    },
    news: {
      black: '/assets/icons/Integration/News/black.svg',
      white: '/assets/icons/Integration/News/white.svg',
      BBC: {
        black: '/assets/icons/Integration/News/BBC/black.svg',
        color: '/assets/icons/Integration/News/BBC/color.svg',
        white: '/assets/icons/Integration/News/BBC/white.svg'
      },
      TechReview: {
        black: '/assets/icons/Integration/News/TechReview/black.svg',
        white: '/assets/icons/Integration/News/TechReview/white.svg'
      },
      Forbes: {
        black: '/assets/icons/Integration/News/Forbes/black.svg',
        white: '/assets/icons/Integration/News/Forbes/white.svg'
      },
      Mashable: {
        black: '/assets/icons/Integration/News/Mashable/black.svg',
        white: '/assets/icons/Integration/News/Mashable/white.svg'
      },
      LifeHacker: {
        black: '/assets/icons/Integration/News/LifeHacker/black.svg',
        white: '/assets/icons/Integration/News/LifeHacker/white.svg'
      },
      ChessCom: {
        black: '/assets/icons/Integration/News/ChessCom/black.svg',
        white: '/assets/icons/Integration/News/ChessCom/white.svg'
      },
      CNN: {
        black: '/assets/icons/Integration/News/CNN/black.svg',
        color: '/assets/icons/Integration/News/CNN/color.svg',
        white: '/assets/icons/Integration/News/CNN/white.svg'
      },
      ESPN: {
        black: '/assets/icons/Integration/News/ESPN/black.svg',
        white: '/assets/icons/Integration/News/ESPN/white.svg'
      },
      FoxNews: {
        black: '/assets/icons/Integration/News/FoxNews/black.svg',
        color: '/assets/icons/Integration/News/FoxNews/color.svg',
        white: '/assets/icons/Integration/News/FoxNews/white.svg'
      },
      GoogleNews: {
        black: '/assets/icons/Integration/News/GoogleNews/black.svg',
        color: '/assets/icons/Integration/News/GoogleNews/color.svg',
        white: '/assets/icons/Integration/News/GoogleNews/white.svg'
      },
      NewYorkTimes: {
        black: '/assets/icons/Integration/News/NewYorkTimes/black.svg',
        white: '/assets/icons/Integration/News/NewYorkTimes/white.svg'
      },
      TechCrunch: {
        black: '/assets/icons/Integration/News/TechCrunch/black.svg',
        white: '/assets/icons/Integration/News/TechCrunch/white.svg'
      },
      Wired: {
        black: '/assets/icons/Integration/News/Wired/black.svg',
        color: '/assets/icons/Integration/News/Wired/color.svg',
        white: '/assets/icons/Integration/News/Wired/white.svg'
      },
      YahooFinance: {
        black: '/assets/icons/Integration/News/YahooFinance/black.svg',
        white: '/assets/icons/Integration/News/YahooFinance/white.svg'
      }
    },
    pictures: {
      black: '/assets/icons/Integration/Pictures/black.svg',
      white: '/assets/icons/Integration/Pictures/white.svg',
      Unsplash: {
        black: '/assets/icons/Integration/Pictures/Unsplash/black.svg',
        white: '/assets/icons/Integration/Pictures/Unsplash/white.svg'
      }
    },
    video: {
      black: '/assets/icons/Integration/Video/black.svg',
      white: '/assets/icons/Integration/Video/white.svg'
    },
    google: {
      black: '/assets/icons/Integration/Music/black.svg',
      color: '/assets/icons/Integration/Music/color.svg',
      white: '/assets/icons/Integration/Music/white.svg',
      gcalendar: {
        black: '/assets/icons/Integration/Music/Spotify/black.svg',
        color: '/assets/icons/Integration/Music/Spotify/color.svg',
        white: '/assets/icons/Integration/Music/Spotify/white.svg'
      }
    }
  },
  linkedin: {
    color: '/assets/icons/LinkedIN/color.svg',
    gray: '/assets/icons/LinkedIN/gray.svg'
  },

  netflix: {
    black: '/assets/icons/Netflix/black.svg',
    color: '/assets/icons/Netflix/color.svg',
    white: '/assets/icons/Netflix/white.svg'
  },
  Nike: {
    color: '/assets/icons/Nike/color.svg',
    gray: '/assets/icons/Nike/gray.svg'
  },
  pinterest: {
    color: '/assets/icons/Pinterest/color.svg',
    gray: '/assets/icons/Pinterest/gray.svg'
  },
  reddit: {
    color: '/assets/icons/Reddit/color.svg',
    gray: '/assets/icons/Reddit/gray.svg'
  },
  Slideshare: {
    color: '/assets/icons/Slideshare/color.svg',
    gray: '/assets/icons/Slideshare/gray.svg'
  },
  Stackoverflow: {
    color: '/assets/icons/Stackoverflow/color.svg',
    gray: '/assets/icons/Stackoverflow/gray.svg'
  },
  Techcrunch: {
    color: '/assets/icons/Techcrunch/color.svg',
    gray: '/assets/icons/Techcrunch/gray.svg'
  },
  TikTok: {
    color: '/assets/icons/TikTok/color.svg',
    gray: '/assets/icons/TikTok/gray.svg'
  },
  twitter: {
    color: '/assets/icons/Twitter/color.svg',
    gray: '/assets/icons/Twitter/gray.svg'
  },
  weather: {},
  wikipedia: {
    color: '/assets/icons/Wikipedia/color.svg',
    gray: '/assets/icons/Wikipedia/gray.svg'
  },
  Wolframalpha: {
    color: '/assets/icons/Wolframalpha/color.svg',
    gray: '/assets/icons/Wolframalpha/gray.svg'
  },
  yahoo: {
    color: '/assets/icons/Yahoo/color.svg',
    gray: '/assets/icons/Yahoo/gray.svg'
  },
  Yandex: {
    color: '/assets/icons/Yandex/color.svg',
    gray: '/assets/icons/Yandex/gray.svg'
  },
  youtube: {
    black: '/assets/icons/Youtube/black.svg',
    white: '/assets/icons/Youtube/white.svg',
    color: '/assets/icons/Youtube/color.svg',
    gray: '/assets/icons/Youtube/gray.svg'
  },
  Chrome: {
    black: '/assets/icons/Chrome/color.png',
    white: '/assets/icons/Chrome/color.png',
    color: '/assets/icons/Chrome/color.png',
    gray: '/assets/icons/Chrome/color.png'
  }
};
