import { assetsIcons } from "../config/images";
import { GetImageUrl } from "./GetImageUrl";

export const BookmarkGetImageSrcUrl = (srcUrl) => {
  let urlToReturn =
    "https://s2.googleusercontent.com/s2/favicons?sz=64&domain_url=" + srcUrl;
  //whatever url was entered by user we will match it with regex to grab "url" and "hostname" separately
  const match = srcUrl.match(/^(?:http[s]?:)?(?:\/\/)?([^\/\?]+)/i);
  //the match[0] contains url(eg. https://www.xyz.com) and match[1] have hostname(eg.www.mail.google.com)
  const url = match && match[0];
  let hostname = match && match[1];
  //only look for "google" domain(eg. if we have mail.google.com we want to check for google only
  //for optimized if logic to avoid multiple if else if the domain wasn't google)
  const splittedDomain = hostname.split(".");

  //sice we can have direct domain "eg. google" or with www eg. www.google.com
  //we have to check either of them
  if (splittedDomain[1] === "google" || splittedDomain[2] === "google") {
    //some of the google sites is not listed as subdomain rather as subdir
    //eg. (finance -> www.google.com/finance) so we have to check it separately
    //the following line will give the first subDir encountered in url eg.(finance)
    let googleSubDir = srcUrl.replace(/^http[s]?:\/\//, "").split("/")[1];
    //we will also remove "www" so we don't have to check the hostname prefixed with "www" separately in "if codition"
    hostname = hostname.replace("www.", "");
    if (hostname === "chat.google.com" || googleSubDir === "chat") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_chat);
    } else if (hostname === "mail.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.gmail.color);
    } else if (hostname === "calendar.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_calendar);
    } else if (googleSubDir === "meet") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_meet);
    } else if (hostname === "contacts.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_contacts);
    } else if (hostname === "chat.google.com" || googleSubDir === "chat") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_chat);
    } else if (
      hostname === "finance.google.com" ||
      googleSubDir === "finance"
    ) {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_finance);
    } else if (hostname === "hangouts.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_hangouts);
    } else if (hostname === "jamboard.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_jamboard);
    } else if (hostname === "classroom.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_classroom);
    } else if (hostname === "earth.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_earth);
    } else if (hostname === "docs.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_docs);
    } else if (hostname === "books.google.com") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_books);
    } else if (googleSubDir === "business") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_business);
    } else if (googleSubDir === "maps") {
      urlToReturn = GetImageUrl(assetsIcons.googleservices.google_maps);
    }
  }
  //check for whatsapp
  if (splittedDomain[1] === "whatsapp" || splittedDomain[2] === "whatsapp") {
    urlToReturn =
      "https://s2.googleusercontent.com/s2/favicons?sz=64&domain_url=http://www.whatsapp.com";
  }
  //since gmail.com is not "google" domain but we still have to consider it separately for loading right favicon
  if (hostname === "gmail.com" || hostname === "www.gmail.com") {
    urlToReturn = GetImageUrl(assetsIcons.gmail.color);
  }
  //return the url
  return urlToReturn;
};
