import React from "react";
import { Share, Timer10 } from "@mui/icons-material";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "./ShareSendEmail.css";
import { GetImageUrl } from "../../Utils/GetImageUrl";
import {
  facebookShare,
  gmailShare,
  linkedinShare,
  pinterestShare,
  redditShare,
  twitterShare
} from "../../Utils/SocialShareMediaFunctions";
import { CHROME_EXTENTION_URL } from "../../config/constants";
import { assetsIcons } from "../../config/images";

export default function ShareSendEmail(props) {
  if (props.display) {
    return (
      <div
        className="container-btn-share-meavana"
        style={props.display ? { opacity: 0, cursor: "inherit" } : {}}
      >
        <Share className="home-icon-font-size" />
      </div>
    );
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        key="OverlayTrigger-share-meavana"
        placement="top"
        rootClose={true}
        overlay={
          <Popover
            id={`popover-share-meavana`}
            className="popover-share-meavana"
          >
            <Popover.Body className="popover-share-meavana-body">
              <div className="share-meavana-grid">
                {/*<div className="share-meavana-grid-item">
                  <img src="face incon" alt={"share meavana facebook icon"} />
                  <span></span>
                </div>*/}
                <img
                  className="about-image-social-media"
                  alt="facebook icon"
                  src={GetImageUrl(assetsIcons.facebook.color)}
                  onClick={() => facebookShare(CHROME_EXTENTION_URL)}
                />
                <img
                  className="about-image-social-media"
                  alt="Twitter icon"
                  src={GetImageUrl(assetsIcons.twitter.color)}
                  onClick={() =>
                    twitterShare(
                      CHROME_EXTENTION_URL,
                      "Check out MeaVana: an exciting new platform!",
                      "meavanadash",
                      "Meavana"
                    )
                  }
                />
                <img
                  className="about-image-social-media"
                  alt="LinkedIN icon"
                  src={GetImageUrl(assetsIcons.linkedin.color)}
                  onClick={() => linkedinShare(CHROME_EXTENTION_URL)}
                />
                <img
                  className="about-image-social-media"
                  alt="Pinterest icon"
                  src={GetImageUrl(assetsIcons.pinterest.color)}
                  onClick={() => pinterestShare(CHROME_EXTENTION_URL)}
                />
                <img
                  className="about-image-social-media"
                  alt="Reddit icon"
                  src={GetImageUrl(assetsIcons.reddit.color)}
                  onClick={() =>
                    redditShare(
                      CHROME_EXTENTION_URL,
                      " Check out MeaVana: an exciting new platform!"
                    )
                  }
                />
                <img
                  className="about-image-social-media"
                  alt="Gmail icon"
                  src={GetImageUrl(assetsIcons.gmail.color)}
                  onClick={() =>
                    gmailShare(
                      "Check out MeaVana: an exciting new platform!",
                      encodeURIComponent(
                        "I’ve been enjoying using MeaVana - the world’s most customizable personal dashboard. Check out!\n\n" +
                          CHROME_EXTENTION_URL
                      )
                    )
                  }
                />
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="container-btn-share-meavana">
          <Share className="home-icon-font-size" />
        </div>
      </OverlayTrigger>
    );
  }
}
