import React from "react";
import "./Settings.css";
import { useDispatch, useSelector } from "react-redux";

import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";

import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import {
  updateAppStateLoadingAction,
  updateBackgroundModeAction,
  updateThemeColorAction,
  updateThemeFontFamilyAction,
  updateThemeModeAction,
  updateThemeStyleAction,
  updateThemeTextSizeAction
} from "../../store/reducers/appReducer/action";
import { updateTimeFontSizeAction } from "../../store/reducers/clockReducer/action";
import {
  minimalistHomeTheme,
  oldFashionedHomeTheme,
  standardHomeTheme
} from "../../store/reducers/appReducer/constants";
import { updateHomeThemeAction } from "../../store/reducers/homeReducer/action";
import MyFormControlLabel from "../../Utils/MyFormControlLabel";
import {
  updateActiveAnimationBackgroundAction,
  updateAnimationBackgroundAction
} from "../../store/reducers/animReducer/action";

export default function AppearanceSettings() {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.app);
  const homeState = useSelector((state) => state.home);
  const animationState = useSelector((state) => state.animation);
  const authState = useSelector((state) => state.auth);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const updateAnimatedBackgroundMode = (event, newValue) => {
    event.preventDefault();
    dispatch(updateAnimationBackgroundAction(newValue));
    dispatch(updateBackgroundModeAction(newValue));
  };

  const updateThemeMode = (event, newValue) => {
    event.preventDefault();
    document.body.classList.remove(appState.themeMode);
    document.body.classList.add(newValue);
    if (document.body.classList.contains(appState.themeMode)) {
      document.body.classList.replace(appState.themeMode, newValue);
    } else {
      document.body.classList.add(newValue);
    }
    dispatch(updateThemeModeAction(newValue));
  };

  const updateThemeColor = (event, newValue) => {
    event.preventDefault();

    if (document.body.classList.contains(appState.themeMode)) {
      document.body.classList.replace(
        appState.themeMode,
        newValue + "-" + appState.themeStyle + "-theme"
      );
    } else {
      document.body.classList.add(
        newValue + "-" + appState.themeStyle + "-theme"
      );
    }

    dispatch(updateThemeColorAction(newValue));
  };
  const updateThemeStyle = (event, newValue) => {
    event.preventDefault();

    if (document.body.classList.contains(appState.themeMode)) {
      document.body.classList.replace(
        appState.themeMode,
        appState.themeColor + "-" + newValue + "-theme"
      );
    } else {
      document.body.classList.add(
        appState.themeColor + "-" + newValue + "-theme"
      );
    }

    dispatch(updateThemeStyleAction(newValue));
  };

  const updateBackgroundMode = (event, newValue) => {
    event.preventDefault();
    if (animationState.active) {
      dispatch(updateActiveAnimationBackgroundAction(false));
    }
    if (document.body.classList.contains(appState.backgroundMode)) {
      document.body.classList.replace(appState.backgroundMode, newValue);
    } else {
      document.body.classList.add(newValue);
    }

    dispatch(updateBackgroundModeAction(newValue));
  };

  const updateThemeFontFamily = (event, newValue) => {
    event.preventDefault();

    if (document.body.classList.contains(appState.themeFontFamily)) {
      document.body.classList.replace(appState.themeFontFamily, newValue);
    } else {
      document.body.classList.add(newValue);
    }

    dispatch(updateThemeFontFamilyAction(newValue));
  };
  const updateThemeTextSize = (event, newValue) => {
    event.preventDefault();

    if (document.body.classList.contains(appState.themeTextSize)) {
      document.body.classList.replace(appState.themeTextSize, newValue);
    } else {
      document.body.classList.add(newValue);
    }

    dispatch(updateThemeTextSizeAction(newValue));
    dispatch(updateTimeFontSizeAction(100));
  };

  const updateHomeTheme = (event, newValue) => {
    event.preventDefault();

    switch (newValue) {
      case "standard":
        dispatch(updateHomeThemeAction(standardHomeTheme));
        break;
      case "minimalist":
        dispatch(updateHomeThemeAction(minimalistHomeTheme));

        break;
      case "old-fashioned":
        dispatch(updateHomeThemeAction(oldFashionedHomeTheme));

        break;

      default:
        dispatch(updateHomeThemeAction(standardHomeTheme));
        break;
    }
  };
  const gridItem = 4;
  return (
    <div className="settings-view">
      <div className="header-settings">
        <span className="header-settings-title">Appearance</span>
        <span className="header-settings-subtitle">Select your appearance</span>
      </div>

      <div className="setting-list-groupe">
        <div className="setting-list-groupe-header">
          <p className="setting-list-groupe-header-title">Mode</p>
        </div>

        <div className="setting-list-groupe-item">
          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "100%" }}
          >
            <RadioGroup
              aria-label="theme-color"
              value={appState.themeColor}
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              onChange={updateThemeColor}
            >
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid key="theme-color-dark" item xs={gridItem}>
                  <MyFormControlLabel
                    value="dark"
                    control={<Radio color="success" />}
                    label="Dark"
                  />
                </Grid>
                <Grid key="theme-color-light" item xs={gridItem}>
                  <MyFormControlLabel
                    value="light"
                    control={<Radio color="success" />}
                    label="Light"
                  />
                </Grid>
                <Grid key="theme-color-meavana" item xs={gridItem}>
                  <MyFormControlLabel
                    value="meavana"
                    control={<Radio color="success" />}
                    label="MeaVana"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="setting-list-groupe">
        <div className="setting-list-groupe-header">
          <p className="setting-list-groupe-header-title">Opacity</p>
        </div>
        <div className="setting-list-groupe-item">
          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "100%" }}
          >
            <RadioGroup
              aria-label="theme-style"
              value={appState.themeStyle}
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              name="row-radio-buttons-group"
              onChange={updateThemeStyle}
            >
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid key="theme-style-opaque" item xs={gridItem}>
                  <MyFormControlLabel
                    value="opaque"
                    control={<Radio color="success" />}
                    label="Opaque"
                  />
                </Grid>
                <Grid key="theme-style-translucent" item xs={gridItem}>
                  <MyFormControlLabel
                    value="translucent"
                    control={<Radio color="success" />}
                    label="Translucent"
                  />
                </Grid>
                <Grid key="theme-style-transparent" item xs={gridItem}>
                  <MyFormControlLabel
                    value="transparent"
                    control={<Radio color="success" />}
                    label="Transparent"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="setting-list-groupe">
        <div className="setting-list-groupe-header">
          <p className="setting-list-groupe-header-title">Size</p>
        </div>
        <div className="setting-list-groupe-item">
          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "100%" }}
          >
            <RadioGroup
              aria-label="Size"
              value={appState.themeTextSize}
              name="radio-buttons-group"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              onChange={updateThemeTextSize}
            >
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid key="grid-text-size-small" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Small-Size"
                    control={<Radio color="success" />}
                    label="Small"
                  />
                </Grid>
                <Grid key="grid-text-size-medium" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Medium-Size"
                    control={<Radio color="success" />}
                    label="Medium"
                  />
                </Grid>
                <Grid key="grid-text-size-large" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Large-Size"
                    control={<Radio color="success" />}
                    label="Large"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="setting-list-groupe">
        <div className="setting-list-groupe-header">
          <p className="setting-list-groupe-header-title">Theme</p>
        </div>
        <div className="setting-list-groupe-item">
          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "100%" }}
          >
            <RadioGroup
              aria-label="Size"
              value={homeState.homeTheme}
              name="radio-buttons-group"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              onChange={updateHomeTheme}
            >
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid key="grid-text-size-small" item xs={gridItem}>
                  <MyFormControlLabel
                    value="standard"
                    control={<Radio color="success" />}
                    label="Standard"
                  />
                </Grid>
                <Grid key="grid-text-size-medium" item xs={gridItem}>
                  <MyFormControlLabel
                    value="minimalist"
                    control={<Radio color="success" />}
                    label="Minimalist"
                  />
                </Grid>
                <Grid key="grid-text-size-large" item xs={gridItem}>
                  <MyFormControlLabel
                    value="old-fashioned"
                    control={<Radio color="success" />}
                    label="Vintage"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="setting-list-groupe">
        <div className="setting-list-groupe-header">
          <p className="setting-list-groupe-header-title">Background</p>
        </div>
        <div className="setting-list-groupe-item">
          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "100%" }}
          >
            <RadioGroup
              aria-label="backgroundMode"
              value={appState.backgroundMode}
              name="radio-buttons-group"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              onChange={updateBackgroundMode}
            >
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid key="grid-background-home-picture" item xs={gridItem}>
                  <MyFormControlLabel
                    value="background-home-picture"
                    control={<Radio color="success" />}
                    label="Picture"
                  />
                </Grid>
                <Grid key="grid-background-home-dark" item xs={gridItem}>
                  <MyFormControlLabel
                    value="background-home-dark"
                    control={<Radio color="success" />}
                    label="Dark"
                  />
                </Grid>
                <Grid key="grid-background-home-light" item xs={gridItem}>
                  <MyFormControlLabel
                    value="background-home-light"
                    control={<Radio color="success" />}
                    label="Light"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="setting-list-groupe">
        <div className="setting-list-groupe-header">
          <p className="setting-list-groupe-header-title">Font</p>
        </div>
        <div className="setting-list-groupe-item">
          <FormControl
            component="fieldset"
            className="seeting-FormControl"
            style={{ width: "100%" }}
          >
            <RadioGroup
              aria-label="font"
              value={appState.themeFontFamily}
              name="radio-buttons-group"
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
              onChange={updateThemeFontFamily}
            >
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid key="theme-font-family-Classic-Font" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Classic-Font"
                    control={<Radio color="success" />}
                    label="Standard"
                  />
                </Grid>
                <Grid key="theme-font-family-Old-Fashioned" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Old-Fashioned-Font"
                    control={<Radio color="success" />}
                    label="Classic"
                  />
                </Grid>

                <Grid key="theme-font-family-Artistic" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Artistic-Font"
                    control={<Radio color="success" />}
                    label="Artistic"
                  />
                </Grid>
                {/* <Grid key="theme-font-family-Professional" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Professional-Font"
                    control={<Radio color="success" />}
                    label="Professional"
                  />
                </Grid>
                <Grid key="theme-font-family-Bold" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Bold-Font"
                    control={<Radio color="success" />}
                    label="Bold"
                  />
                </Grid>
                <Grid key="theme-font-family-Modern" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Modern-Font"
                    control={<Radio color="success" />}
                    label="Modern"
                  />
                </Grid>
               
                <Grid key="theme-font-family-Minimalist" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Minimalist-Font"
                    control={<Radio color="success" />}
                    label="Minimalist"
                  />
                </Grid>
                <Grid key="theme-font-family-Fun" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Fun-Font"
                    control={<Radio color="success" />}
                    label="Fun"
                  />
                </Grid>

                

                <Grid key="theme-font-family-Cute" item xs={gridItem}>
                  <MyFormControlLabel
                    value="Cute-Font"
                    control={<Radio color="success" />}
                    label="Cute"
                  />
                </Grid>
            */}
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
