export const data = {
  userCollection: [],

  meavanaTopic: [],
  meavanaTopicLastUpdate: null,
  loadingMeavanaTopic: false,
  errorMeavanaTopic: null,

  backgroundHistory: [],

  currentBackground: [],
  currentBackgroundError: null,
  currentBackgroundLoading: false,

  selectedMeavanaTopic: {},
  selectedMeavanaTopicLastUpdate: null,
  loadingSelectedTopic: false,
  errorSelectedTopic: null,

  clickedTopicId: null,

  activeMode: 'meavana-topic'
};

export const params = {
  active: false,
  name: 'Wallpaper',
  tabs: [
    {
      id: 'unsplash_picture',
      name: 'Pictures'
    },
    {
      id: 'meavana_topics',
      name: 'Meavana Topics'
    },
    {
      id: 'animations',
      name: 'Animations'
    }
  ]
};

export const initialState = {
  ...data,
  ...params
};

export const wallpaperStateAction = {
  UPDATE_WALLPAPER_WINDOW_STATE: 'wallpaper/UPDATE_WALLPAPER_WINDOW_STATE'
};
