import { SERVER_URL } from "../../config/constants";
import { updateAppStateLoadingAction } from "../../store/reducers/appReducer/action";
import {
  deleteAccountFailureAction,
  deleteAccountRequestAction,
  deleteAccountSuccessAction,
  disableAccountFailureAction,
  disableAccountRequestAction,
  disableAccountSuccessAction,
  LogoutAction,
  updateUserInfoFailureAction,
  updateUserInfoRequestAction,
  updateUserInfoSuccessAction,
  updateUserPasswordFailureAction,
  updateUserPasswordRequestAction,
  updateUserPasswordSuccessAction
} from "../../store/reducers/authReducer/action";
import { meaVanaConsole } from "../../Utils/MeaVanaConsole";
import axiosInstance from "../Middleware/axiosInstance";
import { checkErrorResponseFormat } from "../Middleware/utils.server";

export const UpdateUserInfoUserServer = (newUser) => {
  return async (dispatch) => {
    try {
      meaVanaConsole("UpdateUserInfoAuthAction newuser:", newUser);
      const params = new URLSearchParams();
      params.append("firstName", newUser.firstName);
      params.append("lastName", newUser.lastName);
      params.append("username", newUser.username);
      dispatch(updateUserInfoRequestAction(newUser));
      axiosInstance()
        .put(SERVER_URL + "user/current-user", params)
        .then((response) => {
          meaVanaConsole("UpdateUserInfoAuthAction response: ", response.data);

          dispatch(updateUserInfoSuccessAction(response.data, newUser));
        })
        .catch((error1) => {
          meaVanaConsole(
            "UpdateUserInfoAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            updateUserInfoFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "UpdateUserInfoAuthAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(updateUserInfoFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

export const UpdateUserPasswordUserServer = (newUserPassword) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("oldPassword", newUserPassword.oldPassword);
      params.append("newPassword", newUserPassword.newPassword);
      params.append("passwordConfirm", newUserPassword.passwordConfirm);
      dispatch(updateUserPasswordRequestAction(newUserPassword));
      axiosInstance()
        .put(SERVER_URL + "user/password", params)
        .then((response) => {
          meaVanaConsole(
            "UpdateUserPasswordAuthAction response: ",
            response.data
          );

          dispatch(updateUserPasswordSuccessAction(response.data));
        })
        .catch((error1) => {
          meaVanaConsole(
            "UpdateUserPasswordAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );
          dispatch(
            updateUserPasswordFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "UpdateUserPasswordAuthAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(
        updateUserPasswordFailureAction(checkErrorResponseFormat(error))
      );
    }
  };
};

export const DeleteAccountUserServer = (password) => {
  return async (dispatch) => {
    try {
      dispatch(deleteAccountRequestAction(password));
      const params = new URLSearchParams();
      params.append("password", password);
      axiosInstance()
        .delete(SERVER_URL + "user/current-user", {
          data: { password: password }
        })
        .then((response) => {
          meaVanaConsole("DeleteAccountAuthAction response: ", response.data);

          dispatch(updateAppStateLoadingAction(true));
          dispatch(deleteAccountSuccessAction(response.data));
          console.log("user delete account");
          meaVanaConsole("dispatch LogoutAction delete account");
          dispatch(LogoutAction());
        })
        .catch((error1) => {
          meaVanaConsole(
            "DeleteAccountAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            deleteAccountFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "DeleteAccountAuthAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(deleteAccountFailureAction(checkErrorResponseFormat(error)));
    }
  };
};

export const DisableAccountUserServer = (password) => {
  return async (dispatch) => {
    try {
      dispatch(disableAccountRequestAction(password));
      const params = new URLSearchParams();
      params.append("password", password);
      axiosInstance()
        .put(SERVER_URL + "user/disable-current-user", params)
        .then((response) => {
          meaVanaConsole("DisableAccountAuthAction response: ", response.data);

          dispatch(updateAppStateLoadingAction(true));
          dispatch(disableAccountSuccessAction(response.data));
          console.log("user disable account");
          meaVanaConsole("dispatch LogoutAction disable account");
          dispatch(LogoutAction());
        })
        .catch((error1) => {
          meaVanaConsole(
            "DisableAccountAuthAction err1 ",
            checkErrorResponseFormat(error1)
          );

          dispatch(
            disableAccountFailureAction(checkErrorResponseFormat(error1))
          );
        });
    } catch (error) {
      meaVanaConsole(
        "DisableAccountAuthAction err2",
        checkErrorResponseFormat(error)
      );

      dispatch(disableAccountFailureAction(checkErrorResponseFormat(error)));
    }
  };
};
