import { assetsIcons } from '../config/images';

export const SearchEngineList = [
  {
    name: 'Google',
    url: 'https://google.com/search',
    actionUrl: 'https://google.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.google
  },
  {
    name: 'Youtube',
    url: 'https://youtube.com/search',
    actionUrl: 'https://youtube.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.youtube
  },
  {
    name: 'GDrive',
    url: 'https://drive.google.com/drive/search',
    actionUrl: 'https://drive.google.com/drive/search?q=',
    searchKey: 'q',
    icons: assetsIcons.googledrive
  },
  {
    name: 'Amazon',
    url: 'https://www.amazon.com/s',
    actionUrl: 'https://www.amazon.com/s?k=',
    searchKey: 'k',
    icons: assetsIcons.amazon
  },
  {
    name: 'Twitter',
    url: 'https://Twitter.com/search',
    actionUrl: 'https://Twitter.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.twitter
  },
  {
    name: 'GScholar',
    url: 'https://scholar.google.com/scholar',
    actionUrl: 'https://scholar.google.com/scholar?q=',
    searchKey: 'q',
    icons: assetsIcons.googlescholar
  },
  {
    name: 'Bing',
    url: 'https://bing.com/search',
    actionUrl: 'https://bing.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.bing
  },
  {
    name: 'Wikipedia',
    url: 'https://en.wikipedia.org/w/index.php',
    actionUrl: 'https://en.wikipedia.org/w/index.php?search=',
    searchKey: 'search',
    icons: assetsIcons.wikipedia
  },
  {
    name: 'Facebook',
    url: 'https://Facebook.com/search',
    actionUrl: 'https://Facebook.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.facebook
  },
  {
    name: 'Yahoo',
    url: 'https://search.yahoo.com/search',
    actionUrl: 'https://search.yahoo.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.yahoo
  },
  {
    name: 'DuckDuckGo',
    url: 'https://DuckDuckGo.com/',
    actionUrl: 'https://DuckDuckGo.com/?q=',
    searchKey: 'q',
    icons: assetsIcons.duckduckgo
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/search/results/all',
    actionUrl: 'https://www.linkedin.com/search/results/all?keywords=',
    searchKey: 'keywords',
    icons: assetsIcons.linkedin
  },
  {
    name: 'Baidu',
    url: 'https://www.baidu.com/s',
    actionUrl: 'https://www.baidu.com/s?wd=',
    searchKey: 'wd',
    icons: assetsIcons.baidu
  },
  {
    name: 'Reddit',
    url: 'https://www.reddit.com/search/',
    actionUrl: 'https://www.reddit.com/search/?q=',
    searchKey: 'q',
    icons: assetsIcons.reddit
  },
  {
    name: 'Pinterest',
    url: 'https://www.pinterest.com/search/pins/',
    actionUrl: 'https://www.pinterest.com/search/pins/?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Imdb',
    url: 'https://www.imdb.com/find',
    actionUrl: 'https://www.imdb.com/find?q=',
    searchKey: 'q',
    icons: assetsIcons.IMDB
  },
  {
    name: 'Alibaba',
    url: 'https://www.alibaba.com/trade/search',
    actionUrl: 'https://www.alibaba.com/trade/search?SearchText=',
    searchKey: 'SearchText',
    icons: assetsIcons.Alibaba
  },
  {
    name: 'Nike',
    url: 'https://www.nike.com/w',
    actionUrl: 'https://www.nike.com/w?q=',
    searchKey: 'q',
    icons: assetsIcons.Nike
  },
  {
    name: 'Stackoverflow',
    url: 'https://stackoverflow.com/search',
    actionUrl: 'https://stackoverflow.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.Stackoverflow
  },
  {
    name: 'Github',
    url: 'https://github.com/search',
    actionUrl: 'https://github.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.Github
  },
  {
    name: 'Gitlab',
    url: 'https://gitlab.com/search',
    actionUrl: 'https://gitlab.com/search?search=',
    searchKey: 'search',
    icons: assetsIcons.Gitlab
  },
  {
    name: 'Reddit Programming',
    url: 'https://www.reddit.com/r/programming/search/',
    actionUrl: 'https://www.reddit.com/r/programming/search/?q=',
    searchKey: 'q',
    icons: assetsIcons.reddit
  },
  {
    name: 'Reddit Startups',
    url: 'https://www.reddit.com/r/startups/search/',
    actionUrl: 'https://www.reddit.com/r/startups/search/?q=',
    searchKey: 'q',
    icons: assetsIcons.reddit
  },
  {
    name: 'Ebay',
    url: 'https://www.ebay.com/sch/i.html',
    actionUrl: 'https://www.ebay.com/sch/i.html?_nkw=',
    searchKey: '_nkw',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Behance',
    url: 'https://www.behance.net/search/projects',
    actionUrl: 'https://www.behance.net/search/projects?search=',
    searchKey: 'search',
    icons: assetsIcons.Behance
  },
  {
    name: 'Techcrunch',
    url: 'https://search.techcrunch.com/search',
    actionUrl: 'https://search.techcrunch.com/search?p=',
    searchKey: 'p',
    icons: assetsIcons.Techcrunch
  },
  {
    name: 'Flipkart',
    url: 'https://www.flipkart.com/search',
    actionUrl: 'https://www.flipkart.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.Flipkart
  },
  {
    name: 'TikTok',
    url: 'https://www.tiktok.com/search',
    actionUrl: 'https://www.tiktok.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.TikTok
  },
  {
    name: 'Adidas',
    url: 'https://www.adidas.com/us/search',
    actionUrl: 'https://www.adidas.com/us/search?q=',
    searchKey: 'q',
    icons: assetsIcons.Adidas
  },
  {
    name: 'Grubhub',
    url: 'https://www.grubhub.com/search',
    actionUrl: 'https://www.grubhub.com/search?queryText=',
    searchKey: 'queryText',
    icons: assetsIcons.Grubhub
  },
  {
    name: 'Yandex',
    url: 'https://yandex.com/search/',
    actionUrl: 'https://yandex.com/search/?text=',
    searchKey: 'text',
    icons: assetsIcons.Yandex
  },
  {
    name: 'Slideshare',
    url: 'https://www.slideshare.net/search/slideshow',
    actionUrl: 'https://www.slideshare.net/search/slideshow?q=',
    searchKey: 'q',
    icons: assetsIcons.Slideshare
  },
  {
    name: 'Wolfram Alpha',
    url: 'https://www.wolframalpha.com/input',
    actionUrl: 'https://www.wolframalpha.com/input?i=',
    searchKey: 'i',
    icons: assetsIcons.Wolframalpha
  }
];

/*
  //new search engine
  {
    name: 'Etsy',
    url: 'https://www.etsy.com/search',
    actionUrl: 'https://www.etsy.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },

  {
    name: 'Walmart',
    url: 'https://www.walmart.com/search',
    actionUrl: 'https://www.walmart.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Target',
    url: 'https://www.target.com/s',
    actionUrl: 'https://www.target.com/s?searchTerm=',
    searchKey: 'searchTerm',
    icons: assetsIcons.pinterest
  },

  {
    name: 'Urbanoutfitters',
    url: 'https://www.urbanoutfitters.com/search',
    actionUrl: 'https://www.urbanoutfitters.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Kyliecosmetics',
    url: 'https://kyliecosmetics.com/en-us/search',
    actionUrl: 'https://kyliecosmetics.com/en-us/search?query=',
    searchKey: 'query',
    icons: assetsIcons.pinterest
  },

  {
    name: 'Bestbuy',
    url: 'https://www.bestbuy.com/site/searchpage.jsp',
    actionUrl: 'https://www.bestbuy.com/site/searchpage.jsp?st=',
    searchKey: 'st',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Uniqlo',
    url: 'https://www.uniqlo.com/us/en/search',
    actionUrl: 'https://www.uniqlo.com/us/en/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Snapdeal',
    url: 'https://www.snapdeal.com/search',
    actionUrl: 'https://www.snapdeal.com/search?keyword=',
    searchKey: 'keyword',
    icons: assetsIcons.pinterest
  },

  {
    name: 'Awwwards',
    url: 'https://www.awwwards.com/inspiration/search',
    actionUrl: 'https://www.awwwards.com/inspiration/search?text=',
    searchKey: 'text',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Cssdesignawards',
    url: 'https://www.cssdesignawards.com/search',
    actionUrl: 'https://www.cssdesignawards.com/search?search_term=',
    searchKey: 'search_term',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Canva',
    url: 'https://www.canva.com/search/templates',
    actionUrl: 'https://www.canva.com/search/templates?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Investing',
    url: 'https://www.investing.com/search/',
    actionUrl: 'https://www.investing.com/search/?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Investopedia',
    url: 'https://www.investopedia.com/search',
    actionUrl: 'https://www.investopedia.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Cnn',
    url: 'https://edition.cnn.com/search',
    actionUrl: 'https://edition.cnn.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Thestreet',
    url: 'https://www.thestreet.com/search',
    actionUrl: 'https://www.thestreet.com/search?query=',
    searchKey: 'query',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Marketwatch',
    url: 'https://www.marketwatch.com/search',
    actionUrl: 'https://www.marketwatch.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Seekingalpha',
    url: 'https://seekingalpha.com/search',
    actionUrl: 'https://seekingalpha.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Bloomberg',
    url: 'https://www.bloomberg.com/search',
    actionUrl: 'https://www.bloomberg.com/search?query=',
    searchKey: 'query',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Forbes',
    url: 'https://www.forbes.com/search/?q=',
    actionUrl: 'https://www.forbes.com/search/?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },

  {
    name: 'Fandom',
    url: 'https://www.fandom.com/',
    actionUrl: 'https://www.fandom.com/?s=',
    searchKey: 's',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Craigslist',
    url: 'https://tunis.craigslist.org/search/sss',
    actionUrl: 'https://tunis.craigslist.org/search/sss?query=',
    searchKey: 'query',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Aliexpress',
    url: 'https://www.aliexpress.com/wholesale',
    actionUrl: 'https://www.aliexpress.com/wholesale?SearchText=',
    searchKey: 'SearchText',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Dictionary',
    url: 'https://www.dictionary.com/misspelling',
    actionUrl: 'https://www.dictionary.com/misspelling?term=',
    searchKey: 'term',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Collins dictionary',
    url: 'https://www.collinsdictionary.com/spellcheck/english',
    actionUrl: 'https://www.collinsdictionary.com/spellcheck/english?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Economist',
    url: 'https://www.economist.com/search',
    actionUrl: 'https://www.economist.com/search?q=',
    searchKey: 'q',
    icons: assetsIcons.pinterest
  },

  {
    name: 'Republic',
    url: 'https://republic.com/companies',
    actionUrl: 'https://republic.com/companies?search=',
    searchKey: 'search',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Forentrepreneurs',
    url: 'https://www.forentrepreneurs.com/',
    actionUrl: 'https://www.forentrepreneurs.com/?s=',
    searchKey: 's',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Crypto news',
    url: 'https://crypto.news/',
    actionUrl: 'https://crypto.news/?s=',
    searchKey: 's',
    icons: assetsIcons.pinterest
  },

  {
    name: 'Cointelegraph',
    url: 'https://cointelegraph.com/search',
    actionUrl: 'https://cointelegraph.com/search?query=',
    searchKey: 'query',
    icons: assetsIcons.pinterest
  },

  ///Sepecial search method
  {
    name: 'Myntra',
    url: 'https://www.myntra.com/',
    actionUrl: 'https://www.myntra.com/',
    searchKey: '',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Dribbble',
    url: 'https://dribbble.com/search/',
    actionUrl: 'https://dribbble.com/search/',
    searchKey: '',
    icons: assetsIcons.pinterest
  },
  {
    name: 'kiplinger',
    url: 'https://www.kiplinger.com/search/',
    actionUrl: 'https://www.kiplinger.com/search/',
    searchKey: '',
    icons: assetsIcons.pinterest
  },
  {
    name: 'Apple',
    url: 'https://www.apple.com/us/search/',
    actionUrl: 'https://www.apple.com/us/search/',
    searchKey: '',
    icons: assetsIcons.pinterest
  }
  */
